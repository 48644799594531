import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpParams } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
// import $ from 'jquery';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css'],
})
export class ActivityLogComponent implements OnInit {
  filterForm: FormGroup;
  dateError: boolean = false;
  errorMsg: string = '';
  today = new Date();
  config;
  p: any = 1;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'activityTypeId',
    textField: 'activityTypeName',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 2,

    // allowSearchFilter: true
  };
  dropdownList: any = [];
  logActivities: any = {};
  pageSize: any = 10;
  pageData: any;
  pageNo: any = 1;
  lastPage: boolean = true;
  totalPage: number = 0;
  loader:any=''
  constructor(
    private service: HttpService,
    private formBuilder: FormBuilder,
    private toaster: ToastrManager,
    public permission : PermissionService
  ) {}

  ngOnInit(): void {
    this.newForm();
    this.disableFutureDate();
    this.getActivityType();
    this.getLogActivities();
    setTimeout(() => {
      this.disable();
    }, 1000);
    // this.emptylog()
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: '',
      sortBy: '',
      sortDirection: '',
    };
  }

  newForm() {
    this.filterForm = this.formBuilder.group({
      startDate: new FormControl('', []),
      endDate: new FormControl('', []),
      activityTypeId: new FormControl('', []),
    });
  }

  validateDate() {
    let fromDate = this.filterForm.value.startDate;
    let toDate = this.filterForm.value.endDate;
    if (fromDate != null && toDate != null) {
      if (new Date(fromDate) > new Date(toDate)) {
        this.dateError = true;
        this.toaster.errorToastr('Please select a valid From-Date and To-Date');
      } else {
        this.dateError = false;
      }
    }
  }

  disableFutureDate() {
    //   var date = new Date();
    //   var Month = date.getMonth()+1;
    //   var Date = date.getDate();
    //   var Year = date.getFullYear();
    //   var current =
    // document.getElementById('endDate').setAttribute('max','')
  }
  fromDateValidation(event) {
    if (
      this.filterForm.value.endDate != '' &&
      this.filterForm.value.endDate != null &&
      this.filterForm.value.endDate != undefined &&
      this.filterForm.value.startDate != '' &&
      this.filterForm.value.startDate != null &&
      this.filterForm.value.startDate != undefined
    ) {
      if (
        moment(new Date(this.filterForm.value.endDate)).isBefore(
          moment(new Date(this.filterForm.value.startDate))
        )
      ) {
        this.filterForm.get('endDate').setErrors({ invalid: true });
        this.toaster.errorToastr(
          'Please choose the To date greater than From date!'
        );
      } else {
        this.filterForm.get('endDate').setErrors(null);
      }
    }
  }

  toDateValidation(event) {
    if (
      this.filterForm.value.endDate != '' &&
      this.filterForm.value.endDate != null &&
      this.filterForm.value.endDate != undefined &&
      this.filterForm.value.startDate != '' &&
      this.filterForm.value.startDate != null &&
      this.filterForm.value.startDate != undefined
    ) {
      if (
        moment(new Date(this.filterForm.value.endDate)).isBefore(
          moment(new Date(this.filterForm.value.startDate))
        )
      ) {
        this.filterForm.get('endDate').setErrors({ invalid: true });
        this.toaster.errorToastr(
          'Please choose the To date greater than From date!'
        );
      } else {
        this.filterForm.get('endDate').setErrors(null);
      }
    }
  }
  // initial get call

  getActivityType() {
    this.service.get('/admin/activityLog').subscribe((res: any) => {
      this.dropdownList = res.Content;
    });
  }

  // search  and filter call
  getLogActivities() {
    let form = this.filterForm.value;
    let array = [];
    if (form.activityTypeId != '' && form.activityTypeId != null) {
      form.activityTypeId.forEach((element) => {
        array.push(JSON.stringify(element?.activityTypeId));
      });
    }
this.loader='show'
    let params = new HttpParams({
      fromObject: {
        startDate:
          form.startDate != '' && form.startDate != null ? form.startDate : [],
        endDate: form.endDate != '' && form.endDate != null ? form.endDate : [],
        activityTypeId:
          form.activityTypeId != '' && form.activityTypeId != null ? array : [],
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      },
    });
    this.service
      .getData('/activityLogList/searchAll', params)
      .subscribe((result: any) => {
        this.logActivities = result.content;
        this.pageData = result;
        setTimeout(() => {
          this.loader=''
        }, 300);
      },(error:any)=>{
        this.logActivities=[];
        this.pageData='';
        this.loader=''
      });
  }
  emptylog() {
    let form = this.filterForm.value;
    if (
      (form.endDate != null || form.endDate != '') &&
      (form.startDate != null || form.startDate != '') &&
      (form.activityTypeId != null || form.activityTypeId != '')
    ) {
      this.pageNo = 1;
      this.getLogActivities();
    }
  }

  filterRequired() {
    let form = this.filterForm.value;
    if (
      (form.endDate != null && form.endDate != '') ||
      (form.startDate != null && form.startDate != '') ||
      (form.activityTypeId != null && form.activityTypeId != '')
    ) {
      this.pageNo = 1;
      this.getLogActivities();
    } else {
      // this.getLogActivities()
      this.emptylog();
      this.toaster.errorToastr('Please choose atleast one field to filter!');
    }
  }

  resetLog() {
    this.filterForm.reset();
    this.pageNo = 1;
    this.getLogActivities();
  }
  emptyObject() {
    if (Object.keys(this.logActivities).length != 0) {
      return false;
    } else {
      return true;
    }
  }

  pageChange(event) {
    this.pageNo = event;
    this.getLogActivities();
  }

  paginate(data) {
    if (data === 'dec') {
      // if(Object.keys(this.logActivities).length!=0){
      this.pageNo == 1 ? 1 : this.pageNo--;

      this.getLogActivities();
    }
    if (data === 'inc') {
      if (Object.keys(this.logActivities).length != 0) {
        this.pageNo++;
        this.getLogActivities();
      }
    }
  }

  pTag(p) {
    if (p == 'add') {
      this.disable();
      if (Object.keys(this.logActivities).length != 0) {
        this.pageNo++;
        this.getLogActivities();
        this.lastPage = true;
      } else {
        this.lastPage = false;
      }
    } else {
      if (this.pageNo != 1) {
        this.pageNo--;
        this.getLogActivities();
      }
    }
  }

  disable() {
    let data = Math.ceil(
      this.pageData?.totalPages / this.pageData?.currentPageSize
    );
    this.totalPage = data;

    if (data == this.pageNo) {
      return data;
    } else {
      return data;
    }
  }
  changePage() {
    this.pageNo = this.totalPage;
    this.getLogActivities();
  }
}
