import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ReportDTO } from '../_services/report.dto';
import { ReportService } from '../_services/report.service';
import * as moment from 'moment';
import { checkNull, queryMaker } from 'src/app/constants/custom-functions';
import { PuppysCustomValidation, sweetAlert } from 'src/app/shared/validations/puppys-custom';

@Component({
  selector: 'app-enquiry-report',
  templateUrl: './enquiry-report.component.html',
  styleUrls: ['./enquiry-report.component.css']
})
export class EnquiyReportComponent implements OnInit {



  filterForm: FormGroup;
  pagination: ReportDTO;
  reports: any[] = []

  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false
  }

  formType = [
    { view: 'General', value: 'General' },
    { view: 'Custom Cakes', value: 'customCake' },
    { view: 'Dessert Table', value: 'dessertTable' },
    { view: 'Wedding Cake', value: 'weddingCake' },
  ]
  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.pagination = new ReportDTO()
  }

  ngOnInit(): void {
    this.intialForm()
  }



  intialForm() {
    this.filterForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      formName: new FormControl('')
    }, { validators: PuppysCustomValidation.DateValidator('startDate', 'endDate') })
  }






  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }


  reset() {
    this.intialForm()
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = []
  }

  checkNull(data) {
    return checkNull(data)
  }

  getFormType(type: any) {
    return this.formType.find((ele) => ele?.value == type).view ?? 'General'
  }

  getEnquiryReport(filter?: boolean) {

    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter && false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the enquiry report!')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form.formName)) {
        this.pagination.formName = form.formName.map((ele) => ele?.value)
      } else {
        this.pagination.formName = ''
      }

      console.log(this.pagination);

      this.loader.open()
      this.service.getAllEnquiries(this.pagination).subscribe((res: any) => {
        this.loader.close();
        this.reports = res?.content ?? [];

      }, (err: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again');
        this.reports = []
      })
    } else {
      sweetAlert('error', 'Start date should be before End date')
    }


  }

  export() {
    let form = this.filterForm.value
    if (false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the GST report!')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form.formName)) {
        this.pagination.formName = form.formName.map((ele) => ele?.value)
      } else {
        this.pagination.formName = ''
      }
      this.service.exportReport(`/contactus/report/export${queryMaker(this.pagination)}`, 'Enquiry Report')

    } else {
      sweetAlert('error', 'Start date should be before End date')
    }
  }






  // non-developed
  tableData = [
    {
      date: '12-04-2024',
      name: 'Ganesh',
      mobileNo: '9098987860',
      emailID: 'ganesh123@gmail.com',
      enquiryType: 'General',
      address: '4/57,Sanathi street,Madurai',
      cakePreference: 'Themed Cake, Beauty Women Product',
      imgReference: 'order_img.svg',
      deliveryDateTime: '12-04-2024, 12.30PM',
      subject: "Its Looking very tasty and really delicious",
      formName: 'Door Delivery',

    },
    {
      date: '12-04-2024',
      name: 'Ganesh',
      mobileNo: '9098987860',
      emailID: 'ganesh123@gmail.com',
      enquiryType: 'Wedding Cake',
      address: '4/57,Sanathi street,Madurai',
      cakePreference: 'Themed Cake, Beauty Women Product',
      imgReference: 'order_img.svg',
      deliveryDateTime: '12-04-2024, 12.30PM',
      subject: "Its Looking very tasty and really delicious",
      formName: 'Door Delivery',

    },
    {
      date: '12-04-2024',
      name: 'Ganesh',
      mobileNo: '9098987860',
      emailID: 'ganesh123@gmail.com',
      enquiryType: 'Desert Table',
      address: '4/57,Sanathi street,Madurai',
      cakePreference: 'Themed Cake, Beauty Women Product',
      imgReference: 'order_img.svg',
      deliveryDateTime: '12-04-2024, 12.30PM',
      subject: "Its Looking very tasty and really delicious",
      formName: 'Door Delivery',

    },
    {
      date: '12-04-2024',
      name: 'Rajesh',
      mobileNo: '9098987860',
      emailID: 'raj@gmail.com',
      enquiryType: 'Customized Cake',
      address: '4A/7,Vinayaga street,Madurai',
      cakePreference: 'Themed Cake, Beauty Women Product',
      imgReference: 'order_img.svg',
      deliveryDateTime: '12-04-2024, 12.30PM',
      subject: "Its Looking very tasty and really delicious",
      formName: 'Pickup From Store',

    },
    {
      date: '22-04-2024',
      name: 'Mahi',
      mobileNo: '9878765660',
      emailID: 'mahii@gmail.com',
      enquiryType: 'General',
      address: '4/57,cross street,Madurai',
      cakePreference: 'Themed Cake, Beauty Women Product',
      imgReference: 'order_img.svg',
      deliveryDateTime: '12-04-2024, 12.30PM',
      subject: "Its Looking very tasty and really delicious",
      formName: 'Pickup From Store',

    },
    {
      date: '26-04-2024',
      name: 'Sankar',
      mobileNo: '9098987860',
      emailID: 'sankar@gmail.com',
      enquiryType: 'General',
      address: '4/57,Sanathi street,Madurai',
      cakePreference: 'Themed Cake, Beauty Women Product',
      imgReference: 'order_img.svg',
      deliveryDateTime: '12-04-2024, 12.30PM',
      subject: "Its Looking very tasty and really delicious",
      formName: 'Door Delivery',

    },

  ];
}
