

<section class="offset-width well-white">
    <app-header [pageName]="'Order Issues'"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>


    <div class="container-fluid">
        <h5>Order Issues</h5>
        <br>
        <div class="card bg-table-color">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-9">
                    </div>
                    <div class="col-lg-3">
                        <select style="background-color: #fff;" class="form-control bg-table-color" #statusType (change)="getChange(statusType.value)">
                            <option value="all_status">All issue</option>
                            <option value="open">Open issue</option>
                            <option value="closed">Closed issue</option>
                        </select><br>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">USER NAME</th>
                                <th scope="col">POSTED ON</th>
                                <th scope="col">ORDER ID</th>
                                <th scope="col">COMMENTS</th>
                                <th scope="col">STATUS</th>
                                <th scope="col">ACTION</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let item of getdata | paginate: config;let index = index">
                            <td>{{item.userName | titlecase }}</td>
                            <td>{{item.createdOn | date: 'dd-MM-yyyy, h:mm a' }}</td>
                            <td><a routerLink="/order-detail/{{item.orderId}}" target="_blank">
                                    {{item.orderId}} </a></td>
                            <td style="width: 25%;">{{item.comments}}</td>
                            <td>{{item.status | titlecase }}</td>
                            <td class="text-center">
                                <button *ngIf="permission.hasPermission(['order_issue'],['orderIssue_close']) && item.status == 'open'" class="btn-reject" 
                                    (click)="viewReport(item, 'create'); ">Close this report</button>
                                <button *ngIf="permission.hasPermission(['order_issue'],['orderIssue_view']) && item.status == 'closed'" class="btn-approve" 
                                    (click)="viewReport(item, 'view')">View</button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="pagination-align">
                <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
                    screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                    screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
            </div>
        </div>
    </div>









    <!-- Modal -->
    <div class="modal fade" id="reportModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Order Issues</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"  (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <table class="table">
                        <tbody>
                            <tr>
                                <td>User Name :</td>
                                <td>{{modalData?.userName | titlecase}}</td>
                            </tr>
                            <tr>
                                <td>Posted On :</td>
                                <td>{{modalData?.createdOn | date: 'dd-MM-yyyy, h:mm a' }}</td>
                            </tr>
                            <tr>
                                <td>Order Id :</td>
                                <td>{{modalData?.orderId}}</td>
                            </tr>
                            <tr>
                                <td>Status :</td>
                                <td>{{modalData?.status}}</td>
                            </tr>
                            <tr>
                                <td>User Comments :</td>
                                <td>{{modalData?.comments}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <label>Admin Reply <span style="color:red;">*</span></label>
                                    <textarea placeholder="Enter Reply" #reply
                                        [ngClass]="{'dis-text': disTextBox === true }" class="form-control"
                                        rows="3">{{modalData?.adminComments}}</textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--end modal body-->
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary modal-cls" data-dismiss="modal" (click)="close()">Close</button>
                    <button type="button" class="btn modal-sub" *ngIf="!disTextBox"
                        (click)="changeStatus(reply.value, modalData.id )">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <!--End Modal-->





</section>