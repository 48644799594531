<section class="offset-width well-white">
    <div *ngIf="loader" class="loader-spin"> </div>
    <app-header [pageName]="'Banner'"></app-header>
    <div class="container-fluid">
        <h5>Banner</h5>
        <div class="row ">
            <div class="text-end">
                <button type="button" *ngIf="rolepermission.hasPermission(['banner'],['banner_create'])" (click)="createBanner()"
                class="button-1 btn-20 btn-pink btn-margin"> <i class="fa fa-plus"></i> Create Banner </button>
            </div>
         
        </div>
        <br>
        <div class="row">
            <div class="card col-12 table-bg bg-table-color">
                <div class="card-body ">
                    <br>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">VIEW</th>
                                    <th scope="col">BANNER NAME </th>
                                    <th scope="col">BANNER CLICKABLE </th>
                                    <th scope="col">CREATED DATE AND TIME</th>
                                    <th scope="col">SORT ORDER</th>
                                    <th scope="col" *ngIf="rolepermission.hasPermission(['banner'],['banner_update','banner_delete'])">ACTION</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of getdata;let index = index">
                                <tr>
                                    <td>{{index + 1}} </td>
                                    <td (click)="showSubItem(index, item.image)"> <i class="fa fa-expand pointer"
                                            style="color:#FD7297"></i> </td>
                                    <td> {{item.bannerTypeName}} <a *ngIf="item?.bannerUrl" target="_blank" class="pl-2" href="{{item?.bannerUrl}}"><i class="fa fa-link"></i></a> </td>
                                    <td>
                                        <span *ngIf=" item.isClickable == true ">Yes</span>
                                        <span *ngIf=" item.isClickable == false ">No</span>
                                    </td>
                                    <td>{{item.createdOn | date: 'dd-MM-yyyy, h:mm a' }}</td>
                                    <td>{{item.sortOrder}}</td>
                                    <td *ngIf="rolepermission.hasPermission(['banner'],['banner_update','banner_delete'])">
                                        <img (click)="onClickEdit(item)" *ngIf="rolepermission.hasPermission(['banner'],['banner_update'])" class="eye-icon pointer"
                                            src="/assets/images/icon/pencil-2.svg"> <i *ngIf="rolepermission.hasPermission(['banner'],['banner_delete'])"
                                            (click)="deleteData(item.bannerId)" class="fa fa-trash btn-del"></i>
                                    </td>
                                </tr>
                                <tr *ngIf="visibleIndex === index">
                                    <td colspan="1"></td>
                                    <td colspan="2">
                                        <img style="max-width: 250px;" src="{{imgPth+showImg}}">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>







    <!-- Modal Popup-->
    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form [formGroup]="BannerForm">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"><span>{{isEdited==true ? "Update" : "Create"}}</span> banner</h5>
                        <button type="button" class="close" data-dismiss="createModal" aria-label="Close" (click)="closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label>Banner Type <span style="color: red;">*</span></label><br>
                        <select formControlName="bannerType" class="form-control" #bannerVal
                            (change)="bannerTypeChange(bannerVal.value);valueChange(bannerVal.value);onEditSelect()" >
                            <option value=""> </option>
                            <option value="index_slider_1" >Home Page First Slider</option>
                            <option value="index_Baking_1">What’s Baking in puppy’s?</option>
                            <option value="index_slider_2">Design My Cake Slider</option>
                            <!-- <option value="index_cake_design">Dessert Table & Wedding Cakes</option> -->
                        </select>
                        <div *ngIf="submitted && f.bannerType.errors" class="invalid-text">
                            <div *ngIf="f.bannerType.errors.required">
                                Banner Type is required
                            </div>
                        </div><br>


                        <div class="row">
                            <div class="col-lg-6">
                                <label>Sort Order <span style="color: red;">*</span></label><br>
                                <input type="number" class="form-control" formControlName="sortOrder"
                                    placeholder="Enter Sort Order">
                                <div *ngIf="submitted && f.sortOrder.errors" class="invalid-text">
                                    <div *ngIf="f.sortOrder.errors.required">
                                        Sort Order Type is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <br> <br>
                                <input type="checkbox" (change)="setvalidate($event)" [(ngModel)]="BannerCheckbox" formControlName="isClickable"
                                    class="clickable-checkbox"> <span class="label-checkbox"> Is Image Clickable</span>
                                    
                            </div>
                        </div><br>


                        <div *ngIf="BannerCheckbox">
                            <div>
                                <label> Website URL <span style="color: red;">*</span></label><br>
                                <!-- <select class="form-control" formControlName="redirectId">
                                    <option value=""></option>
                                    <option *ngFor="let item of getdata4" value="{{item.categoryId}}">
                                        {{item.categoryName}}</option>
                                </select><br> -->
                                <input type="text" class="form-control" formControlName="bannerUrl"
                                placeholder="Enter Website banner URL">
                                <div *ngIf="submitted && f.bannerUrl.errors" class="invalid-text">
                                    <div *ngIf="f.bannerUrl.errors.required">
                                        Website banner url  is required
                                    </div>
                                    <div *ngIf="f.bannerUrl.errors.pattern">
                                        website banner url is invalid
                                    </div>
                                </div>
                            </div><br>

                            <div>
                                <label>Mobile View URL <span style="color: red;">*</span></label><br>
                                
                                <input type="text" class="form-control" formControlName="bannerUrlMob" placeholder="Enter Mobile banner URL">
                                <div *ngIf="submitted && f.bannerUrlMob.errors" class="invalid-text">
                                    <div *ngIf="f.bannerUrlMob.errors.required">
                                        Mobile banner url is required
                                    </div>
                                    <div *ngIf="f.bannerUrlMob.errors.pattern">
                                        Mobile banner url is invalid
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div *ngIf="bannerDetailDiv" formGroupName="bannerDetails"> <br>

                            <label>Banner Heading</label><br>
                            <input type="text" class="form-control" formControlName="heading"
                                placeholder="Enter Banner Heading "><br>

                            <label>Banner Content</label><br>
                            <input type="text" class="form-control" formControlName="content"
                                placeholder="Enter Banner Content"><br>

                            <label>Button Name</label><br>
                            <input type="text" class="form-control" formControlName="buttonName"
                                placeholder="Enter Button Name"><br>
                        </div>
                        <div><br>
                            <input type="file" id="upload-degn" [disabled]="BannerForm.controls['bannerType'].value==null||BannerForm.controls['bannerType'].value==''" #file (change)="uploadFile($event)" accept="image/*"
                                style="display:none">
                            <label for="upload-degn" *ngIf="!imgUrl">
                                <span class="btn btn-file-upload">Choose Image </span>
                            </label>
                        <br>
                        <div class="banner-note" #val1 *ngIf="val1 && bannerVal.value!=''">
                            <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
                            <br>
                            <span class="file-formate">Image Size Should not be( width:1519px X height:465px )  </span>
                        </div>
                        <div class="whatsdo-note" #val2  *ngIf="val2 && bannerVal.value!=''">
                            <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
                            <br>
                            <span class="file-formate">Image Size Should be ( width:650px X height:544px )  </span>
                        </div>
                        <div class="special-desert" #val3 *ngIf="val3 && bannerVal.value!=''">
                            <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
                            <br>
                            <span class="file-formate">Image Size Should be( width:1200 X height:1200 )  </span>
                        </div>
                        



                            <div *ngIf="imgUrl" class="mt-2">
                                <i class="fa fa-trash del-icons" aria-hidden="true" (click)="deleteImage()"></i>
                                <img src="{{imgPth+imgUrl}}" class="img img-fluid">
                            </div>

                            <div *ngIf="submitted && f.image.errors" class="invalid-text">
                                <div *ngIf="f.image.errors.required">
                                    Banner Image is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="closeModal()">Close</button>
                        <button type="button" (click)="onSubmit()" class="button-1 btn-pink"> Submit
                            <i style="margin-left: 4px;" class="fa fa-long-arrow-right"></i> </button>
                    </div>
                </form>
            </div>
        </div>
    </div>




</section>