import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-user-privilages',
  templateUrl: './user-privilages.component.html',
  styleUrls: ['./user-privilages.component.css']
})
export class UserPrivilagesComponent implements OnInit {

  loader = '';
  isEdited = false;
  adminAccess

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private route: ActivatedRoute,
    private router: Router,
    public permission: PermissionService
  ) { }

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.loader = '';
    this.emptyForm();
    this.get();
  }


  getdata2
  getdata
  //get role list
  get() {
    this.loader = 'show';
    this.http.get('/admin/roles').subscribe(
      data => {
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        this.loader = '';
      },
      error => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      });
  }
close(){
  $('#createModal').modal('hide')
}

  form: FormGroup;
  submitted
  emptyForm() {
    this.submitted = false;
    this.form = this.formBuilder.group({
      id: [],
      active: [true],
      role: ['', Validators.required],
      // privileges: new FormGroup({
      //   approvalRead: new FormControl(false),
      //   approvalWrite: new FormControl(false),
      //   couponRead: new FormControl(false),
      //   couponWrite: new FormControl(false),
      //   homePageRead: new FormControl(false),
      //   homePageWrite: new FormControl(false),
      //   catalogueRead: new FormControl(false),
      //   catalogueWrite: new FormControl(false),
      //   blogRead: new FormControl(false),
      //   blogWrite: new FormControl(false),
      //   deliveryRead: new FormControl(false),
      //   deliveryWrite: new FormControl(false),
      //   newsLetterRead: new FormControl(false),
      //   newsLetterWrite: new FormControl(false),
      //   userRead: new FormControl(false),
      //   userWrite: new FormControl(false),
      //   homePageNotificationRead: new FormControl(false),
      //   homePageNotificationWrite: new FormControl(false),
      //   deliveryChargeRead: new FormControl(false),
      //   deliveryChargeWrite: new FormControl(false),
      //   reviewRead: new FormControl(false),
      //   reviewWrite: new FormControl(false),
      //   loyaltyRead: new FormControl(false),
      //   loyaltyWrite: new FormControl(false),
      //   privilagesRead: new FormControl(false),
      //   privilagesWrite: new FormControl(false),
      //   dashboardRead: new FormControl(false),
      //   dashboardWrite: new FormControl(false),
      //   instagramRead: new FormControl(false),
      //   instagramWrite: new FormControl(false),
      //   orderIssueRead: new FormControl(false),
      //   orderIssueWrite: new FormControl(false),
      //   pricingUpdateRead: new FormControl(false),
      //   pricingUpdateWrite: new FormControl(false),
      // })
    });
  }



  ///////////////////////edit role///////////////////////////
  onClickCreate() {
    $('#createModal').modal('show');
    this.emptyForm();
    this.isEdited = false
  }

  onClickEdit(item) {
    this.isEdited = true;
    $('#createModal').modal('show');
    this.form = this.formBuilder.group({
      id: [item.id],
      active: [true],
      role: [item.role, Validators.required],
      // privileges: new FormGroup({
      //   approvalRead: new FormControl(item.privileges.approvalRead),
      //   approvalWrite: new FormControl(item.privileges.approvalWrite),
      //   couponRead: new FormControl(item.privileges.couponRead),
      //   couponWrite: new FormControl(item.privileges.couponWrite),
      //   homePageRead: new FormControl(item.privileges.homePageRead),
      //   homePageWrite: new FormControl(item.privileges.homePageWrite),
      //   catalogueRead: new FormControl(item.privileges.catalogueRead),
      //   catalogueWrite: new FormControl(item.privileges.catalogueWrite),
      //   blogRead: new FormControl(item.privileges.blogRead),
      //   blogWrite: new FormControl(item.privileges.blogWrite),
      //   deliveryRead: new FormControl(item.privileges.deliveryRead),
      //   deliveryWrite: new FormControl(item.privileges.deliveryWrite),
      //   newsLetterRead: new FormControl(item.privileges.newsLetterRead),
      //   newsLetterWrite: new FormControl(item.privileges.newsLetterWrite),
      //   userRead: new FormControl(item.privileges.userRead),
      //   userWrite: new FormControl(item.privileges.userWrite),
      //   homePageNotificationRead: new FormControl(item.privileges.homePageNotificationRead),
      //   homePageNotificationWrite: new FormControl(item.privileges.homePageNotificationWrite),
      //   deliveryChargeRead: new FormControl(item.privileges.deliveryChargeRead),
      //   deliveryChargeWrite: new FormControl(item.privileges.deliveryChargeWrite),
      //   reviewRead: new FormControl(item.privileges.reviewRead),
      //   reviewWrite: new FormControl(item.privileges.reviewWrite),
      //   loyaltyRead: new FormControl(item.privileges.loyaltyRead),
      //   loyaltyWrite: new FormControl(item.privileges.loyaltyWrite),
      //   privilagesRead: new FormControl(item.privileges.privilagesRead),
      //   privilagesWrite: new FormControl(item.privileges.privilagesWrite),
      //   dashboardRead: new FormControl(item.privileges.dashboardRead),
      //   dashboardWrite: new FormControl(item.privileges.dashboardWrite),
      //   instagramRead: new FormControl(item.privileges.instagramRead),
      //   instagramWrite: new FormControl(item.privileges.instagramWrite),
      //   orderIssueRead: new FormControl(item.privileges.orderIssueRead),
      //   orderIssueWrite: new FormControl(item.privileges.orderIssueWrite),
      //   pricingUpdateRead: new FormControl(item.privileges.pricingUpdateRead),
      //   pricingUpdateWrite: new FormControl(item.privileges.pricingUpdateWrite),
      // })
    });
  }

  //delete role
  deleteRole(id) {
    this.loader = 'show';
    this.http.delete('/admin/roles/' + id).subscribe(
      data => {
        this.loader = '';
        this.toastr.successToastr('Deleted Successfully', '');
        this.get();
      },
      error => {
        this.loader = '';
        this.toastr.errorToastr(error.message, '');
      });
  }



  get f() { return this.form.controls; }
  //onsubmit create or edit roles privilages
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.isEdited == true) {
      this.loader = 'show';
      this.http.put('/admin/roles/' + this.form.controls["id"].value, this.form.value).subscribe(
        data => {
          this.loader = '';
          this.toastr.successToastr('Success', '');
          this.emptyForm();
          $('#createModal').modal('hide');
          this.get();
        },
        error => {
          this.loader = '';
          this.toastr.errorToastr(error.message, '');
        });

    }

    else {
      this.loader = 'show';
      this.http.post('/admin/roles', this.form.value).subscribe(
        data => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          $('#createModal').modal('hide');
          this.get();
        },
        error => {
          this.loader = '';
          this.toastr.errorToastr(error.message, '');
        });
    }
  }


}
