import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from './services/core/config.service';
import { NavigationService } from './services/core/navigation.service';
import { PermissionService } from './services/core/permission.service';
import { getToken } from './services/helpers/token.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'puppys-admin';

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private permSer: PermissionService,
    private navSer: NavigationService,
    private configService: ConfigService
  ) {
    this.actRoute.paramMap.subscribe((params) => {
      this.showNav();
    });
    this.setPrefernce();
  }

  showNav() {
    if (this.router.url == '/' || this.router.url == '/login') {
      return false;
    } else {
      return true;
    }
  }

  setPrefernce() {
    if (localStorage.getItem('currentUser')) {
      /* set preference */
      this.configService.init(); 
      console.log(this.configService,'this.configService')
      let roleId = this.configService.roleId;
      let permission = this.configService.permission;
      // handle permission based menu
      this.permSer.init(Number(roleId), permission);
      let authMenu = this.permSer.authMenu;
      console.log(authMenu, 'authMenu');
      this.navSer.publish(authMenu);
    }
  }
}
