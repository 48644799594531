import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
declare var $: any;
import { AppConstants } from '../../constants/app.constants';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { PermissionService } from 'src/app/services/core/permission.service';
import { constant } from 'src/app/services/helpers/global.helper';

@Component({
  selector: 'app-delivery-time',
  templateUrl: './delivery-time.component.html',
  styleUrls: ['./delivery-time.component.css'],
})
export class DeliveryTimeComponent implements OnInit {
  isEdited;
  loader;
  adminAccess;
  submitted = false;
  imgPth;

  pageTypes = [
    { label: 'Home', value: 'home' },
    { label: 'Desert', value: 'desert' },
    { label: 'Wedding', value: 'wedding' },
    { label: 'Custom', value: 'custom' },
  ];

  enableCutOff = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  DeliverytimeForm: FormGroup;
  enableCutOffStatus: any;
  showCutoffPrice: boolean = false;
  fromToTime: boolean = false;
  enableCutOffTime: boolean = false;
  uniqueCode: boolean = false;
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService
  ) { }

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.imgUrl = '';
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.loader = '';
    this.submitted = false;
    this.emptyForm();
    this.get();
  }

  enableCutOf(data) {
    this.DeliverytimeForm.get('enableCutOff').markAsUntouched();
    this.DeliverytimeForm.get('enableCutOff').updateValueAndValidity();
    if (data == 'Yes') {
      this.showCutoffPrice = true;
      this.DeliverytimeForm.get('cutOffTime').setValidators(
        Validators.required
      );
      this.DeliverytimeForm.get('cutOffTime').markAsDirty();
      this.DeliverytimeForm.get('cutOffTime').updateValueAndValidity();
    } else {
      this.enableCutOffTime = false;
      this.showCutoffPrice = false;
      this.DeliverytimeForm.get('cutOffTime').clearValidators();
      this.DeliverytimeForm.get('cutOffTime').setValue('');
      this.DeliverytimeForm.get('cutOffTime').updateValueAndValidity();
    }
  }
  //form
  emptyForm() {
    this.submitted = false;
    this.DeliverytimeForm = this.formBuilder.group({
      deliveryTimeName: ['', Validators.required],
      fromTime: ['', Validators.required],
      toTime: ['', Validators.required],
      backingTime: [
        '',
        [
          Validators.required, Validators.min(0),
          Validators.pattern(constant().app.validators.integer),
        ],
      ],
      enableCutOff: ['', Validators.required],
      shortCode: ['', Validators.required],
      cutOffTime: [''],
      deliveryTimeNameID: [''],
    });
    this.DeliverytimeForm.valueChanges.subscribe((from) => {
      if (from?.fromTime != '' && from?.toTime != '') {
        if (from?.fromTime >= from?.toTime) {
          this.fromToTime = true;
        } else {
          this.fromToTime = false;
        }
        if (from?.fromTime != '' && from?.toTime != '' && from?.cutOffTime) {
          if (
            from?.fromTime >= from?.cutOffTime ||
            from?.toTime <= from?.cutOffTime
          ) {
            this.enableCutOffTime = true;
          } else {
            this.enableCutOffTime = false;
          }
        }
      }

      if (from?.deliveryTimeName != '' && from?.shortCode != '') {
        if (from?.deliveryTimeName == from?.shortCode) {
          this.uniqueCode = true;
        } else {
          this.uniqueCode = false;
        }
      }
    });
  }

  close() {
    $('#createModal').modal('hide');
  }

  getdata2;
  getdata;
  get() {
    this.loader = 'show';
    this.http.get('/admin/deliveryTime').subscribe(
      (data) => {
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  onClickCreate() {
    $('#createModal').modal('show');
    this.emptyForm();
    this.isEdited = false;
  }

  //Autofill testmonial form
  onClickEdit(item) {
    this.enableCutOffTime = false;
    this.isEdited = true;
    $('#createModal').modal('show');
    this.emptyForm();
    this.enableCutOffStatus = item.status;
    this.DeliverytimeForm.patchValue({
      deliveryTimeNameID: Number(item?.deliverytimeId),
      deliveryTimeName: item?.deliveryName,
      fromTime: item?.fromTime,
      toTime: item?.toTime,
      backingTime: item?.backingTime,
      enableCutOff: Number(item?.status),
      cutOffTime: item?.cutOffTime,
      shortCode: item?.shortCode,
    });
    // this.imgUrl = item.image;
  }

  // deleteImage() {
  //   this.fileImg = '';
  //   this.imgUrl = '';
  //   this.DeliverytimeForm.controls["image"].setValue('');
  // }

  //delete testmonial
  deleteData(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Confirm?',
      text: 'Are you sure? You want to delete it!',
      showCancelButton: true,
      confirmButtonColor: '#FD7297',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.loader = 'show';
        this.http.delete('/admin/deliveryTime/' + id).subscribe(
          (data) => {
            this.toastr.successToastr('Deleted Successfully', '');
            this.loader = '';
            this.get();
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }
        );
      }
    });
  }

  imgUrl;
  fileImg;
  fileName;
  //upload image and get url
  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //////upload file and get path///////
        if (this.fileImg) {
          const formData: FormData = new FormData();
          formData.append('image', this.fileImg, this.fileName);
          this.loader = 'show';
          this.http.post('/uploadimage', formData).subscribe(
            (data) => {
              this.path2 = data;
              this.imgUrl = this.path2.path;
              console.log(this.imgUrl);
              this.DeliverytimeForm.controls['image'].setValue(this.imgUrl);
              this.loader = '';
            },
            (error) => {
              this.loader = '';
            }
          );
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  path2;
  path;

  get f() {
    return this.DeliverytimeForm.controls;
  }

  //submit form
  onSubmit() {
    this.submitted = true;
    if (
      this.DeliverytimeForm.invalid ||
      this.fromToTime ||
      this.enableCutOffTime
    ) {
      return;
    } else {
      if (this.isEdited == true) {
        let form = this.DeliverytimeForm.value;
        let obj = {
          cutOffTime: form.cutOffTime,
          deliveryName: form.deliveryTimeName,
          deliverytimeId: form.deliveryTimeNameID,
          fromTime: form?.fromTime,
          backingTime: form?.backingTime,
          status: form?.enableCutOff,
          toTime: form?.toTime,
          shortCode: form?.shortCode,
        };
        this.loader = 'show';
        this.http.put('/admin/deliveryTimeUpdate', obj).subscribe(
          (data: any) => {
            this.loader = '';
            let msg =
              data?.success == true
                ? 'Successfully created '
                : String(data?.message);
            this.toastr.successToastr(msg, '');
            this.emptyForm();
            $('#createModal').modal('hide');
            this.get();
          },
          (error: any) => {
            console.log(error, 'while creating------------>');

            let msg = error?.message ? String(error?.message) : 'Try Again';
            this.loader = '';
            this.toastr.errorToastr(msg, '');
          }
        );
      } else {
        let form = this.DeliverytimeForm.value;
        this.loader = 'show';
        let obj = {
          cutOffTime: form.cutOffTime,
          deliveryName: form.deliveryTimeName,
          fromTime: form.fromTime,
          status: form.enableCutOff,
          backingTime: form?.backingTime,
          toTime: form.toTime,
          shortCode: form?.shortCode,
        };

        this.http.post('/admin/deliveryTimeAdd', obj).subscribe(
          (data: any) => {
            this.loader = '';
            console.log(data, 'deliveryTimeAdd');
            let msg =
              data?.success == true
                ? 'Successfully created '
                : String(data?.message);
            this.toastr.successToastr(msg, '');
            this.emptyForm();
            this.get();
            $('#createModal').modal('hide');
          },
          (error: any) => {
            let msg = error?.message ? String(error?.message) : 'Try Again';
            this.loader = '';
            this.toastr.errorToastr(msg, '');
          }
        );
      }
    }
  }

  showImg;
  visibleIndex = -1;
  showSubItem(ind, img) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
      this.showImg = '';
    } else {
      this.visibleIndex = ind;
      this.showImg = img;
    }
  }

  pageChange(e) { }
}
