import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ReportDTO } from './report.dto';
import { HttpParams } from '@angular/common/http';
import { checkNull, queryMaker } from 'src/app/constants/custom-functions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(
    private http: HttpService,
    private toaster: ToastrManager,
    private loader: LoaderService
  ) {}

  // Product Report

  getParentCategories() {
    return this.http.get(`/admin/category/all?type=user`);
  }

  getAllCustomers() {
    return this.http
      .get(`/admin/getAllUser`)
      .pipe(map((ele) => adaptCustomers(ele)));
  }

  getAllProducts() {
    return this.http
      .get(`/admin/product/Pagination?pageNo=1&pageSize=2500`)
      .pipe(map((ele) => adaptProducts(ele)));
  }

  getProductReport(dto: ReportDTO) {
    let query = queryMaker(dto);
    return this.http.getData(`/productReport/searchAll${query}`);
  }

  // Customer Report
  getCustomerReport(dto: ReportDTO) {
    let query = queryMaker(dto);
    return this.http.getData(`/customer/report/searchAll${query}`);
  }

  // GST Report
  getGSTReport(dto: ReportDTO) {
    let params = new HttpParams();
    for (let data in dto) {
      checkNull(dto[data]) ? (params = params.append(data, dto[data])) : '';
    }
    return this.http.getData(`/gst/report/searchAll`, params);
  }

  // Coupon summary report
  getCouponSummaryReport(dto: ReportDTO) {
    let query = queryMaker(dto);
    return this.http.getData(`/coupon/summary/report/searchAll${query}`);
  }

  // Coupon transaction report
  getCouponTransactionReport(dto: ReportDTO) {
    let query = queryMaker(dto);
    return this.http.getData(`/coupon/transaction/report/searchAll${query}`);
  }

  // customer enquiry report

  getAllEnquiries(dto: ReportDTO) {
    let query = queryMaker(dto);
    return this.http.getData(`/contactus/report/searchAll${query}`);
  }

  // Product sale report

  getSoldProducts(dto: ReportDTO) {
    let query = queryMaker(dto);
    return this.http.getData(`/productReport/searchAllPopularitywise${query}`);
  }

  exportReport(endpoint: string, fileName: any, pagination?: any) {
    let params = new HttpParams();

    for (let data in pagination) {
      if (checkNull(pagination[data])) {
        params = params.append(data, pagination[data]);
      }
    }
    this.loader.open();
    this.http.getData(endpoint, params).subscribe(
      (data: any) => {
        console.log(data);

        this.loader.close();
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = fileName;
        a.href = data?.path;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      },
      (error) => {
        this.loader.close();
        console.log('error occured-------------->');
        this.toaster.errorToastr('could not download', '');
      }
    );
  }
}

function adaptCustomers(data: any) {
  return data.Content.map((ele) => {
    return {
      view: ele?.name + ' - ' + ele?.phoneNumber,
      value: ele?.id,
    };
  });
}

function adaptProducts(data: any) {
  return data.content.map((ele) => {
    return {
      view: ele?.sku + ' - ' + ele?.productName,
      value: ele?.productId,
    };
  });
}
