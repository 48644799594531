<section class="offset-width well-white">
    <app-header [pageName]="'Products'" [pageNamethree]="''"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>
    <form [formGroup]="productForm">
        <!--container-->
        <div class="container-fluid">
            <h5>{{isUpdatePage?'Update':'Create'}} Product</h5>

            <div class="card-design">
                <h5 class="section-title mb-0">Cake Details</h5> <br>
                <div class="row align-items-end">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <label class="req">Cake Name</label>
                        <input type="text" class="form-control mrgn-btm input_style" formControlName="productName"
                            placeholder="Enter Cake Name">
                        <div *ngIf="form.productName.touched&&form.productName.invalid" class="invalid-text">

                            <div *ngIf="form.productName.errors?.required">
                                Please provide the cake name
                            </div>
                        </div>
                    </div>

                    <!-- Main Category -->
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <label class="req">Parent Categories </label>
                        <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                        <select formControlName="parentCategory" #parentCategory (change)="changeSubCategory($event)"
                            class="form-control mrgn-btm input_style">
                            <option value='' selected>Select Parent Categories</option>
                            <option *ngFor="let item of parentCategories" value="{{item.categoryId}}">
                                {{item.categoryName}} - {{item?.shortCode}}</option>
                        </select>
                        <div *ngIf="form.parentCategory.touched&&form.parentCategory.invalid" class="invalid-text">
                            <div>
                                Please provide the parent category
                            </div>
                        </div>
                    </div>

                    <!-- Sub Category -->
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="type=='product'">
                        <label [ngClass]="form?.subCategory.disabled ? '' :'req'">Sub Categories </label>
                        <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                        <select class="form-control mrgn-btm input_style" formControlName="subCategory">
                            <option value=''>Select Sub Categories</option>
                            <option *ngFor="let item of childCategories" value="{{item.categoryId}}">
                                {{item.categoryName}} - {{item?.shortCode}}</option>
                        </select>
                        <div *ngIf="form.subCategory.touched&&form.subCategory.invalid" class="invalid-text">
                            <div>
                                Please select the subcategory
                            </div>
                        </div>
                    </div>

                    <!-- Sort Order -->
                    <div class="col-lg-4 col-md-6 col-sm-6 mt-20">
                        <label class="req">Sort Order </label><br>
                        <input type="number" class="form-control input_style" formControlName="sortOrder"
                            placeholder="Enter Sort Order">
                        <div *ngIf="form.sortOrder.touched&&form.sortOrder.invalid" class="invalid-text">
                            <div *ngIf="form.sortOrder.errors.required">
                                Please provide the sortorder
                            </div>
                        </div>
                    </div>


                    <!-- Cake Listings -->


                    <div class="col-lg-8 mt-20" *ngIf="type=='product'">
                        <div class="ml-2">
                            <label class="title">Cake Listing </label><br>
                            <div class="d-flex align-items-center pt-12">

                                <label class="custom-checkbox">
                                    <input formControlName="cakesByOccasion" name="occasion" id="occasion"
                                        type="checkbox" #occasion class="clickable-checkbox checkbox_style"> <span
                                        class="label-checkbox  checkmark"> </span>Cakes by occasion
                                </label>
                                <label class="custom-checkbox">
                                    <input formControlName="trendingCakes" name="Trending" id="Trending" type="checkbox"
                                        #Trending class="clickable-checkbox checkbox_style"> <span
                                        class="label-checkbox  checkmark"> </span>Trending Cake
                                </label>
                                <label class="custom-checkbox">
                                    <input formControlName="BestSellerCakes" name="bestseller" id="bestseller"
                                        type="checkbox" #bestseller class="clickable-checkbox checkbox_style"> <span
                                        class="label-checkbox  checkmark"> </span>Best
                                    Seller Cake
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Add more table -->
                <div class="add-more-table-info">
                    <div class="heading-sec" *ngIf="type=='gift' ? form.pricings.value.length<1:true">
                        <h6>Add More Info</h6>
                        <button (click)="toggle()" class="btn add-btn"><i class="fa fa-plus" aria-hidden="true"></i> Add
                            Info</button>
                    </div>
                    <!-- Add more section -->
                    <div class="add-info" *ngIf="isToggled">
                        <div class="tab-btn" *ngIf="type=='product'">
                            <p (click)="switchTab('single')" [ngClass]="{'active': currentTab=='single'}">Single Add</p>
                            <p (click)="switchTab('bulk')" [ngClass]="{'active': currentTab=='bulk'}">Bulk Add</p>
                        </div>
                        <div *ngIf="currentTab=='single'&&(type=='gift' ? form.pricings.value.length<1:true)">
                            <div class="d-flex align-items-end flex-wrap" formGroupName="singleAdd">

                                <!--type -->
                                <div class="custom-style" *ngIf="type=='product'">
                                    <label [ngClass]="type=='product' ? 'req' : ''">Select Type</label>
                                    <div class="d-flex align-items-center pt-12">
                                        <label class="custom-checkbox mr-2">
                                            <input formControlName="type" value="1" id="kg" type="radio"
                                                class="clickable-checkbox checkbox_style"> <span
                                                class="label-checkbox  checkmark"> </span>Kg
                                        </label>
                                        <label class="custom-checkbox mr-2">
                                            <input formControlName="type" value="2" id="quantity" type="radio"
                                                class="clickable-checkbox checkbox_style"> <span
                                                class="label-checkbox  checkmark"> </span>Quantity
                                        </label>
                                    </div>

                                </div>

                                <!-- KG or Quantity  -->
                                <div class="w-10 custom-style" *ngIf="type=='product'">
                                    <label [ngClass]="type=='product' ? 'req' : ''">Select
                                        {{productForm.get('singleAdd').get('type').value=='2' ?
                                        'Nos':'KG'}}</label>
                                    <img class="down_arrow_icon" src="../../../assets/images/icon/down_arrow.svg"
                                        alt="image">
                                    <select formControlName="unit" class="form-control mrgn-btm input_style">
                                        <option value='' selected>Select
                                            {{productForm.get('singleAdd').get('type').value=='2' ?
                                            'Nos':'KG'}}</option>

                                        <option *ngFor="let data of unitOptions" [value]="productForm.get('singleAdd').get('type').value=='2' ?
                                        data.no : data.kg">{{productForm.get('singleAdd').get('type').value=='2' ?
                                            data.no : data.kg}} {{productForm.get('singleAdd').get('type').value=='2' ?
                                            '' : 'KG'}}</option>

                                    </select>
                                </div>

                                <!-- Flavours -->
                                <div class="w-15 custom-style" *ngIf="type=='product'">
                                    <label style="font-weight: 700;">Flavours</label>
                                    <img class="down_arrow_icon" src="../../../assets/images/icon/down_arrow.svg"
                                        alt="image">
                                    <select formControlName="flavour" class="form-control mrgn-btm input_style">
                                        <option value=''>Select Flavours</option>
                                        <option *ngFor="let data of flavours" [value]="data?.flavoursId">
                                            {{data?.flavoursName}}</option>
                                    </select>
                                </div>

                                <!-- Madewith -->
                                <div class="w-10 custom-style" *ngIf="type=='product'">
                                    <label [ngClass]="type=='product' ? 'req' : ''">Made With</label>
                                    <img class="down_arrow_icon" src="../../../assets/images/icon/down_arrow.svg"
                                        alt="image">
                                    <select formControlName="madeWith" class="form-control mrgn-btm input_style">
                                        <option value=''>Select Preference</option>
                                        <option *ngFor="let data of madeWithOptions;let i = index"
                                            [value]="data?.value">
                                            {{data?.view}}</option>
                                    </select>
                                </div>


                                <div class="w-10 custom-style">
                                    <label class="req">Amount (₹)</label><br>
                                    <input formControlName="amount" type="number" class="form-control input_style"
                                        placeholder="Amount">
                                </div>
                                <div class="w-10 custom-style">
                                    <label style="font-weight: 700;">Discount (%)</label><br>
                                    <input formControlName="discount" type="number" class="form-control input_style"
                                        placeholder="Discount">
                                </div>
                                <div class="w-10 custom-style">
                                    <label class="req">Net Weight</label><br>
                                    <input formControlName="netWeight" (keyup)="weightChange('netWeight','grossWeight')"
                                        type="number" class="form-control input_style" placeholder="Net Weight">
                                </div>
                                <div class="w-10 mt-20">
                                    <label class="req">Gross Weight</label><br>
                                    <input formControlName="grossWeight"
                                        (keyup)="weightChange('grossWeight','netWeight')" type="number"
                                        class="form-control input_style" placeholder="Gross Weight">
                                </div>
                                <!-- <div class="d-flex align-items-center ml-4 mt-3">
                                    <input class="form-check-input" type="checkbox" id="">
                                    <label class="form-check-label" for="mwchk2">To show Product Details</label>
                                </div> -->


                            </div>

                            <div class="same-net-weight mr-2">



                                <div class="d-flex align-items-center ml-4">
                                    <input (change)="sameAsChange($event)" formControlName="sameAsNet"
                                        class="form-check-input" type="checkbox" id="mwchk2">
                                    <label class="form-check-label" for="mwchk2">Same as Net Weight</label>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="currentTab=='bulk'&&type=='product'">
                            <div class="row align-items-end" formGroupName="bulkAdd">
                                <div class="col-xxl-2 col-lg-12 mt-20">
                                    <label class="req">Select</label>
                                    <div class="d-flex align-items-center pt-12">
                                        <label (click)="bulkAddSettings('1')" class="custom-checkbox mr-2">
                                            <input formControlName="type" value="1" id="kg" type="radio"
                                                class="clickable-checkbox checkbox_style"> <span
                                                class="label-checkbox  checkmark"> </span>Kg
                                        </label>
                                        <label (click)="bulkAddSettings('2')" class="custom-checkbox mr-2">
                                            <input formControlName="type" value="2" type="radio"
                                                class="clickable-checkbox checkbox_style">
                                            <span class="label-checkbox  checkmark"> </span>Quantity
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xxl-5 col-lg-6 col-md-6 col-sm-6 mt-20">
                                    <label class="req">Select {{productForm.get('bulkAdd').get('type').value=='2' ?
                                        'Nos':'KG'}}</label>
                                    <ng-multiselect-dropdown formControlName="unit" [placeholder]="productForm.get('bulkAdd').get('type').value=='2' ?
                                    'Select Nos':'Select KG'" [settings]="unitSettings"
                                        [data]="productForm.get('bulkAdd').get('type').value=='2' ? unit2 : unit1">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div class="col-xxl-5 col-lg-6 col-md-6 col-sm-6">
                                    <label>Flavours</label>
                                    <ng-multiselect-dropdown [placeholder]="'Select Flavours'" formControlName="flavour"
                                        [settings]="flavourOptions" [data]="flavours">
                                    </ng-multiselect-dropdown>
                                </div>

                            </div>
                        </div>

                        <div class="btn-section" *ngIf="type=='gift' ? form.pricings.value.length<1:true">
                            <button (click)="toggle()" class="btn cancel-btn mr-2">Cancel</button>
                            <button (click)="addVariants()" class="btn add-btn">{{ currentTab=='bulk' ? 'Generate'
                                : 'Add'}}</button>
                        </div>
                    </div>


                    <div class="modal fade" id="myModal">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <h4 class="modal-title">Bulk Variant Generator</h4>
                                    <button type="button" class="close" data-dismiss="modal"
                                        (click)="close()">&times;</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body" style="height:450px; overflow-y: auto; padding: 0;">
                                    <form>
                                        <table class="table generate-table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th style="width: 10%;" scope="col">
                                                        {{productForm.get('newPricings').value?.length>0 ?
                                                        productForm.get('newPricings').value[0].type=='1' ?
                                                        'KG':'No' : 'KG'}}</th>
                                                    <th scope="col">Flavour</th>
                                                    <th scope="col">Made With<span class="text-danger">*</span></th>
                                                    <th scope="col">Discount(%)</th>
                                                    <th scope="col">Amount <span class="text-danger">*</span></th>
                                                    <th scope="col" class="d-flex"><span>Net Weight<span
                                                                class="text-danger">*</span></span> <input
                                                            [formControl]="productForm.controls.sameAsNet"
                                                            type="checkbox" #netgross
                                                            (change)="bulkSameAs(productForm.controls.sameAsNet.value)">
                                                    </th>
                                                    <th scope="col">
                                                        <span class="mr-2">Gross Weight <span
                                                                class="text-danger">*</span></span>

                                                    </th>

                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody [formGroup]="productForm">
                                                <tr formArrayName="newPricings"
                                                    *ngFor="let controls of productForm.get('newPricings')['controls']; let i = index">
                                                    <ng-container [formGroupName]="i">
                                                        <td>{{ i+1 }}</td>
                                                        <td>
                                                            <input formControlName="unit" type="text"
                                                                class="form-control" placeholder="Recipient's username"
                                                                readonly>
                                                        </td>
                                                        <td>
                                                            <input type="text" formControlName="flavourName"
                                                                class="form-control" placeholder="Recipient's username"
                                                                readonly>
                                                        </td>
                                                        <td>
                                                            <select class="form-control" placeholder="Enter Amount"
                                                                formControlName="madeWith">
                                                                <option value=''>Select Preference</option>
                                                                <option
                                                                    *ngFor="let data of madeWithOptions;let i = index"
                                                                    [value]="data?.value">
                                                                    {{data?.view}}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input formControlName="discount" numbersonly
                                                                class="form-control" placeholder="Enter Discount">

                                                        </td>


                                                        <td>
                                                            <input numbersonly formControlName="amount"
                                                                class="form-control" placeholder="Enter Amount">
                                                        </td>
                                                        <td>
                                                            <input formControlName="netWeight" type="number"
                                                                (keyup)="sameAsChangeBulk(i,'netWeight','grossWeight')"
                                                                class="form-control" placeholder="Enter Net Weight">
                                                        </td>
                                                        <td>
                                                            <input
                                                                (keyup)="sameAsChangeBulk(i,'grossWeight','netWeight')"
                                                                formControlName="grossWeight" type="number"
                                                                class="form-control" placeholder="Enter Gross Weight">
                                                        </td>

                                                        <td>
                                                            <a *ngIf="productForm.get('newPricings').value?.length>1"
                                                                href="javascript:;"
                                                                (click)="removePricing(i,'newPricings' )"><i
                                                                    class="fa fa-trash text-center"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                            </tbody>

                                        </table>
                                    </form>

                                </div>

                                <!-- Modal footer -->
                                <div class="modal-footer">
                                    <button type="button" (click)="close()" class="btn btn-secondary">Back</button>
                                    <button type="button" class="btn btn-pink"
                                        (click)="addBulkVariants()">Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>






                    <div class="info-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Drag</th>
                                    <th *ngIf="type=='product'">Unit</th>
                                    <th *ngIf="type=='product'">Flavour</th>
                                    <th *ngIf="type=='product'">Made with</th>
                                    <th class="text-right">Price (₹)</th>
                                    <th>Discount</th>
                                    <th>Net Weight</th>
                                    <th>Gross Weight</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="productForm?.get('pricings').value?.length==0">
                                <tr>
                                    <td colspan="9">
                                        <div class="no-data">
                                            <div>
                                                <p>No information added, Please click and add what you want</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody cdkDropList (cdkDropListDropped)="dropPricings($event)"
                                *ngIf="productForm?.get('pricings').value?.length!=0">
                                <tr cdkDrag formArrayName="pricings"
                                    *ngFor="let controls of productForm.get('pricings')['controls']; let i = index">

                                    <ng-container [formGroupName]="i">

                                        <td><img src="assets/images/icon/drag.png" alt="drag-icon"></td>
                                        <td *ngIf="type=='product'">
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{controls.get('unit').value}}
                                                {{controls.get('type').value=='2' ?
                                                'Nos':'KG'}}</span>

                                            <ng-container *ngIf="controls.get('editNow').value==true">
                                                <select class="form-control" formControlName="unit">
                                                    <option value='' selected>Select
                                                        {{controls.get('type').value=='2' ?
                                                        'Nos':'KG'}}</option>

                                                    <option *ngFor="let data of unitOptions" [value]="controls.get('type').value=='2' ?
                                                    data.no : data.kg">
                                                        {{controls.get('type').value=='2' ?
                                                        data.no : data.kg}}
                                                        {{controls.get('type').value=='2' ?
                                                        '' : 'KG'}}</option>
                                                </select>
                                            </ng-container>



                                        </td>
                                        <td *ngIf="type=='product'">
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{checkNull(controls.get('flavourName').value)
                                                ?controls.get('flavourName').value : 'NA' }}</span>

                                            <ng-container *ngIf="controls.get('editNow').value==true">


                                                <select class="form-control" placeholder="Select Flavour"
                                                    formControlName="flavour">
                                                    <option value=''>Select Flavour</option>
                                                    <option *ngFor="let data of flavours" [value]="data?.flavoursId">
                                                        {{data?.flavoursName}}</option>
                                                </select>
                                            </ng-container>
                                        </td>
                                        <td *ngIf="type=='product'">
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{(controls.get('madeWith').value=='both'
                                                ? 'Egg & Egless' : controls.get('madeWith').value )|titlecase}}</span>


                                            <ng-container *ngIf="controls.get('editNow').value==true">
                                                <select class="form-control" placeholder="Enter Amount"
                                                    formControlName="madeWith">
                                                    <option value=''>Select Preference</option>
                                                    <option *ngFor="let data of madeWithOptions;let i = index"
                                                        [value]="data?.value">
                                                        {{data?.view}}</option>
                                                </select>
                                            </ng-container>

                                        </td>

                                        <td class="text-right">
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{controls.get('amount').value}}</span>
                                            <input *ngIf="controls.get('editNow').value==true" formControlName="amount"
                                                type="text" class="form-control">
                                        </td>


                                        <td>
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{controls.get('discount').value
                                                > 0 ?controls.get('discount').value : 0 }}
                                                %</span>

                                            <ng-container *ngIf="controls.get('editNow').value==true">


                                                <input formControlName="discount" type="text" class="form-control">
                                            </ng-container>

                                        </td>

                                        <td>
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{controls.get('netWeight').value}}
                                                KG</span>
                                            <input *ngIf="controls.get('editNow').value==true"
                                                formControlName="netWeight" type="text" class="form-control">
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="controls.get('editNow').value!=true">{{controls.get('grossWeight').value}}
                                                KG</span>
                                            <input *ngIf="controls.get('editNow').value==true"
                                                formControlName="grossWeight" type="text" class="form-control">
                                        </td>
                                        <td>
                                            <div class="action-icon">

                                                <ng-container *ngIf="controls.get('editNow').value==true">
                                                    <button type="button" class="btn btn-pink"
                                                        (click)="updateVariant(controls,i)">Update</button>
                                                </ng-container>
                                                <ng-container *ngIf="controls.get('editNow').value!=true">
                                                    <span>
                                                        <img (click)="editVariant(controls,i)"
                                                            *ngIf="controls.get('editNow').value!=true"
                                                            src="assets/images/icon/edit.png" alt="icon">

                                                    </span>
                                                    <span class="ml-2">
                                                        <img (click)="removePricing(i,'pricings')"
                                                            src="assets/images/icon/dlt.png" alt="icon">
                                                    </span>
                                                </ng-container>

                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>



            <!-- Detailed image start-->
            <h6 class="section-title mb-20">Product Detailed Image & Video</h6>
            <div class="card-design">
                <div class="row">
                    <div class="col-lg-3 col-md-6 mb-20" *ngFor="let images of productForm.get('productImages').value; let
                        i=index">
                        <div class="card card-upld-img">
                            <div class="image-options">
                                <span (click)="markAsPrimary(i)" *ngIf="!images?.isDefault" class="mark-primary">
                                    Mark as primary
                                </span>
                                <span class="primary" *ngIf="images?.isDefault==true">
                                    <img class="mr-1" src="assets/images/icon/primary.png" alt="Primary"> Primary
                                </span>

                                <span class="dlt-img" (click)="removeProductImages(i,images?.isDefault)">
                                    <img src="assets/images/icon/dlt.png" alt="icon">
                                </span>
                            </div>
                            <img [src]="images?.image" *ngIf="!images?.image?.includes('.mp4')"
                                class="img img-responsive" height="200">
                            <video class="img img-responsive" *ngIf="images?.image?.includes('.mp4')" height="200"
                                controls>
                                <source [src]="images?.image" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>

                        </div>

                    </div>
                    <div class="col-lg-3 text-center mb-20">
                        <div class="card card-upld-img justify-center">
                            <img class="thumb-img" src="assets/images/image-upload.png" alt="img">
                            <label class="req"> Design Image &
                                Video</label>
                            <div class="file-dash">
                                <input type="file" id="uploader" #file (change)="uploadFiles($event)"
                                    accept="image/*,video/mp4" style="display:none">

                            </div>
                            <span class="file-formate"> JPEG, .JPG, .GIF,.PNG, .Mp4 upto
                                50 MB,</span>
                            <span class="file-formate">Image Size Should be ( width:1442px X height:1229px ) </span>
                            <label for="uploader">
                                <span class="btn btn-file-upload">Upload</span>
                            </label>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Detailed image end-->

            <!--text area Start-->
            <div class="row">
                <div class="col-lg-12">
                    <div>
                        <h6 class="section-title">Product Description</h6>
                        <angular-editor class="te-basic-tools" formControlName="productDescription"
                            [config]="configTextbox">
                        </angular-editor>
                        <br>
                        <h6 class="section-title">Notes</h6>
                        <angular-editor class="te-basic-tools" formControlName="notes" [config]="configTextbox">
                        </angular-editor>
                        <br>
                    </div>
                </div>
            </div>
            <!--text area end-->
            <h6 class="section-title">Restrictions</h6>
            <div class="card-design">
                <div class="row alignEnd mt-2">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <label class="title">Minimum Order Quantity</label>
                        <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg">
                        <select class="form-control input_style" formControlName="minimumQuantity">
                            <option *ngFor="let number of itemQuantityNumber" value="{{number}}">{{number}}
                            </option>
                        </select>
                    </div>




                    <!-- Is PAN India -->
                    <div class="col-lg-4 col-md-6 col-sm-6 mt-20">
                        <div class="cake-list">
                            <label class="req">It's Pan-India Cake</label><br>
                            <div class="d-flex align-items-center pt-12">
                                <label class="custom-checkbox">
                                    <input (click)="panChange(false)" formControlName="isPanIndia" id="yes" value='true'
                                        type="radio" #panIndia class="clickable-checkbox checkbox_style">
                                    <span class="label-checkbox  checkmark">
                                    </span>Yes
                                </label>
                                <label class="custom-checkbox">
                                    <input (click)="panChange(false)" formControlName="isPanIndia" id="no" value='false'
                                        type="radio" #panIndia class="clickable-checkbox checkbox_style">
                                    <span class="label-checkbox  checkmark">
                                    </span>No
                                </label>
                            </div>
                        </div>
                        <div *ngIf="form.isPanIndia.touched&&form.isPanIndia.invalid" class="invalid-text">
                            <div>
                                Please select the product type whether it is a PAN India product or not
                            </div>
                        </div>
                    </div>

                    <!-- Mes -->
                    <div class="col-lg-4 col-md-6 col-sm-6 md-mt-20">
                        <label class="req">Message on the cake</label>
                        <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg">
                        <select class="form-control input_style" formControlName="message">
                            <option value="">Select Message</option>
                            <option value="true">Show</option>
                            <option value="false">Hide</option>
                        </select>



                        <div *ngIf="form.message.touched&&form.message.invalid" class="invalid-text">
                            <div>
                                Please select the message visibility
                            </div>
                        </div>
                    </div>

                    <!-- Product Points -->
                    <div class="col-lg-4 col-md-6 col-sm-6 mt-20">
                        <label class="pointStyle">Product Points</label>
                        <input numbersonly formControlName="points" class="form-control input_style">
                        <div *ngIf="form.points.touched&&form.points.invalid" class="invalid-text">
                            <div>
                                Please provide the product points
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-6 mt-20">
                        <label [ngClass]="form?.deliveryTime?.disabled ? '' : 'req'">Delivery Time</label>
                        <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg">
                        <select class="form-control input_style" formControlName="deliveryTime">
                            <option value="">Select Delivery Time</option>
                            <option *ngFor="let dl of deliveryTimes" value="{{dl.deliverytimeId}}">{{dl.deliveryName}}
                            </option>
                        </select>
                        <div *ngIf="form.deliveryTime.touched&&form.deliveryTime.invalid" class="invalid-text">
                            <div>
                                Please select the delivery time
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-6 col-sm-6 mt-20">
                        <div class="cake-list">
                            <label class="req">Payment Type</label><br>
                            <div class="d-flex align-items-center pt-12">
                                <label class="custom-checkbox">
                                    <input formControlName="payment" id="paymentType" type="checkbox" #paymentType
                                        class="clickable-checkbox checkbox_style"> <span
                                        class="label-checkbox  checkmark">
                                    </span>Online Payment
                                </label>
                            </div>
                        </div>

                    </div>
                    <div class="col-xxl-4 col-lg-8 col-md-12 col-sm-12 mt-20">
                        <div class="cake-list">
                            <label class="req">Delivery Type</label><br>
                            <div class="d-flex align-items-center pt-12">
                                <label class="custom-checkbox">
                                    <input formControlName="isStoreDelivery" id="store" type="checkbox"
                                        class="clickable-checkbox checkbox_style"> <span
                                        class="label-checkbox radius-0 checkmark"> </span>Pickup from Store
                                </label>
                                <label class="custom-checkbox">
                                    <input formControlName="isHomeDelivery" id="door" type="checkbox"
                                        class="clickable-checkbox checkbox_style"> <span
                                        class="label-checkbox radius-0 checkmark"> </span>Door Delivery
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xxl-8 col-lg-8 col-md-8 col-sm-8 mt-20">
                        <div class="d-flex align-items-center bookingNew">
                            <div class="cake-list">
                                <label class="req">Enable Booking</label><br>
                                <div class="d-flex align-items-center pt-12">
                                    <label class="custom-checkbox">
                                        <input value='true' type="radio" name="box" checked
                                            class="clickable-checkbox uppercase checkbox_style">
                                        <span class="label-checkbox  checkmark">
                                        </span>Forever
                                    </label>
                                    <label class="custom-checkbox">
                                        <input name="box" value="false" type="radio" [checked]="false"
                                            class="clickable-checkbox uppercase checkbox_style"
                                            (click)="toggleDateFields($event.target.checked)">
                                        <span class="label-checkbox checkmark"></span>Specific Date(s)
                                    </label>

                                </div>
                            </div>
                            <div class="d-flex align-items-center" *ngIf="showDateFields">
                                <div class="ml-3">
                                    <label class="req d-block">From Date</label>
                                    <input class="form-control uppercase input_style" type="date" id="">
                                </div>
                                <div class="ml-3">
                                    <label class="req d-block">To Date</label>
                                    <input class="form-control uppercase input_style" type="date" id="">
                                </div>
                            </div>

                        </div>
                    </div> -->
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-12">
                    <button (click)="createProduct()" type="button" class="button-1 btn-pink btn-create">Submit
                        <i class="fa fa-long-arrow-right"></i> </button>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>


        <!--container end-->
    </form>
</section>