import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { first } from 'rxjs/operators';
import { ConfigService } from 'src/app/services/core/config.service';
import { PermissionService } from 'src/app/services/core/permission.service';
import { NavigationService } from 'src/app/services/core/navigation.service';
import { saveToken } from 'src/app/services/helpers/token.helper';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  password;
  loginError = ''
  loginForm: FormGroup;
  submitted = false;
  loader = '';
  show = false;
  currentUser
  role

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private configService:ConfigService,
    private permSer: PermissionService,
    private navSer: NavigationService,
  ) { }

  ngOnInit(): void {
    this.password = 'password';
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe((res:any)=>{
      // if(this.loginForm.get('password').value?.length<=0){
      //   this.loginError=''
      // }
      this.loginError=''
  
    })

    if (this.router.url == '/' || this.router.url == '/login') {

    } else {
      this.router.navigate(['/']);
    }
  }


  get f() { return this.loginForm.controls; }
 

  onSubmit() {
    this.loginError = '';
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loader = 'show';
    this.authenticationService.login(this.f.userName.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loader = ''
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.configService.init(); 
          this.role = this.currentUser.privileges;
          let roleId = this.configService.roleId;
          let permission = this.configService.permission;
          this.permSer.init(Number(roleId), permission);
          let authMenu = this.permSer.authMenu;
          this.navSer.publish(authMenu);
          //redirect user based on there permission
          if (this.permSer.hasPermission(["dashboard"],["dashboard_view"])) this.router.navigate(['/home']);
          else if (this.permSer.hasPermission(["order_history"],
          ["order_history_view","order_history_export","order_history_filter",
          'order_history_pending_cancel','order_history_pending_to_accepted','order_history_pending_delay_time',
          'order_history_accepted_cancel','order_history_accepted_to_baking','order_history_accepted_delay_time',
          'order_history_baking_cancel','order_history_baking_to_shipped','order_history_baking_delay_time',
          'order_history_shipped_cancel','order_history_shipped_to_delivered','order_history_shipped_delay_time'])) this.router.navigate(['/order']);
          else if (this.permSer.hasPermission(["coupon"],["coupon_create","coupon_update","coupon_delete"])) this.router.navigate(['/promocode']);
          else if (this.permSer.hasPermission(["banner"],["banner_create","banner_update","banner_delete","banner_view"])) this.router.navigate(['/create-banner']);
          else if (this.permSer.hasPermission(["categories"],["categories_create","categories_update","categories_delete"])) this.router.navigate(['/categories']);
          else if (this.permSer.hasPermission(["blogs"],["blogs_create","blogs_update","blogs_delete","blogs_view"])) this.router.navigate(['/blog']);
          else if (this.permSer.hasPermission(["delivery"],["delivery_create","delivery_update","delivery_delete"])) this.router.navigate(['/deliveries']);
          else if (this.permSer.hasPermission(["news_letter"],["news_letter_status","news_letter_export","news_letter_delete"])) this.router.navigate(['/news-letter']);
          else if (this.permSer.hasPermission(["user"],["user_create","user_update","user_status"])) this.router.navigate(['/create-user']);
          else if (this.permSer.hasPermission(["home_page_notification"],["home_page_notification_update","home_page_notification_view"])) this.router.navigate(['/home-page-popup']);
          else if (this.permSer.hasPermission(["delivery_charge"],["deliveryCharge_update","delivery_chage_view"])) this.router.navigate(['/home-page-popup'], { queryParams: { type: 'delivery' } });
          else if (this.permSer.hasPermission(["review_management"],["review_management_status","review_management_view"])) this.router.navigate(['/review-management']);
          else if (this.permSer.hasPermission(["loyalty_programme"],["loyalty_programme_points","loyalty_programme_update"])) this.router.navigate(['/loyalty-programme']);
          else if (this.permSer.hasPermission(["flavours"],["flavours_create","flavours_update","flavours_delete"])) this.router.navigate(['/flavour']);
          else {
            this.loginError = 'You do not have any permission';
          }
        },
        error => {
          this.loader = ''
          this.loginError = error.message
        });
  }


  //show and hide password text
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
