import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
declare var $: any;
import { Router } from '@angular/router';
import { HttpEventType, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { fromEvent, Subscription, timer } from "rxjs";
import { PermissionService } from 'src/app/services/core/permission.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { Pagination, TransactionService } from './transaction.service';
import { PuppysCustomValidation, sweetAlert } from 'src/app/shared/validations/puppys-custom';
import { checkNull } from 'src/app/constants/custom-functions';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit, AfterViewInit {
  filterForm: FormGroup
  @ViewChild('filterInput') public filterInput: ElementRef;
  pagination: Pagination
  transactions: any[] = []

  status = ['Created', 'Attempted', 'Paid', 'Captured', 'Refunded', 'Failed']
  successStatus = ['paid', 'captured'].map((ele) => ele.toLocaleUpperCase())
  failedStatus = ['Failed'].map((ele) => ele.toLocaleUpperCase())

  constructor(
    private loader: LoaderService,
    private transactionService: TransactionService,
    public permission: PermissionService,
    private router: Router

  ) {
    this.pagination = new Pagination()
  }




  ngOnInit(): void {
    this.intialForm()
    this.getAllTransactions()
  }

  ngAfterViewInit(): void {
    this.filterKeyup()
  }


  intialForm() {
    this.filterForm = new FormGroup({
      endDate: new FormControl(''),
      startDate: new FormControl(''),
      status: new FormControl('')
    }, { validators: PuppysCustomValidation.DateValidator('startDate', 'endDate') })
  }




  filterKeyup() {

    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(debounceTime(300), distinctUntilChanged()).subscribe((res: any) => {
      let val = this.filterInput.nativeElement.value
      this.pagination.search = checkNull(val) ? val.trim() : ''
      // console.log(val);

      this.getAllTransactions(true)
    })
  }



  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }


  reset() {
    this.filterForm.reset();
    this.filterForm.get('status').setValue('');
    this.filterForm.updateValueAndValidity();
    this.pagination = new Pagination();
    this.filterInput.nativeElement.value = '';
    this.getAllTransactions()
  }



  paginate(event) {
    this.pagination.pageNo = event;
    this.getAllTransactions()
  }



  getAllTransactions(bool?: any) {

    let form = this.filterForm.value
    if (this.filterForm.valid) {

      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form.status)) {
        this.pagination.status = form.status
      }

      !bool ? this.loader.open() : ''
      this.transactionService.getAllTransactions(this.pagination).subscribe((res: any) => {
        this.loader.close()
        //console.log(res);
        this.transactions = res?.transactions ?? []
        this.pagination.lastPage = Math.ceil(Number(res.totalLength) / this.pagination.limit)

      }, (err) => {
        this.loader.close()
        this.pagination.lastPage = 0
        this.transactions = []
      })
    } else {

      sweetAlert('error', 'From date should be before To date')
    }

  }

  navigate(data) {
    if (checkNull(data)) {
      this.router.navigate([`/order-detail/${data}`])
    }
  }

  refershStatus(data, index) {
    this.loader.open()
    this.transactionService.refreshOrderStatus(data?.orderId).subscribe((res: any) => {
      this.loader.close();
      console.log(res);

    }, (err) => {
      console.log(err);
      this.loader.close()
      if (checkNull(err.text)) {
        sweetAlert('success', err?.text ?? 'Status updated successfully.')
        this.getAllTransactions()
      } else {
        sweetAlert('error', 'Try again.')
      }
    })
  }







}




