<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"> </div>
  <app-header [pageName]="'Instagram Feeds'"></app-header>
  <div class="container-fluid">
    <h5>Instagram Feeds</h5>
    <button type="button" *ngIf="permission.hasPermission(['instagram'],['instagram_create'])" (click)="create()" class="button-1 btn-pink btn-margin"> <i class="fa fa-plus"></i> Create
    </button>
    <br><br>

    <div class="card bg-table-color">
      <div class="card-body">
        <div class="table-responsive">

          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">POST NAME</th>
                <th scope="col">INSTA ID</th>
                <th scope="col" *ngIf="permission.hasPermission(['instagram'],['instagram_delete'])">ACTION</th>
              </tr>
            </thead>
            <tr *ngFor="let item of getdata;let i=index">
              <td>{{i+1}}</td>
              <td>
                <span *ngIf="item.postName">{{item.postName}}</span>
                <span *ngIf="!item.postName">NA</span>
              </td>
              <td><a target="_blank" href="https://www.instagram.com/p/{{item.postId}}/">{{item.postId}}</a></td>
              <td *ngIf="permission.hasPermission(['instagram'],['instagram_delete'])">
                <i class="fa fa-trash del-icon" (click)="deleteData(item.id)"></i>
              </td>
            </tr>
          </table>

        </div>
      </div>
    </div>


    <!-- Modal Form-->
    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form [formGroup]="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add instagram Post</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <label>Post Name</label><br>
              <input type="text" class="form-control" placeholder="Enter Post Name (optional)"
                formControlName="postName">
              <br>

              <label>Instagram Url<span style="color:red;">*</span></label><br>
              <input type="text" class="form-control" placeholder="Paste Instagram Url" formControlName="postId">
              <div *ngIf="submitted && f.postId.errors" class="invalid-text">
                <div *ngIf="f.postId.errors.required">
                  Instagram Url is required
                </div>
              </div>
              <br>

            </div>
            <div class="modal-footer">
              <button type="button" class="button-1 btn-white" data-dismiss="modal"  (click)="close()">Close</button>
              <button type="button" class="button-1 btn-pink" (click)="onSubmit()">Submit <i style="margin-left: 4px;"
                  class="fa fa-long-arrow-right"></i> </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--modal End-->


  </div>
</section>