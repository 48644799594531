<section class="offset-width well-white">
   <app-header [pageName]="'Update Coupon Setting'"></app-header>
   <!-- <div *ngIf="loader" class="loader-spin"> </div> -->
   <div class="container-fluid">
      <div class="mt-2 px-2">
         <h1>Update Coupon Setting</h1>
      </div>
      <div class="px-2">
         <ul class="nav nav-tabs">
            <li class="nav-item" (click)="tabChange(i,data)" *ngFor="let data of Occasions;let i=index;">
               <a class="nav-link " [ngClass]="active==i ? 'active' : ''" data-toggle="tab"
                  href="#{{data?.couponOccasionId}}">{{data?.couponTypeName}}</a>
            </li>
         </ul>
         <div class="tab-content">
            <div class="tab-pane fade show active" id="birthday">
            </div>
            <div class="tab-pane fade" id="work_anniversary">
            </div>
            <div class="tab-pane fade" id="wedding_anniversary">
            </div>
            <div class="tab-pane fade" id="valentines_day">
            </div>
            <div class="tab-pane fade" id="childrens_day">
            </div>
            <form [formGroup]="createForm">
               <!-- Tab 1 Content -->
               <div class="row">

                  <!-- Prefix -->

                  <div class="col-lg-6 col-xl-3 col-md-6 mt-4 mb-3">

                     <div class="mt-1">
                        <label class="label_style">Coupon Code Prefix<span class="req">*</span></label>
                        <input style='text-transform:uppercase' formControlName="prefix" type="text"
                           placeholder="Enter Coupon Code Prefix"
                           class="form-control  search_widthset form-control-sm input_style input-style padding_add">
                     </div>
                     <div class="hinata" *ngIf="form.prefix.touched&&form.prefix.invalid">
                        <!-- Required -->
                        <span
                           *ngIf="form.prefix.errors?.required&&(!form.prefix.errors?.pattern&&!form.prefix.errors?.minlength)">Please
                           provide
                           the
                           prefix</span>
                        <!-- Minimum Length -->
                        <span
                           *ngIf="form.prefix.errors?.minlength&&(!form.prefix.errors?.required&&!form.prefix.errors?.pattern)">Coupon
                           code should have atleast 3 characters</span>
                        <!-- Pattern -->
                        <span
                           *ngIf="form.prefix.errors?.pattern&&(!form.prefix.errors?.required&&!form.prefix.errors?.required)">Please
                           provide a
                           valid prefix</span>
                     </div>

                  </div>

                  <!-- Reminder Days -->

                  <div class="col-lg-6 col-xl-3 col-md-6 mt-4 mb-3">
                     <div>
                        <label class="label_style">Coupon Reminder Days<span class="req">*</span></label>
                        <input formControlName="reminder" numbersonly placeholder="Enter Coupon Reminder Days"
                           class="form-control search_widthset form-control-sm input_style input-style padding_add">
                     </div>
                     <div class="hinata" *ngIf="form.reminder.touched&&form.reminder.invalid">
                        <!-- Required -->
                        <span
                           *ngIf="form.reminder.errors?.required&&(!form.reminder.errors?.min&&!form.reminder.errors?.max)">Please
                           provide
                           the
                           reminder days</span>
                        <!-- Minimum -->
                        <span
                           *ngIf="form.reminder.errors?.min&&(!form.reminder.errors?.required&&!form.reminder.errors?.max)">Reminder
                           days should be greater than zero</span>
                     </div>

                  </div>


                  <!-- Discount Type -->

                  <div class="col-lg-6 col-xl-3 col-md-6 mt-4 mb-3">
                     <label class="label_style">Discount Type<span class="req">*</span></label>
                     <div class="d-flex align-items-center pt-12">
                        <label class="custom-checkbox">
                           <input type="radio" (change)="discountTypeChange()" formControlName="discountType" value="1"
                              class="clickable-checkbox checkbox_style">
                           <span class="label-checkbox  checkmark">
                           </span>Flat
                        </label>
                        <label class="custom-checkbox">
                           <input type="radio" (change)="discountTypeChange()" formControlName="discountType" value="2"
                              class="clickable-checkbox checkbox_style">
                           <span class="label-checkbox  checkmark">
                           </span>Percentage
                        </label>
                     </div>
                  </div>


                  <div class="col-lg-6 col-xl-3 col-md-6 mt-4 mb-3" *ngIf="form.discountType.value=='1'">
                     <div class="relative">
                        <label class="label_style">Discount Amount<span class="req">*</span></label>
                        <input formControlName="discountAmount" numbersonly placeholder="Enter Discount Amount"
                           class="form-control search_widthset form-control-sm input_style input-style padding_add">
                     </div>
                     <div class="hinata" *ngIf="form.discountAmount.touched&&form.discountAmount.invalid">
                        <!-- Required -->
                        <span
                           *ngIf="form.discountAmount.errors?.required&&(!form.discountAmount.errors?.min&&!form.discountAmount.errors?.max)">Please
                           provide
                           the
                           discount amount</span>
                        <!-- Minimum -->
                        <span
                           *ngIf="form.discountAmount.errors?.min&&(!form.discountAmount.errors?.required&&!form.discountAmount.errors?.max)">Discount
                           amount should be greater than zero</span>
                        <!-- maximum -->
                        <span
                           *ngIf="form.discountAmount.errors?.max&&(!form.discountAmount.errors?.required&&!form.discountAmount.errors?.min)">Discount
                           amount should be less than or equal to 100</span>
                     </div>
                  </div>


                  <div class="col-lg-6 col-xl-3 col-md-6 mt-4 mb-3" *ngIf="form.discountType.value=='2'">
                     <div class="relative">
                        <label class="label_style">Discount Percentage<span class="req">*</span></label>
                        <input formControlName="discountPercentage" numbersonly placeholder="Enter Discount Percentage"
                           class="form-control search_widthset form-control-sm input_style input-style padding_add">
                     </div>

                     <div class="hinata" *ngIf="form.discountPercentage.touched&&form.discountPercentage.invalid">
                        <!-- Required -->
                        <span
                           *ngIf="form.discountPercentage.errors?.required&&(!form.discountPercentage.errors?.min&&!form.discountPercentage.errors?.max)">Please
                           provide
                           the
                           discount percentage</span>
                        <!-- Minimum -->
                        <span
                           *ngIf="form.discountPercentage.errors?.min&&(!form.discountPercentage.errors?.required&&!form.discountPercentage.errors?.max)">Discount
                           percentage should be greater than zero</span>
                        <!-- maximum -->
                        <span
                           *ngIf="form.discountPercentage.errors?.max&&(!form.discountPercentage.errors?.required&&!form.discountPercentage.errors?.min)">Discount
                           percentage should be less than hundred</span>
                     </div>
                  </div>
               </div>



               <div class="row">

                  <!-- Minimum Amount -->
                  <div class="col-lg-6 col-xl-3 col-md-6 mb-3">
                     <div>
                        <label class="label_style">Min.Spend<span class="req">*</span></label>
                        <input formControlName="minimumAmount" numbersonly type="text" placeholder="Enter Min.Spend"
                           class="form-control search_widthset form-control-sm input_style input-style padding_add">
                     </div>

                     <div class="hinata"
                        *ngIf="form.minimumAmount.touched&&(form.minimumAmount.invalid||createForm.hasError('limit'))">
                        <!-- Required -->
                        <span
                           *ngIf="form.minimumAmount.errors?.required&&(!form.minimumAmount.errors?.min&&!createForm.hasError('limit'))">Please
                           provide
                           the
                           minimum amount</span>
                        <!-- Minimum -->
                        <span
                           *ngIf="form.minimumAmount.errors?.min&&(!form.minimumAmount.errors?.required&&!createForm.hasError('limit'))">Minimum
                           amount should be greater than zero</span>
                        <!-- Limit -->
                        <span
                           *ngIf="createForm.hasError('limit')&&(!form.minimumAmount.errors?.required&&!form.minimumAmount.errors?.min)">Minimum
                           amount should be less than the maximum amount</span>
                     </div>

                  </div>

                  <!-- Maximum Amount -->
                  <div class="col-lg-6 col-xl-3 col-md-6 mb-3">
                     <div>
                        <label class="label_style">Max.Spend<span class="req">*</span></label>
                        <input formControlName="maximumAmount" numbersonly type="text" placeholder="Enter Max.Spend"
                           class="form-control search_widthset form-control-sm input_style input-style padding_add">
                     </div>

                     <div class="hinata"
                        *ngIf="form.maximumAmount.touched&&(form.maximumAmount.invalid||createForm.hasError('limit'))">
                        <!-- Required -->
                        <span
                           *ngIf="form.maximumAmount.errors?.required&&(!form.maximumAmount.errors?.min&&!createForm.hasError('limit'))">Please
                           provide
                           the
                           maximum amount</span>
                        <!-- Minimum -->
                        <span
                           *ngIf="form.maximumAmount.errors?.min&&(!form.maximumAmount.errors?.required&&!createForm.hasError('limit'))">Maximum
                           amount should be greater than zero</span>
                        <!-- Limit -->
                        <span
                           *ngIf="createForm.hasError('limit')&&(!form.maximumAmount.errors?.required&&!form.maximumAmount.errors?.min)">Maximum
                           amount should be greater than the minimum amount</span>
                     </div>

                  </div>


               </div>
            </form>
            <div class="col-lg-12 col-md-12 text-right">
               <button *ngIf="permission.hasPermission(['coupon_new'],['coupon_new_update'])" (click)="updateOccasion()" type="button" class="button-1 my-4 btn-pink btn-create">Submit
                  <i class="fa fa-long-arrow-right ml_4"></i> </button>
            </div>
         </div>
      </div>
   </div>
</section> 