<section class="offset-width well-white">
   <div *ngIf="loader" class="loader-spin"> </div>
   <app-header [pageName]="'Order History'" [pageNameTwo]="'Order Details'" [pageLink]="'/order'"></app-header>
   <!----------------  New design  ----------------->
   <div class="container-fluid">
      <h5 class="detail_head">Order Details <img class="ml-2" src="../../../assets/images/img/right_arrow.svg"
            alt=""><span class="ml-2">
            {{getdata?.orderId}}
         </span>
      </h5>
      <div *ngIf="getdata">
         <div class="d-flex align-items-center justify-content-between mt5">
            <div class="d-flex align-items-baseline">
               <h6 class="mb-0">Order Status: </h6>
               <button class="ml-2 status_order {{getdata.status}} " type=button>{{getdata.statusDisplayName}}</button>
            </div>
            <div class="order_status">
               <button class="track_order"
                  *ngIf="getdata?.isPanIndia=='YES' && !checkNull(getdata?.data?.lineItemsInfo[0]?.attribute?.deliveryIn)"
                  (click)="openTrackOrder(getdata)">Track Order</button>


               <div *ngIf="showStatus.includes(getdata?.status)&&displayNext(getdata.status,getdata)?.all">
                  <button class="change_status" *ngIf="displayNext(getdata.status,getdata).status"
                     (click)="changeOrderStatus(getdata.status,getdata)">{{displayNext(getdata.status,getdata).name}}</button>

                  <button class="order_cancel" *ngIf="displayNext(getdata.status,getdata).cancel"
                     (click)="orderCancelled(getdata.orderId)">Cancel Order</button>
                  <br>
               </div>

               <!-- <div *ngIf="getdata.status == 'Delivered'"><br> <span class="status-text">Delivered</span></div>
                  <div *ngIf="getdata.status == 'Cancelled'" class="order_cancel"> <br> <span class="status-text">Cancel</span> </div>
                  -->
               <div *ngIf="getdata.status == 'InCart'"> <br> <span class="status-text">Abandoned Carts</span> </div>
            </div>
         </div>
         <div class="profile_details d-flex align-items-center">
            <div class="d-flex align-items-center mr40">
               <img class="view_profile_img" *ngIf="user.gender=='male'" src="../../../assets/images/img/profile.svg"
                  alt="">
               <img class="view_profile_img newstyle" *ngIf="user.gender=='female'"
                  src="../../../assets/images/img/f_avatar.png" alt="">
               <div class="ml-3">
                  <h1>{{user.firstName}}</h1>
                  <h2>First Name</h2>
               </div>
            </div>
            <div class="m40">
               <h1>{{user.lastName}}</h1>
               <h2>Last Name</h2>
            </div>
            <div class="m40">
               <h1>{{user.email}}</h1>
               <h2>Email</h2>
            </div>
            <div class="m40">
               <h1>{{user.gender | titlecase }}</h1>
               <h2>Gender</h2>
            </div>
            <div class="m40 border_none">
               <h1>{{user.phoneNumber}}</h1>
               <h2>Mobile</h2>
            </div>
         </div>
         <div class="row">
            <div class="col-xl-6 col-lg-12">
               <div class="detail_header">
                  <h1 class="d-flex align-items-center">Order Detail <img
                        *ngIf="!checkNull(getdata?.data?.lineItemsInfo[0]?.attribute?.deliveryIn)" class="ml-3"
                        src="../../../assets/images/img/pan_india.svg" alt=""></h1>
                  <div class="card_new">
                     <table class="bg_card">
                        <tr>
                           <th>Order Status</th>
                           <td>
                              <span class="{{getdata.status}} status-payment ">{{getdata.statusDisplayName}}</span>
                           </td>
                        </tr>
                        <tr *ngIf="getdata?.midnightCharge>0">
                           <th>Delivery</th>
                           <td class="mid_night_deliverystatus">Mid Night Delivery</td>
                        </tr>
                        <tr>
                           <th>Order Id</th>
                           <td>{{getdata?.orderId}}</td>
                        </tr>
                        <tr>
                           <th>Invoice No</th>
                           <td>{{getdata?.invoicePrefix}}-{{getdata?.invoiceYear}}-{{getdata?.invoiceNumber}}</td>
                        </tr>
                        <tr>
                           <th>Ordered On</th>
                           <td>{{getdata.createdOn | date: 'dd-MM-yyyy hh:mm' }}</td>
                        </tr>
                        <tr>
                           <th>Order Paid On</th>
                           <td>{{getdata.paidOn | date: 'dd-MM-yyyy hh:mm' }}</td>
                        </tr>
                        <tr>
                           <th>Order Amount</th>
                           <td><span class="mr-0.5">₹</span>{{calculation?.grandTotal?.toFixed(2)}} </td>
                        </tr>
                        <tr *ngIf="getdata?.data?.coupon">
                           <th>Coupon Code</th>
                           <td>{{getdata?.data?.coupon}} </td>
                        </tr>
                        <tr *ngIf="getdata?.data?.coupon">
                           <th>Discount Type</th>
                           <td>{{(getdata?.data?.costDetails?.discountDetails[0]?.type ==true ? 'Flat':'Percentage')}}
                              ({{(getdata?.data?.costDetails?.discountDetails[0]?.type == true) ?
                              ('₹' +
                              changeNumber(getdata?.data?.costDetails?.discountDetails[0]?.discountAmount)?.toFixed(2)):
                              (changeNumber(getdata?.data?.costDetails?.discountDetails[0]?.discountAmount) +'%')}})
                           </td>
                        </tr>
                        <tr *ngIf="getdata?.data?.coupon">
                           <th>Coupon Amount</th>
                           <td>{{ '₹' + calculation?.coupon?.toFixed(2)}} </td>
                        </tr>
                        <tr>
                           <th>Number of Items</th>
                           <td>{{calculation?.quantity}}</td>
                        </tr>
                        <tr class="border_bottom_none">
                           <th>Expected Delivery<br> Date Time</th>
                           <td>{{getdata?.data?.lineItemsInfo[0]?.attribute?.deliveryIn | date:
                              'dd-MM-yyyy hh:mm a'}}
                           </td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-12" *ngIf="hasHome">
               <div class="detail_header">
                  <h1 class="d-flex align-items-center">Delivery Detail (Home Address)</h1>
                  <div class="card_new">
                     <table class="bg_card">
                        <tr class="p_4">
                           <th>Deliver to</th>
                           <td>{{getdata.homeAddress?.name ? (getdata.homeAddress?.name | titlecase):'--' }}</td>
                        </tr>
                        <tr class="p_4">
                           <th>Phone Number</th>
                           <td>{{getdata.homeAddress?.phoneNumber ? getdata.homeAddress?.phoneNumber:'--'}}</td>
                        </tr>
                        <tr class="p_4">
                           <th>Alternate Phone Number</th>
                           <td> {{ getdata.homeAddress?.alternativePhoneNumber ?
                              getdata.homeAddress?.alternativePhoneNumber:'--'}}</td>
                        </tr>
                        <tr class="p_4">
                           <th>Email</th>
                           <td>{{getdata.homeAddress?.email ? getdata.homeAddress?.email :'--'}}</td>
                        </tr>
                        <tr class="p_4">
                           <th>Address</th>
                           <td>{{getdata.homeAddress?.line1 ? getdata.homeAddress?.line1 :'--'}}</td>
                        </tr>
                        <tr class="p_4">
                           <th>City</th>
                           <td>{{getdata.homeAddress?.city? (getdata.homeAddress?.city | titlecase):'--' }}</td>
                        </tr>
                        <tr class="p_4">
                           <th>State</th>
                           <td>{{getdata.homeAddress?.state ? (getdata.homeAddress?.state | titlecase):'--' }}</td>
                        </tr>
                        <tr class="border_bottom_none p_4">
                           <th>Pincode</th>
                           <td>{{getdata.homeAddress?.pinCode? (getdata.homeAddress?.pinCode):'--'}}</td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-12 mt-4">
               <div class="detail_header" *ngIf="getdata?.shippingDetails?.agentName">
                  <h1 class="d-flex align-items-center">Shipping Detail</h1>
                  <div class="card_new">
                     <table class="bg_card">
                        <tr>
                           <th>Agent Name</th>
                           <td>{{getdata.shippingDetails.agentName}}</td>
                        </tr>
                        <tr>
                           <th>Tracking Id</th>
                           <td>{{getdata.shippingDetails.trackingId}}</td>
                        </tr>
                        <tr class="border_bottom_none">
                           <th>Tracking Url</th>
                           <td class="link_tag">
                              <a target="_blank" href="{{getdata.shippingDetails.trackingUrl}}"
                                 *ngIf="getClickableLink(getdata.shippingDetails.trackingUrl) == 1">Click here</a>
                              <a target="_blank" href="http://{{getdata.shippingDetails.trackingUrl}}"
                                 *ngIf="getClickableLink(getdata.shippingDetails.trackingUrl) == 0">Click Here</a>
                           </td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-12 mt-4" *ngIf="hasStore">
               <div class="detail_header">
                  <h1 class="d-flex align-items-center">Delivery Detail<span class="ml-1">(Store Address)</span></h1>
                  <div class="card_new address_show">
                     <table class="bg_card">
                        <tr class="border_bottom_none">
                           <th class="w_42">Address</th>
                           <td [innerHTML]="replaceBR(getdata?.storeAddress?.address)"></td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
            <div class="col-lg-12 mt-4">
               <div class="detail_header">
                  <h1 class="d-flex align-items-center">Ordered Items</h1>
                  <div class="overflow_style">`
                     <table class="ordered_table">
                        <tr class="ordered_list_style">
                           <th>Item Info</th>
                           <th>Unit Price</th>
                           <th>Price</th>
                           <th>Discount</th>
                           <th class="p_30">Total</th>
                        </tr>
                        <tr class="border_bottom_none ordered_head"
                           *ngFor="let item of getdata.data.lineItemsInfo;let index = index">
                           <td class="w_60 radiusrounded_apply">
                              <div class="d-flex align-items-top right_border">
                                 <div class="position-relative">
                                    <img src="{{imgUrl+item.product.productImages}}"
                                       *ngIf="!item.product.productImages.includes('mp4')" class="img-product">
                                    <div class="addon_status" *ngIf="item.attribute.cartType == 'sub' ">Addon</div>
                                    <video src="{{imgUrl+item.product.productImages}}"
                                       *ngIf="item.product.productImages.includes('mp4')" autoplay controls
                                       class="img-product"></video>
                                    <div *ngIf="item.product.designType == 'DESIGN'"><button style="margin-top: 5px;"
                                          class="btn-status" (click)="showSubItem(index)"> View Design</button></div>
                                 </div>
                                 <div class="ml-4 w_64">
                                    <h2>{{item.product.productName | titlecase}}</h2>
                                    <div class="d-flex align-items-center bordered_style">
                                       <h3>Quantity</h3>
                                       <h4> {{item.quantity}}</h4>
                                    </div>
                                    <div class="d-flex align-items-center bordered_style"
                                       *ngIf="item.product.designType !='GIFT' ">
                                       <h3>Weight</h3>
                                       <h4>
                                          <!-- <span *ngIf="item.product.designType == 'GIFT' "> 1</span> -->
                                          <span
                                             *ngIf="item.product.designType != 'GIFT' ">{{item?.product?.isKg==true?item.pricing.kg+'
                                             Kg':item.pricing.kg > 1 ? item.pricing.kg+' Nos':item.pricing.kg+' No'}}
                                          </span>
                                       </h4>
                                    </div>
                                    <div class="d-flex align-items-center bordered_style">
                                       <h3>Flavour</h3>
                                       <h4><span>{{item.pricing.flavour ? item.pricing.flavour : 'NA'}}</span></h4>
                                    </div>
                                    <div class="d-flex align-items-center bordered_style">
                                       <h3>Made with</h3>
                                       <h4>
                                          <span *ngIf="item.attribute.cartType == 'main' ">{{item.attribute.eggType ?
                                             item.attribute.eggType : 'NA'}}</span>
                                          <span *ngIf="item.attribute.cartType == 'sub' ">{{item.attribute.eggType ?
                                             'NA' : 'NA'}}</span>
                                       </h4>
                                    </div>
                                    <div class="d-flex align-items-center">
                                       <h3>Text on cake</h3>
                                       <h4>
                                          <span *ngIf="item.attribute.message">{{item.attribute.message}}</span>
                                          <span *ngIf="item.attribute.message == '' ">NA</span>
                                       </h4>
                                    </div>
                                 </div>
                              </div>
                           </td>
                           <td class="w_14 ">
                              <div class="right_border d-flex align-items-center p_20"><span class="mr-0.5">₹</span>
                                 {{item.costDetails.itemPrice | number: '1.2-2'}}
                              </div>
                           </td>
                           <td class="w_14">
                              <div class="right_border d-flex align-items-center"><span class="mr-0.5">₹</span>
                                 {{item.costDetails.price | number: '1.2-2'}}
                              </div>
                           </td>
                           <td class="w_14">
                              <div class="right_border d-flex align-items-center p_20"><span class="mr-0.5">₹</span>
                                 {{item.costDetails.discount | number: '1.2-2'}}
                              </div>
                           </td>
                           <td class="rounded_radius w_14">
                              <div class="right_border border_unset d-flex align-items-center"><span
                                    class="mr-0.5">₹</span>
                                 {{item.costDetails.total | number: '1.2-2'}}
                              </div>
                           </td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
            <div class="col-lg-12">
               <div class="net_amount d-flex align-items-center justify-content-between">
                  <div>
                     <label class="mb-0" for="">Total Net Amount</label>
                  </div>
                  <div>
                     <label class="mb-0" for="" class="fs_final"> <span
                           class="mr-0.5">₹</span>{{newSubtotal(getdata?.data)| number: '1.2-2'}}</label>
                  </div>
               </div>
            </div>
            <div class="col-lg-5"></div>
            <div class="col-lg-7">
               <div class="card_new mt-4 mb-4">
                  <table class="bg_card w_full text_right">
                     <tr>
                        <th>Total Price</th>
                        <td><span class="mr-0.5">₹</span>{{calculation?.total?.toFixed(2)}}</td>
                     </tr>
                     <tr *ngIf="calculation?.coupon>0">
                        <th>Coupon <span class="ml-1">(-)</span></th>
                        <td><span class="mr-0.5">₹</span>- {{calculation?.coupon?.toFixed(2)}}</td>
                     </tr>
                     <tr>
                        <th>Discount <span class="ml-1">(-)</span></th>
                        <td><span class="mr-0.5">₹</span>- {{calculation?.discount?.toFixed(2)}}</td>
                     </tr>
                     <tr>
                        <th>Net Amount</th>
                        <td><span class="mr-0.5">₹</span>{{calculation?.netAmount?.toFixed(2)}}</td>
                     </tr>
                     <tr *ngIf="this.getdata2[1]?.homeAddress?.available==true">
                        <th>Delivery Charge <span class="ml-1">(+)</span></th>
                        <td> <span class="mr-0.5">₹</span>{{calculation?.delivery.toFixed(2)}}
                        </td>
                     </tr>
                     <tr *ngIf="calculation?.midNight>0">
                        <th>Midnight Delivery Charge<span class="ml-1">(+)</span></th>
                        <td><span class="mr-0.5">₹</span>{{calculation?.midNight?.toFixed(2)}}</td>
                     </tr>
                     <tr class="border_bottom_none">
                        <th class="fw_bold">Total Order Amount</th>
                        <td class="final_amount"><span class="mr-0.5">₹</span>{{calculation?.grandTotal?.toFixed(2)}}
                        </td>
                     </tr>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <!-- Modal change order status -->
      <div class="modal fade" id="confirm-order" tabindex="-1" role="dialog">
         <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title">Confirmation</h5>
                  <button type="button" class="close" data-dismiss="modal" (click)='close()' aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <h5 ngIg="orderMessage">{{orderMessage}}</h5>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" (click)='close()' data-dismiss="modal"
                     (click)="orderStatusCancel()">No</button>
                  <button type="button" class="button-1 btn-pink" (click)='close()' data-dismiss="modal"
                     (click)="orderChangeStatusfun()">Yes</button>
               </div>
            </div>
         </div>
      </div>
      <div class="modal fade" id="shipped-order" tabindex="-1" role="dialog">
         <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title">Shipping Details</h5>
                  <button type="button" class="close" data-dismiss="modal" (click)='close1()' aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <form [formGroup]="form">
                     <div formGroupName="shippingDetails">
                        <label>Delivery Agent Name</label>
                        <input type="text" #name class="form-control" formControlName="agentName"
                           placeholder="Enter Delivery Agent Name">
                        <br>
                        <label>Tracking Id</label>
                        <input type="text" #id class="form-control" formControlName="trackingId"
                           placeholder="Enter Tracking Id">
                        <br>
                        <label>Tracking URL</label>
                        <input type="text" #url class="form-control" formControlName="trackingUrl"
                           placeholder="Enter Tracking URL">
                        <br>
                        <div class="invalid-text" *ngIf="shipError"> All Field Required </div>
                     </div>
                  </form>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" data-dismiss="modal"
                     (click)="orderStatusCancel();close1()">Close</button>

                  <button type="button" class="button-1 btn-pink"
                     (click)="orderChangeToShipped(name.value,id.value,url.value)">Shipped</button>
               </div>
            </div>
         </div>
      </div>

   </div>
</section>

<track-order [trackOrder]="trackOrder1" [random]="random"></track-order>