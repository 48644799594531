import { Injectable, NgZone } from '@angular/core';

import { mergeMapTo, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { environment } from 'environments/environment';
// import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { ApiService } from '../otherServices/api.service';
import { Router } from '@angular/router';
// import { GenerateRouteService } from './generate-route.service';
import { GenerateRouteService } from '../otherServices/generate-route.service';
import { decodedToken } from '../helpers/token.helper';
import { get } from 'lodash';
// import { CurrentUserService } from './current-user.service';
import { CurrentUserService } from '../otherServices/currentuser.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AppConstants } from 'src/app/constants/app.constants';
import { PermissionService } from './permission.service';

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    removeMessage = new BehaviorSubject(null);
    reloadNotificationList = new BehaviorSubject(null);
    users: any = [];
    config = AppConstants.SERVER_CONFIG;
    baseurl = this.config[this.config.USE_URL];
    currentUser:any;

    constructor(
        private httpClient: HttpClient,
        private apiService: ApiService,
        private router: Router,
        private ngZone: NgZone,
        private generateRouteService: GenerateRouteService,
        private currentUserService: CurrentUserService,
        private angularFireMessaging: AngularFireMessaging,
        public rolePermission : PermissionService
      ) {
        this.users = this.currentUserService.users;
        this.permission = this.isSupported() ? 'default' : 'denied';
        this.requestPermissions();
        this.currentUser=JSON.parse(localStorage.getItem('currentUser'))
        
    }


    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */

    requestPermission(userId) {
        // console.log("enter FCM")
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                console.log('-- FCM Token -- ', token);
                const tokens = decodedToken();
                var type = get(tokens, 'type')
                if(this.rolePermission.hasPermission(['notification'],['notification_view'])){
                    this.sendFCMToken(token);
                }

            }, (err) => {
                console.error('Unable to get permission to notify.', err);
         }
        );
    }
// update FCM token with call 
    sendFCMToken(token) {
let id = this.currentUser.id;
console.log(id);


        // let obj = { token: token }
        let params = new HttpParams({
            fromObject:{
                fcmToken:token
            }
        })
        return this.httpClient.put(this.baseurl +`/admin/fcmTokenUpdate/${id}`,params)
            .toPromise()
            .then(this.handleData)
            .catch(this.handleError);
    }

    private handleData(res: any) {
        const body = res;
        return body || {};
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log('new message received. ', payload);
                const notifyMsg = payload['data'];
                if (notifyMsg) {
                    notifyMsg.title = notifyMsg.title ? notifyMsg.title : '';
                    notifyMsg.data = notifyMsg.data ? JSON.parse(notifyMsg.data) : '';
                        this.notify(notifyMsg.title, {
                            body: notifyMsg.body, icon: 'favicon.ico',
                            page: notifyMsg.page, data: notifyMsg.data,
                        });
                        this.currentMessage.next(notifyMsg);
                        this.appendQueueCount(1);

                }
            });
    }

    appendQueueCount(num) {
        let count;
        let oldNotification: any = localStorage.getItem('queueCount');
        let oldNotificationList: any = JSON.parse(oldNotification);
        const append = num !=0  ? (oldNotificationList + 1) : (oldNotificationList - 1);
        this.generateRouteService.setQueueCount(append);
    }

    requestPermissions(): void {
        let self = this;
        if ('Notification' in window) {
            Notification.requestPermission(function (status) {
                return self.permission = status;
            });
        }
    }

    public permission: Permission;

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    notify(title, options) {
        this.generateNotification(title, options);
    }

    generateNotification(title, options): void {
        let self = this;
        let notify = self.create(title, options).subscribe();
    }
    deleteToken() {
        this.angularFireMessaging.getToken
            .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
            .subscribe((token) => { console.log('Token deleted!')});
    }
    create(title: string, options?: PushNotification): any {
        let self = this;
        return new Observable((obs) => {
            if (!('Notification' in window)) {
                console.log('Notifications are not available in this environment');
                obs.complete();
            }
            if (self.permission !== 'granted') {
                console.log("The user hasn't granted you permission to send push notifications");
                obs.complete();
            }
            let _notify = new Notification(title, options);
            _notify.onshow = (e) => { return obs.next({ notification: _notify, event: e }); };
            _notify.onclick = (e) => {
                this.ngZone.run(() => {
                    let parsedData = (options['data']);
                    console.log(options['data'])
                    if (options['portal'] == "admin") {
                        this.generateRouteService.navigateRouteForGym(options['portal'], options['page'], parsedData);
                    }
                    else {
                        this.generateRouteService.navigateRouteForGym(options['portal'], options['page'], parsedData);
                    }
                });
            };
            _notify.onerror = (e) => { return obs.error({ notification: _notify, event: e }); };
            _notify.onclose = function () { return obs.complete(); };
        });
    }

}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
}