<div class="modal fade" id="trackOrderModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Track Order</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body" *ngIf="checkNull(data?.day)">
            <div>
               <div class="d-flex">
                  <p>Estimated Delivery Date</p>
               </div>
               <div class="track-date">
                  <p class="day">{{data?.day}}</p>
                  <p class="month">{{data?.month}}</p>
                  <div class="date-year">
                     <h1 class="date">{{data?.date}}</h1>
                     <span class="year">{{data?.year}}</span>
                  </div>
               </div>
             
            </div>
         </div>
         <div class="modal-footer d-block">
            
            <div class="tracking-process">

               <ng-container *ngFor="let item of status?.deliveryStatus;let i=index">
                  <div class="track-detail">
                     <div class="timing">
                        <p>{{item?.date|date:'mediumDate'}}</p>
                        <span>{{item?.date|date:'shortTime'}}</span>
                     </div>
                     <div class="circle">
                        <div *ngIf="(i+1)!=(status?.deliveryStatus?.length-1)" class="dots"></div>
                     </div>
                     <div class="">
                        <p><b>Activity: </b>{{item?.activity}}</p>
                        <p class="pb-0"><b>Location: </b>{{item?.location}}</p>
                     </div>
                  </div>
               </ng-container>
               <ng-container *ngIf=" !checkNull(status)||status?.deliveryStatus?.length==0">

                  <div class="" style="text-align: center;font-weight: 500;color: #ff7297;">
                     No delivery activities found
                  </div>

               </ng-container>

              
            </div>
         </div>
      </div>
   </div>
</div>