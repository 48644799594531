<section class="offset-width well-white">
    <app-header [pageName]="'Roles-Permission'"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>

    <div class="container-fluid">
        <h5>Administration</h5>

        <button type="button" *ngIf="permission.hasPermission(['role_permission'],['rolePermission_update'])"  class="button-1 btn-pink btn-margin" (click)="update()"><img src="./assets/images/img/edit.png" alt="">
            Update </button> <br><br>

        <div class="card bg-table-color">

            <div class="card-body">

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="item">
                                <th class="headcol" scope="col">PERMISSION </th>
                                <td *ngFor="let role of roles">
                                    <label for="label">{{role.role}}</label>
                                    <ui-switch size="small" color="#007bff" [checked]="selectedRole.has(role.id)"
                                    (change)="overAllSelect($event,role.id)"></ui-switch>
                                </td>
                            </tr>

                            <tr *ngFor="let menu of menus">
                                <th class="headcol" scope="col">{{ menu.menuName }}</th>
                                <td *ngFor="let role of roles;">
                                    <ui-switch size="small" color="#007bff" [checked]="permList.has(menu.menuId + ',' + role.id )"
                                    (change)="onValChange($event, menu.menuId + ',' + role.id)"></ui-switch>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>