import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormControlName, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { REGEXP } from "src/app/constants/custom-functions";
import { PuppysCustomValidation } from "src/app/shared/validations/puppys-custom";
import { CouponcodeService } from "../promocode-general/couponcode.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorMessageV2Service } from "src/app/services/otherServices/error-message-v2.service";
import { LoaderService } from "src/app/shared/service/loader.service";
import { Router } from "@angular/router";
import { PermissionService } from "src/app/services/core/permission.service";
declare var $: any;
interface Options { view: any, value: any }
const couponLabels = [{
  "name": "couponType",
  "label": "coupon type",
  "type": "select"
}, {
  "name": "customerName",
  "label": "customer name",
  "type": "select"
}, {
  "name": "productName",
  "label": "product name",
  "type": "select"
}, {
  "name": "couponName",
  "label": "coupon name",
  "type": "provide"
}, {
  "name": "couponCode",
  "label": "coupon code",
  "type": "provide"
}, {
  "name": "discountType",
  "label": "discount type",
  "type": "select"
}, {
  "name": "discountAmount",
  "label": "discount amount",
  "type": "provide",
  "min": 0,
  "number": 0
}, {
  "name": "discountPercentage",
  "label": "discount percentage",
  "type": "provide",
  "min": 0,
  "max": 100,
  "number": 0
}, {
  "name": "showToUser",
  "label": "showToUser",
  "type": "provide"
}, {
  "name": "description",
  "label": "description",
  "type": "provide",
  "min": 25,
  "number": 25
}, {
  "name": "usageFor",
  "label": "usageFor",
  "type": "provide"
}, {
  "name": "limitCustomerUsage",
  "label": "limitCustomerUsage",
  "type": "provide"
}, {
  "name": "limitCouponUsage",
  "label": "limitCouponUsage",
  "type": "provide"
}, {
  "name": "limit",
  "label": "coupon usage limit",
  "type": "provide"
}, {
  "name": "minimumAmount",
  "label": "minimum amount",
  "type": "provide",
  "min": 0,
  "number": 0
}, {
  "name": "maximumAmount",
  "label": "maximum amount",
  "type": "provide",
  "min": 0,
  "number": 0
}, {
  "name": "startDate",
  "label": "start date and time",
  "type": "select"
}, {
  "name": "endDate",
  "label": "end date and time",
  "type": "select"
}]

interface SelectOptions {
  singleSelection: boolean;
  idField: string;
  textField: string;
  selectAllText?: string;
  unSelectAllText?: string;
  itemsShowLimit: number;
  allowSearchFilter: boolean;
}

@Component({
  selector: "app-promocode-create",
  templateUrl: "./promocode-create.component.html",
  styleUrls: ["./promocode-create.component.css"],
})
export class PromocodeCreateComponent implements OnInit {


  createForm: FormGroup;
  products: Options[] = [];
  customers: Options[] = []
  couponTypeOptions: Options[] = [
    { view: 'General', value: '1' },
    { view: 'Customer', value: '2' },
    { view: 'Product', value: '3' }
  ]

  customerOptions: SelectOptions = {

    idField: 'value',
    textField: 'view',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    singleSelection: true,
  };

  productOptions: SelectOptions = {
    idField: 'value',
    textField: 'view',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    singleSelection: true,
  };

  constructor(
    private service: CouponcodeService,
    private toaster: ToastrManager,
    private errorMessage: ErrorMessageV2Service,
    private loader: LoaderService,
    private router: Router,
    public permission: PermissionService
  ) { }



  async ngOnInit(): Promise<void> {
    await this.getAllProducts()
    await this.getAllCustomers()
    this.initialForm()
  }



  initialForm() {
    this.createForm = new FormGroup({
      couponType: new FormControl('1', [Validators.required]),
      customerName: new FormControl(''),
      productName: new FormControl(''),
      couponName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      couponCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern(REGEXP.removeWhitespace)]),
      discountType: new FormControl('1', [Validators.required]),
      discountAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      discountPercentage: new FormControl(''),
      showToUser: new FormControl(false),
      description: new FormControl('', [Validators.required, Validators.minLength(25)]),
      // Restrictions
      usageFor: new FormControl('2', [Validators.required]),
      limitCustomerUsage: new FormControl(false),
      limitCouponUsage: new FormControl(false),
      limit: new FormControl(''),
      minimumAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      maximumAmount: new FormControl('', [Validators.required, Validators.min(1)]),

      // Duration
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    }, { validators: [PuppysCustomValidation.LimitValidator('minimumAmount', 'maximumAmount'), PuppysCustomValidation.DateAndTimeValidator('startDate', 'endDate')] })

    const startDate = this.createForm.get('startDate') as FormControl;
    const endDate = this.createForm.get('endDate') as FormControl;

    $("#startDate").datetimepicker({
      defaultDate: false,
      format: "d-m-Y H:i",
      yearStart: new Date().getFullYear(),
      minDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      minTime: moment(new Date()).format('HH:mm:ss'),
      step: 1,
      onClose: function (ct, $i) {
      },
      onChangeDateTime: function (ct, $i) {
        startDate.setValue(moment(ct).format('YYYY-MM-DD HH:mm:ss'));
        startDate.updateValueAndValidity()

      }

    })

    $("#endDate").datetimepicker({
      defaultDate: false,
      format: "d-m-Y H:i",
      yearStart: new Date().getFullYear(),
      minDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      step: 1,
      onClose: function (ct, $i) {
      },
      onChangeDateTime: function (ct, $i) {
        endDate.setValue(moment(ct).format('YYYY-MM-DD HH:mm:ss'));
        endDate.updateValueAndValidity()
      }
    })
  }

  get form() {
    return this.createForm.controls
  }


  async getAllProducts() {
    await this.service.getAllProducts().subscribe((res: any) => {
      this.products = res ?? []
    }, (err: any) => {
      this.products = []
    })
  }

  async getAllCustomers() {
    await this.service.getAllCustomers().subscribe((res: any) => {
      this.customers = res ?? []
    }, (err: any) => {
      this.customers = []
    })
  }


  couponTypeChange() {
    let type: '1' | '2' | '3' = this.createForm.get('couponType').value
    let customerName = this.createForm.get('customerName') as FormControl;
    let productName = this.createForm.get('productName') as FormControl;

    switch (type) {
      case '1': {
        customerName.setValue('')
        customerName.clearValidators();
        customerName.updateValueAndValidity();
        productName.setValue('')
        productName.clearValidators();
        productName.updateValueAndValidity();
      }
        break
      case '2': {
        customerName.setValue('')
        customerName.setValidators([Validators.required]);
        customerName.updateValueAndValidity();
        productName.setValue('')
        productName.clearValidators();
        productName.updateValueAndValidity();
      }
        break
      case '3': {
        customerName.setValue('')
        customerName.clearValidators();
        customerName.updateValueAndValidity();
        productName.setValue('')
        productName.setValidators([Validators.required]);
        productName.updateValueAndValidity();
      }
        break
    }
  }

  discountTypeChange() {
    const discountType = this.createForm.get('discountType').value;

    const discountAmount = this.createForm.get('discountAmount') as FormControl;
    const discountPercentage = this.createForm.get('discountPercentage') as FormControl

    switch (discountType) {

      case '1': {
        discountAmount.setValue('');
        discountAmount.setValidators([Validators.required, , Validators.min(1)]);
        discountAmount.markAsUntouched()
        discountAmount.updateValueAndValidity()

        discountPercentage.setValue('');
        discountPercentage.clearValidators();
        discountPercentage.markAsUntouched()
        discountPercentage.updateValueAndValidity()
      }
        break
      case '2': {
        discountPercentage.setValue('');
        discountPercentage.setValidators([Validators.required, , Validators.min(1), Validators.max(100)]);
        discountPercentage.markAsUntouched()
        discountPercentage.updateValueAndValidity()

        discountAmount.setValue('');
        discountAmount.clearValidators();
        discountAmount.markAsUntouched()
        discountAmount.updateValueAndValidity()
      }
        break
    }
  }

  limitChange() {
    const limitCouponUsage = this.createForm.get('limitCouponUsage').value;
    const limit = this.createForm.get('limit') as FormControl
    if (limitCouponUsage) {
      limit.setValue('');
      limit.setValidators([Validators.required, Validators.min(1)]);
      limit.markAsUntouched();
      limit.updateValueAndValidity()
    } else {
      limit.setValue('');
      limit.clearValidators();
      limit.markAsUntouched();
      limit.updateValueAndValidity()
    }

  }

  markAsTouched(control) {
    this.createForm.get(control).markAsTouched();
    this.createForm.get(control).updateValueAndValidity()
  }


  get isToday() {
    const startDate = moment(this.createForm.get('startDate').value).format('YYYY-MM-DD');
    const today = moment(new Date()).format('YYYY-MM-DD');
    return moment(startDate).isSame(today)
  }

  createCoupon() {
    const errors: any[] = this.errorMessage.invalidControls(this.createForm);
    const value = this.createForm.value
    if (errors.length == 0) {
      if (this.createForm.invalid) {
        this.toaster.errorToastr(this.createForm.errors.invalidDate ? 'Start date and time should be before end date and time ' : 'Please fill the mandatory fields')
        return
      }
      let data = {
        "couponCode": value?.couponCode,
        "couponEndDate": moment(value?.endDate).format(`YYYY-MM-DD HH:mm:ss`),
        "couponLimit": value?.limitCouponUsage == true ? value?.limit : null,
        "couponName": value?.couponName ?? '',
        "couponNewId": null,
        "couponStartDate": moment(value?.startDate).format(`YYYY-MM-DD HH:mm:ss`),
        "couponTypeId": value?.couponType,
        "customerEligibility": value?.usageFor == 2 ? true : false,
        "customerId": Number(value?.customerName[0]?.value) ?? '',
        "customerLimit": value?.limitCustomerUsage,
        "description": value?.description,
        "discountAmount": value?.discountType == 1 ? value?.discountAmount : value?.discountPercentage,
        "discountTypeId": value?.discountType == 1 ? true : false,
        "maximumSpend": value?.maximumAmount ?? 0,
        "minimumSpend": value?.minimumAmount ?? 0,
        "productId": (value?.productName[0]?.value) ?? '',
        "remainderDays": 0,
        "showToUser": value?.showToUser
      }
      this.loader.open();
      this.service.createCoupon(data).subscribe(async (res: any) => {
        await this.loader.close();
        await this.toaster.successToastr('Couponcode created successfully');
        await this.router.navigate([`/promocode-general`])
      }, (err) => {
        this.loader.close();
        this.toaster.errorToastr(err?.message ?? 'Try again');
      })
    } else {
      this.errorMessage.throwErrorMessage(this.createForm, errors, couponLabels)
    }



  }

}
