<section class="offset-width well-white">
    <div *ngIf="loader" class="loader-spin"> </div>
    <app-header [pageName]="'Banner'"></app-header>
    <div class="container-fluid">
        <h5>Short Link</h5>
        <div class="row ">
            <div class="text-end" *ngIf="rolepermission.hasPermission(['short_link'],['short_link_create'])">
                <button type="button" (click)="createShortLink()" class="button-1 btn-20 btn-pink btn-margin"> <i
                        class="fa fa-plus"></i>
                    Create Short Link </button>
            </div>

        </div>
        <br>
        <div class="row">
            <div class="card mb-5 table-bg bg-table-color">
                <div class="card-body ">
                    <br>
                    <div class="table-responsive overflow_visible">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Slug </th>
                                    <th scope="col">Desktop Version Url </th>
                                    <th scope="col">Mobile Version Url </th>
                                    <th scope="col" *ngIf="rolepermission.hasPermission(['short_link'],['short_link_update','short_link_delete'])">Action</th>
                                </tr>
                            </thead>
                           
                                <tr *ngFor="let item of getdata;let index = index">
                                    <td class="pt_1"> {{index + 1}} </td>
                                    <td class="pt_1"> {{item.title}} </td>
                                    <td class="cell_space">{{item.slug }} <label class="pl_none tooltip-container"><i style="cursor: pointer; margin-left: 4px;color: #0a58ca" class="fa fa-files-o" (click)="copyToClipBoard(item?.shortlinkReqURL)"></i><div class="tooltip">
                                        <h1 class="tooltip_style">Copy</h1>
                                      </div></label></td>
                                    <td class="cell_space"><a class="pl_none tooltip-container" target="_blank" href="{{item?.desktopVersionUrl ? item?.desktopVersionUrl : ''}}">{{getURL(item?.desktopVersionUrl)}}<div class="tooltip">
                                        <h1 class="tooltip_style">{{item?.desktopVersionUrl}}</h1>
                                      </div></a></td>
                                    <td class="cell_space"><a class="pl_none tooltip-container" target="_blank" href="{{item?.mobileVersionURL ? item?.mobileVersionURL : ''}}">{{getURL(item?.mobileVersionURL)}} <div class="tooltip">
                                        <h1 class="tooltip_style">{{item?.mobileVersionURL}}</h1>
                                      </div></a></td>
                                    <td class="cell_space" *ngIf="rolepermission.hasPermission(['short_link'],['short_link_update','short_link_delete'])">
                                        <img (click)="createShortLink(item)" *ngIf="rolepermission.hasPermission(['short_link'],['short_link_update'])" class="eye-icon pointer pl_none"
                                            src="/assets/images/icon/pencil-2.svg"> <i *ngIf="rolepermission.hasPermission(['short_link'],['short_link_delete'])"
                                            (click)="deleteShortLink(item.shortLinkId)" class="fa fa-trash btn-del"></i>
                                    </td>
                                </tr>

                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form>
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{displayName}} Short Link</h5>
                        <button type="button" class="close" data-dismiss="createModal" aria-label="Close" (click)="close()" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="shortLinkForm">
                        <label>Title <span style="color: red;">*</span></label><br>
                        <input class="form-control" formControlName="title" placeholder="Enter the title" (keyup)="generateSlug($event?.target.value)">
                        <div class="invalid-text" *ngIf="shortLinkForm?.get('title').hasError('required')&& shortLinkForm.get('title').touched">
                            Title is required
                        </div>
                        <label>Slug <span style="color: red;">*</span></label><br>
                        <input class="form-control"  formControlName="slug" placeholder="Enter the slug">
                        <div class="invalid-text" *ngIf="shortLinkForm?.get('slug').hasError('required')&& shortLinkForm.get('slug').touched">
                            Slug is required
                        </div>
                        <label>Desktop Version URL <span style="color: red;">*</span></label><br>
                        <input class="form-control" formControlName="website"  placeholder="Enter the desktop version URL">
                        <div class="invalid-text" *ngIf="shortLinkForm?.get('website').hasError('required')&& shortLinkForm.get('website').touched">
                            Desktop version URL is required
                        </div>
                        <div class="invalid-text" *ngIf="shortLinkForm?.get('website').hasError('pattern')">
                            Desktop version URL is invalid
                        </div>
                        <label>Mobile Version URL <span style="color: red;">*</span></label><br>
                        <input class="form-control" formControlName="mobile"  placeholder="Enter the mobile version URL">
                        <div class="invalid-text" *ngIf="shortLinkForm?.get('mobile').hasError('required')&& shortLinkForm.get('mobile').touched">
                            Mobile version URL is required
                        </div>
                        <div class="invalid-text"
                            *ngIf="shortLinkForm?.get('mobile').hasError('pattern')">
                            Mobile version URL is invalid
                        </div>
                    </form>
                    </div>


                    <div class="modal-footer">
                        <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()" >Close</button>
                        <button type="button"  class="button-1 btn-pink" (click)="submit()" > Submit
                            <i style="margin-left: 4px;" class="fa fa-long-arrow-right"></i> </button>
                    </div>
                </form>


            </div>
        </div>

    </div>



</section>