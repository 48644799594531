import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/core/permission.service';
declare var $: any;

@Component({
  selector: 'app-loyalty-programme',
  templateUrl: './loyalty-programme.component.html',
  styleUrls: ['./loyalty-programme.component.css'],
})
export class LoyaltyProgrammeComponent implements OnInit {
  loader;
  submitted;
  form: FormGroup;
  membersOfferForm: FormGroup;
  adminAccess;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private route: ActivatedRoute,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.emptyForm();
    this.get();
  }

  //form
  emptyForm() {
    this.submitted = false;
    this.form = this.formBuilder.group({
      id: [1, Validators.required],
      signUp: ['', Validators.required],
      referral: ['', Validators.required],
      referrer: ['', Validators.required],
      subscription: ['', Validators.required],
      review: ['', Validators.required],
      minAmount: ['', Validators.required],
      minRedeem: ['', Validators.required],
      orderPoints: ['', Validators.required],
      pointsValue: ['', Validators.required],
      gold: ['', Validators.required],
      diamond: ['', Validators.required],
      silver: ['', Validators.required],
      active: [true],
    });
  }
  close(){
    $('#createModal').modal('hide');
    $('#membersOfferModal').modal('hide')

  }

  getdata2;
  getdata;
  get() {
    this.loader = 'show';
    this.http.get('/admin/loyalty').subscribe(
      (data) => {
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        this.loader = '';
        this.setformvalue(this.getdata);
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  setformvalue(item) {
    this.form = this.formBuilder.group({
      id: [1, Validators.required],
      signUp: [item.signUp, Validators.required],
      referral: [item.referral, Validators.required],
      referrer: [item.referrer, Validators.required],
      subscription: [item.subscription, Validators.required],
      review: [item.review, Validators.required],
      minAmount: [item.minAmount, Validators.required],
      minRedeem: [item.minRedeem, Validators.required],
      orderPoints: [item.orderPoints, Validators.required],
      pointsValue: [item.pointsValue, Validators.required],
      gold: [item.gold, Validators.required],
      diamond: [item.diamond, Validators.required],
      silver: [item.silver, Validators.required],
      active: [true],
    });
  }

  onClickEdit() {
    this.submitted=false;
    this.setformvalue(this.getdata)
    $('#createModal').modal('show');
  }

  //submit form
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loader = 'show';
    this.http.put('/admin/loyalty/1', this.form.value).subscribe(
      (data) => {
        this.loader = '';
        this.toastr.successToastr('Successfully Updated', '');
        $('#createModal').modal('hide');
        this.get();
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  memberDetails;
  @ViewChild('offerPoints') offerPoints: ElementRef;
  @ViewChild('offerMobileNumber') offerMobileNumber: ElementRef;

  //////////Add points to user/////////////////
  onAddPointsToMember() {
    $('#membersOfferModal').modal('show');
    this.memberDetails = '';
    this.offerPoints.nativeElement.value = 0;
    this.offerMobileNumber.nativeElement.value = '';
  }

  onSubmitMembersOffer(mobile, points) {
    if (mobile.length != 10) {
      this.toastr.errorToastr('Enter Valid Mobile Number', '');
      return;
    }

    // if(points>0){
      
    // }
    points = Number(points).toFixed(0)
    this.loader = 'show';
    this.http
      .get(
        '/admin/loyalty/membership?mobileNumber=' + mobile + '&points=' + points
      )
      .subscribe(
        (data: any) => {
          this.loader = '';
          this.memberDetails = data.content;

          if (points != 0) {
            this.toastr.successToastr('Points Added Successfully', '');
            $('#membersOfferModal').modal('hide');
          } else {
            //this.toastr.successToastr('Success', '');
          }
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr(error.message, '');
        }
      );
  }
}
