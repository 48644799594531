<section class="offset-width well-white">
  <!-- <div *ngIf="loader" class="loader-spin"> </div> -->
  <app-header [pageName]="'Transaction'"></app-header>
  <div class="container-fluid">

    <div class="row">
      <div class="col-lg-2 List-item">
        <h5>Transaction</h5><br>
      </div>

    </div>
    <div class="card-body card-design">

      <form [formGroup]="filterForm">
        <div class="filter-filed">

          <div>
            <label class="label_style">From Date</label>
            <input formControlName="startDate" type="date" placeholder="DD-MM-YYYY" #orderFrom
              class="form-control input_style">
          </div>
          <div>
            <label class="label_style">To Date</label>
            <input formControlName="endDate" type="date" placeholder="DD-MM-YYYY" #orderTo
              class="form-control input_style">
          </div>

          <div>
            <div class="relative">
              <label class="label_style">Status</label>
              <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
              <select formControlName="status" class="form-control form-control-sm input_style">
                <option [selected]="true" disabled value="">Select Status</option>
                <option *ngFor="let data of status" [value]="data">{{data|titlecase}}</option>
              </select>
            </div>
          </div>

        </div>
      </form>

      <div class="my-18 filter-filed">
        <div class="relative">
          <label class="label_style">Search</label>
          <img class="search_img" src="../../../assets/images/icon/search.svg" alt="image">
          <input #filterInput type="text" placeholder="Search by Order Id"
            class="form-control form-control-sm input_style input-style padding_add">
        </div>
        <div class="filter-filed">
          <button *ngIf="hasOneValue" (click)="getAllTransactions()"
            class="btn btn-sm btn-reset btn_search">Search</button>
          <button *ngIf="hasOneValue" (click)="reset()" class="btn btn-sm btn-reset reset_btn tooltip-container ml-4">
            <img src="../../../assets/images/logo/reset.svg" alt="">
            <div class="tooltip">
              <h1 class="tooltip_style">Reset</h1>
            </div>
          </button>
          <!-- <button class="btn btn-sm btn-reset btn_search refresh-payment ml-4">Refresh Payment</button> -->
        </div>
      </div>
    </div>
  </div>
  <div class="card col-12 table-bg">
    <div class="card-body ">
      <div class="row">

        <!--  -->
        <div class="table-responsive"
          *ngIf="permission.hasPermission(['transaction'],['transaction_view', 'transaction_update'])">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">DATE</th>
                <th scope="col">ORDER ID</th>
                <th scope="col">PAYMENT REF ID</th>
                <!-- <th scope="col">Amount</th> -->
                <th scope="col"></th>
                <th scope="col">STATUS</th>
              </tr>
            </thead>
            <!-- -98*549 -->
            <tbody>
              <tr class="" *ngFor="let data of transactions;let i = index;">
                <td>{{data.date}}</td>
                <td style="text-align: center;">

                  <span *ngIf="data?.orderId==''">-</span>
                  <li style="cursor: pointer;" (click)="navigate(data?.orderId)" class="nav-item pan_india"
                    *ngIf="data?.orderId!=''">
                    <!-- <img class="tag" src="../../../assets/images/icon/tag.svg" alt="image"> -->
                    <!-- <img class="path_1" src="../../../assets/images/img/path_1.png" alt="">
                    <img class="path_2" src="../../../assets/images/img/path_2.png"> -->
                    {{data.orderId!='' ?data.orderId : '-' }}
                  </li>
                </td>


                <td>{{data.referenceId!='' ?data.referenceId : '-' }}</td>
                <!-- <td>{{data.amount!='' ?data.amount : '-' }}</td> -->

                <!-- success failed Pending-->

                <td style="cursor: pointer;"> <i
                    *ngIf="!successStatus.includes(data?.status)&&permission.hasPermission(['transaction'],['transaction_update'])"
                    (click)="refershStatus(data,i)" class="fa fa-refresh" aria-hidden="true"></i></td>

                <td><span class="payment-status"
                    [ngClass]="failedStatus.includes(data?.status)  ? 'failed' : successStatus.includes(data?.status) ?'success':'Pending' ">{{data?.status}}</span>
                </td>

              </tr>

            </tbody>
          </table>

          <div class="d-flex justify-content-center" style="font-weight: 500;" *ngIf="transactions.length==0">NO
            TRANSACTIONS FOUND</div>

          <div class="pagination-align" *ngIf="transactions.length!=0">
            <app-paginator [lastPage]="pagination.lastPage" (paginate)="paginate($event)"></app-paginator>

          </div>
        </div>
      </div>
    </div>
    <br><br>
  </div>
</section>