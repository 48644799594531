<section class="offset-width well-white">
   <app-header [pageName]="'Enquiry Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Enquiry Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row align_items_end">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>


               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <ng-multiselect-dropdown [formControl]="filterForm?.get('formName')" class="multi_selectStyle"
                        [placeholder]="'Select Enquiry Type '" [settings]="dropdownSettings" [data]="formType">
                     </ng-multiselect-dropdown>
                  </div>
               </div>


               <div class="col-lg-2 col-xl-3 col-md-2 text-align-right">
                  <div class="d-flex align-items-center">
                     <button (click)="getEnquiryReport(true)"
                        class="btn btn-sm btn-reset btn_search product_btn">Search</button>
                     <button (click)="reset()" *ngIf="hasOneValue||reports?.length>0" class="btn btn-sm reset_btn tooltip-container ml-4">
                        <img src="../../../assets/images/logo/reset.svg" alt="icon">
                        <div class="tooltip">
                           <h1 class="tooltip_style">Reset</h1>
                        </div>
                     </button>
                     <button *ngIf="reports?.length>0" (click)="export()"
                        class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4">
                        <img src="../../../assets/images/logo/order_last.svg" alt="icon">
                        <div class="tooltip">
                           <h1 class="tooltip_style">Export</h1>
                        </div>
                     </button>
                  </div>
               </div>
            </div>
            <div class="row  mt-4">

            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Name
                        </th>
                        <th scope="col">Mobile No</th>
                        <th scope="col">
                           Email Id
                        </th>
                        <th scope="col">Enquiry Type</th>
                        <th scope="col">Address</th>
                        <th scope="col">Cake Preference</th>
                        <th scope="col">Image Reference</th>
                        <th scope="col">Delivery Date & Time</th>
                        <th scope="col">
                           Subject
                        </th>
                        <th scope="col">
                           Delivery Type
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of reports">
                        <td class="pl-26">{{ (item.createdDate|date :'dd-MM-yyyy hh:mm a')|emptyText }}</td>
                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.name }}</span>
                              <div *ngIf="checkNull(item.name)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.name }}</h1>
                              </div>
                           </div>
                        </td>
                        <td class="text-left">
                           {{item.mobileNumber|emptyText:'-'}}
                        </td>
                        <td>
                           <div class="tooltip-container">
                              <span class="trim_text"> {{item.emailId|emptyText:'-'}}</span>
                              <div *ngIf="checkNull(item.emailId)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0"> {{item.emailId|emptyText:'-'}}</h1>
                              </div>
                           </div>

                        </td>
                        <td>{{ getFormType(item.formName) }}</td>


                        <td>
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.address |emptyText }}</span>
                              <div *ngIf="checkNull(item.address)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.address }}</h1>
                              </div>
                           </div>
                        </td>
                        <td>
                           <div class="tooltip-container">
                              <span class="trim_text"> {{ item.cakeReference |emptyText}}</span>
                              <div *ngIf="checkNull(item.cakeReference)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0"> {{ item.cakeReference }}</h1>
                              </div>
                           </div>
                        </td>
                        <td><span *ngIf="!checkNull(item?.imageReference)&&item?.imageReference=='-'">-</span><img
                              *ngIf="checkNull(item?.imageReference)&&item?.imageReference!='-'" class="img_reference"
                              [src]="item?.imageReference" alt=""></td>
                        <td>{{ (checkNull(item.deliveryDateTime) ? (item?.deliveryDateTime)
                           :'-') }}
                        </td>
                        <td>
                           <div class="tooltip-container">
                              <span class="trim_text"> {{ item.description|emptyText }}</span>
                              <div *ngIf="checkNull(item.description)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0"> {{ item.description|emptyText }}</h1>
                              </div>
                           </div>
                        </td>

                        <td>{{ item.deliveryType|emptyText }}</td>


                     </tr>
                  </tbody>
               </table>

               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO ENQUIRY RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>