import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;
import { AppConstants } from '../../constants/app.constants';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.css'],
})
export class CategoriesListComponent implements OnInit {
  loader;
  categoryForm: FormGroup;
  submitted = false;
  imgPth;
  haveImg;
  mainDomain;
  config;
  adminAccess;
  @ViewChild('file') file: ElementRef;
  @ViewChild('uName') searchValue: ElementRef;
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private router: Router,
    private route: ActivatedRoute,
    public rolepermission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.loader = '';
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.mainDomain = AppConstants.SERVER_CONFIG.DOMAIN;
    this.haveImg = '';
    this.submitted = false;
    this.emptyForm();
    this.getCategoryName();

    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: '',
    };

    this.route.queryParams.subscribe((params) => {
      this.pageValue(params['page']);
    });
  }

  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get();
      window.scrollTo(0, 0);
    } else {
      this.config.currentPage = 1;
      this.get();
    }
  }

  pageChange(newPage: number) {
    this.router.navigate(['/categories'], { queryParams: { page: newPage } });
  }

  close() {
    $('#createModal').modal('hide');
  }

  //////search function//////
  svalue: any;
  search(searchvalue) {
    this.svalue = searchvalue;
    setTimeout(() => {
      if (this.svalue == searchvalue) {
        this.config.currentPage = 1;
        this.get(searchvalue);
      }
    }, 1000);
  }

  //get data
  getdata2;
  getdata;
  noResult = false;
  get(search?) {
    if (!search) {
      search = '';
    }

    this.noResult = false;
    this.loader = 'show';
    this.http
      .get(
        '/admin/category/page?page=' +
          this.config.currentPage +
          '&search=' +
          search
      )
      .subscribe(
        (data) => {
          this.getdata2 = data;
          this.getdata = this.getdata2.content;
          this.loader = '';
          if (this.getdata2.content.length == 0) {
            this.noResult = true;
          }
          this.config = {
            currentPage: this.getdata2.currentPage,
            itemsPerPage: this.getdata2.currentPageCategories,
            totalItems: this.getdata2.totalCategories,
          };
        },
        (error) => {
          this.toastr.errorToastr('Try Again', '');
          this.loader = '';
          this.noResult = true;
        }
      );
  }

  emptyForm() {
    this.submitted = false;
    this.categoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required],
      parentCategoryId: ['', Validators.required],
      subCategoryName: ['', Validators.required],
      level: [''],
      shortCode: ['', Validators.required],
      sortOrder: ['', Validators.required],
      imagePath: [''],
      showUser: [true],
      formType: [0],
      active: true,
    });
  }

  selected(id) {
    console.log(id);
    let cName = this.getdata4.filter((x) => x.categoryId == id);
    console.log(cName);
    if (cName?.length > 0) {
      this.categoryForm.get('subCategoryName').setValue(cName[0]?.categoryName);
    } else {
      this.categoryForm.get('subCategoryName').setValue(0);
    }

    console.log(this.categoryForm.value);
  }
  imgUrl;
  fileImg;
  fileName;
  /* uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    if (event.target.files && event.target.files[0]) {
      this.haveImg = 'show';
      var reader = new FileReader();
      reader.onload = (event: any) => {

        //////upload file and get path///////
        if (this.fileImg) {
          this.loader = 'show';
          const formData: FormData = new FormData();
          formData.append('image', this.fileImg, this.fileName);
          this.http.post('/uploadimage', formData).subscribe(
            data => {
              this.path = data;
              this.imgUrl = this.path.path;
              this.categoryForm.controls["imagePath"].setValue(this.imgUrl);
              this.loader = '';
            },
            error => {
              this.toastr.errorToastr('Try Again', '');
              this.loader = '';
            });
        }

      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }*/

  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    // maximum dimensions
    const max_width = 350;
    const max_height = 350;
    // file types supported  && ********(here only included the images)*********
    const filetypes = [
      'image/jpeg',
      'image/png',
      // 'video/mp4',
      'image/jpg',
      'image/jfif',
    ];
    // maximum file size  in MB
    const max_size = 50;

    if (this.fileImg) {
      // file size validation
      if (this.fileImg.size < 1024 * 1024 * max_size) {
        // file type validation
        if (!_.includes(filetypes, this.fileImg.type)) {
          this.file.nativeElement.value = '';
          this.loader = '';
          this.toastr.errorToastr(
            'Please provide the file with valid  file  type'
          );
        } else {
          //file dimensionvalidation
          const reader = new FileReader();
          const image = new Image();
          // imp
          reader.readAsDataURL(this.fileImg);

          reader.onload = (read: any) => {
            image.src = read.target.result;
            image.onload = (load: any) => {
              const height = load.currentTarget['height'];
              const width = load.currentTarget['width'];
              if (max_height == height && max_width == width) {
                if (this.fileImg) {
                  this.loader = 'show';
                  const formData: FormData = new FormData();
                  formData.append('image', this.fileImg, this.fileName);
                  formData.append('fileType', 'catagory');
                  formData.append('thumbnail', 'true');
                  this.http.post('/uploadimage', formData).subscribe(
                    (data) => {
                      this.path = data;
                      this.imgUrl = this.path.path;
                      this.categoryForm.controls['imagePath'].setValue(
                        this.imgUrl
                      );
                      this.loader = '';
                      this.haveImg = 'show';
                      this.toastr.successToastr(
                        'File uploaded successfully',
                        ''
                      );
                    },
                    (error) => {
                      this.toastr.errorToastr('Try Again', '');
                      this.loader = '';
                      this.haveImg = '';
                    }
                  );
                }
              } else {
                this.toastr.errorToastr(
                  'Please provide valid file dimension of ' +
                    max_height +
                    '×' +
                    max_width +
                    'pixels'
                );
                this.file.nativeElement.value = '';
                this.loader = '';
                this.haveImg = '';
              }
            };
          };
        }
      } else {
        this.toastr.errorToastr('Please provide the file with valid file type');
        this.file.nativeElement.value = '';
        this.loader = '';
      }
    }
  }

  deleteImage() {
    this.fileImg = '';
    this.imgUrl = '';
    this.haveImg = '';
    this.categoryForm.controls['imagePath'].setValue('');
    this.file.nativeElement.value = '';
  }

  editData;
  parentId;
  showEditModal(id) {
    this.fileImg = '';
    this.imgUrl = '';
    this.haveImg = '';
    this.submitted = false;
    this.emptyForm();
    console.log(id);

    this.isEdited = 'edited';
    this.editData = this.getdata.filter((element) => element.categoryId == id);

    if (this.editData[0].parentCategoryId) {
      this.parentId = this.editData[0].parentCategoryId;
    } else {
      this.parentId = '0';
    }

    this.categoryForm = this.formBuilder.group({
      categoryName: [this.editData[0].categoryName, Validators.required],
      parentCategoryId: [this.parentId, Validators.required],
      subCategoryName: [this.editData[0].subCategoryName],
      level: [this.editData[0].level],
      shortCode: [this.editData[0].shortCode, Validators.required],
      sortOrder: [this.editData[0].sortOrder, Validators.required],
      imagePath: [this.editData[0].imagePath],
      categoryId: [this.editData[0].categoryId],
      showUser: [this.editData[0].showUser],
      formType: [this.editData[0].formType],
      active: true,
    });

    if (this.editData[0].imagePath) {
      this.imgUrl = this.editData[0].imagePath;
      this.haveImg = 'show';
    }

    $('#createModal').modal('show');
  }

  ///////////create//////////////
  isEdited;
  createCategory() {
    this.fileImg = '';
    this.imgUrl = '';
    this.haveImg = '';
    this.submitted = false;
    this.emptyForm();
    $('#createModal').modal('show');
    this.isEdited = '';
  }

  /////////onsubmin form////////////
  path2;
  path;
  // convenience getter for easy access to form fields
  get f() {
    return this.categoryForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    }

    if (this.isEdited == 'edited') {
      this.loader = 'show';
      this.http
        .put(
          '/admin/category/' + this.categoryForm.controls['categoryId'].value,
          this.categoryForm.value
        )
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Successfully updated', '');
            this.emptyForm();
            $('#createModal').modal('hide');
            this.searchValue.nativeElement.value = '';
            this.get();
            this.getCategoryName();
          },
          (error) => {
            this.loader = '';
            if (error.message) {
              this.toastr.errorToastr(error.message, '');
            } else {
              this.toastr.errorToastr('Try Again', '');
            }
          }
        );
    } else {
      this.loader = 'show';
      this.http.post('/admin/category', this.categoryForm.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          $('#createModal').modal('hide');
          this.searchValue.nativeElement.value = '';
          this.get();
          this.getCategoryName();
        },
        (error) => {
          this.loader = '';
          if (error.message) {
            this.toastr.errorToastr(error.message, '');
          } else {
            this.toastr.errorToastr('Try Again', '');
          }
        }
      );
    }
  }

  //Delete Category
  deleteCategory(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Confirm?',
      text: 'Are you sure? You want to delete it!',
      showCancelButton: true,
      confirmButtonColor: '#FD7297',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.loader = 'show';
        this.http.delete('/admin/category?id=' + id).subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Category Deleted Successfully', '');
            this.emptyForm();
            this.get();
            this.getCategoryName();
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr(error.message, '');
          }
        );
      }
    });
  }

  //get Category List
  getdata3;
  getdata4;
  getCategoryName() {
    this.loader = 'show';
    this.http.get('/admin/category/all').subscribe(
      (data) => {
        this.getdata3 = data;
        console.log(this.getdata3, 'log for te parent ');
        this.getdata4 = this.getdata3.content;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }
}
