import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
import { AppConstants } from '../../constants/app.constants';
import { Router } from '@angular/router';
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ThumbnailsPosition,
  ImageSize,
} from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import { Lightbox } from '@ngx-gallery/lightbox';
import Swal from 'sweetalert2';
import { HttpEventType, HttpParams } from '@angular/common/http';
import { PermissionService } from 'src/app/services/core/permission.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css'],
})
export class ProductsListComponent implements OnInit {
  loader;
  imgPth;
  mainDomain;
  adminAccess;
  data;

  items: GalleryItem[];
  fileDesign: any;
  fileDesignName: any;
  designImgurlData: Object;
  filename: any;
  bulkUploadError: any[] = [];
  deliveryButton: boolean = true;
  constructor(
    public http: HttpService,
    private route: ActivatedRoute,
    public toastr: ToastrManager,
    private router: Router,
    public gallery: Gallery,
    public lightbox: Lightbox,
    public rolepermission: PermissionService,
    private loaderservice: LoaderService
  ) { }
  config;
  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.loader = '';
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.mainDomain = AppConstants.SERVER_CONFIG.DOMAIN;

    // console.log(this.mainDomain, 'this is the main domain');


    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: '',
    };

    this.route.queryParams.subscribe((params) => {
      this.pageValue(params['page']);
    });
  }

  close() {
    $('#createModal').modal('hide');
    $('#createModal-2').modal('hide');

    //createModal
  }
  gallerypopup(img) {

    this.data = [];
    img.forEach(element => {
      this.data.push(
        {
          srcUrl: element,
          previewUrl: element,
        },
      );
    });
    this.items = this.data.map((item) => {
      return new ImageItem({
        src: `${this.imgPth + item.srcUrl}`,
        thumb: `${this.imgPth + item.previewUrl}`,
      });
    });
    this.basicLightboxExample();
    this.withCustomGalleryConfig();

  }
  //pagination
  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get();
      window.scrollTo(0, 0);
    } else {
      this.config.currentPage = 1;
      this.get();
    }
  }

  isVideoUrl(url) {
    return url && url.split('.').pop() == 'mp4';
  }

  pageChange(newPage: number) {
    this.router.navigate(['/products'], { queryParams: { page: newPage } });
  }

  //IMage Loader
  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }

  withCustomGalleryConfig() {
    // 2. Get a lightbox gallery ref
    const lightboxGalleryRef = this.gallery.ref('anotherLightbox');

    // (Optional) Set custom gallery config to this lightbox
    lightboxGalleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
    });

    // 3. Load the items into the lightbox
    lightboxGalleryRef.load(this.items);
  }
  // (click)="gallerypopup(items)"

  //Search
  svalue: any;
  search(searchvalue) {
    this.svalue = searchvalue;
    setTimeout(() => {
      if (this.svalue == searchvalue) {
        this.config.currentPage = 1;
        this.get(searchvalue);
      }
    }, 1000);
  }

  //sort
  sort(type) {
    this.config.currentPage = 1;
    this.get('', type);
  }

  sortData = '';
  getdata2;
  getdata;
  noResult = false;
  //get product with search, filter & sort
  get(search?, sorting?) {
    if (!search) {
      search = '';
    }

    if (sorting) {
      if (sorting == 'time') {
        this.sortData = '&time=yes';
      }
      if (sorting == 'category') {
        this.sortData = '&category=yes';
      }
      if (sorting == 'no') {
        this.sortData = '';
      }
    }

    this.noResult = false;
    this.loader = 'show';
    this.http
      .get(
        '/admin/product?page=' +
        this.config.currentPage +
        '&search=' +
        search +
        this.sortData
      )
      .subscribe(
        (data) => {
          this.getdata2 = data;
          this.getdata = this.getdata2.content;

          this.loader = '';
          if (this.getdata2.content.length == 0) {
            this.noResult = true;
          }
          this.deliveryButton = this.getdata.some((res) => res?.deliveryStatus == false) ? true : false
          this.config = {
            currentPage: this.getdata2.currentPage,
            itemsPerPage: this.getdata2.currentPageItems,
            totalItems: this.getdata2.totalItems,
          };
        },
        (error) => {
          this.toastr.errorToastr('Try Again', '');
          this.loader = '';
          this.noResult = true;
        }
      );
  }

  //select all product for delete (checkbox)
  checkBoxChecked = false;
  selectAll(event) {
    this.selection = [];
    if (event.target.checked === true) {
      //push
      this.selection = this.getdata;
      this.checkBoxChecked = true;
    } else {
      //remove
      this.selection = [];
      this.checkBoxChecked = false;
    }
  }

  selection = [];
  //onchange check box
  changeCheckbox(item: any, event) {
    if (event.target.checked === true) {
      //push
      this.selection = [...this.selection, item];
    } else {
      //remove
      this.selection = this.selection.filter((user) => user !== item);
    }
  }

  deleteId = [];
  //delete multiple product
  deleteProduct() {
    this.deleteId = [];
    this.selection.map((x) => {
      this.deleteId = [...this.deleteId, x.productId];
    });

    if (this.deleteId) {
      Swal.fire({
        icon: 'warning',
        title: 'Confirm?',
        text: 'Are you sure? You want to delete it!',
        showCancelButton: true,
        confirmButtonColor: '#FD7297',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.loader = 'show';
          this.http
            .delete('/admin/product?id=' + this.deleteId.toString())
            .subscribe(
              (data) => {
                this.loader = '';
                this.toastr.successToastr('Product Deleted Successfully', '');
                this.deleteId = [];
                this.get();
              },
              (error) => {
                this.loader = '';

                this.toastr.errorToastr('Try Again', '');
              }
            );
        }
      });
    }
  }



  deleteSingle(data) {

    Swal.fire({
      icon: 'warning',
      title: 'Confirm?',
      text: 'Are you sure? You want to delete it!',
      showCancelButton: true,
      confirmButtonColor: '#FD7297',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.loader = 'show';
        this.http.delete(`/admin/product/${data?.productId}`).subscribe((res: any) => {
          this.loader = '';
          this.toastr.successToastr('Product Deleted Successfully', '');
          this.deleteId = [];
          this.get();
        }, (err) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        })

      }
    })


  }
  onClickCreate() {
    $('#createModal').modal('show');

  }

  onClickCreateCake() {
    this.filename = ''
    $('#createModal-2').modal('show');

  }



  //  CSV BULK


  // CSV UPLOAD SECTION
  bulkUploadinCsv(event: any) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.fileDesign = event.target.files[0];
      this.filename = event.target.files[0].name;
      this.fileDesignName = this.fileDesign['name'];
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onSubmit() {
    if (this.fileDesign) {
      this.loader = 'show';
      const formData: FormData = new FormData();
      let dataType = this.fileDesignName.split('.').splice(-1);
      this.bulkUploadError = [];
      if (dataType == 'csv') {
        formData.append('file ', this.fileDesign);
        this.http.post('/api/csv/upload', formData).subscribe(
          (data) => {
            this.loader = '';
            $('#createModal-2').modal('hide');
            $('.modal-backdrop').remove();
            this.get();
            this.bulkUploadError = [];
          },
          (error) => {

            this.loader = '';
            if (error.data.length > 0) {
              this.bulkUploadError = error.data;
              $('#createModal-2').modal('hide');
              $('.modal-backdrop').remove();
              $('#response-modal').modal('show');
            } else {
              this.toastr.errorToastr('Try Again', '');
            }

          }
        );
      } else {
        this.loader = '';
        this.toastr.errorToastr('Please Upload CSV file', '');

      }
    }
  }
  bulkErrorClose() {
    $('#response-modal').modal('hide');
    $('.modal-backdrop').remove();
  }
  private status: any
  changeStatus(product: any) {
    console.log(product?.productId, product?.status, ' on toggle the status button');

    if (product?.status == 1) {
      this.status = '0'
    } else {
      this.status = '1'
    }


    const params = new HttpParams({
      fromObject: {
        productId: product?.productId,
        statusId: this.status

      }
    })

    this.http.getData('/admin/product/changeStatusById', params).
      subscribe((res: any) => {
        this.loader = 'show'
        if (res?.errorCode) {
          this.loader = ''
          this.toastr.successToastr(res?.msg, '')
          this.get();
        } else {
          this.toastr.errorToastr(res?.msg, '')
          this.loader = ''
          this.get();
        }

      })

  }

  changeDeliveryStatus(data) {
    let status = data?.deliveryStatus ? 0 : 1;

    const obj = {
      "productId": data?.productId,
      "deliveryStatusId": String(status)
    }
    let params = new HttpParams();
    for (let data in obj) {
      if (obj[data] != null && obj[data] != '' && obj[data] != undefined) {
        params = params.set(data, obj[data])
      }
    }

    this.http.getData('/admin/product/globalStatus', params).
      subscribe((res: any) => {
        this.loader = 'show'
        if (res?.errorCode) {
          this.loader = ''
          this.toastr.successToastr(res?.msg, '')
          this.get();
        } else {
          this.toastr.errorToastr(res?.msg, '')
          this.loader = ''
          this.get();
        }

      })

  }

  changeAllDeliveryStatus(status) {


    const obj = {
      "productId": 'All',
      "deliveryStatusId": status
    }
    let params = new HttpParams();
    for (let data in obj) {
      if (obj[data] != null && obj[data] != '' && obj[data] != undefined) {
        params = params.set(data, obj[data])
      }
    }

    this.http.getData('/admin/product/globalStatus', params).
      subscribe((res: any) => {
        this.loader = 'show'
        if (res?.errorCode) {
          this.loader = ''
          this.toastr.successToastr(res?.msg, '')
          this.get();
        } else {
          this.toastr.errorToastr(res?.msg, '')
          this.loader = ''
          this.get();
        }

      })

  }

  // not yet started

  onClickdownload() {
    console.warn('Bulk download is not started');
    let params = new HttpParams();
    let token = JSON.parse(localStorage.getItem('currentUser')).accessToken;
    // params = params.set('authorization', `Bearer ${token}`)
    this.http
      .FilterDownloadFile(`/api/csv/export`).subscribe(
        (data: any) => {
          switch (data.type) {
            case HttpEventType.DownloadProgress:

              this.loader = '';
              break;
            case HttpEventType.Response:

              this.loader = '';
              const downloadedFile = new Blob([data.body], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = `Product List`;
              a.href = URL.createObjectURL(downloadedFile);
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
          }
        },
        (error) => {


          this.loader = '';
          this.toastr.errorToastr('could not download', '');
        }
      );

  }
  update(item) {

    this.router.navigate([`/products/create-product`], { queryParams: { productId: item?.productId, version: item?.version ?? 0, type: item?.sku.includes('GIFT') ? 'gift' : 'product' } })
  }

  videoPreview(index) {
    var elem = document.getElementById(index);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    }
    //  else if (elem?.mozRequestFullScreen) {
    //   elem.mozRequestFullScreen();
    // } else if (elem?.webkitRequestFullscreen) {
    //   elem.webkitRequestFullscreen();
    // } else if (elem?.msRequestFullscreen) { 
    //   elem.msRequestFullscreen();
    // }
  }
}


