import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { NavigationService } from 'src/app/services/core/navigation.service';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit {
  categorie;
  homePage;
  menuItems: any = [];
  menuItemSub: Subscription;
  menuindex: any;
  subMenus = 'close'
  routeEventSub: any;
  constructor(
    private authenticationService: AuthenticationService,
    public navService: NavigationService,
    private router:Router
  ) {}
  role;
  currentUser;
  ngOnInit(): void {
    this.categorie = 'show';
    this.homePage = 'show';

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.privileges;
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      this.menuItems = res;
      console.log(this.menuItems, ' this.menuItems');
     let index = this.menuItems.findIndex((menu:any) => (menu?.sub?.find((subMenu:any) => this.router.url.includes(subMenu.state)))?.state != undefined );
     console.log(index,'index',this.router.url)
     this.open(index);
    });
    this.routeEvent(this.router);
  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }

  categoriesToggle() {
    if (this.categorie == 'show') {
      this.categorie = '';
    } else {
      this.categorie = 'show';
    }
  }

  homePageToggle() {
    if (this.homePage == 'show') {
      this.homePage = '';
    } else {
      this.homePage = 'show';
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  open(index){
    this.menuindex = index;
    if(this.subMenus == 'close'){
      this.subMenus = 'open';
    }
    else {
      this.subMenus = 'close';
    }
  }

  changeSubmenu(){
    this.menuindex = '';
    this.subMenus = 'close';
  }
  routeEvent(router: Router) {
    this.routeEventSub = router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        console.log('index',this.router.url)
        if (e.url) {
          console.log('index',this.router.url)
          if(this.menuItems.find((menu:any) => (menu?.sub?.find((subMenu:any) => this.router.url.includes(subMenu.state)))?.state != undefined)?.sub == undefined){
           this.changeSubmenu();
          }else{
            let index = this.menuItems.findIndex((menu:any) => (menu?.sub?.find((subMenu:any) => this.router.url.includes(subMenu.state)))?.state != undefined );
            console.log(index,'index',this.router.url)
            if(this.subMenus == 'close'){
            this.open(index);
            }
          }
         
        }
      }
    });
  }
}
