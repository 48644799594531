import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../constants/app.constants';
declare var $: any;
import * as _ from 'lodash';
import { ViewChild } from '@angular/core';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css'],
})
export class BlogListComponent implements OnInit {
  editBlog;
  blogList;
  isEdited;
  loader;
  form: FormGroup;
  submitted = false;
  @ViewChild('file') file: ElementRef;
  imgPth;
  mainDomain;
  adminAccess;
  // @ViewChild('file') file : ElementRef

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.blogList = 'show';
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.mainDomain = AppConstants.SERVER_CONFIG.DOMAIN;
    this.loader = '';
    this.submitted = false;
    this.emptyForm();
    this.get();
  }

  //form
  emptyForm() {
    this.submitted = false;
    this.imgUrl = [];
    this.form = this.formBuilder.group({
      author: ['', Validators.required],
      sortOrder: [''],
      content: ['', Validators.required],
      images: ['', Validators.required],
      createdOn: [''],
      updatedOn: [''],
      title: ['', Validators.required],
      active: true,
    });
  }

  getdata2;
  getdata;
  get() {
    this.loader = 'show';
    this.http.get('/admin/blogs').subscribe(
      (data) => {
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  //onclick show form
  onClickCreate() {
    if (this.editBlog == 'show') {
      this.blogList = 'show';
      this.editBlog = '';
    } else {
      this.blogList = '';
      this.editBlog = 'show';
    }

    this.emptyForm();
    this.isEdited = false;
  }

  //onclick show form with auto fill data
  onClickEdit(item) {
    this.isEdited = true;
    this.showEdit();
    this.imgUrl = []; //empty
    this.imgUrl.push(item.images[0]);

    this.form = this.formBuilder.group({
      blogsId: [item.blogsId],
      author: [item.author, Validators.required],
      sortOrder: [item.sortOrder],
      content: [item.content, Validators.required],
      images: [this.imgUrl, Validators.required],
      createdOn: [''],
      updatedOn: [''],
      title: [item.title, Validators.required],
      active: true,
    });
  }

  deleteImage() {
    this.fileImg = '';
    this.imgUrl = [];
    this.form.controls['images'].setValue('');
  }

  ///delete Blog
  deleteData(id) {
    this.loader = 'show';
    this.http.delete('/admin/blogs/' + id).subscribe(
      (data) => {
        this.toastr.successToastr('Deleted Successfully', '');
        this.loader = '';
        this.get();
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  //uplaod image
  imgUrl = [];
  fileImg;
  fileName;
  /* uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {

        //////upload file and get path///////
        if (this.fileImg) {
          const formData: FormData = new FormData();
          formData.append('image', this.fileImg, this.fileName);
          this.loader = 'show';
          this.http.post('/uploadimage', formData).subscribe(
            data => {
              this.path2 = data;
              this.imgUrl = [] //empty
              this.imgUrl.push(this.path2.path);

              this.form.controls["images"].setValue(this.imgUrl);
              this.loader = '';
            },
            error => {
              this.loader = '';
            });
        }
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  } */

  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    // maximum dimensions
    const max_width = 440;
    const max_height = 225;
    // file types supported  && ********(here only included the images)*********
    const filetypes = [
      'image/jpeg',
      'image/png',
      // 'video/mp4',
      'image/jpg',
      'image/jfif',
    ];
    // maximum file size  in MB
    const max_size = 50;

    if (this.fileImg) {
      // file size validation
      if (this.fileImg.size < 1024 * 1024 * max_size) {
        // file type validation
        if (!_.includes(filetypes, this.fileImg.type)) {
          this.file.nativeElement.value = '';
          this.loader = '';
          this.toastr.errorToastr(
            'Please provide the file with valid  file  type'
          );
        } else {
          //file dimensionvalidation
          const reader = new FileReader();
          const image = new Image();
          // imp
          reader.readAsDataURL(this.fileImg);

          reader.onload = (read: any) => {
            image.src = read.target.result;
            image.onload = (load: any) => {
              const height = load.currentTarget['height'];
              const width = load.currentTarget['width'];
              if (max_height == height && max_width == width) {
                if (this.fileImg) {
                  const formData: FormData = new FormData();
                  formData.append('image', this.fileImg, this.fileName);
                  formData.append('fileType', 'blog');
                  formData.append('thumbnail', 'false');
                  this.loader = 'show';
                  this.http.post('/uploadimage', formData).subscribe(
                    (data) => {
                      this.path2 = data;
                      this.imgUrl = []; //empty
                      this.imgUrl.push(this.path2.path);

                      this.form.controls['images'].setValue(this.imgUrl);
                      this.loader = '';
                    },
                    (error) => {
                      this.loader = '';
                    }
                  );
                }
              } else {
                this.toastr.errorToastr(
                  'Please provide valid file dimension of ' +
                    max_width +
                    '×' +
                    max_height +
                    'pixels'
                );
                this.file.nativeElement.value = '';
                this.loader = '';
              }
            };
          };
        }
      } else {
        this.toastr.errorToastr('Please provide the file with valid file type');
        this.file.nativeElement.value = '';
        this.loader = '';
      }
    }
  }

  path2;
  path;
  get f() {
    return this.form.controls;
  }
  //submit form
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.isEdited == true) {
      this.loader = 'show';
      this.http
        .put(
          '/admin/blogs/' + this.form.controls['blogsId'].value,
          this.form.value
        )
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Successfully Updated', '');
            this.emptyForm();
            this.hideEdit();
            this.get();
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }
        );
    } else {
      this.loader = 'show';
      this.http.post('/admin/blogs/', this.form.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          this.get();
          this.hideEdit();
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        }
      );
    }
  }

  showEdit() {
    this.blogList = '';
    this.editBlog = 'show';
  }

  hideEdit() {
    this.blogList = 'show';
    this.editBlog = '';
  }

  //text editor configurations
  configText: any = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage'], ['insertVideo'], ['customClasses']],
  };

  //show and hide table rows
  showImg;
  visibleIndex = -1;
  showSubItem(ind, img) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
      this.showImg = '';
    } else {
      this.visibleIndex = ind;
      this.showImg = img;
    }
  }
}
function viewchild(arg0: string) {
  throw new Error('Function not implemented.');
}
