import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;
import { AppConstants } from '../../constants/app.constants';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-gallary',
  templateUrl: './gallary.component.html',
  styleUrls: ['./gallary.component.css'],
})
export class GallaryComponent implements OnInit {
  isEdited;
  loader;
  adminAccess;
  testmonialForm: FormGroup;
  submitted = false;
  imgPth;
  @ViewChild('file') file: ElementRef;

  pageTypes = [
    { label: 'Home', value: 'home' },
    { label: 'Desert', value: 'desert' },
    { label: 'Wedding', value: 'wedding' },
    { label: 'Custom', value: 'custom' },
  ];

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.imgUrl = '';
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.loader = '';
    this.submitted = false;
    this.emptyForm();
    this.get();
  }

  //form
  emptyForm() {
    this.submitted = false;
    this.imgUrl = '';
    this.testmonialForm = this.formBuilder.group({
      pageType: ['', Validators.required],
      sortOrder: ['', Validators.required],
      image: ['', Validators.required],
      createdOn: [''],
      updatedOn: [''],
      active: true,
    });
  }

  getdata2;
  getdata;
  get() {
    this.loader = 'show';
    this.http.get('/admin/gallery').subscribe(
      (data) => {
        console.log(data);
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  onClickCreate() {
    $('#createModal').modal('show');
    this.emptyForm();
    this.isEdited = false;
  }

  //Autofill testmonial form
  onClickEdit(item) {
    this.isEdited = true;
    $('#createModal').modal('show');
    this.testmonialForm = this.formBuilder.group({
      galleryId: [item.galleryId],
      pageType: [item?.pageType, Validators.required],
      sortOrder: [item.sortOrder],
      image: [item.image, Validators.required],
      createdOn: [''],
      updatedOn: [''],
      active: true,
    });
    this.imgUrl = item.image;
  }

  deleteImage() {
    this.fileImg = '';
    this.imgUrl = '';
    this.testmonialForm.controls['image'].setValue('');
  }

  //delete testmonial
  deleteData(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Confirm?',
      text: 'Are you sure? You want to delete it!',
      showCancelButton: true,
      confirmButtonColor: '#FD7297',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.loader = 'show';
        this.http.delete('/admin/gallery/' + id).subscribe(
          (data) => {
            this.toastr.successToastr('Deleted Successfully', '');
            this.loader = '';
            this.get();
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }
        );
      }
    });
  }

  imgUrl;
  fileImg;
  fileName;
  //upload image and get url
  uploadFiles(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //////upload file and get path///////
        if (this.fileImg) {
          const formData: FormData = new FormData();
          formData.append('image', this.fileImg, this.fileName);
          formData.append('fileType', 'gallery');
          formData.append('thumbnail', 'false');
          this.loader = 'show';
          this.http.post('/uploadimage', formData).subscribe(
            (data) => {
              this.path2 = data;
              this.imgUrl = this.path2.path;
              console.log(this.imgUrl);
              this.testmonialForm.controls['image'].setValue(this.imgUrl);
              this.loader = '';
            },
            (error) => {
              this.loader = '';
            }
          );
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    // maximum dimensions
    const max_width = 300;
    const max_height = 215;
    // file types supported  && ********(here only included the images)*********
    const filetypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/jfif'];
    // maximum file size  in MB
    const max_size = 50;

    if (this.fileImg) {
      // file size validation
      if (this.fileImg.size < 1024 * 1024 * max_size) {
        // file type validation
        if (!_.includes(filetypes, this.fileImg.type)) {
          this.file.nativeElement.value = '';
          this.loader = '';
          this.toastr.errorToastr(
            'Please provide the file with valid  file  type'
          );
        } else {
          //file dimensionvalidation
          const reader = new FileReader();
          const image = new Image();
          // imp
          reader.readAsDataURL(this.fileImg);

          reader.onload = (read: any) => {
            image.src = read.target.result;
            image.onload = (load: any) => {
              const height = load.currentTarget['height'];
              const width = load.currentTarget['width'];
              if (max_height == height && max_width == width) {
                if (this.fileImg) {
                  const formData: FormData = new FormData();
                  formData.append('image', this.fileImg, this.fileName);
                  formData.append('fileType', 'gallery');
                  formData.append('thumbnail', 'false');
                  this.loader = 'show';
                  this.http.post('/uploadimage', formData).subscribe(
                    (data: any) => {
                      this.path2 = data;
                      this.imgUrl = this.path2.path;
                      console.log(this.imgUrl);
                      this.testmonialForm.controls['image'].setValue(
                        this.imgUrl
                      );
                      this.loader = '';
                      this.file.nativeElement.value = '';
                      this.toastr.infoToastr(data.message);
                    },
                    (error) => {
                      this.loader = '';
                    }
                  );
                }
              } else {
                this.toastr.errorToastr(
                  'Please provide valid file dimension of ' +
                    max_width +
                    '×' +
                    max_height +
                    'pixels'
                );
                this.file.nativeElement.value = '';
                this.loader = '';
              }
            };
          };
        }
      } else {
        this.toastr.errorToastr('Please provide the file with valid type');
        this.file.nativeElement.value = '';
        this.loader = '';
      }
    }
  }

  path2;
  path;
  get f() {
    return this.testmonialForm.controls;
  }
  //submit form
  onSubmit() {
    this.submitted = true;
    if (this.testmonialForm.invalid) {
      return;
    }

    if (this.isEdited == true) {
      this.loader = 'show';
      this.http
        .put(
          '/admin/gallery/' + this.testmonialForm.controls['galleryId'].value,
          this.testmonialForm.value
        )
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Successfully Updated', '');
            this.emptyForm();
            $('#createModal').modal('hide');
            this.get();
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }
        );
    } else {
      this.loader = 'show';
      this.http.post('/admin/gallery', this.testmonialForm.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          this.get();
          $('#createModal').modal('hide');
        },
        (error) => {
          this.loader = '';
          console.log(error);
          this.toastr.errorToastr('Try Again', '');
        }
      );
    }
  }

  showImg;
  visibleIndex = -1;
  showSubItem(ind, img) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
      this.showImg = '';
    } else {
      this.visibleIndex = ind;
      this.showImg = img;
    }
  }
  close() {
    this.file.nativeElement.value = '';
    this.imgUrl = '';
    this.testmonialForm.get('pageType').setValue('');
    this.testmonialForm.get('sortOrder').setValue('');
    this.testmonialForm.get('image').setValue('');

    $('#createModal').modal('hide');
  }
}
