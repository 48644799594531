import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ToastrManager } from "ng6-toastr-notifications";
import { checkNull, queryMaker } from "src/app/constants/custom-functions";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/shared/service/loader.service";
import { PuppysCustomValidation, sweetAlert } from "src/app/shared/validations/puppys-custom";
import { ReportDTO } from "../_services/report.dto";
import { ReportService } from "../_services/report.service";

@Component({
  selector: "app-customer-summary-report",
  templateUrl: "./customer-summary-report.component.html",
  styleUrls: ["./customer-summary-report.component.css"],
})
export class CustomerSummaryReportComponent implements OnInit {
  filterForm: FormGroup;
  pagination: ReportDTO;
  statusSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false
  }
  statusOptions: any = [
    { view: 'Active', value: true },
    { view: 'In-active', value: false }
  ]


  reports: any[] = []

  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.pagination = new ReportDTO()
  }

  ngOnInit(): void {
    this.intialForm()
  }

  intialForm() {
    this.filterForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      status: new FormControl('')
    }, { validators: PuppysCustomValidation.DateValidator('startDate', 'endDate') })
  }






  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }


  reset() {
    this.intialForm()
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = []
  }

  getCustomerReport(filter?: boolean) {

    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter && false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the product report!')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form.status)) {
        this.pagination.status = form.status.map((ele) => ele?.value)
      } else {
        this.pagination.status = ''
      }


      this.loader.open()
      this.service.getCustomerReport(this.pagination).subscribe((res: any) => {
        this.loader.close();
        this.reports = res?.content ?? [];
        console.log(res);

      }, (err: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again');
        this.reports = []
      })
    } else {
      sweetAlert('error', 'Start date should be before End date')
    }


  }

  checkNull(data) {
    return checkNull(data)
  }

  export() {
    let form = this.filterForm.value
    if (false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the customer report!')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form.status)) {
        this.pagination.status = form.status.map((ele) => ele?.value)
      } else {
        this.pagination.status = ''
      }

      this.service.exportReport(`/customer/report/export${queryMaker(this.pagination)}`, 'Customer Report')

    } else {
      sweetAlert('error', 'Start date should be before End date')
    }
  }


  tableData = [
    {
      createdDate: "26-02-2024",
      cusId: "#003",
      cusName: "Raju",
      mobileNo: "8987877660",
      emailId: "raju123@gmail.com",
      gender: "Male",
      date_ofBirth: "12-04-2024",
      status: "Inactive",

    },
    {
      createdDate: "14-02-2024",
      cusId: "#009",
      cusName: "Maha",
      mobileNo: "8787676540",
      emailId: "maha@gmail.com",
      gender: "Female",
      date_ofBirth: "10-04-2024",
      status: "Active",

    },
    {
      createdDate: "26-02-2024",
      cusId: "#006",
      cusName: "Ganesh",
      mobileNo: "8987877660",
      emailId: "ganesh345@gmail.com",
      gender: "Male",
      date_ofBirth: "02-04-2024",
      status: "Inactive",

    },
    {
      createdDate: "26-02-2024",
      cusId: "#004",
      cusName: "Raju",
      mobileNo: "8987877660",
      emailId: "raju123@gmail.com",
      gender: "Female",
      date_ofBirth: "22-03-2024",
      status: "Active",

    },
    {
      createdDate: "26-02-2024",
      cusId: "#004",
      cusName: "Raju",
      mobileNo: "8987877660",
      emailId: "raju123@gmail.com",
      gender: "Female",
      date_ofBirth: "22-03-2024",
      status: "Active",

    },
    {
      createdDate: "26-02-2024",
      cusId: "#004",
      cusName: "Raju",
      mobileNo: "8987877660",
      emailId: "raju123@gmail.com",
      gender: "Female",
      date_ofBirth: "22-03-2024",
      status: "Active",

    },

  ];
}
