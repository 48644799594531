import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  PuppysCustomValidation,
  sweetAlert,
} from 'src/app/shared/validations/puppys-custom';
import { ReportDTO } from '../_services/report.dto';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ReportService } from '../_services/report.service';
import * as moment from 'moment';
import { checkNull, queryMaker } from 'src/app/constants/custom-functions';

@Component({
  selector: 'app-coupon-code-transaction-report',
  templateUrl: './coupon-code-transaction-report.component.html',
  styleUrls: ['./coupon-code-transaction-report.component.css'],
})
export class CouponCodeTransactionReportComponent implements OnInit {
  filterForm: FormGroup;
  pagination: ReportDTO;
  reports: any[] = [];

  selectboxSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  statusOptions: any[] = [
    { view: 'Accepted', value: 'Accepted' },
    { view: 'Cake is Baking', value: 'Baking' },
    { view: 'On Transit', value: 'Shipped' },
    { view: 'Delivered', value: 'Delivered' },
    { view: 'Cancelled', value: 'Cancelled' },
    { view: 'Abandoned Carts', value: 'InCart' },
  ];

  couponTypeOptions: any[] = [
    { view: 'General', value: '1' },
    { view: 'Customer', value: '2' },
    { view: 'Product', value: '3' },
    { view: 'Birthday', value: '4' },
    { view: 'Work Anniversary', value: '5' },
    // { view: 'Wedding Anniversary', value: '6' },
    // { view: 'Valentine\'s day', value: '7' },
    // { view: 'Children\'s Day', value: '8' },
  ];

  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.pagination = new ReportDTO();
  }

  ngOnInit(): void {
    this.intialForm();
  }

  intialForm() {
    this.filterForm = new FormGroup(
      {
        startDate: new FormControl(''),
        endDate: new FormControl(''),
        couponTypeId: new FormControl(''),
        orderStatus: new FormControl(''),
      },
      {
        validators: PuppysCustomValidation.DateValidator(
          'startDate',
          'endDate'
        ),
      }
    );
  }

  checkNull(data) {
    return checkNull(data);
  }

  navigateToOrder(orderId) {
    this.router.navigate([`order-detail/${orderId}`]);
  }

  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form)
      .map((ele) => checkNull(ele))
      .some((val) => val == true);
    return hasOneValue;
  }

  reset() {
    this.intialForm();
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = [];
  }

  getCouponTransactionReport(filter?: boolean) {
    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter && false) {
      this.toastr.errorToastr(
        'Kindly fill atleast one field to filter the transaction report!'
      );
      return;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD');
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required');
        return;
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD');
      }

      if (checkNull(form?.orderStatus)) {
        this.pagination.orderStatus = form?.orderStatus.map((ele) => ele?.value);
      } else {
        this.pagination.orderStatus = '';
      }

      if (checkNull(form.couponTypeId)) {
        this.pagination.couponTypeId = form.couponTypeId.map(
          (ele) => ele?.value
        );
      } else {
        this.pagination.couponTypeId = '';
      }

      queryMaker(this.pagination);

      this.loader.open();
      this.service.getCouponTransactionReport(this.pagination).subscribe(
        (res: any) => {
          this.loader.close();
          this.reports = res?.content ?? [];
          console.log(res);
        },
        (err: any) => {
          this.loader.close();
          this.toastr.errorToastr('Try again');
          this.reports = [];
        }
      );
    } else {
      sweetAlert('error', 'Start date should be before End date');
    }
  }

  export() {
    let form = this.filterForm.value;
    if (false) {
      this.toastr.errorToastr(
        'Kindly fill atleast one field to filter the transaction report!'
      );
      return;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD');
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required');
        return;
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD');
      }

      if (checkNull(form?.orderStatus)) {
        this.pagination.orderStatus = form?.orderStatus.map((ele) => ele?.value);
      } else {
        this.pagination.orderStatus = '';
      }
      if (checkNull(form.couponTypeId)) {
        this.pagination.couponTypeId = form.couponTypeId.map(
          (ele) => ele?.value
        );
      } else {
        this.pagination.couponTypeId = '';
      }

      this.service.exportReport(
        `/coupon/transaction/report/export${queryMaker(this.pagination)}`,
        'Coupon Transaction Report'
      );
    } else {
      sweetAlert('error', 'Start date should be before End date');
    }
  }

  findStatus(value) {
    return this.statusOptions.find((ele) => ele.value == value)?.view;
  }

  // Non-developed
  couponType = {};
  statusShow = {};
  couponList = [];
  statusList = [];

  tableData = [
    {
      orderedOn: '26-02-2024',
      orderID: '#ID00987YD',
      customer: 'Praksah',
      couponType: 'Anniversary',
      couponCode: '#VAOO#123',
      grossAmt: '₹ 100.00',
      discountAmt: '₹ 60.00',
      netAmt: '₹ 200.00',
      deliveryCharge: '₹ 80.00',
      orderAmt: '₹ 350.00',
      status: 'On Transit',
    },
    {
      orderedOn: '26-02-2024',
      orderID: '#ID00987YD',
      customer: 'Abdul Shajith',
      couponType: 'Product',
      couponCode: '#VAOO#123',
      grossAmt: '₹ 100.00',
      discountAmt: '₹ 60.00',
      netAmt: '₹ 200.00',
      deliveryCharge: '₹ 80.00',
      orderAmt: '₹ 350.00',
      status: 'Cake is Baking',
    },
    {
      orderedOn: '26-02-2024',
      orderID: '#ID00987YD',
      customer: 'Arun Kumar',
      couponType: 'General',
      couponCode: '#VAOO#123',
      grossAmt: '₹ 100.00',
      discountAmt: '₹ 60.00',
      netAmt: '₹ 200.00',
      deliveryCharge: '₹ 80.00',
      orderAmt: '₹ 350.00',
      status: 'Accepted',
    },
    {
      orderedOn: '26-02-2024',
      orderID: '#ID00987YD',
      customer: 'Dinesh',
      couponType: 'Product',
      couponCode: '#VAOO#123',
      grossAmt: '₹ 100.00',
      discountAmt: '₹ 60.00',
      netAmt: '₹ 200.00',
      deliveryCharge: '₹ 80.00',
      orderAmt: '₹ 350.00',
      status: 'Abandoned Carts',
    },
    {
      orderedOn: '07-02-2024',
      orderID: '#ID00987YD',
      customer: 'Mahesh',
      couponType: 'Customer',
      couponCode: '#VAOO#123',
      grossAmt: '₹ 100.00',
      discountAmt: '₹ 60.00',
      netAmt: '₹ 200.00',
      deliveryCharge: '₹ 80.00',
      orderAmt: '₹ 350.00',
      status: 'Delivered',
    },
    {
      orderedOn: '12-02-2024',
      orderID: '#ID00987YD',
      customer: 'Siva',
      couponType: 'Birthday',
      couponCode: '#AKOO#123',
      grossAmt: '₹ 100.00',
      discountAmt: '₹ 60.00',
      netAmt: '₹ 200.00',
      deliveryCharge: '₹ 80.00',
      orderAmt: '₹ 350.00',
      status: 'Cancelled',
    },
  ];
}
