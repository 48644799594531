import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PermissionService } from 'src/app/services/core/permission.service';
declare var $: any;



@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.css']
})
export class InstagramComponent implements OnInit {

  adminAccess
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService,
  ) { }

  loader
  form: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.loader = '';
    this.submitted = false;
    this.emptyForm();
    this.get();
  }
close(){
  $('#createModal').modal('hide')

}
  //form  
  emptyForm() {
    this.submitted = false;
    this.form = this.formBuilder.group({
      postId: ['', Validators.required],
      postName: ['']
    });
  }


  //get Instagram Id
  getdata2
  getdata
  get() {
    this.loader = 'show';
    this.http.get('/instafeeds').subscribe(
      (data: any) => {
        this.getdata = data.content;
        this.loader = '';
      },
      error => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      });
  }

  create() {
    this.emptyForm();
    $('#createModal').modal('show');
  }


  //submit form
  get f() { return this.form.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let postId = this.form.value.postId
    let parts = postId.split('/');
    var resultId = parts[parts.length - 2];
    if (!resultId) {
      this.toastr.errorToastr('Invalid Instagram Url', '');
      return
    }
    this.form.controls["postId"].setValue(resultId);

    this.loader = 'show';
    this.http.post('/admin/instafeeds', this.form.value).subscribe(
      data => {
        this.loader = '';
        this.toastr.successToastr('Successfully Submitted', '');
        this.emptyForm();
        this.get();
        $('#createModal').modal('hide');
      },
      error => {
        this.loader = '';
        console.log(error);
        this.toastr.errorToastr('Try Again', '');
      });
  }


  //Delete Instagrem Id
 deleteData(id) {
    this.loader = 'show';
    this.http.delete('/admin/instafeeds/' + id).subscribe(
      data => {
        this.toastr.successToastr('Deleted Successfully', '');
        this.loader = '';
        this.get();
      },
      error => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      });
  }
}
