// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL:'',
  firebaseConfig : {
    apiKey: "AIzaSyBNX7w3Kh7Qxg2y7Y2AD6cAv6_DetQ1veM",
    authDomain: "puppy-s-customer-portal.firebaseapp.com",
    projectId: "puppy-s-customer-portal",
    storageBucket: "puppy-s-customer-portal.appspot.com",
    messagingSenderId: "175257152927",
    appId: "1:175257152927:web:06929649cc45ad4e9421aa",
    measurementId: "G-D1VYN8WS11"
  }
  // firebaseConfig : {
  //   apiKey: "AIzaSyCUBaB3AFrk-c0mzxgtmHMLCrC2FGMRlEE",
  //   authDomain: "puppy-s-push-notification.firebaseapp.com",
  //   projectId: "puppy-s-push-notification",
  //   storageBucket: "puppy-s-push-notification.appspot.com",
  //   messagingSenderId: "348729408179",
  //   appId: "1:348729408179:web:2abab67dffd9e4239b0ec6",
  //   measurementId: "G-TP1SSXZJXW"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
