import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashComponent } from './components/dash/dash.component';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ProductsFormComponent } from './components/products-form/products-form.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { CakeComponent } from './components/cake/cake.component';
import { BannerComponent } from './components/banner/banner.component';
import { TestmonialComponent } from './components/testmonial/testmonial.component';
// import { UserComponent } from './components/user/user.component';
// import { UserComponent } from './components/Administration/user/user.component';
import { UserComponent } from './components/Administration/users/user.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderDetailListComponent } from './components/order-detail-list/order-detail-list.component';
import { DeliveriesListComponent } from './components/deliveries-list/deliveries-list.component';
import { BlogListComponent } from './components/blog-list/blog-list.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { JwtInterceptor } from './services/interceptor/jwt.interceptor';
import { ErrorInterceptor } from './services/interceptor/error.interceptor';
import { NewsletterListComponent } from './components/newsletter-list/newsletter-list.component';
import { HomePagePopupComponent } from './components/home-page-popup/home-page-popup.component';
import { ReviewManagementComponent } from './components/review-management/review-management.component';
import { LoyaltyProgrammeComponent } from './components/loyalty-programme/loyalty-programme.component';
import { BarRatingModule } from 'ngx-bar-rating';
// import { UserPrivilagesComponent } from './components/user-privilages/user-privilages.component';
// import { UserPrivilagesComponent } from './components/Administration/user-privilages/user-privilages.component';
import { UserPrivilagesComponent } from './components/Administration/Roles/user-privilages.component';
import { InstagramComponent } from './components/instagram/instagram.component';
import { OrderReportComponent } from './components/order-report/order-report.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GallaryComponent } from './components/gallary/gallary.component';
import { DeliveryTimeComponent } from './components/delivery-time/delivery-time.component';
import { ImageExporalComponent } from './components/image-exporal/image-exporal.component';
import { ProductBuildUploadComponent } from './components/product-build-upload/product-build-upload.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { environment } from 'environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
// import * as firebase from 'firebase';
import { MessagingService } from './services/core/messaging.service';
import { CoreModule } from './services/core/core.module';
import { environment } from 'src/environments/environment';
// import { RolesPermissionComponent } from './components/side-nav/roles-permission/roles-permission.component';
import { RolesPermissionComponent } from './components/Administration/roles-permission/roles-permission.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InvoicebillComponent } from './components/invoicebill/invoicebill.component';
import { FlavourComponent } from './components/flavour/flavour.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { DecimalNumbers, NumbersOnlyDirective, PercentageNumber } from './components/products-form/numbersonly.directive';
import { TrackOrderComponent } from './components/track-order/track-order.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { PaginatorComponent } from './shared/components/paginator/paginator.component';
import { CreateProductComponent } from './components/create-product/create-product.component';
import { PromocodeCreateComponent } from './components/promocode-create/promocode-create.component';
import { PromocodeGeneralComponent } from './components/promocode-general/promocode-general.component';
import { PromocodeUpdateComponent } from './components/promocode-update/promocode-update.component';


import { ShortLinkComponent } from './components/short-link/short-link.component';
import { OccasionUpdateComponent } from './components/occasion-update/occasion-update.component';
import { ProductReportComponent } from './components/reports/product-report/product-report.component';
import { OrderReportNewComponent } from './components/reports/order-report-new/order-report-new.component';
import { GSTAuditReportComponent } from './components/reports/gst-audit-report/gst-audit-report.component';
import { CustomerSummaryReportComponent } from './components/reports/customer-summary-report/customer-summary-report.component';
import { CouponCodeTransactionReportComponent } from './components/reports/coupon-code-transaction-report/coupon-code-transaction-report.component';
import { ContactUsReportComponent } from './components/reports/contact-us-report/contact-us-report.component';
import { CouponCodeSummaryReportComponent } from './components/reports/coupon-code-summary-report/coupon-code-summary-report.component';
import { TrimText } from './shared/pipes/trim.pipe';
import { EmptyText } from './shared/pipes/empty.pipe';
import { DigitPipe } from './shared/pipes/digit.pipe';
import { SellingReportComponent } from './components/reports/selling-report/selling-report.component';
import { EnquiyReportComponent } from './components/reports/enquiry-report/enquiry-report.component';
import { CustomersComponent } from './components/customers/customers.component';
// import * as ApexCharts from 'apexcharts';?
// import { UserPrivilagesComponent } from './components/Administration/user-privilages/user-privilages.component';

const firebase = environment.firebaseConfig;

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    HeaderComponent,
    DashComponent,
    CouponCodeTransactionReportComponent,
    CouponCodeSummaryReportComponent,
    ContactUsReportComponent,
    CategoriesListComponent,
    ProductsListComponent,
    ProductsFormComponent,
    AdminLoginComponent,
    CakeComponent,
    BannerComponent,
    TestmonialComponent,
    UserComponent,
    CustomerSummaryReportComponent,
    OrderListComponent,
    OrderDetailListComponent,
    DeliveriesListComponent,
    PromocodeGeneralComponent,
    BlogListComponent,
    NewsletterListComponent,
    HomePagePopupComponent,
    ReviewManagementComponent,
    LoyaltyProgrammeComponent,
    UserPrivilagesComponent,
    InstagramComponent,
    OrderReportComponent,
    PricingComponent,
    GallaryComponent,
    DeliveryTimeComponent,
    ImageExporalComponent,
    ProductBuildUploadComponent,
    NotificationComponent,
    NotificationListComponent,
    InvoiceComponent,
    ActivityLogComponent,
    RolesPermissionComponent,
    UserComponent,
    UserPrivilagesComponent,
    RolesPermissionComponent,
    InvoicebillComponent,
    FlavourComponent,
    TransactionComponent,
    NumbersOnlyDirective,
    TrackOrderComponent,
    LoaderComponent,
    PaginatorComponent,
    CreateProductComponent,
    PromocodeCreateComponent,
    PromocodeUpdateComponent,
    OrderReportNewComponent,
    ShortLinkComponent,
    DecimalNumbers,
    PercentageNumber,
    OccasionUpdateComponent,
    ProductReportComponent,
    GSTAuditReportComponent,
    TrimText,
    EmptyText, DigitPipe,
    SellingReportComponent,
    EnquiyReportComponent,
    CustomersComponent


  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),

    ReactiveFormsModule,
    UiSwitchModule,
    AppRoutingModule,
    AngularEditorModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    CommonModule,
    BarRatingModule,
    DragDropModule,
    GalleryModule,
    LightboxModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebase),
    CoreModule,
    NgApexchartsModule,


  ],
  exports: [TrackOrderComponent, LoaderComponent, PaginatorComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MessagingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
