import { Pipe, PipeTransform } from "@angular/core";
import { checkNull } from "src/app/constants/custom-functions";

@Pipe({
  name: "emptyText",
})
export class EmptyText implements PipeTransform {
  transform(text: string, str?: String) {
    if (checkNull(text)) {
      return text
    } else {
      return str ?? `-`
    }

  }
}
