<section class="offset-width well-white">
    <app-header [pageName]="'Contact Us Report'"></app-header>
    <div class="container-fluid">
       <h5 class="list-item">Contact Us Report</h5>
       <div class="card-body card-design">
          <form [formGroup]="contactForm">
             <div class="row">
                <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                   <div>
                      <label class="label_style">From Date</label>
                      <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                   </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                   <div>
                      <label class="label_style">To Date</label>
                      <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                   </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                   <div class="relative">
                      <label class="label_style">Type</label>
                      <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                      <select formControlName="couponType" class="form-control input_style form-control-sm" #isPan>
                         <option selected value="">Select Type</option>
                         <option>Type_1
                         </option>
                         <option>Type_2
                         </option>
                      </select>
                   </div>
                </div>
   
           
             </div>
             <div class="row align_items_end mt-4">       
                <div class="col-lg-3 col-xl-2 col-md-3 text-align-right">
                   <button  class="btn btn-sm btn-reset btn_search">Search</button>
                </div>
                <div class="col-lg-2 col-xl-1 col-md-2 text-align-right">
                   <button 
                      class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-3">
                      <img src="../../../assets/images/logo/order_last.svg" alt="">
                      <div class="tooltip">
                         <h1 class="tooltip_style">Export</h1>
                      </div>
                   </button>
                </div>
             </div>
          </form>
       </div>
       <div class="card bg-table-color">
          <div class="card-body">
             <div class="table-responsive">
                <table class="table">
                   <thead>
                      <tr>
                         <th scope="col">Created Date</th>
                         <th scope="col">Category</th>
                         <th scope="col">Product Name</th>
                         <th scope="col">SKU</th>
                         <th scope="col">Order Type</th>
                         <th scope="col">Delivery Status</th>
                         <th scope="col">Delivery Type</th>
                         <th scope="col">Status</th>
                      </tr>
                   </thead>
                   <tbody>
                      <tr *ngFor="let item of tableData">
                         <td class="pl-26">{{ item.createdDate }}</td>
                         <td>{{ item.category }}</td>
                         <td>{{ item.productName }}</td>
                         <td>{{ item.sku }}</td>
                         <td>{{ item.orderType }}</td>
                         <td>{{ item.deliveryStatus }}</td>
                         <td>{{ item.deliveryType }}</td>
                         <td ><span [ngClass]="{'inactive-status': item.status === 'Inactive', 'active-status': item.status === 'Active'}">{{ item.status }}</span></td>
                      </tr>
                   </tbody>
                </table>
             </div>
          </div>
       </div>
    </div>
 </section>