<section class="offset-width well-white">
  <app-header [pageName]="'Users'"></app-header>
  <div *ngIf="loader" class="loader-spin"> </div>

  <div class="container-fluid">

    <h5>Users</h5>

    <button type="button" *ngIf="permission.hasPermission(['user'],['user_create'])" (click)="onClickCreate()"
      class="button-1 btn-pink btn-margin"> <i class="fa fa-plus"></i>
      Create </button> <br><br>


    <div class="card col-12 table-bg">
      <div class="card-body "> <br>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  USER NAME </th>
                <th scope="col">EMAIL ID </th>
                <th scope="col">MOBILE NUMBER </th>
                <th scope="col">CREATED DATE AND TIME </th>
                <th scope="col">GENDER</th>
                <th scope="col">ROLE</th>
                <th scope="col" *ngIf="permission.hasPermission(['user'],['user_update']) || permission.hasPermission(['user'],['user_status'])">ACTION</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of getdata | paginate: config;let index = index">
              <tr>
                <td>{{item.firstName | titlecase}}</td>
                <td> <span *ngIf="item.email;else emailNA "> {{item.email}}</span>
                  <ng-template #emailNA> NA </ng-template>
                </td>
                <td>
                  <span *ngIf="item.phoneNumber;else phoneNA "> {{item.phoneNumber}} </span>
                  <ng-template #phoneNA> NA </ng-template>
                </td>
                <td>{{ item.createdOn | date: 'dd-MM-yyyy, h:mm a'}}</td>
                <td>{{item.gender | titlecase }}</td>
                <td>{{item.newRole | titlecase}}</td>
                <td *ngIf="permission.hasPermission(['user'],['user_update']) || permission.hasPermission(['user'],['user_status'])">
                  <a *ngIf="permission.hasPermission(['user'],['user_update'])"> <span class="pencil-dot">..</span><img (click)="onClickEdit(item)"
                      style="margin-top: 3px;position: absolute;" class="pointer"  src="/assets/images/icon/pencil-2.svg"></a>
                  <ui-switch size="small"  *ngIf="permission.hasPermission(['user'],['user_status'])"  color="#007bff" style="margin-left: 30px" [checked]="item.active"
                    (change)='isActiveChange(item)'></ui-switch>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-align">
            <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
              screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
              screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
          </div>
        </div>
      </div>
    </div>

    <br><br>

<!-- spa -->

    <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form [formGroup]="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><span>{{isEdited==true ? "Update" : "Create"}}</span> User</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6 down-text">

                    <label>First Name <span style="color: red;">*</span></label>
                    <input type="text" formControlName="firstName" class="form-control box-control"
                      placeholder="Enter First Name">
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-text">
                      <div *ngIf="f.firstName.errors.required"> First Name is required</div>
                    </div>
                  </div>

                  <div class="col-lg-6 down-text">
                    <label>Last Name </label>
                    <input type="text" formControlName="lastName" class="form-control box-control"
                      placeholder="Enter Last Name">
                  </div><br>

                  <div class="col-lg-12 down-text">
                    <label>Gender <span style="color: red;">*</span></label>
                    <select formControlName="gender" class="form-control">
                      <option value=''></option>
                      <option value='male'>Male</option>
                      <option value='female'>Female</option>
                    </select>
                    <div *ngIf="submitted && f.gender.errors" class="invalid-text">
                      <div *ngIf="f.gender.errors.required">Gender is required</div>
                    </div>
                  </div>

                  <div class="col-lg-12 down-text">
                    <label>Email <span style="color: red;">*</span></label>
                    <input type="email" formControlName="email" class="form-control box-control"
                      placeholder="Enter User Email Id">
                    <div *ngIf="submitted && f.email.errors" class="invalid-text">
                      <div *ngIf="f.email.errors.required"> Email is required</div>
                    </div>
                  </div>

                  <div class="col-lg-12 down-text">
                    <label>Mobile </label>
                    <input type="number" formControlName="phoneNumber" class="form-control box-control"
                      placeholder="Enter User Mobile">
                    <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-text">
                      <div *ngIf="f.phoneNumber.errors.required"> Phone Number is required</div>
                    </div>
                  </div>

                  <div class="col-lg-12 down-text">
                    <label>Role <span style="color: red;">*</span></label>
                    <select class="form-control box-control" formControlName="roleId">
                      <option value=""></option>
                      <option *ngFor="let item of getdata4" value="{{item.id}}">
                        {{item.role}}</option>
                    </select>
                    <div *ngIf="submitted && f.roleId.errors" class="invalid-text">
                      <div *ngIf="f.roleId.errors.required"> Role is required</div>
                    </div>
                  </div> <br>

                  <div class="col-lg-12 down-text" *ngIf="!isEdited">
                    <label>Set Password <span style="color: red;">*</span> </label>
                    <div class="input-group">
                    <input type="{{show ? text : password}}" formControlName="password" class="form-control box-control"
                      placeholder="Enter User Password">
                      <div class="input-group-append">
                      <button (click)="onClick()" type="button" class="pass-eye">
                        <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                      </button>
                    </div>
                    </div>
                    <div *ngIf="submitted && f.password.errors" class="invalid-text">
                      <div *ngIf="f.password.errors.required"> Password is required</div>
                      <div *ngIf="f.password.errors.minlength"> Password must at least 6 characters </div>
                    </div>
                  </div>

                  <div class="col-lg-12" *ngIf="isEdited">
                    <button class="btn btn-secondary btn-sm pull-right mt-3" (click)="passwordDivFun()">Change
                      Password</button>
                    <div *ngIf="passwordDiv"><br><br>
                      <label>Change Password <span style="color: red;">*</span></label>
                      <div class="input-group">
                      <input type="{{show ? text : password}}" id="updatePassword"  formControlName="updatePassword" class="form-control"
                      placeholder="Password" required>
                      <div class="input-group-append">
                        <button (click)="onClick()" type="button" class="pass-eye">
                          <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                          <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                        </button>
                      </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 bottom-text">
                    <input id="upload-degn" type="file" (change)="uploadFile($event)" style="display:none">
                    <label for="upload-degn" *ngIf="!imgUrl">
                      <span class="btn-file-upload">Choose Image</span>
                    </label>
                    <div *ngIf="imgUrl" class="mt-2">
                      <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i>
                      <img src="{{imgPth+imgUrl}}" class="img img-fluid">
                    </div>
                  </div><br><br>

                </div>
              </div>
              <br><br>
              <div class="modal-footer">
                <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">Close</button>
                <button type="button" class="button-1 btn-pink" (click)="onSubmit()">Submit <i style="margin-left: 4px;"
                    class="fa fa-long-arrow-right"></i> </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
    <!--modal end-->


  </div>
</section>