<section class="offset-width well-white">
    <!-- <div *ngIf="loader" class="loader-spin"> </div> -->
    <app-header [pageName]="'Invoice'"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-lg-2 List-item">
                <h5>Invoice</h5><br>
            </div>

        </div>

        <div class="row">

            <div class="col-lg-12 billing-details">




                <div class="text-right">
                    <button class="btn btn-print " type="submit" onclick="window.print()">Print</button>
                    <button class="btn save-pdf" type="submit" (click)="exportPDF()">Save PDF</button>
                </div>
                <!-- <table class="print-bill" style="margin:5px  auto; background: white;width:75%;border: 1px solid grey;">
                   
                    <tr>
                        <td style="background: pink;">
                            <img alt="Header-logo" height="32"
                                src="https://puppysbakery.com/assets/images/logo/logo_1.png"
                                style="text-decoration:none;height:auto;border:0;display:block;height:80px;width:80px;margin: 10px auto; "
                                title="Header-logo" class="CToWUd">
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <table style="margin: 2px auto ;">
                                <tr>
                                    <td colspan="2" class="text-center" style="padding-top: 15px;"><b>Invoice</b></td>
                                </tr>
                              
                                <tr>
                                    <td colspan="2" class="text-center" style="padding-top: 20px;"><b>Invoice No
                                            :{{orderDetails?.invoicePrefix}}-{{orderDetails?.invoiceYear.slice(-2)}}-{{orderDetails?.invoiceNumber}}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center" style="padding-top: 10px;"><b>Order No :
                                            {{(orderDetails?.orderId!=null)?(orderDetails?.orderId):'-'}}</b></td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center f16" style="padding-top: 10px;"><b>Placed on :
                                            {{(orderDetails?.createdOn!=null)?(orderDetails?.createdOn|date:'longDate'):'-'}}
                                        </b>
                                    </td>
                                </tr>
                              

                                <tr>
                                    <td colspan="2" style="page-break-after: always; page-break-before:always">
                                        <table class="w-100"
                                            style="width: 100%; font-size: 17px; border-spacing: 0px; ">
                                            <tr>
                                                <td colspan="2"
                                                    style="padding-bottom: 10px; padding-left: 15px; width: 185px;">
                                                    <b>Here's what you ordered</b>
                                                </td>

                                                <td style=" padding-right: 15px;" class="text-right">
                                                    Price (Rs)
                                                </td>
                                            </tr>

                                            
                                            <tr style="background: #f9f9f9; border-radius: 5px; padding-top: 5px;"
                                                *ngFor="let cakes of orderDetails?.data?.lineItemsInfo ">
                                                <td style="padding: 10px 15px; ">
                                                    <img [src]="cakes?.product?.productImages" alt="cake" width="75"
                                                        height="75">
                                                </td>
                                                <td style=" width: 300px;">
                                                    <b> {{(cakes?.product?.productName!=null) ?
                                                        (cakes?.product?.productName|titlecase):'-'}}</b> <br>
                                                    Quantity :{{(cakes?.quantity!=null)?(cakes?.quantity):'-'}}<br>
                                                    Flavour
                                                    :{{(cakes?.pricing?.flavour!=null)?(cakes?.pricing?.flavour|titlecase):'-'}}
                                                </td>

                                                <td class="text-right" style=" padding-right: 15px;">
                                                    ₹ {{cakes?.cost!=null ? (cakes?.cost | number: '1.2-2') :'0'}}<br>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-right" style="padding: 20px 0 3px 0 ;">Subtotal
                                                    </td>
                                                <td class="text-right" style="width: 180px; padding: 20px 15px 3px 0"> ₹
                                                    {{ subTotal(orderDetails?.data) | number: '1.2-2' :'' }}</td>
                                            </tr>
                                            <tr *ngIf="discount(orderDetails?.data) > 0">
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">Discount (-)</td>
                                                <td class="text-right" style="padding-right: 15px;">₹ {{
                                                    discount(orderDetails?.data) | number: '1.2-2'}}</td>
                                            </tr>
                                            <tr *ngIf="getCoupon(orderDetails?.data) > 0">
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">Coupon (-)</td>
                                                <td class="text-right" style="padding-right: 15px;">₹ {{
                                                    getCoupon(orderDetails?.data) | number: '1.2-2'}}</td>
                                            </tr>
                                            <tr >
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">Net Amount</td>
                                                <td class="text-right" style="padding-right: 15px;">₹ {{
                                                    getNetamout(orderDetails?.data) | number: '1.2-2'}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">
                                                    GST({{orderDetails?.data.costDetails?.taxPercentage}}%)</td>
                                                <td class="text-right" style="padding-right: 15px;"> ₹ {{
                                                    GSTAmount(orderDetails?.data) | number: '1.2-2'}}</td>
                                            </tr>
                                            <tr *ngIf="orderDetails?.data?.costDetails?.delivery">
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">Shipping (+)</td>
                                                <td class="text-right" style="padding-right: 15px;">₹
                                                    {{(orderDetails?.data?.costDetails?.delivery!=null)
                                                    ?(orderDetails?.data?.costDetails?.delivery | number: '1.2-2') :'0' }}</td>
                                            </tr>
                                            <tr *ngIf="orderDetails?.midnightCharge">
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">Mid Night Charge (+)</td>
                                                <td class="text-right" style="padding-right: 15px;">₹
                                                    {{(orderDetails?.midnightCharge!=null)
                                                    ?(orderDetails?.midnightCharge | number: '1.2-2') :'0' }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-right" style="padding: 3px 0;">Round Off ({{getroundOff(orderDetails?.data?.costDetails?.delivery,
                                                    getNetamout(orderDetails?.data),
                                                    orderDetails?.midnightCharge) >= 0 ? '+': ''}})</td>
                                                <td class="text-right" style="padding-right: 15px;">₹
                                                    {{
                                                        getroundOff(orderDetails?.data?.costDetails?.delivery, 
                                                        getNetamout(orderDetails?.data),
                                                        orderDetails?.midnightCharge) | number: '1.2-2'
                                                     }}</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="text-right" style="padding: 3px 0 30px 0;">
                                                    <b>Total</b></td>
                                                <td style="padding: 3px 15px 30px 0;" class="text-right"><b>₹ {{
                                                   getTotal(orderDetails?.data?.costDetails?.delivery,
                                                        getNetamout(orderDetails?.data),
                                                        orderDetails?.midnightCharge) | number: '1.2-2'
                                                        }}</b></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="padding-print-left" *ngIf="homeAddress"
                                        style="padding-left: 10px; padding-bottom: 30px;">
                                        <table class="billing-address  mt-20"
                                            style=" font-size: 14px; background: #F5F5F3; padding: 10px; width: 90%; border-radius: 15px;">
                                            <tr>
                                                <td style="padding-bottom: 5px; padding-left: 6px;"><b>Billing
                                                        Address</b></td>
                                            </tr>

                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{orderDetails?.homeAddress?.name!=null
                                                    ?(orderDetails?.homeAddress?.name |titlecase):'-'}},
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{orderDetails?.homeAddress?.line1!=null
                                                    ?(orderDetails?.homeAddress?.line1 |titlecase):'-'}},
                                                </td>
                                            </tr>
                                            <tr *ngIf="orderDetails?.homeAddress?.line2!=null">
                                                <td style="padding-left: 6px;">
                                                    {{orderDetails?.homeAddress?.line2!=null
                                                    ?(orderDetails?.homeAddress?.line2 |titlecase):'-'}},
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{orderDetails?.homeAddress?.city!=null
                                                    ?(orderDetails?.homeAddress?.city
                                                    |titlecase):'-'}}-{{orderDetails?.homeAddress?.pinCode!=null
                                                    ?(orderDetails?.homeAddress?.pinCode):''}},
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{orderDetails?.homeAddress?.state!=null
                                                    ?(orderDetails?.homeAddress?.state |titlecase):'-'}}.
                                                </td>
                                            </tr>


                                        </table>
                                    </td>

                                    <td style="padding-bottom: 20px;" *ngIf="shippingAddress">
                                        <table class="billing-address  mt-20"
                                            style=" font-size: 14px; background: #F5F5F3; padding: 10px; width: 95%; border-radius: 15px;">
                                            <tr>
                                                <td style="padding-bottom: 5px; padding-left: 6px; "><b>Shipping
                                                        Address</b></td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{(orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.name!=null)
                                                    ?
                                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.name|titlecase):'-'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{(orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line1!=null)
                                                    ?
                                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line1|titlecase):'-'}}
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line2!=null">
                                                <td style="padding-left: 6px;">
                                                    {{(orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line2!=null)
                                                    ?
                                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line2|titlecase):'-'}},
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{(orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.city!=null)
                                                    ?
                                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.city|titlecase):'-'}}-{{(orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.pinCode!=null)
                                                    ?
                                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.pinCode):'-'}},
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 6px;">
                                                    {{(orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.state!=null)
                                                    ?
                                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.state|titlecase):'-'}}.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>




                                <tr *ngIf="storeAddress">
                                    <td colspan="2" style="padding-left: 10px; padding-bottom: 20px;">
                                        <table
                                            style=" font-size: 14px; text-align: left; background: #F5F5F3; padding: 10px; width: 90%; border-radius: 15px;">
                                            <tr>
                                                <td>
                                                    <b>Pickup from our store</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{orderDetails?.storeAddress?.name!=null ?
                                                    (orderDetails?.storeAddress?.name|titlecase) :'-' }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{orderDetails?.storeAddress?.address!=null ?
                                                    (orderDetails?.storeAddress?.address|titlecase) :'-' }}</td>
                                            </tr>
                                            <tr>
                                                <td style="font-size: 14px;">Mobile
                                                    No:{{orderDetails?.storeAddress?.phone!=null ?
                                                    (orderDetails?.storeAddress?.phone) :'-' }}</td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2">
                                        <table
                                            style="border-top: 1px solid #000; text-align: center; padding: 15px; font-size: 17px; width: 100%; margin: 5px auto;">
                                            <tr>
                                                <td style="padding-bottom: 5px;">
                                                    <b>Any Questions?</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    Call us at <a href="tel:+91 8122210222"
                                                        style="text-decoration: none;"> +91 8122210222</a> or reply to this
                                                    email
                                                    <a href=" "
                                                        style="text-decoration: none;">mailbox@puppys.co.in</a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                   
                    <tr class="footer">
                        <td style="background: pink; text-align: center; padding: 10px 0;">
                            <img src="../../../assets/images/img/facebook-icon.png" alt="facebook" width="50"
                                height="50" style="margin-right: 20px;">
                            <img src="../../../assets/images/img/insta-icon.png" alt="insta" width="50" height="50">
                        </td>
                    </tr>
                </table> -->
                <!--Order Info-->
                <div class="center-list">
                    <div class="secion-left">
                        <h1>Order ID :{{orderDetails?.orderId ?orderDetails?.orderId : '-' }}</h1>
                    </div>
                    <div class="section-right">
                        <h2>Order Date:{{orderDetails?.createdOn ? (orderDetails?.createdOn | date:'dd-MM-yyyy') : '-'}}
                        </h2>
                    </div>
                </div>
                <!--Communication Info
    C/O Bell Hotel, No 353, Thiruthangal Road, Sivakasi Ho, Sivakasi - 626123-->
                <div class="dot-funtion">
                    <div class="dot-a">
                        <div class="dot-a-list">
                            <h1>Sold By</h1>
                            <img src="https://puppysbakery.com/assets/images/logo/logo_1.png" alt="image">
                        </div>
                        <div class="dot-a-left">
                            <!-- <h2 class="around">PUPPYS RESTAURANTS</h2>
                <h3> GST NO -33AHBPR8651G2Z8,</h3>
                <h3>192, THENI MAIN ROAD,</h3>
                <h3> MUDAKKU SALAI,</h3>
                <h3>Madurai, Tamil Nadu 625016</h3> -->
                            <h2 class="around">BELL FROZEN FOODS</h2>
                            <h3> GST NO-33AAPFB1226Q1Z8,</h3>
                            <h3>ADDRESS- 224 & 225,</h3>
                            <h3>THENI MAIN ROAD,</h3>
                            <h3>MADURAI- 625016.</h3>
                        </div>
                    </div>
                    <div class="dot-b">

                        <div class="dot-b-list" *ngIf="storeAddress&&!shippingAddress">
                            <h1>Delivery & Billing Address</h1>
                            <h2 class="around">{{orderDetails?.storeAddress?.name ?
                                (orderDetails?.storeAddress?.name|titlecase):'-'}}</h2>
                            <h2 *ngIf=" splitBycomma( orderDetails?.storeAddress?.address).length==1">{{
                                orderDetails?.storeAddress?.address ? splitBycomma(
                                orderDetails?.storeAddress?.address)[0] : '-'}}</h2><span></span>
                            <h2 *ngIf=" splitBycomma( orderDetails?.storeAddress?.address).length>1">{{
                                orderDetails?.storeAddress?.address ? splitBycomma(
                                orderDetails?.storeAddress?.address)[0] : '-'}},{{ orderDetails?.storeAddress?.address ?
                                splitBycomma( orderDetails?.storeAddress?.address)[1] : '-'}} <span
                                    *ngIf=" splitBycomma( orderDetails?.storeAddress?.address).length>2">,</span></h2>
                            <h2 *ngIf=" splitBycomma( orderDetails?.storeAddress?.address).length>2">{{
                                orderDetails?.storeAddress?.address ? splitBycomma(
                                orderDetails?.storeAddress?.address)[2] : '-'}},{{ orderDetails?.storeAddress?.address ?
                                splitBycomma( orderDetails?.storeAddress?.address)[3] : '-'}}<span
                                    *ngIf=" splitBycomma( orderDetails?.storeAddress?.address).length>4">,</span></h2>
                            <h2 *ngIf=" splitBycomma( orderDetails?.storeAddress?.address).length>4">{{
                                orderDetails?.storeAddress?.address ? splitBycomma(
                                orderDetails?.storeAddress?.address)[3] : '-'}},{{ orderDetails?.storeAddress?.address ?
                                splitBycomma( orderDetails?.storeAddress?.address)[4] : '-'}},</h2>
                            <!-- <h2>Vasanth Nagar,</h2>
                <h2>Madurai - 625003</h2> -->
                            <div class="additional-function">
                                <h5>{{orderDetails?.storeAddress?.phone ? (orderDetails?.storeAddress?.phone):'-'}}</h5>
                                <!-- <h6>{{orderDetails?.storeAddress?.phone ? (orderDetails?.storeAddress?.phone):'-'}}</h6> -->
                            </div>
                        </div>
                        <!-- Home Address -->
                        <div class="dot-b-list"
                            *ngIf="homeAddress&&!orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress">
                            <h1>Delivery & Billing Address</h1>
                            <h2 class="around">{{orderDetails?.homeAddress?.name ? (orderDetails?.homeAddress?.name
                                |titlecase):'-'}}</h2>
                            <h2>{{ orderDetails?.homeAddress?.line1 ? orderDetails?.homeAddress?.line1 : '-'}}</h2>
                            <h2 *ngIf="orderDetails?.homeAddress?.line2!=null">{{orderDetails?.homeAddress?.line2 ?
                                orderDetails?.homeAddress?.line2 : '-'}},</h2>
                            <h2 *ngIf="orderDetails?.homeAddress?.city!=null">{{orderDetails?.homeAddress?.city ?
                                orderDetails?.homeAddress?.city : '-'}},</h2>
                            <h2 *ngIf="orderDetails?.homeAddress?.state!=null">{{orderDetails?.homeAddress?.state ?
                                orderDetails?.homeAddress?.state : '-'}}- <span
                                    *ngIf="orderDetails?.homeAddress?.pinCode!=null">{{
                                    orderDetails?.homeAddress?.pinCode ? orderDetails?.homeAddress?.pinCode :
                                    '-'}}</span></h2>

                            <div class="additional-function">
                                <h5>{{orderDetails?.homeAddress?.phoneNumber ?
                                    (orderDetails?.homeAddress?.phoneNumber):'-'}}</h5>
                                <h6>{{orderDetails?.homeAddress?.email ? (orderDetails?.homeAddress?.email):'-'}}</h6>
                            </div>
                        </div>

                        <div class="dot-b-list"
                            *ngIf="shippingAddress&&orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress">
                            <h1>Delivery & Billing Address</h1>
                            <h2 class="around">{{orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.name ?
                                (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.name |titlecase):'-'}}
                            </h2>
                            <h2>{{ orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line1 ?
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line1 : '-'}}</h2>
                            <h2 *ngIf="orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line2!=null">{{
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line2 ?
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.line2 : '-'}},</h2>
                            <h2 *ngIf="orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.city!=null">{{
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.city ?
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.city : '-'}},</h2>
                            <h2 *ngIf="orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.state!=null">{{
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.state ?
                                orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.state : '-'}}- <span
                                    *ngIf="orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.pinCode!=null">{{
                                    orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.pinCode ?
                                    orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.pinCode : '-'}}</span>
                            </h2>

                            <div class="additional-function">
                                <h5>{{orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.phoneNumber ?
                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.phoneNumber):'-'}}
                                </h5>
                                <h6>{{orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.email ?
                                    (orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.email):'-'}}</h6>
                            </div>
                        </div>

                    </div>
                </div>
                <!--Invoice Info-->
                <div class="second-function d-flex">
                    <div class="line-a event">
                        <h1>Invoice Number:{{orderDetails ?
                            (orderDetails?.invoicePrefix+'-'+orderDetails?.invoiceYear+'-'+orderDetails?.invoiceNumber)
                            : '-'}}</h1>
                    </div>
                    <div class="line-b event">
                        <h2>Invoice Date:{{orderDetails ? (orderDetails?.createdOn|date : 'dd-MM-yyyy') : '-'}}</h2>
                    </div>
                    <div class="line-c event">
                        <h3>No. of. Items:{{orderDetails?.data?.lineItemsInfo?.length}}</h3>
                    </div>
                </div>
                <!--Product Info-->
                <div class="table-division">
                    <table style="width:100%">
                        <tr>
                            <th rowspan="2">Product ID</th>
                            <th rowspan="2" class="text-left">Product Name</th>
                            <th rowspan="2">Item Price (₹)</th>

                            <th rowspan="2">Qty</th>
                            <th rowspan="2">Gross Amount (₹)</th>

                            <th rowspan="2">Discount (₹)</th>
                            <th rowspan="2">Taxable Amount (₹)</th>
                            <th colspan="2" *ngIf="isInsideTamilNadu">CGST</th>
                            <th colspan="2" *ngIf="isInsideTamilNadu">SGST</th>
                            <th colspan="2" *ngIf="!isInsideTamilNadu">IGST</th>
                            <th rowspan="2">Net Amount (₹)</th>
                        </tr>
                        <tr>
                            <ng-container *ngIf="isInsideTamilNadu">
                                <!-- CGST -->
                                <th class="read">%</th>
                                <th>Amount(₹)</th>
                            </ng-container>

                            <ng-container *ngIf="isInsideTamilNadu">
                                <!-- SGST -->
                                <th class="read">%</th>
                                <th>Amount (₹)</th>
                            </ng-container>

                            <ng-container *ngIf="!isInsideTamilNadu">
                                <!-- IGST -->
                                <th class="read">%</th>
                                <th>Amount (₹)</th>
                            </ng-container>
                        </tr>
                        <tr *ngFor="let pro of orderDetails?.data?.lineItemsInfo">
                            <td>{{pro?.sku ? pro?.sku : '-'}}</td>
                            <td>
                                <p class="productname">{{pro?.product?.productName ?
                                    (pro?.product?.productName|titlecase) : '-'}}</p>
                            </td>
                            <td>{{(pro?.costDetails?.itemPrice).toFixed(2) }}</td>

                            <td>{{pro?.costDetails?.price ?
                                pro.product.isKg==true?(pro?.costDetails?.price/pro?.costDetails?.itemPrice).toFixed(0)+'Kg':
                                (pro?.costDetails?.price/pro?.costDetails?.itemPrice).toFixed(0) > 1
                                ?(pro?.costDetails?.price/pro?.costDetails?.itemPrice).toFixed(0)+"Nos"
                                :(pro?.costDetails?.price/pro?.costDetails?.itemPrice).toFixed(0)+'No'
                                : '0'}}</td>
                            <td class="overwrite">{{pro?.costDetails?.price ? (pro?.costDetails?.price).toFixed(2) :
                                '0.00'}}</td>

                            <td class="overwrite">{{pro?.costDetails?. discount ? (pro?.costDetails?.
                                discount).toFixed(2) : '0.00'}}</td>
                            <td class="overwrite">{{pro?.costDetails?. total ?
                                (((pro?.costDetails?.total)-(singleGST(orderDetails?.data,pro)*2)).toFixed(2)): '0.00'}}
                            </td>

                            <ng-container *ngIf="isInsideTamilNadu">
                                <!-- CGST -->
                                <td>{{orderDetails?.data?.costDetails?.taxPercentage
                                    ?((orderDetails?.data?.costDetails?.taxPercentage)/2):'0' }}</td>
                                <td class="overwrite">{{singleGST(orderDetails?.data,pro).toFixed(2)}}</td>
                            </ng-container>

                            <ng-container *ngIf="isInsideTamilNadu">
                                <!-- SGST -->
                                <td>{{orderDetails?.data?.costDetails?.taxPercentage
                                    ?((orderDetails?.data?.costDetails?.taxPercentage)/2):'0' }}</td>
                                <td class="overwrite">{{singleGST(orderDetails?.data,pro).toFixed(2)}}</td>
                            </ng-container>

                            <ng-container *ngIf="!isInsideTamilNadu">
                                <!-- IGST -->
                                <td>{{orderDetails?.data?.costDetails?.taxPercentage
                                    ?((orderDetails?.data?.costDetails?.taxPercentage)):'0' }}</td>
                                <td class="overwrite">{{IGST(orderDetails?.data,pro).toFixed(2)}}</td>
                            </ng-container>

                            <td>{{(pro?.costDetails?.total).toFixed(2) ?(pro?.costDetails?.total).toFixed(2) : 0.00 }}
                            </td>
                        </tr>
                        <!-- <tr>
                <td>PA#001</td>
                <td><p class="productname">Pass...</p></td>
                <td class="overwrite">5.00</td>
                <td>1</td>
                <td class="overwrite">1</td>
                <td class="overwrite">4.25</td>
                <td>9</td>
                <td class="overwrite">0.38</td>
                <td>9</td>
                <td class="overwrite">0.38</td>
                <td>5.00</td>
            </tr> -->
                        <tr>
                            <td></td>
                            <td [attr.colspan]="isInsideTamilNadu ?10:8" class="overwrite">Sub Total(₹)</td>
                            <td>{{calculation.total?.toFixed(2)}}</td>
                        </tr>
                        <tr *ngIf="calculation.discount > 0">
                            <td></td>
                            <td [attr.colspan]="isInsideTamilNadu ?10:8" class="overwrite">Discount(₹)</td>
                            <td>-{{calculation.discount.toFixed(2)}}</td>
                        </tr>
                        <tr *ngIf="calculation.coupon > 0">
                            <td></td>
                            <td [attr.colspan]="isInsideTamilNadu ?10:8" class="overwrite">Coupon Discount(₹)</td>
                            <td>-{{calculation.coupon.toFixed(2)}}</td>
                        </tr>
                        <!-- <tr>
                            <td></td>
                            <td colspan="10" class="overwrite">Net Amount(₹)</td>
                            <td>{{calculation.netAmount?.toFixed(2)}}</td>
                        </tr> -->
                        <tr *ngIf="calculation.delivery>0">
                            <td></td>
                            <td [attr.colspan]="isInsideTamilNadu ?10:8" class="overwrite">Delivery Charge(₹)</td>
                            <td>{{calculation.delivery.toFixed(2)}}</td>
                        </tr>
                        <tr *ngIf="calculation.midNight>0">
                            <td></td>
                            <td [attr.colspan]="isInsideTamilNadu ?10:8" class="overwrite">Mid-Night Delivery Charge(₹)
                            </td>
                            <td>{{calculation.midNight.toFixed(2)}}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td [attr.colspan]="isInsideTamilNadu ?10:8" class="overwrite">Total Amount(₹)</td>
                            <td>{{calculation.grandTotal.toFixed(2)}}</td>
                        </tr>
                    </table>
                </div>

















            </div>








        </div>







    </div>

</section>