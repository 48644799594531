import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PermissionService } from 'src/app/services/core/permission.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ApiService } from 'src/app/services/otherServices/api.service';
declare var $: any;
@Component({
  selector: 'app-flavour',
  templateUrl: './flavour.component.html',
  styleUrls: ['./flavour.component.css']
})
export class FlavourComponent implements OnInit {
  loader: any;
  public form: FormGroup;
  isEdited: boolean = false;
  submitted: boolean = false;

  falvourList: any = []

  constructor(private formBuilder: FormBuilder,
    private apiService: ApiService,
    public http: HttpService,
    public toastr: ToastrManager,
    public permission : PermissionService) { }

  ngOnInit(): void {
    this.getFlavourList();
    this.newForm()
  }

  close() {
    this.submitted = false;
    this.form.reset();
    this.newForm();
    $('#flavour_modal').modal('hide')
  }
  create() {
    this.submitted = false;
    this.isEdited = false;
    this.form.reset();
    this.newForm()
    $('#flavour_modal').modal('show');

  }
  edit(data: any) {
    console.log(data, 'let me check the data');

    this.isEdited = true;
    this.form.get('flavoursName').setValue(data?.flavoursName);
    this.form.get('flavoursId').setValue(data?.flavoursId)
    this.form.get('flavoursName').setValidators(Validators.required);
    this.form.get('flavoursName').updateValueAndValidity()
    $('#flavour_modal').modal('show');

  }
  newForm() {
    this.form = this.formBuilder.group({
      flavoursName: new FormControl('', [Validators.required, Validators.pattern('')]),
      flavoursId: new FormControl('')
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      if (!this.isEdited) {
        this.loader = "show"
        this.http.post('/admin/flavours', this.form.value).subscribe((res: any) => {
          this.loader = '';
          $('#flavour_modal').modal('hide')
          this.toastr.successToastr('Flavour created successfully', "");
          this.getFlavourList()
        },
          (error: any) => {
            this.loader = ""
            this.toastr.warningToastr(error?.message, "")
          })
      } else {
        this.loader = "show"
        this.http.put(`/admin/flavours/${this.form.value?.flavoursId}`, this.form.value).subscribe((res: any) => {
          this.loader = '';
          $('#flavour_modal').modal('hide')
          this.toastr.successToastr('Flavour updated successfully', "");
          this.getFlavourList()
        },
          (error: any) => {
            this.loader = ""
            this.toastr.warningToastr(error?.message, "")
          })
      }
    }
    else {
      this.toastr.warningToastr("Please provide the mandatory fields!", "")
    }
  }
  //
  getFlavourList() {
    this.http.get('/admin/getAllFlavours').subscribe((res: any) => {
      this.falvourList = res?.content;
    },
      (err: any) => {
        switch (err?.msg) {
          case "NOT_FOUND" : this.toastr.errorToastr('No data found...')
            
            break;
        
          default:   this.toastr.errorToastr('Something went wrong!')
            break;
        }
        this.falvourList = []
     
      })
  }

  get f() {
    return this.form.controls;
  }

  // delete call

  deleteFlavour(id:any){
    this.loader='show'
    console.log(id,'this is ready to delete');
    this.http.delete(`/admin/flavours/${id}`).subscribe((res: any) => {
     setTimeout(() => {
       this.loader=""
     }, 500);
      this.toastr.successToastr(res?.message)
      this.getFlavourList()
    },
      (err: any) => {
        this.loader=""
        this.toastr.errorToastr(err?.message)
      })
    
  }
}
