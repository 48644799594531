export class ReportDTO {
    startDate?
    endDate?
    status?
    categoryId?
    deliveryStatus?
    deliveryType?
    orderTypeId?
    pageNo?
    pageSize?
    search?
    sortBy?
    sortDirection?

    couponFromDate
    couponToDate
    couponAvailStartDate
    couponAvailEndDate
    couponTypeId
    customerEligibility
    customerId
    discountTypeId
    productId
    showToUser
    couponStatusId

    orderStatus

    formName

}


