export class dashboardDTO{
    status:string
    pageNo:number
    pageSize:number
    search:string
    sortBy:string
    sortDirection:string
    type:string
    date:any
}

export class topProducts{
    productLimit:number
    type:string
}