import { IMenuItem } from './navigation.model';

export let adminMenu: IMenuItem[] = [
  {
    name: 'Dashboard',
    type: 'link',
    tooltip: 'Dashboard',
    module: ['dashboard'],
    icon: 'fa fa-pie-chart',
    state: 'home',
  },
  {
    name: 'Home Page',
    type: 'dropDown',
    tooltip: 'Home Page',
    icon: 'fa fa-home',
    module: [
      'banner',
      'testimonial',
      'feature_cake',
      'trending_cake',
      'best_seller',
    ],
    state: '',
    sub: [
      {
        name: 'Banner',
        module: ['banner'],
        icon: 'fa fa-picture-o',
        state: 'create-banner',
      },
      {
        name: 'Short Link',
        module: ['short_link'],
        icon: 'fa fa-link',
        state: 'short-link',
      },
      {
        name: 'Cakes By occasion',
        module: ['feature_cake'],
        icon: 'fa fa-glass',
        state: 'cake-list',
        prams: { cake: 'feature' },
      },
      {
        name: 'Trending Cake',
        module: ['trending_cake'],
        icon: 'fa fa-shield',
        state: 'cake-list',
        prams: { cake: 'trending' },
      },
      {
        name: 'Best Seller',
        module: ['best_seller'],
        icon: 'fa fa-thumbs-up',
        state: 'cake-list',
        prams: { cake: 'bestseller' },
      },
      {
        name: 'Testmonial',
        module: ['testimonial'],
        icon: 'fa fa-hourglass-half',
        state: 'create-testimonial',
      },
    ],
  },
  {
    name: 'Catalogue',
    type: 'dropDown',
    tooltip: 'Catalogue',
    icon: 'fa fa-calendar-o',
    module: ['categories', 'products', 'flavour'],
    state: '',
    sub: [
      {
        name: 'Categories',
        module: ['categories'],
        icon: 'fa fa-tags',
        state: 'categories',
      },
      {
        name: 'Flavour',
        module: ['flavour'],
        icon: 'fa fa-snowflake-o',
        state: 'flavour',
      },
      {
        name: 'Products',
        module: ['products'],
        icon: 'fa fa-birthday-cake',
        state: 'products',
      },
    ],
  },
  {
    name: 'Delivery Management',
    type: 'link',
    tooltip: 'Delivery Management',
    module: ['delivery'],
    icon: 'fa fa-truck',
    state: 'deliveries',
  },
  {
    name: 'Delivery Charge',
    type: 'link',
    tooltip: 'Delivery Charge',
    module: ['delivery_charge'],
    icon: 'fa fa-ship',
    state: 'home-page-popup',
    prams: { type: 'delivery' },
  },
  {
    name: 'Delivery Time',
    type: 'link',
    tooltip: 'Delivery Time',
    module: ['delivery_time'],
    icon: 'fa fa-clock-o',
    state: 'delivery-time',
  },
  {
    name: 'Coupon Management',
    type: 'dropDown',
    tooltip: 'Coupon Management',
    icon: 'fa fa-gift',
    module: ['General', 'Coupon Setting', 'coupon_new'],

    state: '',

    sub: [
      {
        name: 'General',
        module: ['promocode-general', 'coupon_new'],
        icon: 'fa fa-gratipay',
        state: 'promocode-general',
      },
      {
        name: 'Coupon Setting',
        module: ['occasion-update', 'coupon_new'],
        icon: 'fa fa-sliders',
        state: 'occasion-update',
      },
    ],
  },
  {
    name: 'Report',
    type: 'dropDown',
    tooltip: 'Report',
    icon: 'fa fa-cog',
    module: ['Product Report'],

    state: '',

    sub: [
      {
        name: 'Product Report',
        module: ['product-report'],
        icon: 'fa fa-pencil-square',
        state: 'product-report',
      },
      // {
      //   name: "Order Report",
      //   module: ['report/new-order-report'],
      //   icon: 'fa fa-user-plus',
      //   state: "report/new-order-report",
      // },
      {
        name: 'Customer Summary Report',
        module: ['customer-summary-report'],
        icon: 'fa fa-sticky-note-o',
        state: 'customer-summary-report',
      },
      {
        name: 'GST Audit Report',
        module: ['gst-audit-report'],
        icon: 'fa fa-sticky-note-o',
        state: 'gst-audit-report',
      },
      {
        name: 'Coupon Summary Report',
        module: ['coupon-code-usage-summary-report'],
        icon: 'fa fa-database',
        state: 'coupon-code-usage-summary-report',
      },
      {
        name: 'Coupon Transaction Report',
        module: ['coupon-code-transaction-report'],
        icon: 'fa fa-exchange',
        state: 'coupon-code-transaction-report',
      },
      {
        name: 'Popularity Report',
        module: ['popularity-report'],
        icon: 'fa fa-money',
        state: 'popularity-report',
      },
      {
        name: 'Enquiry Report',
        module: ['enquiry-report'],
        icon: 'fa fa-phone-square',
        state: 'enquiry-report',
      },
    ],
  },
  {
    name: 'Home Page Notification',
    type: 'link',
    tooltip: 'Home Page Notification',
    module: ['home_page_notification'],
    icon: 'fa fa-commenting',
    state: 'home-page-popup',
  },
  {
    name: 'Invoice setting',
    type: 'link',
    tooltip: 'Invoice setting',
    module: ['invoice'],
    icon: 'fa fa-cog',
    state: 'invoice',
  },
  {
    name: 'Order History',
    type: 'link',
    tooltip: 'Order History',
    icon: 'fa fa-history',
    module: ['order_history'],
    state: 'order',
    prams: { page: '1', status: 'all_orders' },
  },
  {
    name: 'Order Issue',
    type: 'link',
    tooltip: 'Order Issue',
    module: ['order_issue'],
    icon: 'fa fa-exclamation-circle',
    state: 'order-report',
  },
  {
    name: 'Customers',
    type: 'link',
    tooltip: 'Customers',
    module: ['customers'],
    icon: 'fa fa-user-circle-o',
    state: 'customers',
  },
  {
    name: 'Transaction',
    type: 'link',
    tooltip: 'Transaction',
    module: ['transaction'],
    icon: 'fa fa-money',
    state: 'transaction',
  },
  {
    name: 'Review Management',
    type: 'link',
    tooltip: 'Review Management',
    module: ['review_management'],
    icon: 'fa fa-star',
    state: 'review-management',
  },
  {
    name: 'Gallery',
    type: 'link',
    tooltip: 'Gallery',
    module: ['gallery'],
    icon: 'fa fa-camera-retro',
    state: 'gallary',
  },
  {
    name: 'Blog Contents',
    type: 'link',
    tooltip: 'Blog Contents',
    module: ['blogs'],
    icon: 'fa fa-address-card',
    state: 'blog',
  },
  {
    name: 'News Letter',
    type: 'link',
    tooltip: 'News Letter',
    module: ['news_letter'],
    icon: 'fa fa-newspaper-o',
    state: 'news-letter',
  },
  {
    name: 'Image/Video explorer',
    type: 'link',
    tooltip: 'Image/Video explorer',
    module: ['file_explorer'],
    icon: 'fa fa-file-video-o',
    state: 'image-exporal',
  },
  {
    name: 'Loyalty Programme',
    type: 'link',
    tooltip: 'Loyalty Programme',
    module: ['loyalty_programme'],
    icon: 'fa fa-film',
    state: 'loyalty-programme',
  },
  {
    name: 'Instagram',
    type: 'link',
    tooltip: 'Instagram',
    module: ['instagram'],
    icon: 'fa fa-instagram',
    state: 'instagram',
  },
  {
    name: 'Notifications',
    type: 'link',
    tooltip: 'Notifications',
    module: ['notification'],
    icon: 'fa fa-bell',
    state: 'notification-list',
  },
  {
    name: 'Activity Logs',
    type: 'link',
    tooltip: 'Activity Logs',
    module: ['activity_log'],
    icon: 'fa fa-line-chart',
    state: 'activity-log',
  },

  {
    name: 'Administration',
    type: 'dropDown',
    tooltip: 'Administration',
    icon: 'fa fa-user-circle-o',
    module: ['roles', 'user', 'role_permission'],
    state: '',
    sub: [
      {
        name: 'Roles',
        module: ['roles'],
        icon: 'fa fa-files-o',
        state: 'Roles',
      },
      {
        name: 'Users',
        module: ['user'],
        icon: 'fa fa-user',
        state: 'create-user',
      },
      {
        name: 'Roles & Permission',
        module: ['role_permission'],
        icon: 'fa fa-lock',
        state: 'Roles-Permission',
      },
    ],
  },

  // {
  //   name: "Pricing Update",
  //   type: "link",
  //   tooltip: "Pricing Update",
  //   icon:'fa fa-pie-chart',
  //   module: ['pricing_update'],
  //   state: "pricing",
  // },
];
