import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
declare var $: any;
import { AppConstants } from '../../constants/app.constants';
import * as _ from 'lodash';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  loader = '';
  BannerForm: FormGroup;
  submitted = false;
  isEdited = false;
  imgPth;
  adminAccess;
  fileType: boolean = false;
  @ViewChild('file') file: ElementRef;
  val1: boolean = false;
  val2: boolean = false;
  val3: boolean = false;
  fileSize: boolean = false;
  max_width: Number;
  max_height: Number;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public rolepermission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.emptyForm();
    this.get();
    this.getCategoryName();
    this.getDesignProductList();
  }

  designProductList;
  designProductData;
  getDesignProductList() {
    if (!this.designProductList) {
      this.loader = 'show';
      this.http.get('/design').subscribe(
        (data) => {
          this.designProductData = data;
          this.designProductList = this.designProductData.products;
          this.loader = '';
          console.log(this.designProductList);
        },
        (error) => {
          this.loader = '';
        }
      );
    }
  }

  emptyForm() {
   // /^(https?:\/\/)?(www\.)?testpuppysbakery\.technogenesis\.in(?:\/.*)?$/g
    this.submitted = false;
    this.imgUrl = '';
    this.BannerForm = this.formBuilder.group({
      bannerId: [''],
      bannerType: ['', Validators.required],
      image: ['', Validators.required],
      isClickable: [false],
      bannerUrl: [
        '',
        [
          Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageDev : AppConstants.SERVER_CONFIG.localDev),
        ],
      ],
      bannerUrlMob: ['',
        [
          Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageMob : AppConstants.SERVER_CONFIG.localMob)
        ],
      ],
      redirectType: [''],
      createdOn: [''],
      updatedOn: [''],
      sortOrder: ['', Validators.required],
      active: true,
      bannerDetails: new FormGroup({
        heading: new FormControl(''),
        content: new FormControl(''),
        buttonName: new FormControl(''),
        redirectProductId: new FormControl(''),
      }),
    });
  }

  index_Baking_1 = [];
  ////////////getdata//////////////
  getdata;
  get() {
    this.loader = 'show';
    this.http.get('/admin/banner').subscribe(
      (data) => {
        this.getdata = data;
        this.loader = '';
        this.getdata.map((x) => {
          if (x.bannerType == 'index_Baking_1') {
            this.index_Baking_1.push(x.bannerType);
          }
          if (x.bannerType) {
            switch (x.bannerType) {
              case 'index_slider_1':
                x.bannerTypeName = 'Home Page First Slider';
                break;
              case 'index_Baking_1':
                x.bannerTypeName = 'What’s Baking in puppy’s?';
                break;
              case 'index_slider_2':
                x.bannerTypeName = 'Design My Cake Slider';
                break;
              case 'index_cake_design':
                x.bannerTypeName = 'Dessert Table & Wedding Cakes';
                break;
            }
          }
        });
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  valueChange(value: any) {
    if (value == 'index_slider_1') {
      this.max_height = 465;
      this.max_width = 1519;
      // this.max_height=1
      // this.max_width=1
      this.val1 = true;
      this.val2 = false;
      this.val3 = false;
    } else if (value == 'index_Baking_1') {
      this.max_height = 544;
      this.max_width = 650;
      // this.max_height=1
      // this.max_width=1
      this.val2 = true;
      this.val1 = false;
      this.val3 = false;
    } else if (value == 'index_slider_2') {
      this.max_height = 1200;
      this.max_width = 1200;
      // this.max_height=1
      // this.max_width=1
      this.val3 = true;
      this.val1 = false;
      this.val2 = false;
    } else {
      this.val3 = false;
      this.val1 = false;
      this.val2 = false;
    }
  }

  //////upload file and get path///////
  path;
  imgUrl;
  fileImg;
  fileName;
  // uploadFile(event: any) {
  //   alert('fileuploader')
  //   this.fileImg = event.target.files[0];
  //   this.fileName = this.fileImg['name'];
  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       if (this.fileImg) {
  //         const formData: FormData = new FormData();
  //         formData.append('image', this.fileImg, this.fileName);
  //         this.loader = 'show';
  //         this.http.post('/uploadimage', formData).subscribe(
  //           data => {
  //             this.path = data;
  //             this.imgUrl = this.path.path;
  //             console.log(this.imgUrl);
  //             this.BannerForm.controls["image"].setValue(this.imgUrl);
  //             console.log(this.BannerForm.value);
  //             this.loader = '';
  //           },
  //           error => {
  //             this.loader = '';
  //           });
  //       }
  //     }
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // }

  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    console.log(this.fileImg, this.fileName, this.BannerForm.value);
    let value = this.BannerForm.value?.bannerType;
    const max_size = 50;
    const filetypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/jfif'];

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const image = new Image();
      reader.readAsDataURL(this.fileImg);
      // file type validation
      if (!_.includes(filetypes, this.fileImg.type)) {
        this.fileType = true;
        this.toastr.errorToastr(
          'Please provide the image with valid type' + '' + filetypes
        );
        this.file.nativeElement.value = '';
        console.log(this.fileImg.type);
      }
      // file size validation 50 mb
      if (this.fileImg.size > 1024 * 1024 * max_size) {
        this.fileSize = true;
        this.toastr.errorToastr(
          'Please provide the image with valid size' + max_size + 'MB'
        );
        this.file.nativeElement.value = '';
      }
      // file dimension validation
      reader.onload = (read: any) => {
        image.src = read.target.result;
        image.onload = (load: any) => {
          const height = load.currentTarget['height'];
          const width = load.currentTarget['width'];
          console.log(height, width);
          if (this.max_height == height && this.max_width == width) {
            const formData: FormData = new FormData();
            formData.append('image', this.fileImg, this.fileName);
            formData.append(
              'thumbnail',
              value == 'index_slider_1' ? 'false' : 'true'
            );
            formData.append(
              'fileType',
              this.BannerForm.value.bannerType == 'index_slider_1'
                ? 'banner'
                : this.BannerForm.value.bannerType == 'index_Baking_1'
                ? 'baking'
                : this.BannerForm.value.bannerType == 'index_slider_2'
                ? 'design_cake'
                : ''
            );
            this.loader = 'show';
            this.http.post('/uploadimage', formData).subscribe(
              (data) => {
                this.path = data;
                this.imgUrl = this.path.path;
                console.log(this.imgUrl);
                this.BannerForm.controls['image'].setValue(this.imgUrl);
                console.log(this.BannerForm.value);
                this.loader = '';
              },
              (error) => {
                this.loader = '';
              }
            );
          } else {
            this.toastr.errorToastr(
              'Please provide the image with valid dimension' +
                this.max_width +
                '×' +
                this.max_height +
                'pixels'
            );
            console.log(this.file.nativeElement.value, 'its the value');
            this.file.nativeElement.value = '';
          }
        };
      };
    }
  }
  exporerImge(imgUrl: any) {
    throw new Error('Method not implemented.');
  }

  createBanner() {
    $('#createModal').modal('show');
    this.emptyForm();
    this.isEdited = false;
    this.BannerCheckbox = false;
    this.bannerDetailDiv = false;
    this.redirectProduct = false;
  }

  bannerDetailDiv = false;
  redirectProduct;
  bannerTypeChange(val) {
    if (val == 'index_slider_2' || val == 'index_cake_design') {
      this.bannerDetailDiv = true;
      this.redirectProduct = true;
      //  if (val == 'index_slider_2') { this.redirectProduct = true; }
    } else {
      this.bannerDetailDiv = false;
      this.redirectProduct = false;
    }
  }
  onEditSelect() {
    this.imgUrl = '';
    console.log(this.file.nativeElement.value, 'its the value');
    this.file.nativeElement.value = '';
    this.BannerForm.get('image').setValue('');
    this.BannerForm.get('image').setValidators([Validators.required]);
    this.BannerForm.updateValueAndValidity();
  }

  closeModal() {
    this.emptyForm();
    $('#createModal').modal('hide');
    this.imgUrl = '';
    this.file.nativeElement.value = '';
  }

  setvalidate(event) {
    if (event.target.checked == true) {
     
      this.BannerForm.get('bannerUrl').setValidators([
        Validators.required,
        Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageDev : AppConstants.SERVER_CONFIG.localDev)
      ]);
      this.BannerForm.get('bannerUrlMob').setValidators([
        Validators.required,
        Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageMob : AppConstants.SERVER_CONFIG.localMob)
      ]);
      this.BannerForm.get('bannerUrl').updateValueAndValidity();
      this.BannerForm.get('bannerUrlMob').updateValueAndValidity();
    } else {
      this.BannerForm.get('bannerUrl').clearValidators();
      this.BannerForm.get('bannerUrl').updateValueAndValidity();
      this.BannerForm.get('bannerUrlMob').clearValidators();
      this.BannerForm.get('bannerUrlMob').updateValueAndValidity();
    }
  }

  BannerCheckbox;
  onClickEdit(item) {
    console.log(item);
    this.valueChange(item?.bannerType);
    this.BannerCheckbox = item.isClickable;
    this.isEdited = true;
    this.bannerTypeChange(item.bannerType);
    $('#createModal').modal('show');
    this.BannerForm = this.formBuilder.group({
      bannerId: [item.bannerId],
      bannerType: [item.bannerType, Validators.required],
      image: [item.image, Validators.required],
      isClickable: [item.isClickable],
      bannerUrl: [item.bannerUrl],
      bannerUrlMob: [item.bannerUrlMob ? item.bannerUrlMob:''],
      redirectType: [''],
      createdOn: [''],
      updatedOn: [''],
      sortOrder: [item.sortOrder, Validators.required],
      active: true,
      bannerDetails: new FormGroup({
        heading: new FormControl(item.bannerDetails.heading),
        content: new FormControl(item.bannerDetails.content),
        buttonName: new FormControl(item.bannerDetails.buttonName),
        redirectProductId: new FormControl(
          item.bannerDetails.redirectProductId
        ),
      }),
    });
    this.imgUrl = item.image;
    if (item.isClickable) {
      this.BannerForm.get('bannerUrl').setValidators([
        Validators.required,
        Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageDev : AppConstants.SERVER_CONFIG.localDev)
      ]);
      this.BannerForm.get('bannerUrlMob').setValidators([
        Validators.required,
        Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageMob : AppConstants.SERVER_CONFIG.localMob)
      ]);
      this.BannerForm.get('bannerUrl').updateValueAndValidity();
      this.BannerForm.get('bannerUrlMob').updateValueAndValidity();
    }
    console.log(this.BannerForm.value);
  }

  deleteImage() {
    this.fileImg = '';
    this.imgUrl = '';
    this.BannerForm.controls['image'].setValue('');
  }

  deleteData(id) {
    this.loader = 'show';
    this.http.delete('/admin/banner/' + id).subscribe(
      (data) => {
        this.toastr.successToastr('Deleted Successfully', '');
        this.loader = '';
        this.get();
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  get f() {
    return this.BannerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.BannerForm.invalid) {
      return;
    }

    if (this.isEdited == true) {
      this.loader = 'show';
      this.http
        .put(
          '/admin/banner/' + this.BannerForm.controls['bannerId'].value,
          this.BannerForm.value
        )
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Successfully Updated', '');
            this.emptyForm();
            $('#createModal').modal('hide');
            this.file.nativeElement.value = '';
            this.imgUrl = '';
            this.get();
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }
        );
    } else {
      //   if (this.BannerForm.controls["bannerType"].value == 'index_Baking_1' && this.index_Baking_1.length > 4) {
      //     this.toastr.errorToastr('Notable To Create More Than 4 Banner', '');
      //     return;
      //   }

      this.loader = 'show';
      this.http.post('/admin/banner', this.BannerForm.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          this.get();
          $('#createModal').modal('hide');
          this.file.nativeElement.value = '';
          this.imgUrl = '';
        },
        (error) => {
          this.loader = '';
          console.log(error);
          this.toastr.errorToastr('Try Again', '');
        }
      );
    }
  }

  getdata3;
  getdata4;
  getCategoryName() {
    this.loader = 'show';
    this.http.get('/admin/category/all').subscribe(
      (data) => {
        this.getdata3 = data;
        this.getdata4 = this.getdata3.content;
        console.log('getdata4', this.getdata4);
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  //show and hide table rows
  showImg;
  visibleIndex = -1;
  showSubItem(ind, img) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
      this.showImg = '';
    } else {
      this.visibleIndex = ind;
      this.showImg = img;
    }
  }
}
