import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-newsletter-list',
  templateUrl: './newsletter-list.component.html',
  styleUrls: ['./newsletter-list.component.css'],
})
export class NewsletterListComponent implements OnInit {
  loader = '';
  config;
  adminAccess;
  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private router: Router,
    private route: ActivatedRoute,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: '',
    };

    this.route.queryParams.subscribe((params) => {
      this.pageValue(params['page']);
    });
  }

  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get();
      window.scrollTo(0, 0);
    } else {
      this.config.currentPage = 1;
      this.get();
    }
  }

  pageChange(newPage: number) {
    this.router.navigate(['/news-letter'], { queryParams: { page: newPage } });
  }

  //get News Letter
  getdata2;
  getdata;
  get() {
    this.loader = 'show';
    this.http
      .get('/admin/newsletter?page=' + this.config.currentPage)
      .subscribe(
        (data) => {
          this.getdata2 = data;
          this.getdata = this.getdata2.content;
          this.loader = '';
          this.config = {
            currentPage: this.getdata2.currentPage,
            itemsPerPage: this.getdata2.limit,
            totalItems: this.getdata2.totalItems,
          };
        },
        (error) => {
          this.toastr.errorToastr('Try Again', '');
          this.loader = '';
        }
      );
  }

  //Export Email
  exportData;
  exportEmail() {
    this.loader = 'show';
    this.http.get('/admin/newsletter/export').subscribe(
      (data) => {
        this.loader = '';
        this.exportData = data;
        console.log(this.exportData.path);
        window.open(this.exportData.path, '_blank');
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  //Delete News Letter
  delete(id, index) {
    this.loader = 'show';
    this.http.delete('/admin/newsletter?newsletterId=' + id).subscribe(
      (data) => {
        this.toastr.successToastr('Deleted Successfully', '');
        this.loader = '';
        this.getdata.splice(index, 1);
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  //Active & Inactive NewsLetter
  putMsg;
  isActiveChange(id) {
    this.loader = 'show';
    this.http.put('/admin/newsletter/' + id, '').subscribe(
      (data) => {
        this.putMsg = data;
        this.toastr.successToastr(this.putMsg.message, '');
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }
}
