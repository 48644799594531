<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"></div>
  <app-header [pageName]="'News Letter'"></app-header>

  <div class="container-fluid">
    <h5>News Letter</h5>
    <br />
    <div class="card bg-table-color">
      <div class="card-body">
        <button
          class="btn-export"
          (click)="exportEmail()"
          *ngIf="
            permission.hasPermission(['news_letter'], ['news_letter_export'])
          "
        >
          Export</button
        ><br />
        <div class="table-responsive check-item">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">EMAIL ID</th>
                <th scope="col">CREATED ON</th>
                <th
                  *ngIf="
                    permission.hasPermission(
                      ['news_letter'],
                      ['news_letter_status', 'news_letter_delete']
                    )
                  "
                  scope="col"
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tr
              *ngFor="let item of getdata | paginate: config; let index = index"
            >
              <td>{{ item.email }}</td>
              <td>{{ item.createdOn | date: "dd-MM-yyyy, h:mm a" }}</td>
              <td *ngIf="adminAccess.privileges.newsLetterWrite">
                <i
                  class="fa fa-trash del-icon"
                  (click)="delete(item.newsletterId, index)"
                  *ngIf="
                    permission.hasPermission(
                      ['news_letter'],
                      ['news_letter_delete']
                    )
                  "
                ></i>
                <ui-switch
                  *ngIf="
                    permission.hasPermission(
                      ['news_letter'],
                      ['news_letter_status']
                    )
                  "
                  size="small"
                  color="#007bff"
                  style="margin-left: 15px"
                  [checked]="item.active"
                  (change)="isActiveChange(item.newsletterId)"
                ></ui-switch>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="pagination-align">
        <pagination-controls
          (pageChange)="pageChange($event)"
          autoHide="true"
          maxSize="5"
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page"
          responsive="true"
        ></pagination-controls>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade bd-example-modal-lg"
      id="createModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Coupon Management
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label>Coupon Name</label><br />
            <input type="text" class="form-control" placeholder="Coupon Name" />
            <br />

            <input type="radio" name="offer" /> Price
            <input type="radio" name="offer" class="percentage" /> Percentage
            <br /><br />

            <label>Offer Value</label><br />
            <input
              type="number"
              class="form-control"
              placeholder="Enter Value"
            />
            <br />

            <div class="row">
              <div class="col-lg-6">
                <label>Offer Valid From Date</label>
                <input type="date" class="form-control" /><br />
              </div>
              <div class="col-lg-6">
                <label>Offer Valid To Date</label>
                <input type="date" class="form-control" /><br />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-1 btn-white"
              data-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="button-1 btn-pink">
              Add
              <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--End Modal-->
  </div>
</section>
