import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { checkNull } from 'src/app/constants/custom-functions';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import {
  PuppysCustomValidation,
  sweetAlert,
} from 'src/app/shared/validations/puppys-custom';
import { ReportDTO } from '../_services/report.dto';
import { ReportService } from '../_services/report.service';

@Component({
  selector: 'app-selling-report',
  templateUrl: './selling-report.component.html',
  styleUrls: ['./selling-report.component.css'],
})
export class SellingReportComponent implements OnInit {
  filterForm: FormGroup;
  pagination: ReportDTO;

  reports: any[] = [];

  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.pagination = new ReportDTO();
  }

  ngOnInit(): void {
    this.intialForm();
  }

  intialForm() {
    this.filterForm = new FormGroup(
      {
        startDate: new FormControl(''),
        endDate: new FormControl(''),
      },
      {
        validators: PuppysCustomValidation.DateValidator(
          'startDate',
          'endDate'
        ),
      }
    );
  }

  checkNull(data) {
    return checkNull(data);
  }

  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form)
      .map((ele) => checkNull(ele))
      .some((val) => val == true);
    return hasOneValue;
  }

  reset() {
    this.intialForm();
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = [];
  }

  navigateToOrder(orderId) {
    this.router.navigate([`order-detail/${orderId}`]);
  }

  getSalesReport(filter?: boolean) {
    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter && false) {
      this.toastr.errorToastr(
        'Kindly fill atleast one field to filter the product report!'
      );
      return;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD');
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required');
        return;
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD');
      }
      this.loader.open();
      this.service.getSoldProducts(this.pagination).subscribe(
        (res: any) => {
          this.loader.close();
          this.reports = res?.content ?? [];
        },
        (err: any) => {
          this.loader.close();
          this.toastr.errorToastr('Try again');
          this.reports = [];
        }
      );
    } else {
      sweetAlert('error', 'Start date should be before End date');
    }
  }

  calculate(data) {
    if (Number(data?.soldQuantity) > 0) {
      return (Number(data?.soldQuantity) * Number(data?.unitPrice)).toFixed(2);
    } else {
      return '0.00';
    }
  }

  export() {
    let form = this.filterForm.value;
    if (false) {
      this.toastr.errorToastr(
        'Kindly fill atleast one field to filter the GST report!'
      );
      return;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD');
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required');
        return;
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD');
      }
      this.service.exportReport(
        '/productReport/exportPopularitywise',
        'Popularity Sales Report',
        this.pagination
      );
    } else {
      sweetAlert('error', 'Start date should be before End date');
    }
  }
}
