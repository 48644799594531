import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpService) { }

  getParentCategories() {
    return this.http.get(`/admin/category/all?type=user`);
  }

  getChildCategories(id) {
    return this.http.get(`/admin/category/sub?id=${id}`);
  }

  getDeliveryTime() {
    return this.http.get(`/admin/deliveryTime`);
  }

  getFlavours() {
    return this.http.get(`/admin/getAllFlavours`);
  }

  createProducts(data) {
    return this.http.post('/admin/product', data)
  }

  updateProduct(productId, data, version) {
    return this.http.put(`/admin/product/${productId}/${version}`, data)
  }

  getProductDetails(productId) {
    return this.http.get(`/admin/product/${productId}`)
  }
  // version
}
