
<section class="offset-width well-white">
  <app-header [pageName]="'Gallery'"></app-header>
  <div *ngIf="loader" class="loader-spin"> </div>

  <div class="container-fluid">
    <h5>Gallery</h5>
    <button type="button"  *ngIf="permission.hasPermission(['gallery'],['gallery_create'])" (click)="onClickCreate()"
      class="button-1 btn-pink btn-margin"> <i class="fa fa-plus"></i>
      Create </button>
      <br>
    <hr>
    <div class="row">
      <div class="card col-12 table-bg">
        <div class="card-body "> <br>
          <div class="table-responsive ">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"> # </th>
                  <th scope="col"> IMAGE</th>
                  <th scope="col"> PAGE TYPE </th>
                  <th scope="col">CREATED ON </th>
                  <th scope="col">SORT</th>
                  <th scope="col" *ngIf="permission.hasPermission(['gallery'],['gallery_update']) || permission.hasPermission(['gallery'],['gallery_delete'])">ACTION</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of getdata; let index = index">
                  <tr>
                      <td>{{index + 1}}</td>
                      <td>
                          <img class="custom-img" src="{{imgPth+item?.image}}">
                      </td>
                      <td>{{item?.pageType | titlecase}}</td>
                      <td>
                          <span>{{item?.createdOn | date: 'dd-MM-yyyy, h:mm a'}}</span>
                      </td>
                      <td>{{item?.sortOrder}}</td>

                      <td *ngIf="permission.hasPermission(['gallery'],['gallery_update']) || permission.hasPermission(['gallery'],['gallery_delete'])">
                          <span *ngIf="permission.hasPermission(['gallery'],['gallery_update'])" (click)="onClickEdit(item)"> <img class="pointer"  src="/assets/images/icon/pencil-2.svg"></span>
                          <i *ngIf="permission.hasPermission(['gallery'],['gallery_delete'])" class="fa fa-trash del-table" (click)="deleteData(item.galleryId)" aria-hidden="true"></i>
                      </td>
                  </tr>            
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>





    <!-- Modal Form-->
    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form [formGroup]="testmonialForm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> <span>{{isEdited!=true ? "Create":"Update"}}</span> Gallery</h5>
              <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <label>Page Type <span style="color:red;">*</span></label><br>
              <select class="form-control" formControlName="pageType">
                <option  value = "" [disabled]="true">Page Type</option>
              <option *ngFor ="let type of pageTypes;"  value = "{{type.value}}">{{type.label | titlecase}}</option>
              </select>
              <div *ngIf="submitted && f.pageType.errors" class="invalid-text">
                <div *ngIf="f.pageType.errors.required">
                  Page type is required
                </div>
              </div><br>

              <label>Sort <span style="color:red;">*</span></label><br>
              <input type="number" formControlName="sortOrder" class="form-control">
              <div *ngIf="submitted && f.sortOrder.errors" class="invalid-text">
                <div *ngIf="f.sortOrder.errors.required">
                  Sort is required
                </div>
              </div><br>
              <input id="upload-degn" accept="image/png, image/jpeg" type="file" #file (change)="uploadFile($event)" style="display:none"><br>
              <label for="upload-degn" *ngIf="!imgUrl">
                <span class="btn btn-file-upload">Choose Image</span>
              </label>
              <br>
              <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG upto 50 MB,</span>
                            <br>
                            <span class="file-formate">Image Size Should be( width:300 X height:215px )  </span>
              <div *ngIf="imgUrl" class="mt-2">
                <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i>
                <img src="{{imgPth+imgUrl}}" class="img img-fluid">
              </div>

              <div *ngIf="submitted && f.image.errors" class="invalid-text">
                <div *ngIf="f.image.errors.required">
                  Image is required
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">Close</button>
              <button type="button" class="button-1 btn-pink" (click)="onSubmit()">Submit <i style="margin-left: 4px;"
                  class="fa fa-long-arrow-right"></i> </button>
            </div>
          </div>
        </form>
      </div>
    </div>
     <!--End Modal Form-->


  </div><br><br>
</section>