import { Pipe, PipeTransform } from "@angular/core";
import { checkNull } from "src/app/constants/custom-functions";

@Pipe({
  name: "digit",
})
export class DigitPipe implements PipeTransform {
  transform(value: string | number, digit?: number) {

    if (checkNull(value)) {
      return Number(value).toFixed(digit ?? 2)
    } else {
      return '-'
    }
  }
}
