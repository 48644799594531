export class CustomerDTO {
    pageNo = 1;
    endDate;
    pageSize = 50;
    search;
    sortBy;
    sortDirection;
    startDate;
    status;
    lastPage;
}