import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportDTO } from '../_services/report.dto';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ReportService } from '../_services/report.service';
import {
  PuppysCustomValidation,
  sweetAlert,
} from 'src/app/shared/validations/puppys-custom';
import { checkNull, queryMaker } from 'src/app/constants/custom-functions';
import * as moment from 'moment';

interface SelectOptions {
  singleSelection: boolean;
  idField: string;
  textField: string;
  selectAllText?: string;
  unSelectAllText?: string;
  itemsShowLimit: number;
  allowSearchFilter: boolean;
}

@Component({
  selector: 'app-product-report',
  templateUrl: './product-report.component.html',
  styleUrls: ['./product-report.component.css'],
})
export class ProductReportComponent implements OnInit {
  filterForm: FormGroup;
  pagination: ReportDTO;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  orderTypeOptions: any = [
    { view: 'PAN India Delivery', value: true },
    { view: 'Local Delivery', value: false },
  ];

  deliveryTypeOptions: any = [
    // { view: 'Both', value: 'both' },
    { view: 'Pickup From Store', value: 'store delivery' },
    { view: 'Home Delivery', value: 'home delivery' },
  ];

  deliveryStatusOptions: any = [
    { view: 'Delivery On', value: 1 },
    { view: 'Delivery Off', value: 0 },
  ];

  statusOptions: any = [
    { view: 'Active', value: 1 },
    { view: 'In-active', value: 0 },
  ];

  categorySettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };

  orderTypeSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  deliveryTypeSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  deliveryStatusSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };
  statusSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  reports: any[] = [];

  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.pagination = new ReportDTO();
  }

  ngOnInit(): void {
    this.intialForm();
    this.getAllParentCategories();

    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  intialForm() {
    this.filterForm = new FormGroup(
      {
        startDate: new FormControl(''),
        endDate: new FormControl(''),
        categoryId: new FormControl(''),
        deliveryStatus: new FormControl(''),
        deliveryType: new FormControl(''),
        orderTypeId: new FormControl(''),
        status: new FormControl(''),
      },
      {
        validators: PuppysCustomValidation.DateValidator(
          'startDate',
          'endDate'
        ),
      }
    );
  }

  parentCategories: any[] = [];
  getAllParentCategories() {
    this.service.getParentCategories().subscribe(
      (res: any) => {
        this.loader.close();
        this.parentCategories =
          res.content.map((ele: any) => {
            return {
              view: ele?.categoryName ?? '',
              value: ele?.categoryId ?? '',
            };
          }) ?? [];
      },
      (error: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again.');
        this.parentCategories = [];
      }
    );
  }

  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form)
      .map((ele) => checkNull(ele))
      .some((val) => val == true);
    return hasOneValue;
  }

  reset() {
    this.intialForm();
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = [];
  }

  getProductReport(filter?: boolean) {
    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter && false) {
      this.toastr.errorToastr(
        'Kindly fill atleast one field to filter the product report!'
      );
      return;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD');
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required');
        return;
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD');
      }

      if (checkNull(form?.categoryId)) {
        if (form?.categoryId?.length == this.parentCategories?.length) {
          this.pagination.categoryId = '';
        } else {
          this.pagination.categoryId = form?.categoryId.map(
            (ele) => ele?.value
          );
        }
      } else {
        this.pagination.categoryId = '';
      }

      if (checkNull(form.deliveryStatus)) {
        this.pagination.deliveryStatus = form?.deliveryStatus.map(
          (ele) => ele?.value
        );
      } else {
        this.pagination.deliveryStatus = '';
      }

      if (checkNull(form.deliveryType)) {
        this.pagination.deliveryType =
          form.deliveryType?.length > 1
            ? ['both']
            : form.deliveryType.map((ele) => ele?.value);
      } else {
        this.pagination.deliveryType = '';
      }

      if (checkNull(form.status)) {
        this.pagination.status = form.status.map((ele) => ele?.value);
      } else {
        this.pagination.status = '';
      }

      if (checkNull(form.orderTypeId)) {
        this.pagination.orderTypeId = form.orderTypeId.map((ele) => ele?.value);
      } else {
        this.pagination.orderTypeId = '';
      }

      queryMaker(this.pagination);

      this.loader.open();
      this.service.getProductReport(this.pagination).subscribe(
        (res: any) => {
          this.loader.close();
          this.reports = res?.content ?? [];
          console.log(res);
        },
        (err: any) => {
          this.loader.close();
          this.toastr.errorToastr('Try again');
          this.reports = [];
        }
      );
    } else {
      sweetAlert('error', 'Start date should be before End date');
    }
  }

  checkNull(data) {
    return checkNull(data);
  }

  export() {
    let form = this.filterForm.value;
    if (false) {
      this.toastr.errorToastr(
        'Kindly fill atleast one field to filter the product report!'
      );
      return;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD');
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required');
        return;
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD');
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD');
      }
    
      if (checkNull(form?.categoryId)) {
        if (form?.categoryId?.length == this.parentCategories?.length) {
          this.pagination.categoryId = '';
        } else {
          this.pagination.categoryId = form?.categoryId.map(
            (ele) => ele?.value
          );
        }
      } else {
        this.pagination.categoryId = '';
      }

      if (checkNull(form.deliveryStatus)) {
        this.pagination.deliveryStatus = form?.deliveryStatus.map(
          (ele) => ele?.value
        );
      } else {
        this.pagination.deliveryStatus = '';
      }

      if (checkNull(form.deliveryType)) {
        this.pagination.deliveryType = form.deliveryType.map(
          (ele) => ele?.value
        );
      } else {
        this.pagination.deliveryType = '';
      }

      if (checkNull(form.status)) {
        this.pagination.status = form.status.map((ele) => ele?.value);
      } else {
        this.pagination.status = '';
      }

      if (checkNull(form.orderTypeId)) {
        this.pagination.orderTypeId = form.orderTypeId.map((ele) => ele?.value);
      } else {
        this.pagination.orderTypeId = '';
      }

      this.service.exportReport(
        `/productReport/export${queryMaker(this.pagination)}`,
        'Product Report'
      );
    } else {
      sweetAlert('error', 'Start date should be before End date');
    }
  }

  // Non-Developed
  tableData = [
    {
      createdDate: '26-02-2024',
      category: 'Classic Chocolate Cake',
      productName: 'Belgian White Forest Cake',
      sku: 'CLC-NR9SYGU7A5-0000',
      orderType: 'PAN India',
      deliveryType: 'Door Delivery',
      deliveryStatus: 'Delivery Off',
      status: 'Active',
    },
    {
      createdDate: '16-02-2024',
      category: 'Themed Cake',
      productName: 'Chocolate Cake',
      sku: 'CLC-NR9SYGU7A5-0000',
      orderType: 'Local',
      deliveryType: 'Pickup From Store, Door Delivery',
      deliveryStatus: 'Delivery On',
      status: 'Inactive',
    },
    {
      createdDate: '22-04-2024',
      category: 'Classic Chocolate Cake',
      productName: 'Belgian White Forest Cake',
      sku: 'BWF-340095',
      orderType: 'PAN India',
      deliveryType: 'Door Delivery',
      deliveryStatus: 'Delivery Off',
      status: 'Active',
    },
    {
      createdDate: '13-01-2024',
      category: 'Themed Cake',
      productName: 'Belgian White Forest Cake',
      sku: 'ATC-CTBUE5H1SU-0000',
      orderType: 'Local',
      deliveryType: 'Door Delivery, Pickup From Store',
      deliveryStatus: 'Delivery On',
      status: 'Inactive',
    },
  ];
}
