<section class="offset-width well-white">
  <app-header [pageName]="'Testimonial'"></app-header>
  <div *ngIf="loader" class="loader-spin"> </div>

  <div class="container-fluid">
    <h5>Testimonial</h5>
    <button type="button" *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_create'])"
      (click)="onClickCreate()" class="button-1 btn-pink btn-margin"> <i class="fa fa-plus"></i>
      Create </button><br>
    <hr>
    <div class="row">
      <div class="card col-12 table-bg">
        <div class="card-body "> <br>
          <div class="table-responsive ">
            <table class="table">
              <thead>
                <tr>
                  <!-- *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_view'])" -->
                  <th scope="col"> # </th>
                  <th scope="col"> VIEW </th>
                  <th scope="col"> NAME </th>
                  <th scope="col">MESSAGE </th>
                  <th scope="col">PAGE TYPE </th>
                  <th scope="col">CREATED DATE AND TIME </th>
                  <th scope="col">SORT </th>
                  <th scope="col"
                    *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_update','testimonial_delete'])">
                    ACTION</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of getdata; let index = index">
                <tr>
                  <td> {{index + 1}}</td>
                  <!-- *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_view'])" -->
                  <td (click)="showSubItem(index, item.image)"> <i class="fa fa-expand pointer"
                      style="color:#FD7297"></i> </td>
                  <td> {{item.name}}</td>
                  <td style="width: 35%;"> {{item.message}} </td>
                  <td>
                    {{item?.pageType | titlecase}}
                  </td>
                  <td> {{ item.createdOn | date: 'dd-MM-yyyy, h:mm a' }} </td>

                  <td> {{item.sortOrder}} </td>
                  <td *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_update','testimonial_delete'])">
                    <span (click)="onClickEdit(item)"
                      *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_update'])"> <img class="pointer"
                        src="/assets/images/icon/pencil-2.svg"></span>
                    <i class="fa fa-trash del-table"
                      *ngIf="rolepermission.hasPermission(['testimonial'],['testimonial_delete'])"
                      (click)="deleteData(item.testimonialId)" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr *ngIf="visibleIndex === index && imgVisible">
                  <td colspan="1"></td>
                  <td colspan="2">
                    <img style="max-width: 250px;" src="{{imgPth+showImg}}">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>





    <!-- Modal Form-->
    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form [formGroup]="testmonialForm">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> <span>{{isEdited==true ? "Update" : "Create"}}</span>
                Testimonial</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <label>Page Type <span style="color: red;">*</span></label><br>
              <select class="form-control" formControlName="pageType" placeholder="Page Type">
                <option value="" [disabled]="true">Page Type</option>
                <option *ngFor="let type of pageTypes;" value="{{type.value}}">{{type.label}}</option>
              </select>
              <div *ngIf="submitted && f.pageType.errors" class="invalid-text">
                <div *ngIf="f.pageType.errors.required">
                  Page type is required
                </div>
              </div><br>

              <label>Name <span style="color: red;">*</span></label><br>
              <input type="text" formControlName="name" class="form-control">
              <div *ngIf="submitted && f.name.errors" class="invalid-text">
                <div *ngIf="f.name.errors.required">
                  Name is required
                </div>
              </div><br>

              <label>Message <span style="color: red;">*</span></label><br>
              <textarea class="form-control" formControlName="message" rows="4"></textarea>
              <div *ngIf="submitted && f.message.errors" class="invalid-text">
                <div *ngIf="f.message.errors.required">
                  Message is required
                </div>
              </div><br>

              <label>Sort <span style="color: red;">*</span></label><br>
              <input type="number" formControlName="sortOrder" class="form-control">

              <input id="upload-degn" type="file" #file (change)="uploadFile($event)" style="display:none"><br>
              <label for="upload-degn" *ngIf="!imgUrl">
                <span class="btn btn-file-upload">Choose Image</span>
              </label>
              <br>
              <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG , upto 50 MB,</span>
              <br>
              <span class="file-formate">Image Size Should not exceed ( width:100px X height:100px ) </span>
              <div *ngIf="imgUrl" class="mt-2">
                <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i>
                <img src="{{imgPth+imgUrl}}" class="img img-fluid">
              </div>

              <div *ngIf="submitted && f.image.errors" class="invalid-text">
                <div *ngIf="f.image.errors.required">
                  Image is required
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">Close</button>
              <button type="button" class="button-1 btn-pink" (click)="onSubmit()">Submit <i style="margin-left: 4px;"
                  class="fa fa-long-arrow-right"></i> </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--End Modal Form-->


  </div><br><br>
</section>