import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../constants/app.constants';
import { ToastrManager } from 'ng6-toastr-notifications';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: any;
    data2: any;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient,
        public toastr: ToastrManager
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }


    config = AppConstants.SERVER_CONFIG;
    baseurl = this.config[this.config.USE_URL];

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(userName: string, password: string) {
        return this.http.post<any>(this.baseurl + '/auth/login', { userName, password })
            .pipe(map(data => {
                this.user = data;
                if (this.user.role == 'user') {
                    this.toastr.errorToastr('You have only user access', '');
                    return;
                }
                // login successful if there's a jwt token in the response
                if (this.user && this.user.accessToken) {
                    console.log(this.user,'----------------> for the image URL');
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(this.user));
                    this.currentUserSubject.next(this.user);
                }
                return this.user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        location.reload();
    }

}



