<section class="offset-width well-white">
   <app-header [pageName]="'Product Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Product Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <label class="label_style">Category</label>

                  <ng-multiselect-dropdown [formControl]="filterForm?.get('categoryId')" class="multi_selectStyle"
                     [placeholder]="'Select Category'" [settings]="categorySettings" [data]="parentCategories">
                  </ng-multiselect-dropdown>
               </div>
               <!-- <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Category</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="categoryId " class="form-control input_style form-control-sm">
                        <option selected value="">Select Category</option>
                        <option *ngFor="let data of parentCategories;" [value]="data?.value">{{data?.view}}</option>
                     </select>
                  </div>
               </div> -->
               <!-- Order Type -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Order Type</label>

                     <ng-multiselect-dropdown [formControl]="filterForm?.get('orderTypeId')" class="multi_selectStyle"
                        [placeholder]="'Select Order Type'" [settings]="orderTypeSettings" [data]="orderTypeOptions">
                     </ng-multiselect-dropdown>
                     <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="orderTypeId" class="form-control input_style form-control-sm">
                        <option selected value="">Select Order Type</option>
                        <option *ngFor="let data of orderTypeOptions" [value]="data?.value">{{data?.view}}</option>
                     </select> -->
                  </div>
               </div>
            </div>

            <!-- Delivery Type -->
            <div class="row align_items_end mt-4">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Delivery Type</label>

                     <ng-multiselect-dropdown [formControl]="filterForm?.get('deliveryType')" class="multi_selectStyle"
                        [placeholder]="'Select Delivery Type'" [settings]="deliveryTypeSettings"
                        [data]="deliveryTypeOptions">
                     </ng-multiselect-dropdown>
                     <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="deliveryType" class="form-control input_style form-control-sm">
                        <option selected value="">Select Delivery Type</option>
                        <option *ngFor="let data of deliveryTypeOptions" [value]="data?.value">{{data?.view}}</option>
                     </select> -->
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Delivery Status</label>

                     <ng-multiselect-dropdown [formControl]="filterForm?.get('deliveryStatus')"
                        class="multi_selectStyle" [placeholder]="'Select Delivery Status'"
                        [settings]="deliveryStatusSettings" [data]="deliveryStatusOptions">
                     </ng-multiselect-dropdown>
                     <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="deliveryStatus" class="form-control input_style form-control-sm">
                        <option selected value="">Select Delivery Status</option>
                        <option *ngFor="let data of deliveryStatusOptions" [value]="data?.value">{{data?.view}}</option>
                     </select> -->
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Status</label>

                     <ng-multiselect-dropdown [formControl]="filterForm?.get('status')" class="multi_selectStyle"
                        [placeholder]="'Select Status'" [settings]="statusSettings" [data]="statusOptions">
                     </ng-multiselect-dropdown>
                     <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="status" class="form-control input_style form-control-sm">
                        <option selected value="">Select Status</option>
                        <option *ngFor="let data of statusOptions" [value]="data?.value">{{data?.view}}</option>

                     </select> -->
                  </div>
               </div>


               <div class="col-lg-2 col-xl-1 col-md-2 text-align-right">
                  <button (click)="getProductReport(true)"
                     class="btn btn-sm btn-reset btn_search product_btn">Search</button>
               </div>
               <div class="col-lg-3  col-xl-3  col-xxl-2 col-md-3 mt_0  mt-4">
                  <button *ngIf="hasOneValue||reports?.length>0" (click)="reset()" class="btn btn-sm reset_btn tooltip-container ml-5">
                     <img src="../../../assets/images/logo/reset.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Reset</h1>
                     </div>
                  </button>
                  <button *ngIf="reports?.length>0" (click)="export()"
                     class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4">
                     <img src="../../../assets/images/logo/order_last.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Export</h1>
                     </div>
                  </button>
               </div>
            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col" class="w_10">Created Date</th>
                        <th scope="col">Category
                        </th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Order Type</th>
                        <th scope="col">Delivery Type</th>
                        <!-- <th scope="col" class="text-right pr_5">Price(₹)</th> -->
                        <th scope="col">Status</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of reports">
                        <td class="pl-26">{{ item.createdOn | date : 'dd-MM-yyyy hh:mm a' }}</td>
                        <td>{{ item.categoryName|emptyText }}</td>
                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.productName }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.productName }}</h1>
                              </div>
                           </div>
                        </td>
                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ checkNull(item?.createdByName) ?(item.createdByName|emptyText)
                                 : (item.updatedByName|emptyText) }}</span>
                              <div *ngIf="checkNull(item?.updatedByName)||checkNull(item?.createdByName)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ checkNull(item?.createdByName) ?(item.createdByName|emptyText)
                                    : (item.updatedByName|emptyText) }}</h1>
                              </div>
                           </div>
                        </td>
                        <td>{{ item.sku }}</td>
                        <td>{{ item.isPanIndiaProduct ==true ? 'PAN India Delivery' :'Local Delivery' }}</td>
                        <td>
                           {{ item.deliveryType | titlecase }}
                           <div
                              [ngClass]="item?.deliveryStatus==true ? 'status_delivery':'status_delivery status_delivery_off'">
                              {{ item?.deliveryStatus==true ? 'Delivery On' :'Delivery Off'}}
                           </div>
                        </td>
                        <!-- <td class="text-right pr_4">₹ {{item?.price|digit:2}}</td> -->
                        <td><span [ngClass]=" item.active==true  ? 'active-status' :'inactive-status'">{{
                              item.active==true ? 'Active' : 'In-active'}}</span></td>
                     </tr>
                  </tbody>
               </table>

               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO PRODUCT RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>