<section class="offset-width well-white">
   <app-header [pageName]="'Selling Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Popularity Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row align_items_end">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-3 col-xl-2 col-md-3 text-align-right">
                  <button (click)="getSalesReport(true)"
                     class="btn btn-sm btn-reset btn_search product_btn">Search</button>
               </div>
               <div class="col-lg-3  col-xl-3 col-md-3">
                  <button (click)="reset()" *ngIf="hasOneValue||reports?.length>0"
                     class="btn btn-sm reset_btn tooltip-container ml_0 ml-4">
                     <img src="../../../assets/images/logo/reset.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Reset</h1>
                     </div>
                  </button>
                  <button *ngIf="reports?.length>0" (click)="export()"
                     class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4">
                     <img src="../../../assets/images/logo/order_last.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Export</h1>
                     </div>
                  </button>
               </div>
            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col" class="w_10">Product ID</th>
                        <th scope="col">Product Name </th>
                        <th scope="col">SKU Code</th>
                        <th scope="col">Category Name</th>
                        <th scope="col">KG/NOS</th>
                        <th scope="col">Flavour</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">
                           <div class="text-right pr_2">Sold Amount (₹) </div>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of reports">
                        <td class="pl-26">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.productId|trimtext:20:'.' }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.productId }}</h1>
                              </div>
                           </div>
                        </td>
                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.productName|trimtext:20:'.' }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.productName }}</h1>
                              </div>
                           </div>
                        </td>
                        <td class="text-left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.sku}}</span>
                           </div>
                        </td>

                        <td class="text-left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.categoryName |trimtext:20}}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.categoryName }}</h1>
                              </div>
                           </div>
                        </td>

                        <td class="text-left">
                           <div class="tooltip-container">
                              <span class="trim_text"> {{checkNull(item?.kg) ? item?.kg :'-'}} {{checkNull(item?.kg) ?(
                                 item?.isKg==true ? 'KG'
                                 : 'NOs'):''}}</span>
                           </div>
                        </td>

                        <td class="text-left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{checkNull(item?.flavour)? item?.flavour :'NA' }}</span>
                           </div>
                        </td>



                        <td>
                           {{ item.soldQuantity|emptyText:'0' }}
                        </td>
                        <td>
                           <div class="text-right pr_2">{{item?.soldAmount|emptyText:'-'}}</div>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO PRODUCT SALES RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>