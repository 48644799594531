<section class="offset-width well-white" *ngIf="permission.hasPermission(['dashboard'], ['dashboard_view'])">
  <div *ngIf="loader" class="loader-spin"></div>
  <app-header></app-header>
  <h5>Dashboard</h5>
  <div class="container-fluid">
    <div class="row" style="white-space: nowrap;">
      <div class="col-lg mrgn-btm" *ngIf="
        permission.hasPermission(['dashboard'], ['dashboard_totalOrder_view'])
      ">
        <div class="card card-top">
          <div class="card-body-1">
            <div class="card-content">
              <div class="card-details">
                <p class="box">Total Order</p>
                <h3 class="card-h3">{{ totalOrders > 0 ? totalOrders : 0 }}</h3>
              </div>
              <div class="box-icons"> <img class="icons" src="assets/images/icon/dash_cart.png" alt="" />
              </div>
            </div>
            <div class="card-f" *ngIf="ordersPercent>0"> <img class="arrow-g" src="assets/images/icon/arrow_g.png"
                alt="" style="padding-right: 2px;" /> <span class="arrow-details-g">{{ordersPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="ordersPercent<0"> <img class="arrow-g" src="assets/images/icon/arrow-r.png"
                alt="" style="padding-right: 2px;" /> <span class="arrow-details-r">{{ordersPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="ordersPercent==undefined || ordersPercent == 0"> <span
                class="arrow-details-g">0%</span>
              <p class="month-d">Current Month</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg mrgn-btm" *ngIf="
        permission.hasPermission(
          ['dashboard'],
          ['dashboard_uniqueCustomer_view']
        )
      ">
        <div class="card card-top">
          <div class="card-body-1">
            <div class="card-content">
              <div class="card-details">
                <p class="box">Unique Customer</p>
                <h3 class="card-h3"> {{ uniqueCustomers > 0 ? uniqueCustomers : 0 }} </h3>
              </div>
              <div class="box-icons"> <img class="icons" src="assets/images/icon/dash_cow.png" alt="" />
              </div>
            </div>
            <div class="card-f" *ngIf="UniquePercent>0"> <img class="arrow-g" src="assets/images/icon/arrow_g.png"
                alt="" style="padding-right: 2px;" /> <span class="arrow-details-g">{{UniquePercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="UniquePercent<0"> <img class="arrow-g" src="assets/images/icon/arrow-r.png"
                alt="" style="padding-right: 2px;" /> <span class="arrow-details-r">{{UniquePercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="UniquePercent==undefined || UniquePercent == 0"> <span
                class="arrow-details-g">0%</span>
              <p class="month-d">Current Month</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg mrgn-btm" *ngIf="
        permission.hasPermission(
          ['dashboard'],
          ['dashboard_repeatedUsers_view']
        )
      ">
        <div class="card card-top">
          <div class="card-body-1">
            <div class="card-content">
              <div class="card-details">
                <p class="box">Repeated User</p>
                <h3 class="card-h3"> {{ repeatedUsers > 0 ? repeatedUsers : 0 }} </h3>
              </div>
              <div class="box-icons"> <img class="icons" src="assets/images/icon/dash_repeat.png" alt="" />
              </div>
            </div>
            <div class="card-f" *ngIf="usersPercent>0"> <img class="arrow-g" src="assets/images/icon/arrow_g.png" alt=""
                style="padding-right: 2px;" /> <span class="arrow-details-g">{{usersPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="usersPercent<0"> <img class="arrow-g" src="assets/images/icon/arrow-r.png" alt=""
                style="padding-right: 2px;" /> <span class="arrow-details-r">{{usersPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="usersPercent==undefined || usersPercent == 0"> <span
                class="arrow-details-g">0%</span>
              <p class="month-d">Current Month</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg mrgn-btm" *ngIf="
        permission.hasPermission(
          ['dashboard'],
          ['dashboard_taotalProducts_view']
        )
      ">
        <div class="card card-top">
          <div class="card-body-1">
            <div class="card-content">
              <div class="card-details">
                <p class="box">Total Products</p>
                <h3 class="card-h3"> {{ totalProducts > 0 ? totalProducts : 0 }} </h3>
              </div>
              <div class="box-icons"> <img class="icons" src="assets/images/icon/dash_cake.png" alt="" />
              </div>
            </div>
            <div class="card-f" *ngIf="productPercent>0"> <img class="arrow-g" src="assets/images/icon/arrow_g.png"
                alt="" style="padding-right: 2px;" /> <span
                class="arrow-details-g">{{productPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="productPercent<0"> <img class="arrow-g" src="assets/images/icon/arrow-r.png"
                alt="" style="padding-right: 2px;" /> <span
                class="arrow-details-r">{{(productPercent).toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="productPercent==undefined || productPercent == 0"> <span
                class="arrow-details-g">0%</span>
              <p class="month-d">Current Month</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg mrgn-btm" *ngIf="
        permission.hasPermission(['dashboard'], ['dashboard_totalSales_view'])
      ">
        <div class="card card-top">
          <div class="card-body-1">
            <div class="card-content">
              <div class="card-details">
                <p class="box">Total Sales (₹)</p>
                <h3 class="card-h3"> {{ totalSales>0 ?totalSales :0 }}</h3>
              </div>
              <div class="box-icons"> <img class="icons" src="assets/images/icon/dash_card.png" alt="" />
              </div>
            </div>
            <div class="card-f" *ngIf="salesPercent>0"> <img class="arrow-g" src="assets/images/icon/arrow_g.png" alt=""
                style="padding-right: 2px;" /> <span class="arrow-details-g">{{salesPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="salesPercent<0"> <img class="arrow-g" src="assets/images/icon/arrow-r.png" alt=""
                style="padding-right: 2px;" /> <span class="arrow-details-r">{{salesPercent.toFixed(0)}}%</span>
              <p class="month-d">Current Month</p>
            </div>
            <div class="card-f" *ngIf="salesPercent==undefined || salesPercent == 0  "> <span
                class="arrow-details-g">0%</span>
              <p class="month-d">Current Month</p>
            </div>
          </div>
        </div>
      </div>
    </div> <br /> <br />
    <!--Order and customer analysis start-->
    <div class="row">
      <div class="col-lg-12 col-xl-6 mrgn-btm"
        *ngIf="permission.hasPermission(['dashboard'], ['dashboard_orderAnalysis_view'])">
        <div class="card order-analysis">
          <div class="card-body second-card">
            <div class="order-header row">
              <div class="col-lg-6">
                <h5 class="order-header" (click)="refreshDate()" style="cursor: pointer;"> Order Analysis
                </h5>
              </div>
              <div class="col-lg-6" *ngIf="permission.hasPermission(['dashboard'], ['dashboard_orderAnalysis_filter'])">
                <input type="date" #customerTo (change)="setDate(customerTo.value)"
                  class="form-control order-from pull-right" />
              </div>
            </div>
            <div class="row order-tabs">
              <div class="col-lg-12">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation"> <button class="nav-link active nav-btn" id="pills-home-tab"
                      data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"
                      aria-controls="pills-home" aria-selected="true" (click)="productSummary('Accepted')"> New <span
                        class="badge active badge-secondary">{{ acceptedNot != null && acceptedNot != "" ? acceptedNot :
                        "0" }}</span>
                    </button> </li>
                  <li class="nav-item" role="presentation"> <button class="nav-link nav-btn" id="pills-contact-tab"
                      data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab"
                      aria-controls="pills-contact" aria-selected="false" (click)="productSummary('Baking')"> Baking
                      <span class="badge badge-secondary active">{{ bakingNot != null && bakingNot != "" ? bakingNot :
                        "0" }}</span>
                    </button> </li>
                  <li class="nav-item" role="presentation"> <button class="nav-link nav-btn" id="pills-profile-tab"
                      data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab"
                      aria-controls="pills-profile" aria-selected="false" (click)="productSummary('Delivered')">
                      Delivered <span class="badge badge-secondary active">{{ deliveredNot != null && deliveredNot != ""
                        ? deliveredNot : "0" }}</span>
                    </button> </li>
                  <li class="nav-item" role="presentation"> <button class="nav-link nav-btn" id="pills-contact-tab"
                      data-bs-toggle="pill" data-bs-target="#pills-c" type="button" role="tab" aria-controls="pills-c"
                      aria-selected="false" (click)="productSummary('Cancelled')"> Cancelled <span
                        class="badge badge-secondary active">{{ cancelledNot != null && cancelledNot != "" ?
                        cancelledNot : "0" }}</span>
                    </button> </li>
                </ul>
              </div>
              <div class="col-lg-12">
                <div class="tab-content" id="pills-tabContent">
                  <!-- tab view for the  Accepted newly -->
                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <div class="table-responsive">
                      <table class="table table-striped dash-table">
                        <tbody *ngFor="
                          let data of productList | slice: 0:5;
                          let i = index
                        ">
                          <tr>
                            <td>
                              <h5 (click)="navigateToOrder( data?.orderId)" class="orderid">{{ data?.orderId }}</h5>
                              <p class="table-date">{{ data?.createdOn }}</p>
                            </td>
                            <td>
                              <p class="customer-name">
                                {{ getFromData(data).hasOwnProperty( "cartAddress" ) ?
                                getFromData(data)?.cartAddress?.name : "Store Pickup" }}
                              </p>
                              <p class="delv-date"> <span class="pink me-1">Delivery <i
                                    class="fa-solid fa-calendar-days"></i>:</span>{{ getFromData(data)?.deliveryIn |
                                date: "longDate" }}
                              </p>
                            </td>
                            <td>
                              <p class="no-text" style="visibility: hidden;"> ssss </p>
                              <p class="time"><i class="fa-solid fa-clock"></i>{{ getFromData(data)?.deliveryIn | date:
                                "shortTime" }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 style="text-align: center;" *ngIf="productList?.length <= 0"> No orders found </h5>
                    </div>
                    <div class="view-more_table text-center" *ngIf="productList?.length>=1"> <button type="button"
                        class="btn view-btn" routerLink="/order" *ngIf="
                        permission.hasPermission(
                          ['order_history'],
                          ['order_history_view']
                        )
                      "> View More <i class="fa-solid fa-arrow-right"></i> </button> </div>
                  </div> <!-- tab view for the  Delivered -->
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <tbody *ngFor="
                          let data of productList | slice: 0:5;
                          let i = index
                        ">
                          <tr>
                            <td>
                              <h5 (click)="navigateToOrder( data?.orderId)" class="orderid">{{ data?.orderId }}</h5>
                              <p class="table-date">{{ data?.createdOn }}</p>
                            </td>
                            <td>
                              <p class="customer-name">
                                {{ getFromData(data).hasOwnProperty( "cartAddress" ) ?
                                getFromData(data)?.cartAddress?.name : "Store Pickup" }}
                              </p>
                              <p class="delv-date"> <span class="pink me-1">Delivery <i
                                    class="fa-solid fa-calendar-days"></i>:</span>{{ getFromData(data)?.deliveryIn |
                                date: "longDate" }}
                              </p>
                            </td>
                            <td>
                              <p class="no-text" style="visibility: hidden;"> ssss </p>
                              <p class="time"><i class="fa-solid fa-clock"></i>{{ getFromData(data)?.deliveryIn | date:
                                "shortTime" }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 style="text-align: center;" *ngIf="productList?.length <= 0"> No orders found </h5>
                    </div>
                    <div class="view-more_table text-center" *ngIf="productList?.length>=1"> <button type="button"
                        class="btn view-btn" routerLink="/order" *ngIf="
                        permission.hasPermission(
                          ['order_history'],
                          ['order_history_view']
                        )
                      "> View More <i class="fa-solid fa-arrow-right"></i> </button> </div>
                  </div> <!-- tab view for the  Baking -->
                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <tbody *ngFor="
                          let data of productList | slice: 0:5;
                          let i = index
                        ">
                          <tr>
                            <td>
                              <h5 (click)="navigateToOrder( data?.orderId)" class="orderid">{{ data?.orderId }}</h5>
                              <p class="table-date">{{ data?.createdOn }}</p>
                            </td>
                            <td>
                              <p class="customer-name">
                                {{ getFromData(data).hasOwnProperty( "cartAddress" ) ?
                                getFromData(data)?.cartAddress?.name : "Store Pickup" }}
                              </p>
                              <p class="delv-date"> <span class="pink me-1">Delivery <i
                                    class="fa-solid fa-calendar-days"></i>:</span>{{ getFromData(data)?.deliveryIn |
                                date: "longDate" }}
                              </p>
                            </td>
                            <td>
                              <p class="no-text" style="visibility: hidden;"> ssss </p>
                              <p class="time"><i class="fa-solid fa-clock"></i>{{ getFromData(data)?.deliveryIn | date:
                                "shortTime" }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 style="text-align: center;" *ngIf="productList?.length <= 0"> No orders found </h5>
                    </div>
                    <div class="view-more_table text-center" *ngIf="productList?.length>=1"> <button type="button"
                        class="btn view-btn" routerLink="/order" *ngIf="
                        permission.hasPermission(
                          ['order_history'],
                          ['order_history_view']
                        )
                      "> View More <i class="fa-solid fa-arrow-right"></i> </button> </div>
                  </div> <!-- tab view for the  Canceleld -->
                  <div class="tab-pane fade" id="pills-c" role="tabpanel" aria-labelledby="pills-c">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <tbody *ngFor="
                          let data of productList | slice: 0:5;
                          let i = index
                        ">
                          <tr>
                            <td>
                              <h5 (click)="navigateToOrder( data?.orderId)" class="orderid">{{ data?.orderId }}</h5>
                              <p class="table-date">{{ data?.createdOn }}</p>
                            </td>
                            <td>
                              <p class="customer-name">
                                {{ getFromData(data).hasOwnProperty( "cartAddress" ) ?
                                getFromData(data)?.cartAddress?.name : "Store Pickup" }}
                              </p>
                              <p class="delv-date"> <span class="pink me-1">Delivery <i
                                    class="fa-solid fa-calendar-days"></i>:</span>{{ getFromData(data)?.deliveryIn |
                                date: "longDate" }}
                              </p>
                            </td>
                            <td>
                              <p class="no-text" style="visibility: hidden;"> ssss </p>
                              <p class="time"><i class="fa-solid fa-clock"></i>{{ getFromData(data)?.deliveryIn | date:
                                "shortTime" }}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h5 style="text-align: center;" *ngIf="productList?.length <= 0"> No orders found </h5>
                    </div>
                    <div class="view-more_table text-center" *ngIf="productList?.length>=1"> <button type="button"
                        class="btn view-btn" routerLink="/order" *ngIf="
                        permission.hasPermission(
                          ['order_history'],
                          ['order_history_view']
                        )
                      "> View More <i class="fa-solid fa-arrow-right"></i> </button> </div>
                  </div>
                </div> <!--  -->
              </div> <!-- summary ends -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-6 mrgn-btm"
        *ngIf="permission.hasPermission(['dashboard'], ['dashboard_mostPeople_view'])">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-xl-6">
                <h5>Most People Visited</h5>
              </div>
              <div class="col-lg-6" *ngIf="permission.hasPermission(['dashboard'], ['dashboard_mostPeople_filter'])">
                <div class="dropdown text-end"> <button class="btn btn12 btn-pink dropdown-toggle" type="button"
                    id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {{ visitorBtn
                    | titlecase }} </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenu2"> <button class="dropdown-item"
                      type="button" (click)="visitors('week')"> This Week </button> <button class="dropdown-item"
                      type="button" (click)="visitors('month')"> This Month </button>
                    <button class="dropdown-item" type="button" (click)="visitors('year')"> This Year
                    </button>
                  </div>
                </div>
              </div>
            </div> <br />
            <div class="row">
              <div class="col-lg-12 col-xl-6">
                <div id="chart" #chart>
                  <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                    [stroke]="chartOptions.stroke" [dataLabels]="chartOptions.dataLabels" [yaxis]="chartOptions.yaxis"
                    [labels]="chartOptions.labels" [legend]="chartOptions.legend" [title]="chartOptions.title"
                    [subtitle]="chartOptions.subtitle" [responsive]="chartOptions.responsive"></apx-chart>
                </div>
              </div>
            </div> <br />
          </div>
        </div>
      </div>
    </div>
    <!--Order and customer analysis end--> <br /> <br />
    <!--product and sales analysis start-->
    <div class="row">
      <div class="col-lg-12 col-xl-6 mrgn-btm"
        *ngIf="permission.hasPermission(['dashboard'], ['dashboard_topSeen_view'])">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <h5>Top Products Seen</h5>
              </div>
              <div class="col-lg-6" *ngIf="permission.hasPermission(['dashboard'], ['dashboard_topSeen_filter'])">
                <div class="text-end d-flex justify-content-end"> <button type="button"
                    class="btn btn11 btn-pink dropdown-toggle me-1" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" name="top"> {{ topBtn | titlecase }} </button>
                  <div class="dropdown-menu"> <a class="dropdown-item" (click)="topProductsCount(3)">Top 3</a>
                    <a class="dropdown-item" (click)="topProductsCount(5)">Top 5</a> <a class="dropdown-item"
                      (click)="topProductsCount(10)">Top 10</a>
                  </div>
                  <div class="ss"> <button type="button" class="btn btn12 btn-pink text-end dropdown-toggle ms-1"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" name="dates"> {{ topSeenBtn |
                      titlecase }}
                    </button>
                    <div class="dropdown-menu"> <a class="dropdown-item" (click)="topProductsPeriod('week')">This
                        Week</a> <a class="dropdown-item" (click)="topProductsPeriod('month')">This Month</a> <a
                        class="dropdown-item" (click)="topProductsPeriod('year')">This Year</a> </div>
                  </div>
                </div>
              </div>
            </div> <br />
            <div class="bar-chart">
              <div class="row">
                <!-- <div class="col-lg-5">
                <div class="d-flex align-items-baseline">
                  <label class="box me-1" ></label><p class="pro-detail me-1">product 1</p><p class="pro-name me-1">A</p>

                </div>
                <div class="d-flex align-items-baseline" >
                  <label class="box me-1" style="background-color: #ffc107 ;" ></label><p class="pro-detail me-1">product 2</p><p class="pro-name me-1">B</p>

                </div>
                <div class="d-flex align-items-baseline" >
                  <label class="box me-1" style="background-color: #0d6efd;" ></label><p class="pro-detail me-1">product 3</p><p class="pro-name me-1">C</p>

                </div>
            


              </div> -->
                <div class="">
                  <div id="barchart" #barchart>
                    <apx-chart [series]="chartOptions2.series" [chart]="chartOptions2.chart"
                      [xaxis]="chartOptions2.xaxis" [stroke]="chartOptions2.stroke"
                      [dataLabels]="chartOptions2.dataLabels" [yaxis]="chartOptions2.yaxis"
                      [labels]="chartOptions2.labels" [legend]="chartOptions2.legend" [title]="chartOptions2.title"
                      [colors]="chartOptions2.colors" [subtitle]="chartOptions2.subtitle"
                      [plotOptions]="chartOptions2.plotOptions" [responsive]="chartOptions2.responsive"></apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-6 mrgn-btm"
        *ngIf="permission.hasPermission(['dashboard'], ['dashboard_mostTime_view'])">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <h5>Most Visted Time</h5>
              </div>
              <div class="col-lg-6" > <input
                  type="date" #visitedTo [max]="formatteddate" (change)="getTimelyVisitors(visitedTo.value)" class="form-control order-from pull-right" /> </div>
              <!-- <div class="col-lg-6">
              <div class="dropdown text-end">
                <button class="btn btn12 btn-pink  dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Toady
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button class="dropdown-item" type="button" >This Week </button>
                  <button class="dropdown-item" type="button">This Month</button>
               
                </div>
              </div>
              


            </div> -->
            </div> <br />
            <div class="bar-chart">
              <div class="row">
                <div id="line" #line>
                  <apx-chart [series]="chartOptions3.series" [chart]="chartOptions3.chart" [xaxis]="chartOptions3.xaxis"
                    [stroke]="chartOptions3.stroke" [dataLabels]="chartOptions3.dataLabels"
                    [yaxis]="chartOptions3.yaxis" [labels]="chartOptions3.labels" [legend]="chartOptions3.legend"
                    [title]="chartOptions3.title" [subtitle]="chartOptions3.subtitle"
                    [responsive]="chartOptions3.responsive"></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- Product and sales analysis end--> <br /> <br />
  </div>
  <!--End Container-->
</section>
<!-- 
<section class="offset-width well-white">
<div *ngIf="loader" class="loader-spin"> </div>
<app-header></app-header>

<h5>Dashboard</h5>

<div class="container-fluid"> -->
<!-- <div class="row " *ngIf="getdata" style="white-space: nowrap;">

    <div class="col-lg mrgn-btm">
      <div class="card">
        <div class="card-body">
          <span> <i class="	fa fa-bar-chart money-bag"></i></span><br>
          <b class="box-number">{{getdata.totalOrder}}</b> <i class="fa fa-long-arrow-up up-arrow"></i> <br>
          <span class="box-span">Total Order</span>
        </div>
      </div>
    </div>

    <div class="col-lg mrgn-btm">
      <div class="card">
        <div class="card-body">
          <span> <i class="	fa fa-bar-chart money-bag"></i></span><br>
          <b class="box-number"> {{getdata.totalDeliveredOrder}} </b> <i class="fa fa-long-arrow-up up-arrow"></i>
          <br>
          <span class="box-span">Total Order Success</span>
        </div>
      </div>
    </div>

    <div class="col-lg mrgn-btm">
      <div class="card">
        <div class="card-body">
          <span> <i class="	fa fa-bar-chart money-bag"></i></span><br>
          <b class="box-number">{{getdata.uniqueCustomerOrder}}</b> <i class="fa fa-long-arrow-up up-arrow"></i> <br>
          <span class="box-span">Unique Customer</span>
        </div>
      </div>
    </div>

    <div class="col-lg mrgn-btm">
      <div class="card">
        <div class="card-body">
          <span> <i class="	fa fa-bar-chart money-bag"></i></span><br>
          <b class="box-number"> {{getdata.repeatOrderCustomer}} </b> <i class="fa fa-long-arrow-up up-arrow"></i>
          <br>
          <span class="box-span">Repeated Users</span>
        </div>
      </div>
    </div>

    <div class="col-lg mrgn-btm">
      <div class="card">
        <div class="card-body">
          <span> <i class="	fa fa-bar-chart money-bag"></i></span><br>
          <b class="box-number"> {{getdata.totalProduct}} </b> <i class="fa fa-long-arrow-up up-arrow"></i> <br>
          <span class="box-span">Total products</span>
        </div>
      </div>
    </div>

    <div class="col-lg mrgn-btm">
      <div class="card">
        <div class="card-body">
          <span> <i class="	fa fa-bar-chart money-bag"></i></span><br>
          <b class="box-number"><i class="fa fa-inr"> </i> {{getdata.totalOrderCost | number : '1.2-2'}} </b> <i
            class="fa fa-long-arrow-up up-arrow"></i>
          <br>
          <span class="box-span">Total Sales</span>
        </div>
      </div>
    </div>
  </div><br><br> -->
<!--Order and customer analysis start-->
<!-- <div class="row">
    <div class="col-lg-6 mrgn-btm">
      <div class="card">
        <div class="card-body">
          <h5>Order Analysis</h5><br>
          <div class="row">
            <div class="col-lg-6">
              <label>From Date</label>
              <input type="date" #orderFrom value="{{globalFromDate}}" class="form-control"><br>
            </div>
            <div class="col-lg-6">
              <label>To Date</label>
              <input type="date" #orderTo value="{{globalToDate}}" class="form-control"><br>
            </div>
          </div>
          <button class="button-1 btn-pink float-right" (click)="getOrderAnalysis(orderFrom.value,orderTo.value)"
            type="submit">Filter</button><br>
          <br>
          <div class="table-responsive">
            <table class="table" *ngIf="getOrder">
              <tbody>
                <tr>
                  <td>Number Of Orders</td>
                  <td>{{getOrder?.totalOrder}}</td>
                </tr>
                <tr>
                  <td>Number Of Orders Delivered</td>
                  <td>{{getOrder?.totalDeliveredOrder}}</td>
                </tr>
                <tr>
                  <td>Number Of Orders Cancelled</td>
                  <td>{{getOrder?.totalCancelledOrder}}</td>
                </tr>
                <tr>
                  <td>Average Order Price</td>
                  <td> <i class="fa fa-inr"></i> {{getOrder?.averageOrderCost | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <td>Total Sales</td>
                  <td> <i class="fa fa-inr"></i> {{getOrder?.totalOrderCost | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>

              </tbody>
            </table><br>
          </div>
        </div>
      </div>
    </div>



    <div class="col-lg-6 mrgn-btm">
      <div class="card">
        <div class="card-body">
          <h5>Customers Analysis</h5><br>
          <div class="row">
            <div class="col-lg-6">
              <label>From Date</label>
              <input type="date" #customerFrom value="{{globalFromDate}}" class="form-control"><br>
            </div>
            <div class="col-lg-6">
              <label>To Date</label>
              <input type="date" #customerTo value="{{globalToDate}}" class="form-control"><br>
            </div>
          </div>
          <button class="button-1 btn-pink float-right"
            (click)="getCustomerAnalysis(customerFrom.value,customerTo.value)" type="submit">Filter</button><br>
          <br>
          <div class="table-responsive">
            <table class="table" *ngIf="getCustomer">
              <tbody>
                <tr>
                  <td>Total Customer</td>
                  <td>{{getCustomer.totalCustomer}}</td>
                </tr>
                <tr>
                  <td>Number Of New Customer</td>
                  <td>{{getCustomer.newCustomer}}</td>
                </tr>
                <tr>
                  <td>Average Order Per Customer</td>
                  <td>{{getCustomer.averageCustomerOrder}} orders</td>
                </tr>
                <tr>
                  <td>Average Price Spend Per Customer</td>
                  <td><i class="fa fa-inr"></i> {{getCustomer.averageCustomerCost}}</td>
                </tr>
                <tr>
                  <td> Repeated Users</td>
                  <td> {{getCustomer.repeatOrderCustomer}} </td>
                </tr>
                <tr>
                  <td>User With No Order Placed </td>
                  <td> {{getCustomer.noOrderCustomer}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div> -->
<!--Order and customer analysis end-->
<!-- <br><br> -->
<!--product and sales analysis start-->
<!-- <div class="row ">
    <div class="col-lg-6 mrgn-btm">
      <div class="card">
        <div class="card-body">
          <h5>Product Analysis</h5>
          <br>
          <div class="table-responsive" *ngIf="getProduct">
            <table class="table">
              <tbody>
                <tr>
                  <td>Number Of Product</td>
                  <td>{{getProduct.totalProduct}}</td>
                </tr>
                <tr>
                  <td>Number Of Active Product</td>
                  <td>{{getProduct.activeProduct}}</td>
                </tr>
                <tr>
                  <td>Number Of Inactive Product</td>
                  <td>{{getProduct.inActiveProduct}}</td>
                </tr>
                <tr>
                  <td>Average Product Price</td>
                  <td> <i class="fa fa-inr"></i> {{getProduct.averageProductPrice | number : '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>



    <div class="col-lg-6 mrgn-btm" style="display: none;">
      <div class="card">
        <div class="card-body">

          <h5>Sales Analysis</h5><br>
          <div class="row">
            <div class="col-lg-6">
              <label>From Date</label>
              <input type="date" class="form-control"><br>
            </div>
            <div class="col-lg-6">
              <label>To Date</label>
              <input type="date" class="form-control"><br>
            </div>
          </div>
          <button class="button-1 btn-pink float-right" type="submit">Filter</button><br>
          <br>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td>Total Sale Amount</td>
                  <td><i class="fa fa-inr"></i> 30,500</td>
                </tr>
                <tr>
                  <td>Total Number Of Order</td>
                  <td>300 orders</td>
                </tr>
                <tr>
                  <td>Total Tax</td>
                  <td><i class="fa fa-inr"></i> 1,500</td>
                </tr>
                <tr>
                  <td> Total Delivery Charge</td>
                  <td> <i class="fa fa-inr"></i> 2,000 </td>
                </tr>
                <tr>
                  <td> Total Sale Without Tax & Delivery Charge</td>
                  <td> <i class="fa fa-inr"></i> 27,000 </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- Product and sales analysis end-->
<!-- <br><br>
</div> -->
<!--End Container-->
<!-- </section> -->
<!-- <div class="row">


<div class="col-lg-6 mrgn-btm">
  <div class="card">
    <div class="card-body ">
      <h5>Order Analysis</h5>
      <br>
      <div class="row">
        <div class="col-lg-6">
          <label>From Date</label>
          <input type="date" #orderFrom value="{{globalFromDate}}" class="form-control"><br>
        </div>
        <div class="col-lg-6">
          <label>To Date</label>
          <input type="date" #orderTo value="{{globalToDate}}" class="form-control"><br>
        </div>
      </div>
      <button class="button-1 btn-pink float-right" (click)="getOrderAnalysis(orderFrom.value,orderTo.value)"
        type="submit">Filter</button><br>
      <br>
      <div class="table-responsive">
        <table class="table" *ngIf="getOrder">
          <tbody>
            <tr>
              <td>Number Of Orders</td>
              <td>{{getOrder?.totalOrder}}</td>
            </tr>
            <tr>
              <td>Number Of Orders Delivered</td>
              <td>{{getOrder?.totalDeliveredOrder}}</td>
            </tr>
            <tr>
              <td>Number Of Orders Cancelled</td>
              <td>{{getOrder?.totalCancelledOrder}}</td>
            </tr>
            <tr>
              <td>Average Order Price</td>
              <td> <i class="fa fa-inr"></i> {{getOrder?.averageOrderCost | number : '1.2-2'}}</td>
            </tr>
            <tr>
              <td>Total Sales</td>
              <td> <i class="fa fa-inr"></i> {{getOrder?.totalOrderCost | number : '1.2-2'}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-6 mrgn-btm">
  <div class="card">
    <div class="card-body">
      <h5>Customers Analysis</h5>
      <br>
      <div class="row">
        <div class="col-lg-6">
          <label>From Date</label>
          <input type="date" #customerFrom value="{{globalFromDate}}" class="form-control"><br>
        </div>
        <div class="col-lg-6">
          <label>To Date</label>
          <input type="date" #customerTo value="{{globalToDate}}" class="form-control"><br>
        </div>
      </div>
      <button class="button-1 btn-pink float-right"
        (click)="getCustomerAnalysis(customerFrom.value,customerTo.value)" type="submit">Filter</button><br>
      <br>
      <div class="table-responsive">
        <table class="table" *ngIf="getCustomer">
          <tbody>
            <tr>
              <td>Total Customer</td>
              <td>{{getCustomer.totalCustomer}}</td>
            </tr>
            <tr>
              <td>Number Of New Customer</td>
              <td>{{getCustomer.newCustomer}}</td>
            </tr>
            <tr>
              <td>Average Order Per Customer</td>
              <td>{{getCustomer.averageCustomerOrder}} orders</td>
            </tr>
            <tr>
              <td>Average Price Spend Per Customer</td>
              <td><i class="fa fa-inr"></i> {{getCustomer.averageCustomerCost}}</td>
            </tr>
            <tr>
              <td> Repeated Users</td>
              <td> {{getCustomer.repeatOrderCustomer}} </td>
            </tr>
            <tr>
              <td>User With No Order Placed </td>
              <td> {{getCustomer.noOrderCustomer}} </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div> -->
<!--Order and customer analysis start-->
<!-- <div class="row">
    <div class="col-lg-6 mrgn-btm">
      <div class="card">
        <div class="card-body ">
          <h5>Order Analysis</h5>
          <br>
          <div class="row">
            <div class="col-lg-6">
              <label>From Date</label>
              <input type="date" #orderFrom value="{{globalFromDate}}" class="form-control"><br>
            </div>
            <div class="col-lg-6">
              <label>To Date</label>
              <input type="date" #orderTo value="{{globalToDate}}" class="form-control"><br>
            </div>
          </div>
          <button class="button-1 btn-pink float-right" (click)="getOrderAnalysis(orderFrom.value,orderTo.value)"
            type="submit">Filter</button><br>
          <br>
          <div class="table-responsive">
            <table class="table" *ngIf="getOrder">
              <tbody>
                <tr>
                  <td>Number Of Orders</td>
                  <td>{{getOrder?.totalOrder}}</td>
                </tr>
                <tr>
                  <td>Number Of Orders Delivered</td>
                  <td>{{getOrder?.totalDeliveredOrder}}</td>
                </tr>
                <tr>
                  <td>Number Of Orders Cancelled</td>
                  <td>{{getOrder?.totalCancelledOrder}}</td>
                </tr>
                <tr>
                  <td>Average Order Price</td>
                  <td> <i class="fa fa-inr"></i> {{getOrder?.averageOrderCost | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <td>Total Sales</td>
                  <td> <i class="fa fa-inr"></i> {{getOrder?.totalOrderCost | number : '1.2-2'}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <br>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 mrgn-btm">
      <div class="card">
        <div class="card-body">
          <h5>Customers Analysis</h5>
          <br>
          <div class="row">
            <div class="col-lg-6">
              <label>From Date</label>
              <input type="date" #customerFrom value="{{globalFromDate}}" class="form-control"><br>
            </div>
            <div class="col-lg-6">
              <label>To Date</label>
              <input type="date" #customerTo value="{{globalToDate}}" class="form-control"><br>
            </div>
          </div>
          <button class="button-1 btn-pink float-right"
            (click)="getCustomerAnalysis(customerFrom.value,customerTo.value)" type="submit">Filter</button><br>
          <br>
          <div class="table-responsive">
            <table class="table" *ngIf="getCustomer">
              <tbody>
                <tr>
                  <td>Total Customer</td>
                  <td>{{getCustomer.totalCustomer}}</td>
                </tr>
                <tr>
                  <td>Number Of New Customer</td>
                  <td>{{getCustomer.newCustomer}}</td>
                </tr>
                <tr>
                  <td>Average Order Per Customer</td>
                  <td>{{getCustomer.averageCustomerOrder}} orders</td>
                </tr>
                <tr>
                  <td>Average Price Spend Per Customer</td>
                  <td><i class="fa fa-inr"></i> {{getCustomer.averageCustomerCost}}</td>
                </tr>
                <tr>
                  <td> Repeated Users</td>
                  <td> {{getCustomer.repeatOrderCustomer}} </td>
                </tr>
                <tr>
                  <td>User With No Order Placed </td>
                  <td> {{getCustomer.noOrderCustomer}} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!--Order and customer analysis end-->