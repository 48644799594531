import { Injectable } from '@angular/core';
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";
import { constant } from '../helpers/global.helper';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  appSetting = {
    portalType: '',
    branchId: '',
    userType: '',
    userId: '',
    roleId: '',
    permission: '',
    domain: '',
  };

  constructor(
  ) {


  }

  init() {
    const token = decodedToken();
    this.appSetting.portalType = get(token, 'type') ? get(token, 'type') : '';
    this.appSetting.userType = get(token, 'user_type') ?? '';
    this.appSetting.userId = get(token, 'acl_user_id') ?? '';
    this.appSetting.branchId = get(token, 'branch_id') ?? '';
    this.appSetting.domain = get(token, 'domain_name') ?? '';
    this.appSetting.roleId = get(token, 'acl_role_id') ?? '';
    this.appSetting.permission = get(token, 'permission') ?? '';
   
  }


  get portalType() {
    return this.appSetting.portalType;
  }

  get branchId() {
    return this.appSetting.branchId;
  }

  get domain() {
    return this.appSetting.domain;
  }

  get userType() {
    return this.appSetting.userType;
  }

  get userId() {
    return this.appSetting.userId;
  }

  get roleId() {
    return this.appSetting.roleId;
  }

  get permission() {
    return this.appSetting.permission;
  }


}
