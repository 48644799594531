import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})

export class PricingComponent implements OnInit {

  constructor(
    public http: HttpService,
    private route: ActivatedRoute,
    public toastr: ToastrManager,
    private router: Router,
    public rolepermission: PermissionService,
  ) { }

  flavourList = ["chocolate", "strawberry", "vanilla", "velvet"];
  config
  ngOnInit(): void {

    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: ''
    };

    this.route.queryParams.subscribe(params => {
      this.pageValue(params['page']);
    });

  }

  //pagination
  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get();
      window.scrollTo(0, 0)
    } else {
      this.config.currentPage = 1;
      this.get();
    }
  }

  pageChange(newPage: number) {
    this.router.navigate(['/pricing'], { queryParams: { page: newPage } });
  }

  //////Search Product//////
  svalue: any;
  search(searchvalue) {
    this.svalue = searchvalue
    setTimeout(() => {
      if (this.svalue == searchvalue) {
        this.config.currentPage = 1;
        this.get(searchvalue);
      }
    }, 1000);
  }



  loader
  getdata2
  getdata
  noResult = false;
  searchTxt = '';
  incrementNumber = 0
  get(search?) {

    if (search) {
      this.searchTxt = search
    }

    this.noResult = false
    this.loader = 'show';
    this.http.get('/admin/product?page=' + this.config.currentPage + '&search=' + this.searchTxt).subscribe(
      data => {
        this.loader = '';
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        if (this.getdata2.content.length == 0) {
          this.noResult = true
        }
        this.config = {
          currentPage: this.getdata2.currentPage,
          itemsPerPage: this.getdata2.currentPageItems,
          totalItems: this.getdata2.totalItems
        };
        this.incrementNumber = (this.config.currentPage - 1) * this.config.itemsPerPage;
        this.getdata.map(x => {
          x.productDetails.pricing.map(y => {
            if (!y.sku) {
              y.sku = ''
            }
          })
        });

      },
      error => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
        this.noResult = true
      });
  }


  //delete pricing
  deleteIndex(index, i) {
    this.getdata[index].productDetails.pricing.splice(i, 1);
    console.log(this.getdata[index].productDetails.pricing)
  }


  ////add pricing ngmodal
  addPricing(index, productId) {
    if (!this.addItem.sku || !this.addItem.kg || !this.addItem.discount || !this.addItem.price) {
      this.priceError = true;
      return;
    }
    this.getdata[index].productDetails.pricing.push(this.addItem);
    console.log(this.getdata[index].productDetails.pricing)
    this.emptyAddItem();
  }


  //Update Items
  updateItem(type, value, index, i) {
    this.getdata[index].productDetails.pricing[i][type] = value
  }


  //submit all pricing
  onSubmit() {
    let emptyPriceError
    emptyPriceError = false

    //check if product contain empty pricing
    this.getdata.map(x => {
      if (x.productDetails.pricing.length == 0) {
        emptyPriceError = true
      }
    });
    if (emptyPriceError == true) {
      this.toastr.errorToastr('All Product must contain atleast One Price Varient', '');
      return
    }

    this.loader = 'show';
    this.http.put('/admin/product/bulk', this.getdata).subscribe(
      data => {
        this.get();
        this.toastr.successToastr('Successfully updated', '');
      },
      error => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      });
  }


  addItem = {
    kg: "",
    price: "",
    flavour: "",
    discount: "",
    sku: "",
  }

  priceError = false
  emptyAddItem() {
    this.priceError = false
    this.addItem = {
      kg: "",
      price: "",
      flavour: "",
      discount: "",
      sku: "",
    }
  }


  //chow and hide add pricing
  visibleIndex = -1;
  showSubItem(ind) {
    this.emptyAddItem();
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


}
