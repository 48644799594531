<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"> </div> 
  <app-header [pageName]="'Image / Video Explorer'"></app-header>

  <div class="container-fluid">
    <div class="row">
      <h5>Image/Video  Explorer</h5>
      <!-- <div class="expo-note" style="text-align:center;padding: 4px 6px ; margin: 3% 5% 1% 16%;" >
         
        </div> -->
        <div class="search">
<form [formGroup]="searchForm " class="m-60">
    <input type="text" class="form-control form-control-sm " placeholder="Search" formControlName="search" >
    <!-- <button class="btn-upload mar-5 ">
      <i class="fa fa-search"></i>
      Search</button> -->
</form>
</div>

      <div class="text-end">
        <!-- <input #file accept="image/jpg,image/jpeg,image/jfif,image/png,video/mp4" type="file" (change)="uploadFile($event)" style="display:none"><br>
            <button type="button"  class="button-1 btn-pink btn-margin" (click)="file.click(`)"> <i class="fa fa-plus"></i>
              Create </button> -->
        <button type="button" *ngIf="permission.hasPermission(['file_explorer'],['fileExplorer_create'])" class="button-1 btn-pink btn-margin" (click)="onClickCreate()"> <i class="fa fa-plus"></i>
          Create </button>
      </div>
      <div class="card col-12 table-bg">
        <div class="card-body ">
          <div class="thumbnail-image-list">
            <div class="image-list" *ngFor="let exporer of exporerImages?.content| paginate: config;let i =index">
              <i class="fa fa-trash del-icon-2" *ngIf="permission.hasPermission(['file_explorer'],['fileExplorer_delete'])" aria-hidden="true" (click)="deleteImageList(exporer,i)"></i>
              <div>
                <img *ngIf="videoOrImg(exporer?.image_url) == 'img'" src="{{exporer?.image_url}}" alt="Image list">

              </div>

              <a rel="noopener noreferrer" *ngIf="videoOrImg(exporer?.image_url) == 'video'">
                <video controls controlsList="nofullscreen " class="img img-responsive" width="130" height="75">
                  <source [src]="exporer?.image_url" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </a>
              <!-- <video class="img img-responsive"  height="200" controls>
                            <source [src]="exporer?.image_url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video> -->
              <p style="text-align: center; margin-top: 3px; margin-bottom: 3px;">{{exporer?.name}}
                <span *ngIf="permission.hasPermission(['file_explorer'],['fileExplorer_update'])" class="edit-pen" (click)="onClickEdit(exporer,i)">
                  <img class="pointer"  src="/assets/images/icon/pencil-2.svg" /></span>
              </p>

              <a (click)="copyText(exporer?.image_url,i)"> {{ (copied && i == indexForCopy) == true ? 'Copied!!': 'Copy
                Link'}}</a>
             

            </div>
          </div>
          <p style="text-align: center;" *ngIf="exporerImages?.content?.length == 0">No Data Found...!</p>
        </div>
      </div>
    </div>
    <div class="pagination-align my-4">
      <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
        screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"
        responsive="true"></pagination-controls>
    </div>
  </div>




  <!-- Modal Popup-->
  <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <form [formGroup]="imageForm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create Image/Video Explorer</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="margin-left: 2px;">Image/Video Name <span class="star">*</span></label><br>
            <input type="text" class="form-control" formControlName="imageName">
            <!-- <div *ngIf="submitted && f.sortOrder.errors" class="invalid-text">
              <div *ngIf="f.sortOrder.errors.required">
                Sort is required
              </div>
            </div> -->
            <br>
            <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
            <br>
            <span class="file-formate">Image Size Should be ( width:346px X height:295px ) </span>
            <br>
            <input #file accept="image/jpg,image/jpeg,image/jfif,image/png,video/mp4" type="file"
             (change)="uploadFile($event)" style="display:none"><br>
            <button type="button" class="button-1 btn-pink btn-margin" (click)="file.click()"> <i
                class="fa fa-plus"></i>
              Choose Image
            </button>

            <div *ngIf="checkImage()" class="mt-2">
              <!-- <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i> -->
              <img src="{{imgUrl}}" class="img img-fluid">
               <i class="fa fa-trash del-icon-1" aria-hidden="true" (click)="clearForm()"></i>
            </div>

            <div *ngIf="checkVideo()" class="mt-2">
              <!-- <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i> -->
              <video src="{{imgUrl}}" controls class="img img-fluid"></video>
               <i class="fa fa-trash del-icon-1" aria-hidden="true" (click)="clearForm()"></i>
            </div>
            <!--          
            <button type="button"  class="button-1 btn-pink btn-margin"  (change)="uploadFile($event)">Choose Image</button>
            <br> -->
            <!-- <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
                          <br>
                          <span class="file-formate">Image Size Should be( width:300 X height:215px )  </span> -->
            <!-- <div *ngIf="imgUrl" class="mt-2">
              <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i>
              <img src="{{imgPth+imgUrl}}" class="img img-fluid">
            </div> -->

            <!-- <div *ngIf="submitted && f.image.errors" class="invalid-text">
              <div *ngIf="f.image.errors.required">
                Image is required
              </div>
            </div> -->


          </div>
          <div class="modal-footer">
            <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)=" close()">Close</button>
            <button type="button" class="button-1 btn-pink" (click)="exporerImge()">Submit <i style="margin-left: 4px;"
                class="fa fa-long-arrow-right"></i> </button>
          </div>
        </div>
      </form>
    </div>
  </div>



    <!--Edit form  Modal Popup-->
    <div class="modal fade bd-example-modal-lg" id="editModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <form [formGroup]="imageForm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Image/Video Explorer</h5>
            <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="margin-left: 2px;">File Name</label><br>
            <input type="text" class="form-control" formControlName="imageName">
            <!-- <div *ngIf="submitted && f.sortOrder.errors" class="invalid-text">
              <div *ngIf="f.sortOrder.errors.required">
                Sort is required
              </div>
            </div> -->
            <br>
            <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
            <br>
            <span class="file-formate">Image Size Should be ( width:1442px X height:1229px ) </span>
            <br>
            <input #file accept="image/jpg,image/jpeg,image/jfif,image/png,video/mp4" type="file"
             (change)="uploadFile($event)" style="display:none"><br>
            <button type="button" class="button-1 btn-pink btn-margin" (click)="file.click()"> <i
                class="fa fa-plus"></i>
              Choose Image
            </button>

            <!-- <div *ngIf="" class="mt-2">
              <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i> 
              <img src="{{imgUrl}}" class="img img-fluid">
              <i class="fa fa-trash del-icon-1" aria-hidden="true" (click)="clearForm()"></i>
            </div> -->

            <!-- preview -->
            <div *ngIf="checkImage()" class="mt-2">
              <!-- <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i> -->
              <img src="{{imgUrl}}" class="img img-fluid" >
               <i class="fa fa-trash del-icon-1" aria-hidden="true" (click)="clearForm()"></i>
            </div>

            <div  *ngIf="checkVideo()" class="mt-2">
              <!-- <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i> -->
              <video src="{{imgUrl}}" controls class="img img-fluid"></video>
               <i class="fa fa-trash del-icon-1" aria-hidden="true" (click)="clearForm()"></i>
            </div>
            <!--          
            <button type="button"  class="button-1 btn-pink btn-margin"  (change)="uploadFile($event)">Choose Image</button>
            <br> -->
            <!-- <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto 50 MB,</span>
                          <br>
                          <span class="file-formate">Image Size Should be( width:1442 X height:1229px )  </span> -->
            <!-- <div *ngIf="imgUrl" class="mt-2">
              <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i>
              <img src="{{imgPth+imgUrl}}" class="img img-fluid">
            </div> -->

            <!-- <div *ngIf="submitted && f.image.errors" class="invalid-text">
              <div *ngIf="f.image.errors.required">
                Image is required
              </div>
            </div> -->


          </div>
          <div class="modal-footer">
            <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)=" close()">Close</button>
            <button type="button" class="button-1 btn-pink" (click)="updateImage()">Submit <i style="margin-left: 4px;"
                class="fa fa-long-arrow-right"></i> </button>
          </div>
        </div>
      </form>
    </div>
  </div>




</section>