<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"></div>
  <app-header
    [pageName]="'Home Page Notification'"
    *ngIf="!isDelivery"
  ></app-header>
  <app-header [pageName]="'Delivery Charge'" *ngIf="isDelivery"></app-header>

  <!-- Home Page Notification Start-->
  <div class="container-fluid" *ngIf="!isDelivery">
    <h5>Home Page Notification</h5>
    <br />
    <div
      class="card bg-table-color"
      *ngIf="
        permission.hasPermission(
          ['home_page_notification'],
          ['home_page_notification_view ']
        )
      "
    >
      <div class="table-responsive card-body" *ngIf="getdata">
        <table class="table text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">HOME PAGE HEADER NOTIFICATION</th>
              <th scope="col">EDITED ON</th>
              <th
                scope="col"
                *ngIf="
                  permission.hasPermission(
                    ['home_page_notification'],
                    ['home_page_notification_update ']
                  )
                "
              >
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td style="width: 30%">{{ getdata.header }}</td>
              <td>{{ getdata.updatedOn | date: "dd-MM-yyyy, h:mm a" }}</td>
              <td
                *ngIf="
                  permission.hasPermission(
                    ['home_page_notification'],
                    ['home_page_notification_update ']
                  )
                "
              >
                <span data-toggle="modal" (click)="open()">
                  <img class="pointer"  src="/assets/images/icon/pencil-2.svg"
                /></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="createModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Home Page Message</h5>
          <button
            type="button"
            class="close"
            aria-label="Close" (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form">
            <label>Header Notification <span style="color: red;">*</span></label>
            <textarea
              class="form-control"
              formControlName="header"
              rows="3"
            ></textarea>
            <div *ngIf="submitted && f.header.errors" class="invalid-text">
              <div *ngIf="f.header.errors.required">
                Header Notification is required
              </div>
            </div>
            <br />

            <div formGroupName="details">
              <input 
                type="checkbox"
                [(ngModel)]="ShowBtnCheckbox"
                formControlName="showDefaultButton"
                class="clickable-checkbox"
             (change)="clickCheck()"  #check />
              <span class="label-checkbox">Show Default Button</span>
              <br /><br />
              <div *ngIf="!ShowBtnCheckbox">
                <label>Button Name </label>
                <input
                  type="text"
                  formControlName="ButtonName"
                  class="form-control"
                />
                <div *ngIf="submitted && form?.controls?.details?.controls?.ButtonName?.errors" class="invalid-text">
                  <div *ngIf="form?.controls?.details?.controls?.ButtonName?.errors?.required">Button name is required</div>
                </div>
                <br />
                <label>Redirect Url </label>
                <input
                  type="text"
                  formControlName="Link"
                  class="form-control"
                />
                <div *ngIf="submitted && form?.controls?.details?.controls?.Link?.errors" class="invalid-text">
                  <div *ngIf="form?.controls?.details?.controls?.Link?.errors?.required">Redirect URL is required</div>
                  <!-- <div *ngIf="form?.controls?.details?.controls?.Link?.errors?.pattern">Please provide a valid redirect URL </div> -->
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="button-1 btn-white" (click)="close()">
            Close
          </button>
          <button type="button" class="button-1 btn-pink" (click)="onSubmit()">
            Submit
            <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Home Page Notification End-->

  <!-- Delivery Charge Start -->
  <div class="container-fluid" *ngIf="isDelivery">
    <h5>Delivery Charge</h5>
    <br />
    <div
      class="card bg-table-color"
      *ngIf="
        permission.hasPermission(['delivery_charge'], ['delivery_charge_view '])
      "
    >
      <div class="table-responsive card-body" *ngIf="getDeliveryData">
        <table class="table">
          <thead>
            <tr>
              <!-- <th scope="col">MINIMUM DELIVERY AMOUNT</th> -->
              <th scope="col" >DELIVERY CHARGE (In Rupees)</th>
              <th scope="col" style="text-align: center;">TAX</th>
              <th style="text-align: center;"
                scope="col"
                *ngIf="
                  permission.hasPermission(
                    ['delivery_charge'],
                    ['delivery_charge_update']
                  )
                "
              >
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- <td style="width:30%">{{getDeliveryData?.priceRule?.minAmount}}</td> -->
              <td style="width: 20%;text-align: right;">
                {{ getDeliveryData?.priceRule?.price | number:'1.2-2'}}
              </td>
              <td style="text-align: center;">{{ getDeliveryData?.priceRule?.tax }}%</td>
              <td
                *ngIf="
                  permission.hasPermission(
                    ['delivery_charge'],
                    ['delivery_charge_update']
                  )
                "
                style="text-align: center;"
              >
                <span data-toggle="modal" (click)="openDelivery(getDeliveryData)">
                  <img class="pointer"  src="/assets/images/icon/pencil-2.svg"
                /></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="delivery"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Update Delivery Charge </h5>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeDelivery()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <label>Minimum Delivery Amount</label><br>
                    <input type="number" value="{{getDeliveryData?.priceRule?.minAmount}}" #minimumDelivery
                        class="form-control">
                    <br> -->
          <label>Delivery Charge (In Rupees) <span style="color: red;">*</span></label><br />
          <input
            type="number"
            value=""
           #deliveryCharge
            id="deliveryCharges"
            class="form-control"
          /><br />

          <label>Tax in Percentage <span style="color: red;">*</span></label><br />
          <input
            type="number"
            value=""
            #tax
            id="taxs"
            class="form-control"
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="button-1 btn-white" (click)="closeDelivery()">
            Close
          </button>
          <button
            type="button"
            class="button-1 btn-pink"
            (click)="onSubmitDelivery(deliveryCharge.value, tax.value)"
          >
            Submit
            <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Delivery Charge End -->
</section>
