import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/core/permission.service';
declare var $: any;

@Component({
  selector: 'app-home-page-popup',
  templateUrl: './home-page-popup.component.html',
  styleUrls: ['./home-page-popup.component.css'],
})
export class HomePagePopupComponent implements OnInit {
  loader;
  submitted;
  form: FormGroup;
  adminAccess;
  submitted2;
  deliveryForm: FormGroup;
  isDelivery;
@ViewChild ('check') public check : ElementRef;
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private route: ActivatedRoute,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.loader = '';
    this.isDelivery = false;
    this.emptyForm();
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('type')) {
        this.isDelivery = true;
        this.getDelivery();
      } else {
        this.isDelivery = false;
        this.get();
      }
    });
    
  }

  ShowBtnCheckbox;
  emptyForm() {
    this.ShowBtnCheckbox = true;
    this.submitted = false;
    this.submitted2 = false;
    this.form = this.formBuilder.group({
      header: ['', Validators.required],
      popup: [''],
      details: new FormGroup({
        showDefaultButton: new FormControl(true),
        ButtonName: new FormControl(''),
        Link: new FormControl(''),
      }),
    });
  }

  ////////////////////Home Page Notification/////////////////////////////
  getdata2;
  getdata;
  get() {
    // let btnName = this.form.get('details')?.get('ButtonName')
    // let linkName = this.form.get('details')?.get('Link')
    this.loader = 'show';
    this.http.get('/admin/home/header').subscribe(
      (data) => {
        this.loader = '';
        this.getdata2 = data;
        this.getdata = this.getdata2.content;

        this.form = this.formBuilder.group({
          header: [this.getdata.header, Validators.required],
          popup: [this.getdata.popup],
          details: new FormGroup({
            showDefaultButton: new FormControl(
              this.getdata.details.showDefaultButton
            ),
            ButtonName: new FormControl(this.getdata.details.ButtonName),
            Link: new FormControl(this.getdata.details.Link),
          }),
        });

        if (this.getdata.details.showDefaultButton == true) {
          this.ShowBtnCheckbox = true;
        } else {
          this.ShowBtnCheckbox = false;
        }
        
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
    
  }

  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
 if(this.form.valid){
      console.log(this.form.controls);
      this.loader = 'show';
      this.http.put('/admin/home/header/1', this.form.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Updated', '');
          this.get();
          this.close();
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        }
      );
    }else{
      this.toastr.errorToastr('Please fill the mandaory fields!');
    }
  
  
  }
  ////////////////////Home Page Notification End/////////////////////////////
  close(){
    $('#createModal').modal('hide');
    $('.modal-backdrop').remove();
  }
  open(){
    $('#createModal').modal('show');
  }

  openDelivery(getDeliveryData){
    $("#deliveryCharges").val(getDeliveryData?.priceRule?.price);
    $("#taxs").val(getDeliveryData?.priceRule?.tax);
   $('#delivery').modal('show');
  }
  closeDelivery(){
    $('#delivery').modal('hide');
    $('.modal-backdrop').remove();
  }
  //////////////Delivery Charge////////////////////
  getDeliveryData2;
  getDeliveryData;
  getDelivery() {
    this.loader = 'show';
    this.http.get('/admin/delivery/charge').subscribe(
      (data) => {
        this.getDeliveryData2 = data;
        this.getDeliveryData = this.getDeliveryData2.content;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  onSubmitDelivery(deliveryCharge, tax) {
    if (!deliveryCharge || !tax) {
      this.toastr.errorToastr('All field is required', '');
      return;
    }

    if (tax.length > 2) {
      this.toastr.errorToastr('Enter Valid Tax', '');
      return;
    }

    this.deliveryForm = this.formBuilder.group({
      name: ['default'],
      description: [null],
      active: true,
      header: [''],
      isDefault: [true],
      deliveryMethodId: [1],
      priceRule: new FormGroup({
        // minAmount: new FormControl(minimumDelivery),
        price: new FormControl(deliveryCharge),
        tax: new FormControl(tax),
      }),
    });

    this.loader = 'show';
    this.http
      .put('/admin/delivery/charge/1', this.deliveryForm.value)
      .subscribe(
        (data) => {
          this.loader = '';
          this.closeDelivery();
          this.toastr.successToastr('Successfully Updated');
          this.getDelivery();
          
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again');
        }
      );
  }

  clickCheck(){
    let checkvalue = this.form.get('details').get('showDefaultButton').value;

    this.form.get('details').get('ButtonName').setValue('');
    this.form.get('details').get('Link').setValue('');
if(checkvalue){
  this.form.get('details').get('ButtonName').clearValidators();
          this.form.get('details').get('Link').clearValidators()
  this.form.get('details').get('showDefaultButton').setValue(true)
}else{
  this.form.get('details').get('showDefaultButton').setValue(false);
}
this.form.get('details')?.get('ButtonName').updateValueAndValidity();
this.form.get('details')?.get('Link').updateValueAndValidity()
  }
}
