<section class="offset-width well-white" *ngIf="permission.hasPermission(['coupon_new'],[  'coupon_new_create',
'coupon_new_update',
'coupon_new_view',
'coupon_new_delete',
'coupon_new_list',
'coupon_new_export'
])">
   <app-header [pageName]="'Coupon Management'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Coupon Management</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row">

               <!-- Start Date -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>

               <!-- End Date -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>

               <!-- Coupon Type -->

               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Coupon Type</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="couponType" class="form-control input_style form-control-sm" #isPan>
                        <option selected value="">Select Coupon Type</option>
                        <option [value]="coupon.value" *ngFor="let coupon of couponTypeOptions">{{coupon.view}} Coupons
                        </option>
                     </select>
                  </div>
               </div>

               <!-- Discount Type -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Discount Type</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="discountType" class="form-control input_style form-control-sm" #orderType>
                        <option selected value="">Select Discount type</option>
                        <option [value]="type.value" *ngFor="let type of discountTypeOptions">{{type.view}}
                        </option>
                     </select>
                  </div>
               </div>
            </div>

            <!-- Status -->
            <div class="row align_items_end mt-4">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Status</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="status" class="form-control input_style form-control-sm" #orderType>
                        <option selected disabled value="">Select Status</option>
                        <option [value]="type.value" *ngFor="let type of statusOptions">{{type.view}}</option>
                     </select>
                  </div>
               </div>


               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Search</label>
                     <img class="search_img" src="../../../assets/images/icon/search.svg" alt="image">
                     <input #filterInput type="text" placeholder="Search by Keyword"
                        class="form-control search_widthset form-control-sm input_style input-style padding_add">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 text-align-right p-70 ">
                  <button (click)="getAllCoupons(false,true)" class="btn btn-sm btn-reset btn_search">Search</button>
               </div>



               <div class="col-lg-6 col-xl-3 col-md-6">
                  <div class="d-flex align-items-center ml_4">
                     <button *ngIf="hasOneValue" (click)="reset()" class="btn btn-sm reset_btn tooltip-container ml-3">
                        <img src="../../../assets/images/logo/reset.svg" alt="">
                        <div class="tooltip">
                           <h1 class="tooltip_style">Reset</h1>
                        </div>
                     </button>
                     <button *ngIf="coupons.length>0&&permission.hasPermission(['coupon_new'],['coupon_new_export'])"
                        (click)="export()"
                        class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-3">
                        <img src="../../../assets/images/logo/order_last.svg" alt="">
                        <div class="tooltip">
                           <h1 class="tooltip_style">Export</h1>
                        </div>
                     </button>
                     <button *ngIf="permission.hasPermission(['coupon_new'],['coupon_new_create'])"
                        routerLink="/promocode-create" class="btn btn-sm btn-add"><i class="fa fa-plus mr-1"></i>
                        Add
                        New</button>
                  </div>
               </div>
            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col">Created Date</th>
                        <th scope="col">Coupon Code</th>
                        <th scope="col">Coupon Type</th>
                        <th scope="col">Discount Type</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Amount/Percentage</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action
                        </th>
                     </tr>
                  </thead>
                  <tr *ngFor="let item of coupons; let i = index">
                     <!-- <td>{{index + 1}}</td> -->
                     <td class="pl-26">{{item.createdOn | date: 'dd-MM-yyyy'}} <br>({{item.createdOn | date: 'h:mm a'
                        }})</td>
                     <td>{{item.couponCode|uppercase}}</td>
                     <td>{{item?.couponType}} Coupon</td>
                     <td>{{item?.discountType}}</td>
                     <td>{{item.startDate | date: 'dd-MM-yyyy'}} <br>({{item.startDate | date: 'h:mm a' }})</td>
                     <td>{{item.endDate | date: 'dd-MM-yyyy'}} <br>({{item.endDate | date: 'h:mm a' }})</td>
                     <td class="text-right pr_add">
                        {{item?.discount}}
                     </td>
                     <td>
                        <span
                           [ngClass]="item.status=='Upcoming' ? 'pending_status' : item.status=='Ongoing' ? 'ongoing_status' :'expired_status'"
                           class="">{{item.status}}</span>
                     </td>
                     <td>
                        <div class="dropdown d-flex align-items-center">
                           <span class="eye_img ml-2" id="dropdownMenuButton" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false"> <i
                                 class="fa fa-ellipsis-v ml-2 three-dots color_add"></i>
                           </span>
                           <div
                              *ngIf="permission.hasPermission(['coupon_new'],['coupon_new_update','coupon_new_delete'])"
                              class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <button *ngIf="permission.hasPermission(['coupon_new'],['coupon_new_update'])"
                                 [ngClass]="['1', '3', '2'].includes(item?.couponTypeId) ? '' : 'disabled-btn'"
                                 [disabled]="['1', '3', '2'].includes(item?.couponTypeId) ? false : true"
                                 (click)="updateCoupon(item)" class="pointer btn_status">Edit</button>

                              <!-- <button *ngIf="permission.hasPermission(['coupon_new'],['coupon_new_delete'])"
                                 [ngClass]="['1', '3', '2'].includes(item?.couponTypeId) ? '' : 'disabled-btn'"
                                 [disabled]="['1', '3', '2'].includes(item?.couponTypeId) ? false : true"
                                 class="dropdown-item btn_status" href="#" (click)="deleteCoupon(item)">Delete
                              </button> -->
                           </div>
                        </div>

                     </td>
                  </tr>
               </table>
               <div *ngIf="coupons.length==0" class="d-flex justify-content-center" style="font-weight: 500;">NO
                  COUPON CODE FOUND</div>

               <div class="pagination-align" *ngIf="coupons.length!=0">
                  <app-paginator [lastPage]="pagination.totalPages" (paginate)="paginate($event)"></app-paginator>

               </div>
            </div>
         </div>
      </div>

   </div>
</section>