import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app/services/auth/auth.guard';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { BannerComponent } from './components/banner/banner.component';
import { BlogListComponent } from './components/blog-list/blog-list.component';
import { CakeComponent } from './components/cake/cake.component';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { DashComponent } from './components/dash/dash.component';
import { DeliveriesListComponent } from './components/deliveries-list/deliveries-list.component';
import { NewsletterListComponent } from './components/newsletter-list/newsletter-list.component';
import { OrderDetailListComponent } from './components/order-detail-list/order-detail-list.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { ProductsFormComponent } from './components/products-form/products-form.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { TestmonialComponent } from './components/testmonial/testmonial.component';
import { TestingModule } from './components/testing/testing.module';
import { HomePagePopupComponent } from './components/home-page-popup/home-page-popup.component';
import { ReviewManagementComponent } from './components/review-management/review-management.component';
import { LoyaltyProgrammeComponent } from './components/loyalty-programme/loyalty-programme.component';

import { InstagramComponent } from './components/instagram/instagram.component';
import { OrderReportComponent } from './components/order-report/order-report.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { GallaryComponent } from './components/gallary/gallary.component';
import { DeliveryTimeComponent } from './components/delivery-time/delivery-time.component';
import { ImageExporalComponent } from './components/image-exporal/image-exporal.component';
import { ProductBuildUploadComponent } from './components/product-build-upload/product-build-upload.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { RolesPermissionComponent } from './components/Administration/roles-permission/roles-permission.component';
// import { UserComponent } from './components/Administration/user/user.component';
// import { UserPrivilagesComponent } from './components/Administration/user-privilages/user-privilages.component';
import { UserComponent } from './components/Administration/users/user.component'
import { UserPrivilagesComponent } from './components/Administration/Roles/user-privilages.component';
import { InvoicebillComponent } from './components/invoicebill/invoicebill.component';
import { FlavourComponent } from './components/flavour/flavour.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { CreateProductComponent } from './components/create-product/create-product.component';
import { PromocodeCreateComponent } from './components/promocode-create/promocode-create.component';
import { PromocodeGeneralComponent } from './components/promocode-general/promocode-general.component';
import { PromocodeUpdateComponent } from './components/promocode-update/promocode-update.component';
import { ShortLinkComponent } from './components/short-link/short-link.component';
import { OccasionUpdateComponent } from './components/occasion-update/occasion-update.component';
import { ProductReportComponent } from './components/reports/product-report/product-report.component';
import { OrderReportNewComponent } from './components/reports/order-report-new/order-report-new.component';
import { GSTAuditReportComponent } from './components/reports/gst-audit-report/gst-audit-report.component';
import { CustomerSummaryReportComponent } from './components/reports/customer-summary-report/customer-summary-report.component';
import { CouponCodeTransactionReportComponent } from './components/reports/coupon-code-transaction-report/coupon-code-transaction-report.component';
import { ContactUsReportComponent } from './components/reports/contact-us-report/contact-us-report.component';
import { CouponCodeSummaryReportComponent } from './components/reports/coupon-code-summary-report/coupon-code-summary-report.component';
import { SellingReportComponent } from './components/reports/selling-report/selling-report.component';
import { EnquiyReportComponent } from './components/reports/enquiry-report/enquiry-report.component';
import { CustomersComponent } from './components/customers/customers.component';


const routes: Routes = [
  {
    path: 'short-link',
    component: ShortLinkComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: DashComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'categories',
    component: CategoriesListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'testing',
    component: TestingModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'products',
    component: ProductsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bulk-upload',
    component: ProductBuildUploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products/create-products',
    component: ProductsFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products/create-product',
    component: CreateProductComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'products/create-products/:productId',
    component: ProductsFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: AdminLoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cake-list',
    component: CakeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-banner',
    component: BannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-testimonial',
    component: TestmonialComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-user',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order',
    component: OrderListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-detail',
    component: OrderDetailListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-detail/:id',
    component: OrderDetailListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'transaction',
    component: TransactionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'deliveries',
    component: DeliveriesListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'promocode-general',
    component: PromocodeGeneralComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'occasion-update',
    component: OccasionUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'blog',
    component: BlogListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'news-letter',
    component: NewsletterListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home-page-popup',
    component: HomePagePopupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'review-management',
    component: ReviewManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'loyalty-programme',
    component: LoyaltyProgrammeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'instagram',
    component: InstagramComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gallary',
    component: GallaryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-report',
    component: OrderReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'loyalty-programme',
    component: LoyaltyProgrammeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Roles',
    component: UserPrivilagesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'Users',
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'delivery-time',
    component: DeliveryTimeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'image-exporal',
    component: ImageExporalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notification-list',
    component: NotificationListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice',
    component: InvoiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'activity-log',
    component: ActivityLogComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'Roles-Permission',
    component: RolesPermissionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice-bill',
    component: InvoicebillComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'flavour',
    component: FlavourComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'promocode-create',
    component: PromocodeCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'product-report',
    component: ProductReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'new-order-report',
    component: OrderReportNewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gst-audit-report',
    component: GSTAuditReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-summary-report',
    component: CustomerSummaryReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'coupon-code-transaction-report',
    component: CouponCodeTransactionReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-us-report',
    component: ContactUsReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'coupon-code-usage-summary-report',
    component: CouponCodeSummaryReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'popularity-report',
    component: SellingReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'enquiry-report',
    component: EnquiyReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'promocode-update',
    component: PromocodeUpdateComponent,
    canActivate: [AuthGuard]
  },

  {
    path: '',
    component: AdminLoginComponent
  },
  {
    path: '**',
    component: AdminLoginComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
