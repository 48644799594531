import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;
import { AppConstants } from 'src/app/constants/app.constants';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  loader = '';
  checked = 'checked';
  isEdited = false;
  imgPth;
  getRole = '';
  config;
  adminAccess;
  password;
  show = false;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private route: ActivatedRoute,
    private router: Router,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.loader = '';
    this.password = 'password';
    this.emptyForm();
    this.getRoleName();

    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('status')) {
        this.getRole = params['status'];
      } else {
        this.getRole = 'all_admin';
      }
    });

    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: '',
    };

    this.route.queryParams.subscribe((params) => {
      this.pageValue(params['page']);
    });
  }

  close() {
    $('#createModal').modal('hide');
  }

  //Pagination
  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get();
      window.scrollTo(0, 0);
    } else {
      this.config.currentPage = 1;
      this.get();
    }
  }

  pageChange(newPage: number) {
    this.router.navigate(['/create-user'], {
      queryParams: { page: newPage, status: this.getRole },
    });
  }

  getdata2;
  getdata;
  get(role?) {
    if (role) {
      this.getRole = role;
      this.config.currentPage = 1;
      this.router.navigate(['/create-user'], {
        queryParams: { page: this.config.currentPage, status: this.getRole },
      });
    }

    this.loader = 'show';
    this.http
      .post(
        '/admin/users?page=' + this.config.currentPage,
        JSON.parse('{ "role": "' + this.getRole + '" }')
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.getdata2 = data;
          this.getdata = this.getdata2.content;
          this.loader = '';
          this.getdata.map((x) => {
            let type = typeof x.privileges;
            if (type == 'string') x.privileges = JSON.parse(x.privileges);
          });

          this.config = {
            currentPage: this.getdata2.currentPage,
            itemsPerPage: this.getdata2.limit,
            totalItems: this.getdata2.total,
          };
        },
        (error) => {
          this.toastr.errorToastr('Try Again', '');
          this.loader = '';
        }
      );
  }

  getdata4;
  getRoleName() {
    this.http.get('/admin/roles').subscribe((data: any) => {
      this.getdata4 = data.content;
    });
  }

  form: FormGroup;
  submitted;
  emptyForm() {
    this.imgUrl = '';
    this.submitted = false;
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      gender: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      imageUrl: [''],
      role: ['admin'],
      roleId: ['', Validators.required],
    });
  }

  onClickCreate() {
    $('#createModal').modal('show');
    this.emptyForm();
    this.isEdited = false;
  }

  active;
  //change user status
  isActiveChange(item) {
    this.loader = 'show';
    this.http.put('/admin/user/status/' + item.id, '').subscribe(
      (data) => {
        this.loader = '';
        this.toastr.successToastr('Status updated successfully!', '');
        this.get(this.getRole);
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr(error.message, '');
      }
    );
  }

  deleteImage() {
    this.fileImg = '';
    this.imgUrl = '';
    this.form.controls['imageUrl'].setValue('');
  }

  passwordDiv;
  onClickEdit(item) {
    this.imgUrl = '';
    this.isEdited = true;
    $('#createModal').modal('show');
    this.passwordDiv = '';
    this.imgUrl = item.imageUrl;
    this.form = this.formBuilder.group({
      id: [item.id, Validators.required],
      firstName: [item.firstName, Validators.required],
      lastName: [item.name],
      gender: [item.gender, Validators.required],
      email: [item.email, Validators.required],
      phoneNumber: [item.phoneNumber],
      imageUrl: [''],
      role: [item.role, Validators.required],
      roleId: [item.roleId, Validators.required],
      updatePassword: [''],
    });
  }

  passwordDivFun() {
    this.passwordDiv = 'show';
  }

  imgUrl;
  fileImg;
  fileName;
  path2;
  //upload image and get url
  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //////upload file and get path///////
        if (this.fileImg) {
          const formData: FormData = new FormData();
          formData.append('image', this.fileImg, this.fileName);
          formData.append('fileType', 'user');
          formData.append('thumbnail', 'false');
          this.loader = 'show';
          this.http.post('/uploadimage', formData).subscribe(
            (data) => {
              this.path2 = data;
              this.imgUrl = this.path2.path;
              console.log(this.imgUrl);
              this.form.controls['imageUrl'].setValue(this.imgUrl);
              this.loader = '';
            },
            (error) => {
              this.loader = '';
            }
          );
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  //submit form
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.isEdited == true) {
      this.loader = 'show';
      this.http
        .put('/admin/user/' + this.form.controls['id'].value, this.form.value)
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Updated Successfully!', '');
            this.emptyForm();
            this.get(this.getRole);
            $('#createModal').modal('hide');
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr(error.message, '');
          }
        );
    } else {
      this.loader = 'show';
      this.http.post('/auth/signup', this.form.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          this.get(this.getRole);
          $('#createModal').modal('hide');
        },
        (error) => {
          this.loader = '';

          this.toastr.errorToastr(error.message, '');
        }
      );
    }
  }

  visibleIndex = -1;
  showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
