import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend,
  ApexResponsive,
  ApexFill
} from "ng-apexcharts";
declare var $: any;
import * as moment from 'moment';
import { dashboardDTO, topProducts } from './dashboard';
import { HttpParams } from '@angular/common/http';
import { PermissionService } from 'src/app/services/core/permission.service';
import { Router } from '@angular/router';



export type ChartOptions = {
  series: any;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: ApexFill
  responsive: ApexResponsive[]

};
export type ChartOptions2 = {
  series: any;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: any;
  subtitle: ApexTitleSubtitle;
  fill: ApexFill,
  colors: any,
  plotOptions: any,
  responsive: ApexResponsive[]


};
export type ChartOptions3 = {
  series: any;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: ApexFill,
  responsive: ApexResponsive[]

};




@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit {
  @ViewChild("chart") chart: ElementRef;
  @ViewChild("barchart") barchart: ElementRef;
  @ViewChild("line") line: ElementRef;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;
  public chartOptions3: Partial<ChartOptions3>;
  pagination: dashboardDTO;
  topDto: topProducts;
  @ViewChild('customerTo') customerTo: ElementRef
  

  globalFromDate = '2021-01-01';
  globalToDate = '2021-12-01';
  loader = ''
  // first  chart
  public vistor_Xaxis: any
  public visitor_Yaxis: any
  dates = new Date();
  formatteddate = this.dates.toISOString().split('T')[0] 
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService,
    private router: Router
  ) {

    this.topDto = new topProducts()
    this.pagination = new dashboardDTO();
    {
      this.chartOptions = {

        series: [
          {
            name: "View Count",
            color: "#f85782",
            data: [90, 75, 88, 65, 85, 65, 100, 65, 45, 35, 40, 20],
          }
        ],

        chart: {
          type: "area",
          height: 550,
          width: 580,
          zoom: {
            enabled: false
          },
          //   animations: {
          //     enabled: true,
          //     easing: 'easeinout',
          //     speed: 100,
          //     animateGradually: {
          //         enabled: true,
          //         delay: 150
          //     },
          //     dynamicAnimation: {
          //         enabled: true,
          //         speed: 150
          //     },
          // }

        },

        dataLabels: {
          enabled: false

        },

        stroke: {
          curve: "straight",
          colors: ['#f85782'],

        },

        title: {
          text: "",
          align: "left"
        },
        subtitle: {
          text: "",
          align: "left"
        },

        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          opposite: false,
          title: {
            text: "Visitor Count"
          },

        },


        legend: {
          horizontalAlign: "left",
          show: false

        },
        // responsive: [


        //   {
        //     breakpoint: 1025,
        //     options: {
        //       chart:{
        //         height: 400,
        //   width:380,

        //       }



        //     }
        //   }
        // ]




      }


    };

    {
      this.chartOptions2 = {
        series: [{
          data: [21, 7, 16,]
        }],
        chart: {
          height: 280,
          width: 580,
          type: 'bar',


        },
        fill: {
          colors: ["#f85782", '#ffc107', '#0d6efd', ' #ffc3a0', ' #6dd5ed', '#00ccff', '#9966ff'],
        },
        colors: ["#f85782", '#ffc107', '#0d6efd', ' #ffc3a0', ' #6dd5ed', '#00ccff', '#9966ff'],
        plotOptions: {
          bar: {
            columnWidth: '20%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          position: 'left',
          fontWeight: 500,
          customLegendItems: ["Products : A", "Products :B", "Products : C"],


        },
        yaxis: {
          title: {
            text: 'vistors count'
          },
        },


        xaxis: {
          type: 'category',
          categories: [
            'A', 'B', 'C'

          ],
          title: {
            text: 'Products'
          },

          labels: {
            style: {
              colors: ['#000'],
              fontSize: '12px'
            }
          }
        }
      };



    };
    {
      this.chartOptions3 = {

        series: [
          {
            name: "View Count",
            color: "#f85782",
            data: [55, 30, 40, 42, 50, 55, 53, 70],
          }
        ],
        chart: {
          type: "area",
          height: 280,
          width: 580,
          zoom: {
            enabled: false
          },
          animations: {
            enabled: false,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            },
          }

        },


        dataLabels: {
          enabled: false

        },


        stroke: {
          curve: "smooth",
          colors: ['#f85782'],

        },

        title: {
          text: "",
          align: "left"
        },
        subtitle: {
          text: "",
          align: "left"
        },

        xaxis: {
          title: {
            text: "Time per visit in hours"
          },
          categories: ['3', '6', '9', '12', '15', '18', '21', '24',],
        },
        yaxis: {
          opposite: false,
          title: {
            text: "Visitor Count"
          },

        },

        legend: {
          horizontalAlign: "left",
          show: false

        },

      };
    };

  }




  ngOnInit(): void {
    /**former codes with old dashboard */
    // this.get();
    // this.getOrderAnalysis(this.globalFromDate, this.globalToDate);
    // this.getCustomerAnalysis(this.globalFromDate, this.globalToDate);
    // this.getProductAnalysis(this.globalFromDate, this.globalToDate);


    // dashboard card counts
    this.totalOrderCounts();
    this.uniqueCustomersCount();
    this.repeatedUsersCount();
    this.totalProductsCount();
    this.totalSalesCount();

    // summary list with date filter 
    this.productSummary('Accepted');
    this.badgesCount()
    // visitor chart
    this.visitors('year')
    this.topDto.type = 'week'
    // topseen products with top_Options & period option 
    this.topProductsCount(3)
    // vistors based the new date according to time
    
        
   
    this.getTimelyVisitors(this.formatteddate)

    this.check();
  }

  // set the date for the params

  setDate(data: any) {
    this.pagination.date = data;
    this.productSummary(this.pagination.type);
    this.badgesCount()
  }

  refreshDate() {
    this.customerTo.nativeElement.value = ''
    this.pagination.date = ''
    this.productSummary(this.pagination.type);
    this.badgesCount()
  }

  // dashboard counts top cards 
  public totalOrders;
  public preTotalOrder;
  public ordersPercent;
  totalOrderCounts() {
    this.http.get('/dashboard/totalOrderCount').subscribe((res: any) => {
      let data: any = res?.Content;
      data = data.split(':');
      this.totalOrders = data[1];
      let previous = res?.Previous;
      previous = previous.split(':');
      this.preTotalOrder = previous[1];
      if (this.preTotalOrder < this.totalOrders) {
        this.ordersPercent = +(this.preTotalOrder != 0 ? Number(((this.totalOrders - this.preTotalOrder) / this.preTotalOrder) * 100).toFixed(0) : 100)
      }
      else if (this.preTotalOrder == 0 && this.totalOrders == 0) {
        this.ordersPercent = 0
      }
      else {
        this.ordersPercent = -(this.totalOrders != 0 ? Number(((this.preTotalOrder - this.totalOrders) / this.totalOrders) * 100).toFixed(0) : 100)
      }
      console.log(this.totalOrders, this.preTotalOrder, 'previous------------->', this.ordersPercent);
    })
  }

  public uniqueCustomers;
  public preUnique;
  public UniquePercent
  uniqueCustomersCount() {
    this.http.get('/dashboard/uniqueUser').subscribe((res: any) => {
      let data: any = res?.Content;
      data = data.split(':');
      this.uniqueCustomers = data[1];
      let previous = res?.Previous;
      previous = previous.split(':');
      this.preUnique = previous[1]
      if (this.preUnique < this.uniqueCustomers) {
        this.UniquePercent = +(this.preUnique != 0 ? Number(((this.uniqueCustomers - this.preUnique) / this.preUnique) * 100).toFixed(0) : 100)
      }
      else if (this.preUnique == 0 && this.uniqueCustomers == 0) {
        this.UniquePercent = 0
      }
      else {
        this.UniquePercent = -(this.uniqueCustomers != 0 ? Number(((this.preUnique - this.uniqueCustomers) / this.uniqueCustomers) * 100).toFixed(0) : 100)
      }
      console.log(this.uniqueCustomers, this.preUnique, 'previous------------->', this.UniquePercent);
    })
  }

  public repeatedUsers;
  public preUsers;
  public usersPercent;
  repeatedUsersCount() {
    this.http.get('/dashboard/repeatedUser').subscribe((res: any) => {
      let data: any = res?.Content;
      data = data.split(':');
      this.repeatedUsers = data[1];
      let previous = res?.Previous;
      previous = previous.split(':');
      this.preUsers = previous[1]
      if (this.preUsers < this.repeatedUsers) {
        this.usersPercent = +(this.preUsers != 0 ? Number(((this.repeatedUsers - this.preUsers) / this.preUsers) * 100).toFixed(0) : 100)
      }
      else if (this.preUsers == 0 && this.repeatedUsers == 0) {
        this.usersPercent = 0
      }
      else {
        this.usersPercent = -(this.repeatedUsers != 0 ? Number(((this.preUsers - this.repeatedUsers) / this.repeatedUsers) * 100).toFixed(0) : 100)
      }
    })
  }

  public totalProducts;
  public preProducts;
  public productPercent;

  totalProductsCount() {
    this.http.get('/dashboard/productTotalCount').subscribe((res: any) => {
      let data: any = res?.Content;
      data = data.split(':');

      this.totalProducts = data[1];

      let previous = res?.Previous;
      previous = previous.split(':');
      this.preProducts = previous[1]
      if (this.preProducts < this.totalProducts) {
        this.productPercent = +(this.preProducts != 0 ? ((this.totalProducts - this.preProducts) / this.preProducts) * 100 : 100)
      }
      else if (this.preProducts == 0 && this.totalProducts == 0) {
        this.productPercent = 0
      }
      else {
        this.productPercent = -(this.totalProducts != 0 ? Number(((this.preProducts - this.totalProducts) / this.totalProducts) * 100).toFixed(0) : 100)
      }
      console.log(this.totalProducts, this.preProducts, 'previous------------->', this.productPercent);
    })
  }

  // saleTotalCount

  public totalSales;
  public preSales;
  public salesPercent;
  totalSalesCount() {
    this.http.get('/dashboard/saleTotalCount').subscribe((res: any) => {
      let data: any = res?.Content;
      data = data.split(':');
      this.totalSales = data[1];
      //console.log(this.totalSales,'here in the total sales----->');

      let previous = res?.Previous;
      previous = previous.split(':');
      this.preSales = previous[1]
      if (this.preSales < this.totalSales) {
        this.salesPercent = +(this.preSales != 0 ? Number(((this.totalSales - this.preSales) / this.preSales) * 100).toFixed(0) : 100)
      }
      else if (this.preSales == 0 && this.totalSales == 0) {
        this.salesPercent = 0
      }
      else {
        this.salesPercent = -(this.totalSales != 0 ? Number(((this.preSales - this.totalSales) / this.totalSales) * 100).toFixed(0) : 100)
      }
      //console.log(this.totalSales,this.preSales,'previous------------->',this.salesPercent);
    })
  }

  public productList: any = []
  productSummary(data: any) {
    this.productList = []
    this.pagination.type = data
    let params = new HttpParams();
    for (let data in this.pagination) {
      if (this.pagination[data] != '' && this.pagination[data] != null && this.pagination[data] != undefined) {
        params = params.set(data, this.pagination[data])
      }
    }
    this.http.getData('/dashboard/searchAll', params).subscribe((res: any) => {
      this.productList = res?.content;

    })
  }
  public acceptedNot
  public bakingNot
  public deliveredNot
  public cancelledNot

  badgesCount() {
    let params = new HttpParams()
    if (this.pagination.date != '' && this.pagination.date != undefined && this.pagination.date != null) {
      params = params.set('date', this.pagination.date)
    }

    // accepted
    this.http.getData('/dashboard/orderAnalysis?status=Accepted', params).subscribe((res: any) => {
      let data: string = res?.Content
      let data1 = data.split(':');
      this.acceptedNot = data1[1]
    })

    // baking
    this.http.getData('/dashboard/orderAnalysis?status=Baking', params).subscribe((res: any) => {
      let data: string = res?.Content
      let data1 = data.split(':');
      this.bakingNot = data1[1]
    })

    // delivered
    this.http.getData('/dashboard/orderAnalysis?status=Delivered', params).subscribe((res: any) => {
      let data: string = res?.Content
      let data1 = data.split(':');
      this.deliveredNot = data1[1]
    })

    // cancelled
    this.http.getData('/dashboard/orderAnalysis?status=Cancelled', params).subscribe((res: any) => {
      let data: string = res?.Content
      let data1 = data.split(':');
      this.cancelledNot = data1[1]
    })

  }


  getFromData(data: any) {
    let newData = JSON.parse(data?.data);
    let gotData: any
    if (newData != null && newData != undefined) {
      for (const [key, value] of Object.entries(newData?.lineItems)) {
        gotData = newData?.lineItems[key]?.attribute;
        break;
      }
      return gotData
    }


  }
  public visitorBtn: any;
  public dataLabels: any;
  visitors(data: string) {
    // alert(data)
    this.autoDays = []
    this.visitorBtn = data;
    let params = new HttpParams();
    params = params.set('type', data)

    this.http.getData('/dashboard/GetVisititedCount', params).subscribe((res: any) => {
      this.dataLabels = res?.content;
      if (data == 'week') {
        // this.vistor_Xaxis=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
        // this.visitor_Yaxis=[52,86,25,66,99,10,2]
        setTimeout(() => {
          this.getWeeklabels()

        }, 1000);
      }
      else if (data == 'month') {
        setTimeout(() => {
          this.getMonth()

        }, 1000);
      }
      else {
        setTimeout(() => {
          this.getYearLabel();
        }, 1000);
      }
      this.loadVisitorChart(this.vistor_Xaxis, this.visitor_Yaxis)
    })


  }
  /**   get the month from the api**/

  getMonth() {
    let data = this.dataLabels[0]?.visitedOn?.split('-')
    let month = data[1];
    this.calculateDays(month)
  }


  /*    calculate the days  in the month */
  public totalDays
  public autoDays: any = []
  calculateDays(month: number) {
    //console.log(month,'in place where month is calculated------>');

    if (month == (1 || 3 || 5 || 7 || 8 || 10 || 12)) {
      this.totalDays = 31;
      //console.log('first condition',this.totalDays);

    }
    else if (month == 2) {

      this.totalDays = 29
      ////console..log('second condition',this.totalDays);
    }
    else {
      this.totalDays = 30
      ////console.log('third condition',this.totalDays);
    }
    for (let i = 1; i < this.totalDays + 1; i++) {
      let days = { 'visitedOn': i, 'visitedCount': 0 }
      this.autoDays.push(days)
    }
    this.matchAndSet()
    ////console.log(this.autoDays,'for month count ------>');

  }

  matchAndSet() {
    this.dataLabels.forEach(ele => {
      let data = ele.visitedOn.split('-');
      this.autoDays.forEach(day => {
        if (day?.visitedOn == data[2]) {
          day.visitedOn = Number(data[2]);
          day.visitedCount = Number(ele.visitedCount);
        }
      })
    })
    this.vistor_Xaxis = [];
    this.visitor_Yaxis = []
    this.autoDays.forEach(ele => {
      this.vistor_Xaxis.push(ele?.visitedOn);
      this.visitor_Yaxis.push(ele?.visitedCount)
    });
    this.loadVisitorChart(this.vistor_Xaxis, this.visitor_Yaxis)
  }

  // for the week label chart;
  public week = [
    { 'visitedOn': 1, 'day': 'Mon', 'count': 0 },
    { 'visitedOn': 2, 'day': 'Tue', 'count': 0 },
    { 'visitedOn': 3, 'day': 'Wed', 'count': 0 },
    { 'visitedOn': 4, 'day': 'Thu', 'count': 0 },
    { 'visitedOn': 5, 'day': 'Fri', 'count': 0 },
    { 'visitedOn': 6, 'day': 'Sat', 'count': 0 },
    { 'visitedOn': 7, 'day': 'Sun', 'count': 0 },
  ]

  getWeeklabels() {
    this.vistor_Xaxis = [];
    this.visitor_Yaxis = []
    this.dataLabels.forEach(ele => {
      let d = new Date(ele?.visitedOn);
      let date = d.getDay();
      this.week.map(wee => {
        if (wee.visitedOn == date) {
          wee.count = Number(ele.visitedCount)
        }

      })

    });
    this.week.forEach(ele => {
      this.vistor_Xaxis.push(ele.day);
      this.visitor_Yaxis.push(ele.count)
    })
    this.loadVisitorChart(this.vistor_Xaxis, this.visitor_Yaxis)

  }

  public year = [

    { 'visitedOn': 1, 'month': 'Jan', 'count': 0 },
    { 'visitedOn': 2, 'month': 'Feb', 'count': 0 },
    { 'visitedOn': 3, 'month': 'Mar', 'count': 0 },
    { 'visitedOn': 4, 'month': 'Apr', 'count': 0 },
    { 'visitedOn': 5, 'month': 'May', 'count': 0 },
    { 'visitedOn': 6, 'month': 'Jun', 'count': 0 },
    { 'visitedOn': 7, 'month': 'Jul', 'count': 0 },
    { 'visitedOn': 8, 'month': 'Aug', 'count': 0 },
    { 'visitedOn': 9, 'month': 'Sep', 'count': 0 },
    { 'visitedOn': 10, 'month': 'Oct', 'count': 0 },
    { 'visitedOn': 11, 'month': 'Nov', 'count': 0 },
    { 'visitedOn': 12, 'month': 'Dec', 'count': 0 },
  ]

  getYearLabel() {
    if (this.dataLabels?.length > 0) {
      this.vistor_Xaxis = [];
      this.visitor_Yaxis = [];
      this.dataLabels.forEach(ele => {
        let sp = ele?.visitedOn.split('-');
        let month = Number(sp[0]);
        this.year.map(yea => {
          if (yea.visitedOn == month) {
            yea.count = Number(ele?.visitedCount)
          }
        })

      });
      this.year.forEach(ele => {
        this.vistor_Xaxis.push(ele.month);
        this.visitor_Yaxis.push(ele.count)
      })


      this.loadVisitorChart(this.vistor_Xaxis, this.visitor_Yaxis)
    }


  }


  loadVisitorChart(x_axis: any, y_axis: any) {
    this.chartOptions = {

      series: [
        {
          name: "View Count",
          color: "#f85782",
          data: y_axis,
        }
      ],

      chart: {
        type: "area",
        height: 550,
        width: 580,
        zoom: {
          enabled: false
        },
        //   animations: {
        //     enabled: true,
        //     easing: 'easeinout',
        //     speed: 100,
        //     animateGradually: {
        //         enabled: true,
        //         delay: 150
        //     },
        //     dynamicAnimation: {
        //         enabled: true,
        //         speed: 150
        //     },
        // }

      },

      dataLabels: {
        enabled: false

      },

      stroke: {
        curve: "straight",
        colors: ['#f85782'],

      },

      title: {
        text: "",
        align: "left"
      },
      subtitle: {
        text: "",
        align: "left"
      },

      xaxis: {
        categories: x_axis,
      },
      yaxis: {
        opposite: false,
        title: {
          text: "Visitor Count"
        },

      },


      legend: {
        horizontalAlign: "left",
        show: false

      },
      // responsive: [


      //   {
      //     breakpoint: 1025,
      //     options: {
      //       chart:{
      //         height: 400,
      //   width:380,

      //       }



      //     }
      //   }
      // ]




    }
  }

  /**********************************************Visitor chart ends***************************************************************/

  // while loop

  check() {
    // let i = 0
    // while (i < this.week.length) {
    //   i++
    // }
  }

  //Second Chart
  public topBtn: any
  public topSeenBtn: any
  topProductsCount(data: any) {
    data == 5 ? this.topBtn = 'Top-5' : data == 3 ? this.topBtn = 'Top-3' : this.topBtn = 'Top-10';
    this.topDto.productLimit = data;
    this.topProductsPeriod(this.topDto.type)
  }
  public top_xAxis: any = []
  public top_yAxis: any = []

  topProductsPeriod(data: any) {
    // alert(data)
    this.top_xAxis = [];
    this.top_yAxis = [];
    this.topDto.type = data;
    let params = new HttpParams()
    for (let datae in this.topDto) {
      params = params.set(datae, this.topDto[datae])
    }
    this.http.getData('/dashboard/mostVisitedProduct', params).subscribe((res: any) => {
      let non_null: any = res?.content.filter(fil => fil?.produtcName != null)
      non_null.forEach(ele => {
        this.top_xAxis.push(ele?.produtcName == null ? "Deleted product" : String(ele?.produtcName))
        this.top_yAxis.push(Number(ele?.visitedCount))
      });
      if (data == 'week') {
        this.topSeenBtn = 'Week'
      }
      if (data == 'month') {
        this.topSeenBtn = 'Month'
      }
      if (data == 'year') {
        this.topSeenBtn = 'Year'
      }
      setTimeout(() => {
        this.loadTopchart(this.top_xAxis, this.top_yAxis);
      }, 1000);
    })


  }

  navigateToOrder(orderId) {
    this.router.navigate([`/order-detail/${orderId}`])
  }



  loadTopchart(xAxis, yAxis) {

    {
      this.chartOptions2 = {
        series: [{
          data: yAxis
        }],
        chart: {
          height: 280,
          width: 580,
          type: 'bar',


        },
        fill: {
          colors: ['#007BFF', '#E83E8C', '#FD7E14', ' #20C997', '#6F42C1', '#DC3545', '#17A2B8', 'DC3545', '#28A745', '#6C757D'],
        },
        colors: ['#007BFF', '#E83E8C', '#FD7E14', ' #20C997', '#6F42C1', '#DC3545', '#17A2B8', 'DC3545', '#28A745', '#6C757D'],
        plotOptions: {
          bar: {
            columnWidth: '20%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: true,
          position: 'left',
          fontWeight: 500,
          customLegendItems: yAxis,


        },
        yaxis: {
          title: {
            text: 'vistors count'
          },
        },


        xaxis: {
          type: 'category',
          categories: xAxis,
          title: {
            text: 'Products'
          },

          labels: {
            style: {
              colors: ['#000'],
              fontSize: '12px'
            }
          }
        }
      };



    };
  }


  // for the third chart ==> timely visit on the product chart;

  public timeWise = [

    { 'time': 1, 'count': 0 },
    { 'time': 2, 'count': 0 },
    { 'time': 3, 'count': 0 },
    { 'time': 4, 'count': 0 },
    { 'time': 5, 'count': 0 },
    { 'time': 6, 'count': 0 },
    { 'time': 7, 'count': 0 },
    { 'time': 8, 'count': 0 },
    { 'time': 9, 'count': 0 },
    { 'time': 10, 'count': 0 },
    { 'time': 11, 'count': 0 },
    { 'time': 12, 'count': 0 },
    { 'time': 13, 'count': 0 },
    { 'time': 14, 'count': 0 },
    { 'time': 15, 'count': 0 },
    { 'time': 16, 'count': 0 },
    { 'time': 17, 'count': 0 },
    { 'time': 18, 'count': 0 },
    { 'time': 19, 'count': 0 },
    { 'time': 20, 'count': 0 },
    { 'time': 21, 'count': 0 },
    { 'time': 22, 'count': 0 },
    { 'time': 23, 'count': 0 },
    { 'time': 24, 'count': 0 },
  ]
  public time_Xaxis: any = [];
  public time_Yaxis: any = []
  getTimelyVisitors(data?) {
   
    this.time_Yaxis = [];
    this.time_Xaxis = [];
    let params = new HttpParams();
    params = params.set('date', data)
    
    this.http.getData('/dashboard/getTimeWiseVistitorCount', params).subscribe((res: any) => {
      if (res?.content.length > 0) {
        let data = res?.content;
        data.forEach(ele => {

          this.timeWise.map(ti => {
            if (ele?.visitedTime == ti.time) {
              ti.count = ele?.visitedCount
            }
          })

        });
        this.timeWise.forEach(ele => {
          this.time_Xaxis.push(ele.time);
          this.time_Yaxis.push(ele.count)
        })
      } else {
        this.time_Yaxis = [0];
        this.time_Xaxis = [0];
      }
      

      this.loadChart3(this.time_Xaxis, this.time_Yaxis)

    })

  }




  loadChart3(xaxis, yaxis) {
    this.chartOptions3 = {

      series: [
        {
          name: "View Count",
          color: "#f85782",
          data: yaxis
        }
      ],
      chart: {
        type: "area",
        height: 280,
        width: 580,
        zoom: {
          enabled: false
        },
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          },
        }

      },


      dataLabels: {
        enabled: false

      },


      stroke: {
        curve: "smooth",
        colors: ['#f85782'],

      },

      title: {
        text: "",
        align: "left"
      },
      subtitle: {
        text: "",
        align: "left"
      },

      xaxis: {
        title: {
          text: "Time per visit in hours"
        },
        categories: xaxis,
      },
      yaxis: {
        opposite: false,
        title: {
          text: "Visitor Count"
        },

      },

      legend: {
        horizontalAlign: "left",
        show: false

      },

    };
  }





  /**------------------------------------------------------Former Codes-------------------------------------------------------------**/

  getdata
  get() {
    this.loader = 'show';
    this.http.get('/dashboard/summary').subscribe(
      data => {
        this.loader = '';
        this.getdata = data
      },
      error => {
        this.loader = '';
      });
  }

  getOrder
  getOrderAnalysis(from?, to?) {
    // let fromData = moment(from).format('DD-MM-YYYY');
    //let todate = moment(to).format('DD-MM-YYYY');

    let fromData = from
    let todate = to

    if (!from || !to || from > to) {
      this.toastr.errorToastr('Enter Valid Date', '');
      return;
    }
    this.loader = 'show';
    this.http.get('/dashboard/order-summary?fromDate=' + fromData + '&toDate=' + todate).subscribe(
      data => {
        this.getOrder = data
        this.loader = '';
      },
      error => {
        this.loader = '';
      });
  }



  getCustomer
  getCustomerAnalysis(from?, to?) {
    if (!from || !to || from > to) {
      this.toastr.errorToastr('Enter Valid Date', '');
      return;
    }
    this.loader = 'show';
    this.http.get('/dashboard/customer-summary?fromDate=' + from + '&toDate=' + to).subscribe(
      data => {
        this.loader = '';
        this.getCustomer = data
      },
      error => {
        this.loader = '';
      });
  }


  getProduct
  getProductAnalysis(from?, to?) {
    if (!from || !to || from > to) {
      this.toastr.errorToastr('Enter Valid Date', '');
      return;
    }
    this.loader = 'show';
    this.http.get('/dashboard/product-summary?fromDate=' + from + '&toDate=' + to).subscribe(
      data => {
        this.loader = '';
        this.getProduct = data
      },
      error => {
        this.loader = '';
      });
  }



}
