<section class="offset-width well-white">
  <app-header [pageName]="'Review Management'"></app-header>
  <div *ngIf="loader" class="loader-spin"></div>
  <div class="container-fluid">
    <h5>Review Management</h5>
    <br />

    <div class="card bg-table-color">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-9"></div>
          <div
            class="col-lg-3"
            *ngIf="
              permission.hasPermission(
                ['review_management'],
                ['review_management_filter']
              )
            "
          >
            <select
              style="background-color: #fff"
              class="form-control bg-table-color"
              #statusType
              (change)="getChange(statusType.value)"
              id="status2"
            >
              <option value="Pending">Pending Reviews</option>
              <option value="Approved">Approved Reviews</option>
              <option value="Rejected">Rejected Reviews</option></select
            ><br />
          </div>
        </div>
        <div
          class="table-responsive"
          *ngIf="
            permission.hasPermission(
              ['review_management'],
              ['review_management_update']
            )
          "
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">USER NAME</th>
                <th scope="col">POSTED ON</th>
                <th scope="col">PRODUCT NAME</th>
                <th scope="col">STAR</th>
                <th scope="col">COMMENTS</th>
                <th scope="col">STATUS</th>
                <th
                  scope="col"
                  *ngIf="
                    permission.hasPermission(
                      ['review_management'],
                      ['review_management_status']
                    )
                  "
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tr
              *ngFor="let item of getdata | paginate: config; let index = index"
            >
              <td>{{ item.userName }}</td>
              <td>{{ item.createdOn | date: "dd-MM-yyyy, h:mm a" }}</td>
              <td>
                <a
                  href="{{ mainDomain + '/details/' + item.productId }} "
                  target="_blank"
                >
                  {{ item.productName | titlecase }}
                </a>
              </td>
              <td style="pointer-events: none; font-size: 22px; width: 15%">
                <bar-rating
                  [rate]="item.stars"
                  style="position: absolute; margin-top: -5px"
                  [max]="5"
                ></bar-rating>
              </td>
              <td style="width: 25%">{{ item.comments }}</td>
              <td>{{ item.status }}</td>
              <td
                *ngIf="
                  permission.hasPermission(
                    ['review_management'],
                    ['review_management_status']
                  )
                "
                style="width: 15%"
              >
                <button
                  class="btn-approve"
                  *ngIf="item.status == 'Rejected' || item.status == 'Pending'"
                  (click)="changeStatus('Approved', item.id)"
                >
                  Approve
                </button>
                <button
                  class="btn-reject"
                  *ngIf="item.status == 'Approved' || item.status == 'Pending'"
                  (click)="changeStatus('Rejected', item.id)"
                >
                  Reject
                </button>
              </td>
            </tr> 
          
          </table>
          <h5 *ngIf="getdata?.length==0" style="text-align:center ;">No Data Found</h5>
        </div>
      </div>
      <div class="pagination-align" >

        <nav aria-label="Page navigation example" class="mr-20" *ngIf="lastPage >1">
          <ul class="pagination justify-content-end">
            <li class="page-item ">
              <a class="page-link disable-one" tabindex="-1" (click)="paginate('sub')">
                << Previous </a>
            </li>
            <li class="page-item" *ngIf="page == lastPage">
              <a class="page-link">{{ page - 2 }}</a>
            </li>
            <li class="page-item" *ngIf="page > 1">
              <a class="page-link">{{ page - 1 }}</a>
            </li>
            <li class="page-item active">
              <a class="page-link">{{ page }}</a>
            </li>
            <!--   -->
            <li class="page-item" *ngIf="page != lastPage">
              <a class="page-link">{{ page + 1 }}</a>
            </li>
            <li class="page-item" *ngIf="page != 0 && page == 1">
              <a class="page-link">{{ page + 2 }}</a>
            </li>
            <li class="page-item">
              <a class="page-link disable-one"  (click)="paginate('add')">Next >></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</section>
