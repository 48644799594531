export interface CreateProduct {
  productName: string;
  categoryId: string;
  sortOrder: number;
  designImages: string[];
  isPanIndiaProduct: string;
  status: number;
  listings: string[];
  isKg: boolean;
  productPoints: number;
  paymentDetails: PaymentDetails;
  productDetails: ProductDetails;
  enableWelcomeText: string;
  productImages: string[];
  sku: any;
  active: boolean;
  available: boolean;
  minimumOrder: number;
  deliverySpeed: string;
  updatepricedata: Updatepricedaum[];
  flavour: any[];
}

interface PaymentDetails {
  cod: string;
  online: boolean;
}

interface ProductDetails {
  pricing: Pricing[];
  egg: boolean;
  eggless: boolean;
  description: string;
  product_details: string;
  notes: string;
  parentCategoryId: string;
}

interface Pricing {
  kg: number;
  flavour: string;
  discount: string;
  price: string;
  net: string;
  gross: string;
}

interface Updatepricedaum {
  kg: string;
  price: string;
  flavour: string;
  discount: string;
  net: string;
  gross: string;
}

export const productFormLabels = [
  {
    name: 'productName',
    label: 'cake name',
    type: 'provide',
  },
  {
    name: 'parentCategory',
    label: 'parent category',
    type: 'select',
  },
  {
    name: 'subCategory',
    label: 'subcategory',
    type: 'select',
  },
  {
    name: 'sortOrder',
    label: 'sort order',
    type: 'provide',
  },
  {
    name: 'flavours',
    label: 'flavours',
    type: 'select',
  },
  {
    name: 'units',
    label: 'units',
    type: 'select',
  },
  {
    name: 'sku',
    label: 'sku',
    type: 'select',
  },
  {
    name: 'active',
    label: 'active',
    type: 'select',
  },
  {
    name: 'available',
    label: 'available',
    type: 'select',
  },
  {
    name: 'newPricings',
    label: 'newPricings',
    type: 'select',
  },
  {
    name: 'pricings',
    label: 'pricings',
    type: 'provide',
  },
  {
    name: 'netWeight',
    label: 'net weight',
    type: 'provide',
    min: 'zero',
  },
  {
    name: 'grossWeight',
    label: 'gross weight',
    type: 'provide',
    min: 'zero',
  },
  {
    name: 'productImages',
    label: 'productImages',
    type: 'select',
  },
  {
    name: 'productDescription',
    label: 'productDescription',
    type: 'select',
  },
  {
    name: 'notes',
    label: 'notes',
    type: 'select',
  },
  {
    name: 'minimumQuantity',
    label: 'minimumQuantity',
    type: 'select',
  },
  {
    name: 'deliveryTime',
    label: 'delivery time',
    type: 'select',
  },
  {
    name: 'message',
    label: 'message',
    type: 'select',
  },
  {
    name: 'points',
    label: 'points',
    type: 'provide',
  },
  {
    name: 'isPanIndia',
    label: 'product type whether it is PAN India product or not',
    type: 'select',
  },
  {
    name: 'payment',
    label: 'payment',
    type: 'select',
  },
  {
    name: 'isStoreDelivery',
    label: 'isStoreDelivery',
    type: 'select',
  },
  {
    name: 'isHomeDelivery',
    label: 'isHomeDelivery',
    type: 'select',
  },
];

export const pricingLabels = [
  {
    name: 'flavour',
    label: 'flavour',
    min: 1,
    max: 100,
    type: 'select',
  },
  {
    name: 'flavourName',
    label: 'flavourName',
    min: 1,
    max: 100,
    type: 'select',
  },
  {
    name: 'madeWith',
    label: 'cake preference',
    min: 1,
    max: 100,
    type: 'select',
  },
  {
    name: 'amount',
    label: 'amount',
    min: 0,
    max: '',
    type: 'provide',
  },
  {
    name: 'discount',
    label: 'discount',
    min: 1,
    max: 100,
    type: 'provide',
  },
  {
    name: 'netWeight',
    label: 'net weight',
    min: 0,
    max: 100,
    type: 'provide',
  },
  {
    name: 'grossWeight',
    label: 'gross weight',
    min: 0,
    type: 'provide',
  },
  {
    name: 'editNow',
    label: 'editNow',
  },
];
