import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-order-report-new",
  templateUrl: "./order-report-new.component.html",
  styleUrls: ["./order-report-new.component.css"],
})
export class OrderReportNewComponent implements OnInit {
  orderForm: FormGroup;
  constructor() {}

  ngOnInit(): void {}
  tableData = [
    {
      orderdDate: "26-02-2024",
      orderId: "40767654",
      deliveryDate: "14-03-2024",
      customer: "Ganesh",
      orderType: "Pan India",
      delivery: "On",
      amount: "₹ 360.00",
      status: "Accepted",
    },
    {
      orderdDate: "26-02-2024",
      orderId: "40767654",
      deliveryDate: "04-03-2024",
      customer: "Suresh",
      orderType: "Local",
      delivery: "Off",
      amount: "₹ 340.00",
      status: "Pending",
    },
    {
      orderdDate: "26-02-2024",
      orderId: "40767654",
      deliveryDate: "14-03-2024",
      customer: "Ganesh",
      orderType: "Pan India",
      delivery: "Off",
      amount: "₹ 160.00",
      status: "Cake is Baking",
    },
    {
      orderdDate: "24-02-2024",
      orderId: "40767654",
      deliveryDate: "14-03-2024",
      customer: "Rajesh",
      orderType: "Local",
      delivery: "On",
      amount: "₹ 300.00",
      status: "Delivered",
    },
    {
      orderdDate: "26-02-2024",
      orderId: "40767654",
      deliveryDate: "14-03-2024",
      customer: "Ganesh",
      orderType: "Pan India",
      delivery: "Off",
      amount: "₹ 200.00",
      status: "On Transit",
    },
    {
      orderdDate: "16-02-2024",
      orderId: "67877645",
      deliveryDate: "14-03-2024",
      customer: "Ganesh",
      orderType: "Local",
      delivery: "Off",
      amount: "₹ 510.00",
      status: "Abandoned Carts",
    },
  ];
}
