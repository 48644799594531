import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, Output ,EventEmitter} from '@angular/core';

// import { EventEmitter } from 'protractor';
import { HttpService } from 'src/app/services/http/http.service';
import { paginationDTO } from '../paginationDto';
declare var $ :any
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
paginationDTO : paginationDTO;
// public notificationList:any=[];
@Input() notificationList :any;
@Output() dataId = new EventEmitter()

  constructor(private http : HttpService) {

    this.paginationDTO = new paginationDTO();
   }

  ngOnInit(): void {

  }

 getNewNotification(){

 }

 changeStatus(data:any){
    this.hide();
    this.dataId.emit(data);
 }

 hide(){
   $('#myModal2').modal('hide');
 }

}

