import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
declare var $: any;
import { Router } from '@angular/router';
import { HttpEventType, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { map } from "rxjs/operators";
import { fromEvent, Subscription, timer } from "rxjs";
import { PermissionService } from 'src/app/services/core/permission.service';
import { paginationDTO } from '../paginationDto';
import { checkNull } from 'src/app/constants/custom-functions';
import { DeliveryStatus, TrackOrder } from './track';
import { setCalculation } from 'src/app/shared/service/calculation';
import { checkExpired } from 'src/app/shared/validations/date-validations';

interface HistoryStatus {
  name, status?, delay?, cancel?, all?
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  loader
  adminAccess
  delayTimeForm: FormGroup;
  userCreationForm: FormGroup;
  DelayError: boolean = false;
  orderDetails: any;
  noResult: boolean = false;
  minimum: any;
  minimumtime: any;
  date: any;
  Timevalidation: boolean = false;
  private timerSubscription: Subscription;
  params: any[];
  paginationDTO: paginationDTO;
  orderPopupId: any;
  trackdata: TrackOrder
  @ViewChild('filterInput') public filterInput: ElementRef
  status: any;
  showTrackDetails: boolean = false;
  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public toastr: ToastrManager,
    private router: Router,
    public rolepermission: PermissionService,
    private elementRef: ElementRef
  ) {
    this.paginationDTO = new paginationDTO()
  }
  config

  ngOnInit(): void {
    this.emptyForm();
    this.delayTimeForms();
    this.userCreationForms();
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));

    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('status')) {
        this.orderType = params['status']
      } else {
        this.orderType = 'all_orders'
      }
    });

    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: '',
      sortBy: '',
      sortDirection: ''
    };

    this.route.queryParams.subscribe((params: any) => {
      this.pageValue(params['page'], params);

    });


    this.getValues()

  }

  getValues() {

    this.timerSubscription = timer(0, 60000).pipe(
      map(() => {
        this.get(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4], this.params[5], this.params[6]);
      })
    ).subscribe();
  }


  ngOnDestroy() {
    this.timerSubscription.unsubscribe();

  }


  checkvalue(type, check) {
    if (type == 1) {
      if (check == true) {
        this.delay.nativeElement.value = true;
      }
      else {
        this.delay.nativeElement.value = false;
        let delayElement = this.elementRef.nativeElement.querySelector('#delay') as HTMLInputElement;
        delayElement.checked = false;
      }
    }
    else {
      if (check == true) {
        this.mindnight.nativeElement.value = true;
      }
      else {
        this.mindnight.nativeElement.value = false;
        let ele = this.elementRef.nativeElement.querySelector('#mindnight') as HTMLInputElement;
        ele.checked = false;
      }
    }



  }
  filter(fromDate, toDate, orderType, delay, midnight, ispan,searchValue) {
    // console.log(midnight);
    this.config.currentPage = 1;
    this.get(fromDate, toDate, orderType, delay, midnight, ispan, searchValue ? searchValue : '');
  }
  pageValue(page, params) {
    if (page) {
      this.config.currentPage = page;
      this.get(params?.fromDate, params?.toDate, params?.orderType, params?.delay, params?.midnight, params?.isPan, params?.search);

      window.scrollTo(0, 0)
    } else {
      this.config.currentPage = 1;
      this.get(params?.fromDate, params?.toDate, params?.orderType, params?.delay, params?.midnight, params?.isPan, params?.search);
    }
  }

  pageChange(newPage: number) {
    console.log(this.paginationDTO, "this.paginationDTO")
    this.router.navigate(['/order'], {
      queryParams: {
        page: newPage, status: this.orderType, fromDate: this.paginationDTO.fromDate, toDate: this.paginationDTO.toDate,
        midnight: this.paginationDTO.midnight, delay: this.paginationDTO.delay,
        orderType: this.paginationDTO.orderType, search: this.paginationDTO.search, isPan: this.paginationDTO.isPan
      }
    });
  }


  //////Search//////
  svalue: any;
  search(searchvalue) {
    this.svalue = searchvalue
    setTimeout(() => {
      if (this.svalue == searchvalue) {
        this.config.currentPage = 1;
        this.get(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4], this.params[5], searchvalue);
      }
    }, 1000);
  }

  clear() {
    this.seaTxt.nativeElement.value = '';
    this.get(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4], this.params[5], '');
  }

  resetFilter() {
    this.seaTxt.nativeElement.value = '';
    this.orderFrom.nativeElement.value = '';
    this.orderTo.nativeElement.value = '';
    this.ordertype.nativeElement.value = '';
    this.isPan.nativeElement.value = '';
    this.delay.nativeElement.value = false;
    this.mindnight.nativeElement.value = false;
    let mindnightElement = this.elementRef.nativeElement.querySelector('#mindnight') as HTMLInputElement;
    mindnightElement.checked = false;
    let delayElement = this.elementRef.nativeElement.querySelector('#delay') as HTMLInputElement;
    delayElement.checked = false;
    this.config.currentPage = 1;
    this.get('', '', '', '', '', '', '');
  }

  @ViewChild('uName') seaTxt: ElementRef;
  @ViewChild('orderFrom') orderFrom: ElementRef;
  @ViewChild('orderTo') orderTo: ElementRef;
  @ViewChild('orderType') ordertype: ElementRef;
  @ViewChild('delay') delay: ElementRef;
  @ViewChild('mindnight') mindnight: ElementRef;
  @ViewChild('isPan') isPan: ElementRef;

  showStatus = ['Pending', 'Accepted', 'Baking', 'Shipped']


  displayNext(status: 'Pending' | 'Accepted' | 'Baking' | 'Shipped', item): HistoryStatus {
    let data = item?.data
    const isStore = Object.values(data.lineItems).every((ele: any) => ele.attribute?.store == true)
    const hasLocal = Object.values(data.lineItems).every((ele: any) => checkNull(ele.attribute?.deliveryIn))
    const self = this
    function hasPermission(actions: any[]) {
      return self.rolepermission.hasPermission(['order_history'], actions)
    }



    switch (status) {

      case 'Pending': {

        const allActions = hasPermission(['order_history_pending_cancel', 'order_history_pending_to_accepted', 'order_history_pending_delay_time']);
        const cancelActions = hasPermission(['order_history_pending_cancel']);
        const statusActions = hasPermission(['order_history_pending_to_accepted']);
        const delayActions = hasPermission(['order_history_pending_delay_time'])

        return { name: 'Change to Accepted', all: allActions, cancel: cancelActions, status: statusActions, delay: delayActions }
      };
        break
      case 'Accepted': {
        const allActions = hasPermission(['order_history_accepted_cancel', 'order_history_accepted_to_accepted', 'order_history_accepted_delay_time']);
        const cancelActions = hasPermission(['order_history_accepted_cancel']);
        const statusActions = hasPermission(['order_history_accepted_to_baking']);
        const delayActions = hasPermission(['order_history_accepted_delay_time'])
        return { name: 'Change to Cake is Baking', all: allActions, cancel: cancelActions, status: statusActions, delay: delayActions }
      }
        break
      case 'Baking': {
        const allActions = hasPermission(['order_history_baking_cancel', 'order_history_baking_to_accepted', 'order_history_baking_delay_time', 'order_history_shipped_to_delivered']);
        const cancelActions = hasPermission(['order_history_baking_cancel']);
        const statusActions = hasPermission(['order_history_baking_to_shipped', 'order_history_shipped_to_delivered']);
        const delayActions = hasPermission(['order_history_baking_delay_time'])
        if (isStore) {
          return { name: 'Change to Delivered', all: allActions, cancel: cancelActions, status: statusActions && hasLocal, delay: delayActions && item?.isPanIndia == 'NO', }
        } else {
          return { name: 'Change to On Transit', all: allActions, cancel: cancelActions, status: statusActions && hasLocal, delay: delayActions && item?.isPanIndia == 'NO', }
        }
      }
        break
      case 'Shipped': {
        const allActions = hasPermission(['order_history_shipped_cancel', 'order_history_shipped_to_accepted', 'order_history_shipped_delay_time']);
        const cancelActions = hasPermission(['order_history_shipped_cancel']);
        const statusActions = hasPermission(['order_history_shipped_to_delivered']);
        const delayActions = hasPermission(['order_history_shipped_delay_time'])
        return { name: 'Change to Delivered', all: allActions, cancel: cancelActions, status: statusActions, delay: delayActions }
      }
        break
    }
  }


  changeOrderStatus(status: 'Pending' | 'Accepted' | 'Baking' | 'Shipped', item: any) {

    let data = item?.data
    console.log(data, 'item');
    const isStore = Object.values(data.lineItems).every((ele: any) => ele.attribute?.store == true)





    switch (status) {

      case 'Pending': {
        this.orderConfirmed(item.orderId)
      };
        break
      case 'Accepted': {
        this.orderBaking(item.orderId)
      }
        break
      case 'Baking': {
        if (isStore) {
          this.orderDelivered(item.orderId)
        } else {
          this.orderShipped(item.orderId)
        }
      }
        break
      case 'Shipped': {
        this.orderDelivered(item.orderId)
      }
        break
    }

  }

  orderType
  getdata
  getdata2
  ////Get Order With Search & Filter ////////////
  get(fromDate, toDate, orderType, delay, midnight, ispan, search) {

    this.params = [fromDate, toDate, orderType, delay, midnight, ispan, search]

    let sUrl = ''

    let obj = {
      'endDate': toDate ? toDate : null,
      "isDelayed": delay ? delay : false,
      "isMidNight": midnight ? midnight : false,
      "pageNo": this.config.currentPage ? this.config.currentPage : null,
      "pageSize": this.config.itemsPerPage ? this.config.itemsPerPage : null,
      "search": search ? search : null,
      "sortBy": this.config.sortBy ? this.config.sortBy : null,
      "sortDirection": this.config.sortDirection ? this.config.sortDirection : null,
      "startDate": fromDate ? fromDate : null,
      "status": orderType ? orderType : null,
      "isPanIndia": ispan ? ispan : null,

    }
    this.paginationDTO.fromDate = fromDate ? fromDate : '';
    this.paginationDTO.toDate = toDate ? toDate : '';
    this.paginationDTO.midnight = midnight ? midnight : false;
    this.paginationDTO.delay = delay ? delay : false;
    this.paginationDTO.orderType = orderType ? orderType : '';
    this.paginationDTO.search = search ? search : null;
    this.paginationDTO.isPan = ispan ? ispan : null;

    let params = new HttpParams();
    for (let data in obj) {
      if (obj[data] != null) {
        params = params.set(data, obj[data]);
      }

    }
    this.http.getData('/orderfilter/searchAll', params).subscribe(
      data => {
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        if (this.getdata2.content.length == 0) {
          this.noResult = true;
        }
        this.getdata.map(x => {
          let d = x;
          d.data = JSON.parse(d.data);
          d.data.lineItemsInfo = Object.values(d.data?.lineItems) ?? []
          x.calculation = setCalculation(d)

          if (x.status) {
            switch (x.status) {
              case 'InCart':
                x.statusDisplayName = 'Abandoned Carts';
                break;
              case 'Accepted':
                x.statusDisplayName = 'Accepted';
                break;
              case 'Baking':
                x.statusDisplayName = 'Cake is Baking';
                break;
              case 'Shipped':
                x.statusDisplayName = 'On Transit';
                break;
              default:
                x.statusDisplayName = x.status;
                break;
            }
          }
        })
        console.log(this.getdata, 'overall data');

        this.config = {
          currentPage: this.getdata2.currentPageNo,
          itemsPerPage: this.getdata2.currentPageSize,
          totalItems: this.getdata2.totalElements
        };

        console.log(this.paginationDTO, "this.paginationDTO in get call")

        this.router.navigate(['/order'], {
          queryParams: {
            page: this.config.currentPage, status: this.orderType,
            fromDate: this.paginationDTO.fromDate, toDate: this.paginationDTO.toDate,
            midnight: this.paginationDTO.midnight, delay: this.paginationDTO.delay,
            orderType: this.paginationDTO.orderType,
            search: this.paginationDTO.search, isPan: this.paginationDTO.isPan
          }
        });
      },
      error => {
        this.noResult = true;
        this.toastr.errorToastr('Try Again', '');
        // this.loader = '';
      });
  }

  getDate(item) {

    let data: any = item.data

    let date: any = data?.lineItems ? Object.values(data?.lineItems)[0] : ''

    return date.attribute ? date?.attribute?.deliveryIn : ''
  }

  ///////////////////////////Confirm to Change Order Status //////////////////
  orderStatusCancel() {
    this.orderChangeStatus = '';
    this.orderStatusId = '';
    this.submitted = false;
  }

  orderChangeStatus = '';
  orderMessage
  orderStatusId = ''
  orderCancelled(id) {
    $('#confirm-order').modal('show');
    this.orderMessage = 'Are you sure you want to cancel this order?';
    this.orderChangeStatus = 'Cancelled';
    this.orderStatusId = id
  }

  orderConfirmed(id) {
    $('#confirm-order').modal('show');
    this.orderMessage = 'Are you sure you want to Accept this order?';
    this.orderChangeStatus = 'Accepted';
    this.orderStatusId = id
  }

  orderdelayTime(id) {
    this.orderDetails = this.getdata.find((res) => res?.orderId == id);
    let data: any = this.orderDetails?.data
    for (const [key, value] of Object.entries(data?.lineItems)) {
      this.date = data?.lineItems[key]?.attribute.deliveryIn
      break;
    }
    this.minimum = this.orderDetails?.delayDeliveryTime == null ? moment(this.date).format('YYYY-MM-DD') : moment(this.orderDetails?.delayDeliveryTime).format('YYYY-MM-DD');
    this.minimumtime = this.orderDetails?.delayDeliveryTime == null ? moment(this.date).format('HH:mm') : moment(this.orderDetails?.delayDeliveryTime).format('HH:mm');
    this.delayTimeForm.get('delayDate').setValue(this.orderDetails?.delayDeliveryTime != null ? moment(this.orderDetails?.delayDeliveryTime).format('YYYY-MM-DD') : "");
    this.delayTimeForm.get('delayTime').setValue(this.orderDetails?.delayDeliveryTime != null ? moment(this.orderDetails?.delayDeliveryTime).format('HH:mm') : "");
    $('#delay-time').modal('show');
    this.orderMessage = 'Delay Time';
    this.orderChangeStatus = 'Delay';
    this.orderStatusId = id
  }

  orderBaking(id) {
    console.log(id, "id  in list")
    $('#confirm-order').modal('show');
    this.orderMessage = 'Is Cake is Baking?';
    this.orderChangeStatus = 'Baking';
    this.orderStatusId = id
  }

  orderDelivered(id) {
    $('#confirm-order').modal('show');
    this.orderMessage = 'Are you sure this order is delivered?';
    this.orderChangeStatus = 'Delivered';
    this.orderStatusId = id
  }

  orderShipped(id) {
    $('#shipped-order').modal('show');
    this.orderChangeStatus = 'Shipped';
    this.orderStatusId = id
    this.submitted = false;
  }


  // Change Order Status
  orderChangeStatusfun() {
    if (this.orderStatusId == '' || this.orderChangeStatus == '') {
      this.toastr.errorToastr('Try Again', '');
      return;
    }

    this.loader = 'show';
    this.http.put('/admin/order/' + this.orderStatusId + '/', JSON.parse('{ "status" : "' + this.orderChangeStatus + '"}')).subscribe(
      data => {
        this.loader = '';
        this.get('', '', '', '', '', '', '');
        this.toastr.successToastr('Successfully updated', '');
      },
      error => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      });
  }


  form: FormGroup;
  submitted = false;
  shipError = false
  get f() { return this.form.controls; }
  orderChangeToShipped(name, id, url) {

    this.shipError = false
    if (name == '' || id == '' || url == '') {
      this.shipError = true
      return
    }

    if (this.orderStatusId == '' || this.orderChangeStatus != 'Shipped') {
      this.toastr.errorToastr('Try Again', '');
      return;
    }

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.loader = 'show';
    this.http.put('/admin/order/' + this.orderStatusId + '/', this.form.value).subscribe(
      data => {
        this.loader = '';
        $('#shipped-order').modal('hide');
        this.get('', '', '', '', '', '', '');
        this.toastr.successToastr('Successfully updated', '');
      },
      error => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      });

  }


  updateDelayTime() {
    if (this.delayTimeForm.value.delayTime != null && this.delayTimeForm.value.delayTime != '' && this.delayTimeForm.value.delayTime != undefined) {
      this.findInvalidTime();
    }
    if (this.delayTimeForm.valid && !this.Timevalidation) {
      this.DelayError = false;
      this.loader = 'show';
      let obj: any = {
        delayDeliveryTime: this.delayTimeForm.value.delayDate + " " + this.delayTimeForm.value.delayTime
      }
      let params = new HttpParams();
      for (let data in obj) {
        params = params.set(data, obj[data]);
      }
      this.http.put('/admin/delayorder/' + this.orderStatusId, params).subscribe(
        data => {
          this.loader = '';
          $('#delay-time').modal('hide');
          this.delayTimeForm.get('delayDate').setValue('');
          this.delayTimeForm.get('delayTime').setValue('');
          this.delayTimeForm.get('delayDate').markAsUntouched();
          this.delayTimeForm.get('delayTime').markAsUntouched();
          this.get('', '', '', '', '', '', '');
          this.toastr.successToastr('Successfully updated', '');
        },
        error => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        });
    } else {
      this.delayTimeForm.get('delayDate').markAsTouched();
      this.delayTimeForm.get('delayTime').markAsTouched();
      this.DelayError = true;
      if (this.delayTimeForm.value.delayTime == null || this.delayTimeForm.value.delayTime == '' || this.delayTimeForm.value.delayTime == undefined) {
        this.toastr.errorToastr('Fill The Required Filed', '');
      }

    }
  }

  findInvalidTime() {
    let formTime = this.delayTimeForm.get('delayTime').value;
    let formdate = this.delayTimeForm.get('delayDate').value
    let date = moment(new Date(this.date)).format('HH:mm');
    console.log(formTime, formdate, new Date(`${formdate} ${formTime}:00`));

    if (!checkExpired(new Date(`${formdate} ${formTime}:00`))) {
      this.Timevalidation = true;
      this.toastr.errorToastr('Delay time can\'t be the past time', '');
    }
    else {
      this.Timevalidation = false;
    }
  }
  orderStatusDelay() {
    this.orderChangeStatus = '';
    this.orderStatusId = '';
    this.submitted = false;
    this.DelayError = false;
    this.delayTimeForm.get('delayDate').setValue('');
    this.delayTimeForm.get('delayTime').setValue('');
    this.delayTimeForm.get('delayDate').markAsUntouched();
    this.delayTimeForm.get('delayTime').markAsUntouched();
  }

  emptyForm() {
    this.form = this.formBuilder.group({
      status: ['Shipped'],
      shippingDetails: new FormGroup({
        agentName: new FormControl(''),
        trackingId: new FormControl(''),
        trackingUrl: new FormControl('')
      }),
    });
  }

  delayTimeForms() {
    this.delayTimeForm = this.formBuilder.group({
      delayTime: new FormControl('', Validators.required),
      delayDate: new FormControl('', Validators.required)
    });
  }

  userCreationForms() {
    this.userCreationForm = this.formBuilder.group({
      length: new FormControl('', [Validators.required, Validators.min(0.5)]),
      breadth: new FormControl('', [Validators.required, Validators.min(0.5)]),
      height: new FormControl('', [Validators.required, Validators.min(0.5)]),
      weight: new FormControl('', [Validators.required, Validators.min(0.1)])
    });
  }

  orderCreateFormReset() {
    $('#exampleModal').modal('hide');
    this.userCreationForm.reset();
    this.orderPopupId = '';
  }

  orderCreateFormSubmit() {
    if (this.userCreationForm.valid) {
      let form = this.userCreationForm.value;
      let obj = {
        'length': form?.length,
        'breadth': form?.breadth,
        'height': form?.height,
        'weight': form?.weight,
        'orderId': Number(this.orderPopupId)
      }


      this.http.post(`/shiprocket/create/adhoc`, obj).subscribe((res: any) => {
        console.log(res, "shiprocket response")
        if (res) {
          if (res?.status == 'NEW') {

            this.toastr.successToastr('Shiprocket order created successfully');
            setTimeout(() => {
              this.userCreationForm.reset();
              const btn = document.getElementById('closeBtn').click()
              btn
              this.get(this.params[0], this.params[1], this.params[2], this.params[3], this.params[4], this.params[5], this.params[6])
            }, 1000);

          } else {

            this.toastr.errorToastr(res?.message)
          }
        } else {

          this.toastr.errorToastr('Try Again..!')
        }


      })
    } else {
      this.userCreationForm.markAllAsTouched();
      this.userCreationForm.updateValueAndValidity();
      this.toastr.errorToastr('Fill the mandatory fields');
    }


  }

  trackOrder(id) {
    this.random = Math.random()
    this.trackOrder1 = id
    $('#trackOrderModal1').modal('show')

  }
  // sample = [
  //   {
  //     tracking_data: {
  //       track_status: 1,
  //       shipment_status: 42,
  //       shipment_track: [
  //         {
  //           id: 185584215,
  //           awb_code: "1091188857722",
  //           courier_company_id: 10,
  //           shipment_id: 168347943,
  //           order_id: 168807908,
  //           pickup_date: null,
  //           delivered_date: null,
  //           weight: "0.10",
  //           packages: 1,
  //           current_status: "PICKED UP",
  //           delivered_to: "Mumbai",
  //           destination: "Mumbai",
  //           consignee_name: "Musarrat",
  //           origin: "PALWAL",
  //           courier_agent_details: null,
  //           edd: "2021-12-27 23:23:18",
  //         },
  //       ],
  //       shipment_track_activities: [
  //         {
  //           date: "2021-12-23 14:23:18",
  //           status: "X-PPOM",
  //           activity: "In Transit - Shipment picked up",
  //           location: "Palwal_NewColony_D (Haryana)",
  //           "sr-status": "42",
  //         },
  //         {
  //           date: "2021-12-23 14:19:37",
  //           status: "FMPUR-101",
  //           activity: "Manifested - Pickup scheduled",
  //           location: "Palwal_NewColony_D (Haryana)",
  //           "sr-status": "NA",
  //         },
  //         {
  //           date: "2021-12-23 14:19:34",
  //           status: "X-UCI",
  //           activity: "Manifested - Consignment Manifested",
  //           location: "Palwal_NewColony_D (Haryana)",
  //           "sr-status": "5",
  //         },
  //       ],
  //       track_url: "https://shiprocket.co//tracking/1091188857722",
  //       etd: "2021-12-28 10:19:35",
  //     },
  //   },
  // ];

  checkNull(data) {
    return checkNull(data);
  }

  trackOrder1: any
  random: any

  onClickExampleModal(data) {
    console.log(data, "id in click")
    this.orderPopupId = data
    $('#exampleModal').modal('show');

  }

  visibleIndex = -1;
  showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  addnumber(data, item) {
    let cost = JSON.parse(data)
    let price = checkNull(this.getTotalPrice(cost?.lineItems)) ? Number(this.getTotalPrice(cost?.lineItems)) : 0;
    let discount = checkNull(this.getDiscount(cost?.lineItems)) ? Number(this.getDiscount(cost?.lineItems)) : 0;
    let coupon = checkNull(this.getCoupon(cost)) ? Number(this.getCoupon(cost)) : 0;
    let amount = checkNull(cost?.costDetails?.taxPercentage) ? 1 + (Number((cost?.costDetails?.taxPercentage) / 100) * 1) : 0;

    let subTotal = (Number(price) - Number(coupon))
    let eclGST = Number(subTotal) / Number(amount)
    // let gstCal = Number(subTotal/taxPercent)
    if (item?.homeAddress != undefined) {
      let parsed = JSON.parse(item?.homeAddress)
    }

    function parseData(value) {
      console.log('parse function', JSON.parse(value?.data));

      let parsed = JSON.parse(value?.data)
      return checkNull(parsed?.deliveryCharge?.costDetails?.delivery) ? Number(parsed?.deliveryCharge?.costDetails?.delivery) : 0;
    }


    // console.log(item, 'midfhsgfhgh::::::::::::::::::::::::::::::::::'); 

    let midCharge = checkNull(item?.midnightCharge) ? Number(item?.midnightCharge) : 0;
    let delCharge = Number(item?.panDeliveryCharge) > 0 ? Number(item?.panDeliveryCharge) : ((item?.homeAddress) ? parseData(item) > 0 ? parseData(item) : 0 : 0)
    // Number(JSON.parse(item?.homeAddress)?.deliveryCharge != 0 ? JSON.parse(item?.homeAddress)?.deliveryCharge : 0)
    let total = Number(subTotal + midCharge + delCharge);
    return Math.round(Number(total)).toFixed(2)

  }

  getTotalPrice(data: any) {
    let total = 0
    for (const item in data) {
      total += data[item]?.cost
    }
    return checkNull(total) ? Number(total) : 0;
  }

  getDiscount(data) {
    let discount = 0
    for (const item in data) {
      discount += data[item]?.costDetails?.discount
    }
    return checkNull(discount) ? Number(discount) : 0;
  }

  getCoupon(data: any) {
    return checkNull(data?.costDetails?.discount) ? Number(data?.costDetails?.discount) : 0
  }
  getdelayDate(date) {
    return String(moment(new Date(date)).format("DD-MM-YYYY HH:mm"))
  }

  Export(fromDate, toDate, orderType, delay, midnight, search, ispan) {

    this.loader = 'show';
    let obj = {
      'endDate': toDate ? toDate : null,
      "isDelayed": delay ? delay : false,
      "isMidNight": midnight ? midnight : false,
      "pageNo": this.config.currentPage ? this.config.currentPage : null,
      "pageSize": this.config.itemsPerPage ? this.config.itemsPerPage : null,
      "search": search ? search : null,
      "sortBy": this.config.sortBy ? this.config.sortBy : null,
      "sortDirection": this.config.sortDirection ? this.config.sortDirection : null,
      "startDate": fromDate ? fromDate : null,
      "status": orderType ? orderType : null,
      "isPanIndia": ispan ? ispan : null,
    }

    let params = new HttpParams();
    for (let data in obj) {
      if (obj[data] != null) {
        params = params.set(data, obj[data]);
      }

    }
    this.http.getData('/admin/order/export', params).subscribe(
      data => {
        let getdata: any = data;


        this.loader = '';
        const a = document.createElement("a");
        a.setAttribute("style", "display:none;");
        document.body.appendChild(a);
        a.download = '';
        a.href = getdata?.path;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('could not download', '');
      });
  }

  close() {
    $('#confirm-order').modal('hide')
    $('#shipped-order').modal('hide');
    $('#delay-time').modal('hide');
  }
  invoice(orderID: any) {
    this.router.navigate(['invoice-bill'], { queryParams: { orderId: orderID } })
  }

}

