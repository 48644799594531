<section class="offset-width well-white">
    <app-header [pageName]="cake"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>
    <div class="container-fluid">
        <h5 *ngIf="cakeType == 'feature'">Cakes By occasion</h5>
        <h5 *ngIf="cakeType == 'trending'">Trending Cake</h5>
        <h5 *ngIf="cakeType == 'bestseller'">Best Seller</h5>
        <br>
        <hr>


        <div class="row">
            <div class="card col-12 table-bg">
                <div class="card-body "><br>
                    <div class="table-responsive ">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">VIEW</th>
                                    <th scope="col">PRODUCTS NAME </th>
                                    <th scope="col">CATEGORY </th>
                                    <th scope="col">CREATED DATE AND TIME </th>
                                    <th scope="col">ACTIVE</th>
                                    <!-- <th scope="col">ACTION</th> -->
                                </tr>
                            </thead>
                            <tbody *ngFor="let item of getdata; let index = index">
                                <tr>
                                    <td>{{index + 1}} </td>
                                    <td (click)="showSubItem(index, item.productImages[0])"> <i
                                            class="fa fa-expand pointer" style="color:#FD7297"></i>
                                    </td>
                                    <td> <a href="{{mainDomain +'/details/'+item.productId }}"
                                            (click)="navigate(mainDomain +'/details/'+item.productId)" target="_blank">
                                            {{item.productName | titlecase}} </a>
                                    </td>
                                    <td>
                                        <a href="{{mainDomain +'/category/'+item.categoryId }} " target="_blank">
                                            <span *ngIf="!item.categoryName">Parent</span>
                                            {{item.categoryName | titlecase}} </a>
                                    </td>
                                    <td> {{item.createdOn | date: 'dd-MM-yyyy, h:mm a'}} </td>
                                    <td>
                                        <span *ngIf="item.active == true">Yes</span>
                                        <span *ngIf="item.active == false">No</span>
                                    </td>
                                    <!-- <td>
                                        <a routerLink="/products/create-products/{{item.productId}}" (click)="showdata(item)" *ngIf="rolepermission.hasPermission(['featureCake'],['featureCake_view']) || rolepermission.hasPermission(['trendingCake'],['trendingCake_view']) || rolepermission.hasPermission(['bestSeller'],['bestSeller'])"><i
                                                class="fa fa-eye"></i></a>
                                    </td> -->
                                </tr>
                                <tr *ngIf="visibleIndex === index">
                                    <td colspan="1"></td>
                                    <td colspan="2"> <img style="max-width: 250px;" src="{{imgPth+showImg}}"> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>