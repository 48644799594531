import { ConfigService } from "./config.service";
import { MessagingService } from "./messaging.service";
import { NavigationService } from "./navigation.service";
import { PermissionService } from "./permission.service";


export const serviceProvider = [
    MessagingService,
    NavigationService,
    PermissionService,
    ConfigService
];