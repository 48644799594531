import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { map } from 'rxjs/operators';
import { checkNull } from 'src/app/constants/custom-functions';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
export interface COUPON {
    couponNewId: string
    couponTypeId: string
    couponName: string
    couponCode: string
    description: string
    discountTypeId: string
    discountAmount: string
    customerEligibility: string
    couponLimit: any
    customerLimit: string
    minimumSpend: string
    maximumSpend: string
    couponStartDate: string
    couponEndDate: string
    showToUser: string
    customerId: any
    productId: string
    couponStatusId: any
    createdOn: string
    updatedOn: string
    created_by: any
    updated_by: any
    couponTypeName: string
    firstName: any
    productName: any
    couponStatusName: any
}
export class CouponDTO {
    couponStatusId
    couponTypeId
    discountTypeId
    endDate
    pageNo = 1
    pageSize = 10
    search
    sortBy
    sortDirection
    startDate
    totalPages
}

@Injectable({
    providedIn: 'root',
})
export class CouponcodeService {


    constructor(
        private http: HttpService,
        private toaster: ToastrManager,
        private loader: LoaderService
    ) { }




    getAllCustomers() {
        return this.http.get(`/admin/getAllUser`).pipe(map((ele) => adaptCustomers(ele)))
    }


    getAllProducts() {
        return this.http.get(`/admin/product/Pagination?pageNo=1&pageSize=2500`).pipe(map((ele) => adaptProducts(ele)))
    }

    createCoupon(data: any) {
        return this.http.post(`/admin/create/couponnew`, data)
    }

    updateCoupon(data: any) {
        return this.http.put(`/admin/update/couponnew`, data)
    }

    getAllCoupons(pagination?: any) {

        let params = new HttpParams();

        for (let data in pagination) {
            if (checkNull(pagination[data])) {
                params = params.append(data, pagination[data])
            }
        }
        return this.http.getData(`/admin/couponnew/searchAll`, params)
    }

    getCoupon(couponId) {
        return this.http.get(`/admin/view/${couponId}`)
    }


    deleteCoupon(id) {
        return this.http.delete(`/admin/admin/delete/${id}`)
    }


    exportCoupons(pagination?: any) {
        let params = new HttpParams();

        for (let data in pagination) {
            if (checkNull(pagination[data])) {
                params = params.append(data, pagination[data])
            }
        }
        this.loader.open()
        this.http
            .getData(`/admin/couponnew/export`, params).subscribe(
                (data: any) => {
                    console.log(data);

                    this.loader.close()
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = `Coupon List`;
                    a.href = (data?.path);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                },
                (error) => {
                    this.loader.close()
                    console.log('error occured-------------->');
                    this.toaster.errorToastr('could not download', '');
                }
            );
    }


    // Occasion Based

    getAllOccasions() {
        return this.http.get(`/admin/viewAll`)
    }


    updateOccasion(data: any, couponOccasionId) {
        return this.http.put(`/admin/update/couponOccasion?couponOccasionId=${couponOccasionId}`, data)
    }

}

function adaptCustomers(data: any) {
    return data.Content.map((ele) => {
        return {
            view: ele?.name + ' - ' + ele?.phoneNumber,
            value: ele?.id
        }
    })
}


function adaptProducts(data: any) {
    return data.content.map((ele) => {
        return {
            view: ele?.sku + ' - ' + ele?.productName,
            value: ele?.productId
        }
    })
}