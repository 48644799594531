import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { checkNull } from 'src/app/constants/custom-functions';
import { HttpService } from 'src/app/services/http/http.service';


export class Pagination {
  endDate
  startDate
  status
  currentPage = 1
  lastPage
  limit = 10
  search
  pageNo: any = 1
  pageSize: any = 0
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {


  constructor(private service: HttpService) {

  }


  getAllTransactions(pagination: Pagination) {
    let params = new HttpParams()

    for (let data in pagination) {
      if (checkNull(pagination[data])) {
        params = params.append(data, pagination[data])
      }
    }
    return this.service.getData('/razorPay/searchAll', params).pipe(map(ele => new Response(ele)))
  }


  refreshOrderStatus(orderId) {

    let params = new HttpParams()
    params = params.append('orderId', orderId)
    return this.service.getData('/v1/razorStatusConfirm', params)
  }

}


class Transaction {
  date
  orderId
  referenceId
  status
  amount
  method
  constructor(data) {
    this.date = moment(data?.createdOn).format('DD-MM-YYYY hh:mm A');
    this.referenceId = data?.razorPayId
    this.status = data?.razorPayStatus.toUpperCase()
    this.amount = (Number(data?.amount) / 100).toFixed(2)
    this.orderId = data?.orderId ?? ''
    this.method = data?.method
  }

}
class Response {
  length
  transactions: Transaction[]
  totalLength: any
  constructor(data) {
    this.length = data.content?.length ?? 0;
    this.transactions = data.content?.length > 0 ? data.content.map(ele => new Transaction(ele)) : [];
    this.totalLength = data?.totalElements ?? 0
  }
}