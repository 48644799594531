import { HttpEventType, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OrdersCalculation, setCalculation } from 'src/app/shared/service/calculation';
import { checkNull } from 'src/app/constants/custom-functions';

@Component({
  selector: 'app-invoicebill',
  templateUrl: './invoicebill.component.html',
  styleUrls: ['./invoicebill.component.css'],
})
export class InvoicebillComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private service: HttpService,
    private toastr: ToastrManager
  ) {
    this.getQuery();
  }

  ngOnInit(): void {
    this.getOrderDetails();
  }

  /****************************************************Get the orderId from params************************************************ */
  public orderId: any;
  getQuery() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      if (res?.orderId != null && res?.orderId != undefined) {
        this.orderId = res?.orderId;
        //////console.log(this.orderId);
      }
    });
  }

  /*********************************************************Get invoice details or order details**********************************/
  public orderDetails: any;
  public homeAddress: boolean;
  public shippingAddress: boolean;
  public storeAddress: boolean;
  public calculation: OrdersCalculation
  getOrderDetails() {
    if (this.orderId != null && this.orderId != undefined) {
      this.loader = 'show';
      this.service.get(`/admin/order/${this.orderId}`).subscribe(
        (res: any) => {
          this.loader = '';
          this.orderDetails = res[1];
          this.calculation = setCalculation(res[1]);
          console.log(this.calculation, 'this is the calculation', this.orderDetails);

          this.homeAddress =
            Object.keys(this.orderDetails?.homeAddress).length > 0;
          this.shippingAddress =
            this.orderDetails?.data?.lineItemsInfo[0]?.attribute?.door;
          this.storeAddress =
            Object.keys(this.orderDetails?.storeAddress).length > 0;
          // console.log(this.orderDetails, 'this is the order details page------------->>>>>>>>>>>', this.homeAddress, "Home", this.storeAddress, "Store", this.shippingAddress, "shipping");
        },
        (error: any) => {
          this.loader = '';
          this.toastr.errorToastr('Something went wrong!');
        }
      );
    }
  }


  get isInsideTamilNadu() {
    return (this.orderDetails?.homeAddress?.state == 'Tamil Nadu' || this.orderDetails?.data?.lineItemsInfo[0]?.attribute?.cartAddress?.state == 'Tamil Nadu') || checkNull(this.orderDetails.storeAddress?.id)
  }

  splitBycomma(data: string) {
    return data.split(',')
  }
  public loader: any;
  exportPDF() {
    this.loader = 'show';
    if (this.orderId != undefined && this.orderId != null) {
      let params = new HttpParams();
      let token = JSON.parse(localStorage.getItem('currentUser')).accessToken;
      // params = params.set('authorization', `Bearer ${token}`)
      params = params.set('orderId', this.orderId);
      this.service
        .FilterDownloadFile(
          `/printOrderInvoicePDF/downloadOrderInvoice`,
          params
        )
        .subscribe(
          (data: any) => {
            switch (data.type) {
              case HttpEventType.DownloadProgress:
                this.loader = '';
                break;
              case HttpEventType.Response:
                this.loader = '';
                const downloadedFile = new Blob([data.body], {
                  type: 'application/pdf',
                });
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = `Invoice Report-${this.orderId}`;
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
                break;
            }
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('could not download', '');
          }
        );
    }
  }


  getCoupon(data) {
    let coupon = data?.costDetails?.discount;

    return coupon;
  }

  discount(item) {
    let discount: any = 0;
    item?.lineItemsInfo.filter((res) => {
      discount += res?.costDetails?.discount;
    });
    return discount;
  }

  subTotal(item) {
    ////console.log(item,'for the item');
    let discount: any = 0;
    let Total: any = 0;
    item?.lineItemsInfo.filter((res: any) => {
      // discount += res?.costDetails?.discount;
      Total += Number(res?.cost);
    });
    return Total;
  }

  GSTAmount(data) {
    var constVal = (1 + ((data?.costDetails?.taxPercentage / 100) * 1));
    let excGST = (this.getNetamout(data) / constVal);
    let GST = (this.getNetamout(data) - excGST)
    return Math.round(GST);
  }

  singleGST(data: any, pro: any) {
    var constVal = (1 + ((data?.costDetails?.taxPercentage / 100) * 1))
    let discount = pro?.costDetails?.discount ? pro?.costDetails?.discount : 0;
    let subTotal = pro?.costDetails?.price ? pro?.costDetails?.price : 0
    let exGST = ((subTotal - discount) / constVal)
    // console.log((((subTotal - discount) - exGST) / 2), 'gst', constVal, 'const', subTotal, 'subtotal', exGST, 'exgst');

    return (((subTotal - discount) - exGST) / 2)
  }

  IGST(data: any, pro: any) {
    var constVal = (1 + ((data?.costDetails?.taxPercentage / 100) * 1))
    let discount = pro?.costDetails?.discount ? pro?.costDetails?.discount : 0;
    let subTotal = pro?.costDetails?.price ? pro?.costDetails?.price : 0
    let exGST = ((subTotal - discount) / constVal)
    // console.log((((subTotal - discount) - exGST) / 2), 'gst', constVal, 'const', subTotal, 'subtotal', exGST, 'exgst');

    return (((subTotal - discount) - exGST))
  }

  getNetamout(item) {
    return this.subTotal(item) - this.discount(item) - this.getCoupon(item);
  }

  getTotal(delivery, netamout, midnightCharge) {
    let total = Math.round(Number(delivery) + Number(netamout) + Number(midnightCharge));
    return total
  }
  getroundOff(delivery, netamout, midnightCharge) {
    let realAmount = Number(delivery) + Number(netamout) + Number(midnightCharge);
    let total = Math.round(Number(delivery) + Number(netamout) + Number(midnightCharge));
    return total - realAmount
  }

  getNetTotal(data: any) {
    /*
    1.Delivery charge +
    2.Cake amount - offer & GST included +
    3.Coupon -
    4.Mid-night delivery charge +
     */
    const deliveryCharge = Number(data?.panDeliveryCharge) > 0 ? Number(data?.panDeliveryCharge) : data?.homeAddress?.deliveryCharge > 0 ? data?.homeAddress?.deliveryCharge : 0;
    const midnightCharge = data?.midnightCharge;
    const coupon = data?.data?.costDetails?.discount
    let lineItem = data?.data?.lineItemsInfo.map((ele: any) => ele?.cost);
    let subTotalValue = lineItem?.reduce((a, b) => (a + b), 0);
    return Math.round((Number(subTotalValue) + Number(deliveryCharge) + Number(midnightCharge) - Number(coupon))).toFixed(2)
  }
}
