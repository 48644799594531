import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;
import { AppConstants } from '../../constants/app.constants';
import { PermissionService } from 'src/app/services/core/permission.service';


@Component({
  selector: 'app-cake',
  templateUrl: './cake.component.html',
  styleUrls: ['./cake.component.css']
})
export class CakeComponent implements OnInit {
  loader = ''
  cakeType
  cake
  imgPth


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpService,
    public toastr: ToastrManager,
    public rolepermission: PermissionService,
  ) { }
  mainDomain
  ngOnInit(): void {
    this.mainDomain = AppConstants.SERVER_CONFIG.DOMAIN;
    this.imgPth = AppConstants.SERVER_CONFIG.IMG_URL;
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('cake')) {
        this.cakeType = params['cake'];
        if (this.cakeType == 'bestseller') {
          this.cake = 'bestSellerCake';
          this.visibleIndex = -1;
        }
        if (this.cakeType == 'feature') {
          this.cake = 'featureCake';
          this.visibleIndex = -1;
        }
        if (this.cakeType == 'trending') {
          this.cake = 'trendingCake';
          this.visibleIndex = -1;
        }
      }
      this.get()
    });
  }

  //get cake listing
  getdata
  get() {
    this.loader = 'show';
    this.http.post('/admin/product/search', JSON.parse('{ "listing": "' + this.cake + '" }')).subscribe(
      data => {
        this.getdata = data;
        this.loader = '';
      },
      error => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      });
  }


  //show & hide rows in table
  showImg
  visibleIndex = -1;
  showSubItem(ind, img) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
      this.showImg = '';
    } else {
      this.visibleIndex = ind;
      this.showImg = img.split(',')[0];
      console.log(this.showImg, '--------->');

    }
  }
  showdata(data: any) {
    console.log(data);


  }
  navigate(data: any) {
    console.log(data, 'from the admin page------------------>');

  }

}
