import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public logo: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() { }

  public open() {
    this.status.next(true);
  }

  public close() {
    this.status.next(false);
  }

  private openLogo() {
    this.logo.next(true);
  }

  private closeLogo() {
    this.logo.next(false);
  }
}
