<script>
   function changeBackgroundColor(checkbox) {
      if (checkbox.checked) {
         checkbox.parentElement.style.backgroundColor = '#36B260';
      } else {
         checkbox.parentElement.style.backgroundColor = '';
      }
   }
</script>

<section class="offset-width well-white">
   <div *ngIf="loader" class="loader-spin"> </div>
   <app-header [pageName]="'Order History'"></app-header>
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-2 List-item">
            <h5>Orders</h5>
            <br>
         </div>
      </div>
      <div class="card-body card-design"
         *ngIf="rolepermission.hasPermission(['order_history'],['order_history_filter'])">
         <div class="row">
            <div class="col-lg-3">
               <div>
                  <label class="label_style">From Date</label>
                  <input type="date" #orderFrom class="form-control uppercase input_style">
               </div>
            </div>
            <div class="col-lg-3">
               <div>
                  <label class="label_style">To Date</label>
                  <input type="date" #orderTo class="form-control uppercase input_style">
               </div>
            </div>
            <div class="col-lg-3">
               <div class="relative">
                  <label class="label_style">Order Type</label>
                  <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                  <select class="form-control input_style form-control-sm" #isPan>
                     <option selected disabled value="">Select Order Type</option>
                     <option value="YES">Pan India Order</option>
                     <option value="NO">Local Order</option>
                  </select>
               </div>
            </div>
            <div class="col-lg-3">
               <div class="relative">
                  <label class="label_style">Order Status</label>
                  <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                  <select class="form-control input_style form-control-sm" #orderType>
                     <option selected disabled value="">Select Status</option>
                     <option value="Accepted">Accepted</option>
                     <option value="Baking">Cake is Baking</option>
                     <option value="Shipped">On Transit</option>
                     <option value="Delivered">Delivered</option>
                     <option value="Cancelled">Cancelled</option>
                     <!-- <option value="InCart">Abandoned Carts</option> -->
                  </select>
               </div>
            </div>
         </div>
         <div class="row mt-3 align-items-end">
            <div class="col-lg-4">
               <div class="relative">
                  <label class="label_style">Search</label>
                  <img class="search_img" src="../../../assets/images/icon/search.svg" alt="image">
                  <img src='../../../assets/images/icon/close_icon_new.png' *ngIf="uName.value.length>0"
                     class="crossbutton" (click)="clear()">
                  <input type="text" placeholder="Search by Keyword"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add" #uName
                     (keyup)="search(uName.value)">
                  <!-- <input type="text" class="form-control form-control-sm" #uName > -->
               </div>
            </div>
            <div class="col-lg-4 ">
               <label class="label_style mb-3">Delivery</label>
               <div class="d-flex align-items-center">
                  <label class="custom-checkbox">
                     <input name="delay" id="delay" type="checkbox" [checked]="delay.value ==true"
                        (click)="checkvalue(1, delay.checked)" #delay class="clickable-checkbox checkbox_style"> <span
                        class="label-checkbox  checkmark"> </span>Delayed Delivery <br>
                  </label>
                  <label class="custom-checkbox ml-5">
                     <input name="mindnight" id="mindnight" type="checkbox" [checked]="mindnight.value == true"
                        (click)="checkvalue(2, mindnight.checked)" #mindnight
                        class="clickable-checkbox checkbox_style "> <span class="label-checkbox checkmark"></span>
                     Mid-night Delivery<br>
                  </label>
               </div>
            </div>
            <div class="col-lg-2 text-align-right px-0">
               <button class="btn btn-sm btn-reset btn_search"
                  (click)="filter(orderFrom.value, orderTo.value, orderType.value,  delay.checked,mindnight.checked,isPan.value,svalue)">Search</button>
            </div>
            <div class="col-lg-2">
               <button class="btn btn-sm reset_btn tooltip-container ml-4" (click)="resetFilter()">
                  <img src="../../../assets/images/logo/reset.svg" alt="">
                  <div class="tooltip">
                     <h1 class="tooltip_style">Reset</h1>
                  </div>
               </button>
               <button *ngIf="rolepermission.hasPermission(['order_history'],['order_history_export'])"
                  class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4"
                  (click)="Export(orderFrom.value, orderTo.value, orderType.value, delay.checked,mindnight.checked, uName.value,isPan.value)"><img
                     src="../../../assets/images/logo/order_last.svg" alt="">
                  <div class="tooltip">
                     <h1 class="tooltip_style">Export</h1>
                  </div>
               </button>
            </div>

         </div>
         <br>
      </div>
      <div class="card col-12 table-bg px-0">
         <div class="card-body px-0">
            <div class="table-responsive">
               <table class="table" [ngClass]="getdata?.length==1 ? 'oneClass' : ''">
                  <thead>
                     <tr>
                        <!-- <th scope="col">ORDER ID</th>
                           <th scope="col">INVOICE NO</th>
                           <th scope="col">CUSTOMER</th>
                           <th scope="col">CREATED ON</th>
                           <th scope="col">DELIVERY DATE TIME</th>
                           <th scope="col">AMOUNT</th>
                           <th scope="col">PAN INDIA</th>
                           <th scope="col">STATUS</th>
                           <th scope="col">ACTION</th> -->
                        <th class="text_left" scope="col">Order Id</th>
                        <th class="text_left" scope="col">Ordered On</th>
                        <th class="text_left" scope="col">Invoice No</th>
                        <th class="text_left" scope="col">Customer</th>
                        <th class="text_right" scope="col">Amount</th>
                        <th class="text_left" scope="col">Delivery Date</th>
                        <th class="text_left" scope="col">Status</th>
                        <th class="text_left" scope="col">Action</th>
                     </tr>
                  </thead>
                  <tbody *ngFor="let item of getdata | paginate: config;let index = index">
                     <tr>
                        <td class="ordered_id">
                           <li class="position_relative" class="pan_india nav-item">
                              <img *ngIf="!checkNull(getDate(item))" class="tag"
                                 src="../../../assets/images/icon/tag.svg" alt="image">
                              <img *ngIf="!checkNull(getDate(item))" class="path_1"
                                 src="../../../assets/images/img/path_1.png" alt=""><img
                                 *ngIf="!checkNull(getDate(item))" class="path_2"
                                 src="../../../assets/images/img/path_2.png">{{item.orderId}}
                           </li>
                        </td>
                        <td>{{item.createdOn | date: 'dd-MM-yyyy, h:mm a'}}
                        </td>
                        <td class="bold">{{item.invoicePrefix}}-{{item.invoiceYear}}-{{item.invoiceNumber}}</td>
                        <td>{{item.firstName}} {{item.lastName}}</td>
                        <!-- <td>{{item.paymentId}}</td> -->
                        <td class="text-right pr_add"> <i class="fa fa-inr" aria-hidden="true"></i>
                           {{item.calculation?.grandTotal.toFixed(2)}} </td>
                        <td>{{item.delayDeliveryTime == null ? checkNull(getDate(item)) ? (getDate(item) | date:
                           'dd-MM-yyyy, h:mm a'):'--' :
                           (item?.delayDeliveryTime | date: 'dd-MM-yyyy, h:mm a')}}
                        </td>
                        <td>
                           <div class="d-flex align-items-center">
                              <span class="status-payment {{item.status}}"> {{item.statusDisplayName}} </span>
                              <span class="delivered_style" *ngIf="item?.isPanIndia=='YES' && item?.shipStatus!=null">
                                 <img src="../../../assets/images/icon/delivered.svg" alt="">
                              </span>
                              <!-- <span class="reset_style" *ngIf="item?.isPanIndia=='YES'">
                              <img src="../../../assets/images/icon/reset_new.svg" alt="">
                              </span>  -->
                              <label *ngIf="item.delayDeliveryTime != null &&  item.delayDeliveryTime != ''"
                                 class="mt-0 mb-0 ml-3 delay-txt"
                                 data-title="{{getdelayDate(item?.delayDeliveryTime)}}">D</label>
                           </div>
                        </td>
                        <td>

                           <div class="dropdown d-flex align-items-center">
                              <a routerLink="/order-detail/{{item.orderId}}"
                                 *ngIf="rolepermission.hasPermission(['order_history'],['order_history_view'])">
                                 <span class="eye_img eye_action">
                                    <img src="../../../assets/images/icon/eye.svg" alt="image">
                                 </span></a>
                              <span class="eye_img ml-2" id="dropdownMenuButton" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fa fa-ellipsis-v ml-2 three-dots color_add"></i>
                              </span>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="show">
                                 <button *ngIf="item?.isPanIndia=='YES'&& !checkNull(getDate(item))" class="btn-status"
                                    (click)="trackOrder(item)">Track
                                    Order</button>
                                 <button
                                    *ngIf="item?.isPanIndia=='YES' && item?.shipStatus==null  && item?.status=='Baking' && !checkNull(getDate(item))"
                                    class="btn-status" data-toggle="modal" data-target="#exampleModal"
                                    (click)="onClickExampleModal(item.orderId)">Create Ship rocket order</button>



                                 <div *ngIf="showStatus.includes(item?.status)&&displayNext(item.status,item).all">

                                    <button *ngIf="displayNext(item.status,item).cancel" class="btn-status"
                                       (click)="orderCancelled(item.orderId)">Cancel</button>
                                    <button class="btn-status" *ngIf="displayNext(item.status,item).status"
                                       (click)="changeOrderStatus(item.status,item)">{{displayNext(item.status,item).name}}</button>
                                    <button class="btn-status"
                                       *ngIf="checkNull(getDate(item))&&displayNext(item.status,item).delay"
                                       (click)="orderdelayTime(item.orderId)">Update Delay Time</button>

                                 </div>





                                 <div *ngIf="item.status == 'Delivered'"> <span class="btn-status">Delivered</span>
                                 </div>
                                 <div *ngIf="item.status == 'Cancelled'"> <span class="btn-status">Cancelled</span>
                                 </div>
                                 <div *ngIf="item.status == 'InCart'"> <span class="btn-status">Abandoned Carts</span>
                                 </div>
                                 <button class="btn-status" (click)="invoice(item.orderId)">Invoice</button>
                                 <!-- <div> <span class="btn-status curser-pointer">Refresh Payment</span> </div> -->
                              </div>
                           </div>
                        </td>
                     </tr>
                     <tr *ngIf="visibleIndex === index">
                        <td colspan="6"> </td>
                     </tr>
                  </tbody>
               </table>
               <div>
                  <h5 class="noResult" *ngIf="getdata?.length<=0">No Result Found</h5>
               </div>
               <div class="pagination-align">
                  <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
                     screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                     screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
               </div>
            </div>
         </div>
      </div>
      <br><br>
      <!-- Modal change order status -->
      <div class="modal fade" id="confirm-order" tabindex="-1" role="dialog">
         <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title">Confirmation</h5>
                  <button type="button" class="close" data-dismiss="modal" (click)='close()' aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <h5 ngIg="orderMessage">{{orderMessage}}</h5>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" (click)='close()' data-dismiss="modal"
                     (click)="orderStatusCancel()">No</button>
                  <button type="button" class="button-1 btn-pink" (click)='close()' data-dismiss="modal"
                     (click)="orderChangeStatusfun()">Yes</button>
               </div>
            </div>
         </div>
      </div>
      <!-- Modal shipped-order -->
      <div class="modal fade" id="shipped-order" tabindex="-1" role="dialog">
         <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title">Shipping Details</h5>
                  <button type="button" class="close" data-dismiss="modal" (click)='close()' aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <form [formGroup]="form">
                     <div formGroupName="shippingDetails">
                        <label>Delivery Agent Name</label>
                        <input type="text" #name class="form-control" formControlName="agentName"
                           placeholder="Enter Delivery Agent Name">
                        <br>
                        <label>Tracking Id</label>
                        <input type="text" #id class="form-control" formControlName="trackingId"
                           placeholder="Enter Tracking Id">
                        <br>
                        <label>Tracking URL</label>
                        <input type="text" #url class="form-control" formControlName="trackingUrl"
                           placeholder="Enter Tracking URL">
                        <br>
                        <div class="invalid-text" *ngIf="shipError"> All Field Required </div>
                     </div>
                  </form>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" data-dismiss="modal"
                     (click)="orderStatusCancel();close()">Close</button>
                  <button type="button" class="button-1 btn-pink"
                     (click)="orderChangeToShipped(name.value,id.value,url.value)">Shipped</button>
               </div>
            </div>
         </div>
      </div>
      <!-- modal delay time -->
      <div class="modal fade" id="delay-time" tabindex="-1" role="dialog">
         <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title">Delay Time Details</h5>
                  <button type="button" (click)="orderStatusDelay();close()" class="close" data-dismiss="modal"
                     aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <form [formGroup]="delayTimeForm">
                     <div>
                        <div class="col-sm-5 dis-inline">
                           <label class="pr-2"><b>Order Id: </b> {{orderDetails?.orderId }}</label>
                           <label class="pr-2"><b>Amount: </b>₹ {{orderDetails?.calculation?.grandTotal.toFixed(2)}}
                           </label>
                        </div>
                        <div class="col-sm-7 dis-inline">
                           <label class="pr-2"><b>Customer Name: </b> {{orderDetails?.firstName}}
                              {{orderDetails?.lastName}}
                           </label>
                           <label class="pr-2"><b>Invoice No: </b>
                              {{orderDetails?.invoicePrefix}}-{{orderDetails?.invoiceYear}}-{{orderDetails?.invoiceNumber}}</label>
                        </div>
                        <div class="col-sm-12">
                           <label><b>Expected Delivery Date Time: </b> {{date | date: 'dd-MM-yyyy h:mm a'}}</label>
                           <label *ngIf="orderDetails?.delayDeliveryTime != null"><b>Previous Delay Date Time: </b>
                              {{orderDetails?.delayDeliveryTime | date: 'dd-MM-yyyy h:mm a'}}</label>
                        </div>
                        <br>
                     </div>
                     <div class="pl-3 pr-3">
                        <label>Expected Delay Date/Time</label>
                        <div>
                           <div class=" dis-inline">
                              <input type="date" #name [min]="minimum" class="form-control" formControlName="delayDate">
                              <br>
                           </div>
                           <div class="dis-inline pl-3">
                              <input type="time" #name [min]="minimumtime" class="form-control"
                                 formControlName="delayTime">
                              <br>
                           </div>
                        </div>
                        <div class="invalid-text"
                           *ngIf="(delayTimeForm.controls['delayDate'].hasError('required') && delayTimeForm.controls['delayDate'].touched)
                           || (delayTimeForm.controls['delayTime'].hasError('required') && delayTimeForm.controls['delayTime'].touched)">
                           Please provide
                           the delay date and time
                        </div>
                        <!-- <div class="invalid-text" *ngIf="delayTimeForm.controls['delayTime'].hasError('required') && delayTimeForm.controls['delayTime'].touched"
                           > Please provide the delay time</div> -->
                     </div>
                  </form>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" data-dismiss="modal"
                     (click)="orderStatusDelay();close()">Close</button>
                  <button type="button" class="button-1 btn-pink" (click)="updateDelayTime()">Update</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Shiprocket order creation</h5>
            <button id="closeBtn" (click)="orderCreateFormReset()" type="button" class="close" data-dismiss="modal"
               aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body p_reduce">
            <form [formGroup]="userCreationForm">
               <div class="">
                  <label for="">Length <span style="font-size: 10px;">in CM</span><span
                        class="required ml_2">*</span></label>

                  <input type="number" formControlName="length" class="form-control" aria-label="Username"
                     aria-describedby="basic-addon1">
                  <div
                     *ngIf="this.userCreationForm.get('length').hasError('required') && this.userCreationForm.get('length').touched"
                     class="invalid-text">Length is required
                  </div>
                  <div *ngIf="this.userCreationForm.get('length').hasError('min')" class="invalid-text">Length must be
                     greater than 0.5 CM
                  </div>

               </div>
               <div class="">
                  <label for="">Breadth <span style="font-size: 10px;">in CM</span><span
                        class="required ml_2">*</span></label>

                  <input type="number" formControlName="breadth" class="form-control" aria-label="Username"
                     aria-describedby="basic-addon1">
                  <div
                     *ngIf="this.userCreationForm.get('breadth').hasError('required') && this.userCreationForm.get('breadth').touched"
                     class="invalid-text">Breadth is required
                  </div>
                  <div *ngIf="this.userCreationForm.get('breadth').hasError('min')" class="invalid-text">Breadth must be
                     greater than 0.5 CM
                  </div>
               </div>
               <div class="">
                  <label for="">Height <span style="font-size: 10px;">in CM</span><span
                        class="required ml_2">*</span></label>

                  <input type="number" formControlName="height" class="form-control" aria-label="Username"
                     aria-describedby="basic-addon1">
                  <div
                     *ngIf="this.userCreationForm.get('height').hasError('required') && this.userCreationForm.get('height').touched"
                     class="invalid-text">Height is required
                  </div>
                  <div *ngIf="this.userCreationForm.get('height').hasError('min')" class="invalid-text">Height must be
                     greater than 0.5
                     CM
                  </div>
               </div>
               <div class="">
                  <label for="">Weight <span style="font-size: 10px;">in KG</span><span
                        class="required ml_2">*</span></label>

                  <input type="number" formControlName="weight" class="form-control" aria-label="Username"
                     aria-describedby="basic-addon1">
                  <div
                     *ngIf="this.userCreationForm.get('weight').hasError('required') && this.userCreationForm.get('weight').touched"
                     class="invalid-text">Weight is required
                  </div>
                  <div *ngIf="this.userCreationForm.get('weight').hasError('min')" class="invalid-text">Weight must be
                     greater than 0
                     KG
                  </div>
               </div>
            </form>
         </div>
         <div class="modal-footer border_top_none">
            <button type="button" class="btn save_btn" (click)="orderCreateFormSubmit()">Submit <i
                  class="fa fa-long-arrow-right ml-2"></i></button>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="trackOrderModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Track Order</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div>
               <div class="d-flex">
                  <p>Estimated Delivery Date</p>
               </div>
               <div class="track-date">
                  <p class="day">{{status?.day}}</p>
                  <p class="month">{{status?.month}}</p>
                  <div class="date-year">
                     <h1 class="date">{{status?.date}}</h1>
                     <span class="year">{{status?.year}}</span>
                  </div>
               </div>
               <div class="track-status" *ngIf="checkNull(status?.status)">
                  <p>Status</p>
                  <h1>{{status?.status}}</h1>
               </div>
            </div>
         </div>
         <div class="modal-footer d-block">
            <div class="track-id-sec">
               <div class="d-flex">
                  <img src="assets/images/delivery.png" alt="">
                  <p class="ml-2 mt-2">{{status?.companyName}}</p>
               </div>
               <div *ngIf="checkNull(status?.shipmentId)">
                  <p>Tracking ID</p>
                  <span>{{status?.shipmentId}}</span>
               </div>
            </div>
            <div class="tracking-process">

               <ng-container *ngFor="let item of status?.deliveryStatus;let i=index">
                  <div class="track-detail">
                     <div class="timing">
                        <p>{{item?.date|date:'mediumDate'}}</p>
                        <span>{{item?.date|date:'shortTime'}}</span>
                     </div>
                     <div class="circle">
                        <div *ngIf="(i)!=(status?.deliveryStatus?.length)" class="dots"></div>
                     </div>
                     <div class="">
                        <p><b>Activity: </b>{{item?.activity}}</p>
                        <p class="pb-0"><b>Location: </b>{{item?.location}}</p>
                     </div>
                  </div>
               </ng-container>


            </div>
         </div>
      </div>
   </div>
</div>

<track-order [trackOrder]="trackOrder1" [random]="random"></track-order>