<section class="offset-width well-white">
   <app-header [pageName]="'Coupon Code Summary Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Coupon Summary Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row align_items_end alignEnd">
               <!-- Coupon Created Dates -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <h2>Coupon Created On</h2>
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="couponFromDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="couponToDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>

               <!-- Coupon Availed Dates -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <h2>Coupon Availed On</h2>
                  <div>
                     <label class="label_style">Start Date</label>
                     <input formControlName="couponAvailStartDate" type="date"
                        class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">End Date</label>
                     <input formControlName="couponAvailEndDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>


            </div>

            <div class="row align_items_end alignEnd mt-4 ">

               <!-- Coupon Type -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb-4 mb_20">
                  <div class="relative">
                     <label class="label_style">Coupon Type</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="couponTypeId" class="form-control input_style form-control-sm"
                        (change)="onCouponTypeChange($event)">
                        <option selected value="">Select Coupon Type</option>
                        <option *ngFor="let data of couponTypeOptions" [value]="data?.value">{{data?.view}}</option>
                     </select>
                  </div>
               </div>

               <!-- Product -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb-4 mb_20" *ngIf="filterForm?.get('couponTypeId')?.value=='3'">
                  <label class="label_style">Product</label>
                  <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                  <select formControlName="productId" class="form-control input_style form-control-sm">
                     <option selected value="">Select Product</option>
                     <option *ngFor=" let data of products" [value]="data?.value">{{data?.view}}</option>
                  </select>
               </div>

               <!-- Customer -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb-4 mb_20" *ngIf="filterForm?.get('couponTypeId')?.value=='2'">
                  <label class="label_style">Customer</label>
                  <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                  <select formControlName="productId" class="form-control input_style form-control-sm">
                     <option selected value="">Select Customer</option>
                     <option *ngFor=" let data of customers" [value]="data?.value">{{data?.view}}</option>
                  </select>
               </div>


               <!-- Discount Type -->

               <div class="col-lg-6 col-xl-3 col-md-6 mb-4 mb_20">
                  <label class="label_style">Discount Type</label>
                  <ng-multiselect-dropdown [formControl]="filterForm?.get('discountTypeId')"
                     [placeholder]="'Select Discount Type'" [settings]="selectboxSettings" [data]="discountTypeOptions">
                  </ng-multiselect-dropdown>
               </div>
               <!-- Customer Eligibility -->
               <div class="col-lg-6 col-xl-3 col-md-6 mb-4 mb_20">
                  <label class="label_style">Customer Eligibility</label>
                  <ng-multiselect-dropdown [formControl]="filterForm?.get('customerEligibility')"
                     [placeholder]="'Select Customer Eligibility'" [settings]="selectboxSettings"
                     [data]="eligibilityOptions">
                  </ng-multiselect-dropdown>
               </div>
               <!-- <div class="col-lg-6 col-xl-3 col-md-6 mb-4 mb_20">
                  <label class="label_style">Show to User</label>
                  <ng-multiselect-dropdown [placeholder]="'Select Show to User'" [settings]="showUser"
                     [data]="userList">
                  </ng-multiselect-dropdown>
               </div> -->

               <div class="col-lg-6 col-xl-3 col-md-6 mb-4  mb_20">
                  <label class="label_style">Status</label>
                  <ng-multiselect-dropdown [formControl]="filterForm?.get('couponStatusId')"
                     [placeholder]="'Select Coupon Status'" [settings]="selectboxSettings" [data]="statusOptions">
                  </ng-multiselect-dropdown>
               </div>



               <div class="col-lg-3 col-xl-5 col-md-6 mb-4  text-align-right">
                  <div class="d-flex align-items-center">

                     <button (click)="getCouponSummaryReport(true)"
                        class="btn btn-sm btn-reset btn_search">Search</button>
                     <div class="d-flex align-items-center">
                        <button *ngIf="hasOneValue||reports?.length>0" (click)="reset()"
                           class="btn btn-sm reset_btn tooltip-container ml-4">
                           <img src="../../../assets/images/logo/reset.svg" alt="">
                           <div class="tooltip">
                              <h1 class="tooltip_style">Reset</h1>
                           </div>
                        </button>
                        <button *ngIf="reports?.length>0" (click)="export()"
                           class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4">
                           <img src="../../../assets/images/logo/order_last.svg" alt="">
                           <div class="tooltip">
                              <h1 class="tooltip_style">Export</h1>
                           </div>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col">Created On</th>
                        <th scope="col">Coupon Code</th>
                        <th scope="col">Coupon Type</th>
                        <th scope="col">Coupon Name</th>
                        <th *ngIf="filterForm?.get('couponTypeId')?.value=='2'" scope="col">Customer Name</th>
                        <th *ngIf="filterForm?.get('couponTypeId')?.value=='3'" scope="col">Product Name</th>
                        <th scope="col">Discount Type</th>
                        <th scope="col">Customer Eligibility</th>
                        <th scope="col">Coupon Limit</th>
                        <th scope="col">Used Coupon</th>
                        <th scope="col">Available Coupon</th>
                        <th scope="col">Show to User</th>
                        <th scope="col">Reminder</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Status</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of reports">

                        <td class="pl-26">{{ item.createdOn|date:'dd-MM-yyyy hh:mm a' }}</td>

                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text ">{{ item.couponCode }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.couponCode }}</h1>
                              </div>
                           </div>
                        </td>

                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text ">{{ item.couponTypeName }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.couponTypeName }}</h1>
                              </div>
                           </div>
                        </td>

                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text">{{ item.couponName }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.couponName }}</h1>
                              </div>
                           </div>
                        </td>

                        <td *ngIf="filterForm?.get('couponTypeId')?.value=='2'">{{ item.userName|emptyText }}</td>
                        <td *ngIf="filterForm?.get('couponTypeId')?.value=='3'">{{ item.productName|emptyText }}</td>


                        <td>{{ item.discountTypeId=='1' ? 'Flat':'Percentage' }}</td>

                        <td>{{ item.customerEligibility=='1' ? 'All users':'New user' }}</td>
                        <td>{{ checkNull(item.couponLimit)? item.couponLimit :'Unlimited' }}</td>
                        <td>{{ item.usedCoupon|emptyText:0 }}</td>
                        <td>{{ (checkNull(item.couponLimit))? item.availableCoupon :
                           'Unlimited' }}</td>
                        <td>{{ item.showToUser=='1'?'YES':'NO' }}</td>
                        <td>{{ item.remainderDays|emptyText }}</td>
                        <td>{{ item.couponStartDate|date:'dd-MM-yyyy hh:mm a' }}</td>
                        <td>{{ item.couponEndDate |date:'dd-MM-yyyy hh:mm a'}}</td>
                        <td>
                           <span
                              [ngClass]="item.couponStatusName=='Upcoming' ? 'pending_status' : item.couponStatusName=='Ongoing' ? 'ongoing_status' :'expired_status'"
                              class="pending_status ">{{item.couponStatusName}}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>

               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO COUPON SUMMARY RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>