<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"></div>
  <app-header [pageName]="'Loyalty Programme'"></app-header>

  <!-- loyalty programm -->
  <div class="container-fluid">
    <h5>Loyalty Programme</h5>
    <br />
    <div class="card bg-table-color">
      <div class="table-responsive card-body">
        <button
          class="edit-btn"
          style="right: 100px"
          *ngIf="
            permission.hasPermission(
              ['loyalty_programme'],
              ['loyalty_programme_points']
            )
          "
          (click)="onAddPointsToMember()"
        >
     
          Points to Members
        </button>
        <!-- loyalty_programme_update -->
        <button
          (click)="onClickEdit()"
          class="edit-btn"
          *ngIf="
            permission.hasPermission(
              ['loyalty_programme'],
              ['loyalty_programme_update']
            )
          "
        >
          Edit</button
        ><br /><br />
        <table class="table" *ngIf="getdata">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Loyalty Type</th>
              <th scope="col">Points</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Signup Points</td>
              <td>{{ getdata.signUp }}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Referral Points</td>
              <td>{{ getdata.referral }}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Referrer Points</td>
              <td>{{ getdata.referrer }}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Subscription Points</td>
              <td>{{ getdata.subscription }}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Review Points</td>
              <td>{{ getdata.review }}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Minimum Redeem Points</td>
              <td>{{ getdata.minRedeem }} & above</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Minimum Amount for Order Points</td>
              <td>{{ getdata.minAmount }} & above</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Points Per Order</td>
              <td>{{ getdata.orderPoints }}</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Per Points Value</td>
              <td>{{ getdata.pointsValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <br /><br /><br />

  <!-- Modal Form-->
  <div
    class="modal fade bd-example-modal-lg"
    id="createModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Loyalty Programme</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form">
            <label>Signup Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="signUp"
            />
            <div *ngIf="submitted && f.signUp.errors" class="invalid-text">
              <div *ngIf="f.signUp.errors.required">This Field is Required</div>
            </div>
            <br />

            <label>Referral Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="referral"
            />
            <div *ngIf="submitted && f.referral.errors" class="invalid-text">
              <div *ngIf="f.referral.errors.required">
                This Field is Required
              </div>
            </div>
            <br />

            <label>Referrer Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="referrer"
            />
            <div *ngIf="submitted && f.referrer.errors" class="invalid-text">
              <div *ngIf="f.referrer.errors.required">
                This Field is Required
              </div>
            </div>
            <br />

            <label>Subscription Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="subscription"
            />
            <div
              *ngIf="submitted && f.subscription.errors"
              class="invalid-text"
            >
              <div *ngIf="f.subscription.errors.required">
                This Field is Required
              </div>
            </div>
            <br />

            <label>Review Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="review"
            />
            <div *ngIf="submitted && f.review.errors" class="invalid-text">
              <div *ngIf="f.review.errors.required">This Field is Required</div>
            </div>
            <br />

            <label>Minimum Redeem Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="minRedeem"
            />
            <div *ngIf="submitted && f.minRedeem.errors" class="invalid-text">
              <div *ngIf="f.minRedeem.errors.required">
                This Field is Required
              </div>
            </div>
            <br />

            <label>Minimum Amount for Order Points   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="minAmount"
            />
            <div *ngIf="submitted && f.minAmount.errors" class="invalid-text">
              <div *ngIf="f.minAmount.errors.required">
                This Field is Required
              </div>
            </div>
            <br />

            <label>Points Per Order   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="orderPoints"
            />
            <div *ngIf="submitted && f.orderPoints.errors" class="invalid-text">
              <div *ngIf="f.orderPoints.errors.required">
                This Field is Required
              </div>
            </div>
            <br />

            <label>Per Points Value   <span style="color:red;">*</span></label>
            <input
              type="number"
              class="form-control"
              formControlName="pointsValue"
            />
            <div *ngIf="submitted && f.pointsValue.errors" class="invalid-text">
              <div *ngIf="f.pointsValue.errors.required">
                This Field is Required
              </div>
            </div>
            <br />
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">
            Close
          </button>
          <button type="button" class="button-1 btn-pink" (click)="onSubmit()">
            Submit
            <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--End Modal Form-->

  <!--Add points to Member Modal -->
  <div
    class="modal fade bd-example-modal-lg"
    id="membersOfferModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Members Offer</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close()  "
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div [ngClass]="{ dsplyNone: memberDetails?.userName }">
            <label>Mobile Number <span style = "color:red;">*</span> </label>
            <input
              type="number"
              #offerMobileNumber
              placeholder="Enter Customer Mobile Number"
              class="form-control"
            /><br />
          </div>

          <div
            *ngIf="memberDetails?.userName"
            style="font-size: 15px; font-weight: 500"
          >
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>User Name</td>
                  <td>{{ memberDetails?.userName }}</td>
                </tr>
                <tr>
                  <td>Total Points Earned</td>
                  <td>{{ memberDetails?.totalEarned }}</td>
                </tr>
                <tr>
                  <td>Total Points Having</td>
                  <td>{{ memberDetails?.totalPoints }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />

          <div [ngClass]="{ dsplyNone: !memberDetails?.userName }">
            <label>Enter Points to Add: </label>
            <input
              type="number"
              value="0"
              min="1"
              #offerPoints
              placeholder="Enter Points to Add"
              class="form-control"
            />
            <p > Note: Point should be atleast greater than zero.</p>
            <!-- <p></p> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">
            Close
          </button>
          <button
            type="button"
            class="button-1 btn-pink"
            (click)="
              onSubmitMembersOffer(offerMobileNumber.value, offerPoints.value)
            "
          >
            Submit
            <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--End Add points to Member Modal -->
</section>
