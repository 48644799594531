<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"> </div>
  <app-header [pageName]="'Categories'"></app-header>

  <div class="container-fluid">
    <div class="row">
      <h5>Categories</h5>

      <div class="text-end" *ngIf="rolepermission.hasPermission(['categories'],['categories_create'])">
        <button type="button" (click)="createCategory()" class="button-1 btn-pink btn-margin"> <i
            class="fa fa-plus"></i> Create </button>
      </div>

      <div class="card col-12 table-bg">
        <div class="card-body ">

          <div class="row">
            <div class="col-lg-2 add-item">
              <h5>Categories List</h5>
            </div>
            <div class="col-lg-4">
              <input type="text" class="form-control form-control-sm" #uName (keyup)="search(uName.value)"
                placeholder="Search By Name & Short Code">
            </div>
          </div><br> <br>

          <div class="table-responsive">

            <table class="table">
              <thead>
                <tr>
                  <th scope="col"> CATEGORIES NAME</th>
                  <th scope="col"> PARENT CATEGORIES NAME</th>

                  <th scope="col">SORT ORDER</th>
                  <th scope="col">SHORT CODE</th>
                  <th scope="col">CREATED DATE AND TIME </th>
                  <th scope="col" *ngIf="rolepermission.hasPermission(['categories'],['categories_update','categories_delete'])">ACTION </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of getdata | paginate: config;let index = index ">
                  <td>
                    <a href="{{mainDomain +'/category/'+item.categoryName.split(' ').join('-')+'-'+item.categoryId }} "
                      target="_blank"> {{item.categoryName |
                      titlecase }} </a>
                  </td>
                  <td>{{item.subCategoryName == '0' ? '-' : (item.subCategoryName!=null ?item.subCategoryName :'-')}}</td>
                  <td> {{item.sortOrder}} </td>
                  <td> {{item.shortCode}} </td>
                  <td>{{item.createdOn | date: 'dd-MM-yyyy, h:mm a' }}</td>
                  <td *ngIf="rolepermission.hasPermission(['categories'],['categories_update','categories_delete'])"> <span class="pencil-dot">..</span><img class="pointer" 
                      (click)="showEditModal(item.categoryId)" src="/assets/images/icon/pencil-2.svg" *ngIf="rolepermission.hasPermission(['categories'],['categories_update'])">
                    <i class="fa fa-trash btn-del2" *ngIf="rolepermission.hasPermission(['categories'],['categories_delete'])" (click)="deleteCategory(item.categoryId)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <h5 class="noResult" *ngIf="noResult">No Result Found</h5> 
            </div>
            <div class="pagination-align">
              <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
 screenReader PaginationLabel="Pagination" screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>








  <!-- Modal -->
  <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{isEdited == 'edited' ? 'Update' : 'Create'}} Category</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true" >&times;</span>
          </button>
        </div>
        <form [formGroup]="categoryForm">
          <div class="modal-body">
            <!--row-->
            <div class="row">

              <div class="col-lg-4 mt-2">
                <label>Category Name <span style="color: red;">*</span></label><br>
                <input type="text" class="form-control" formControlName="categoryName"
                  placeholder="Enter Category Name">
                <div *ngIf="submitted && f.categoryName.errors" class="invalid-text">
                  <div *ngIf="f.categoryName.errors.required">
                    Category is required
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-2">
                <label>Short Code <span style="color: red;">*</span></label><br>
                <input type="text" class="form-control" formControlName="shortCode"
                  [ngClass]="{'diable-textbox': isEdited == 'edited'}" placeholder="Enter Short Code">
                <div *ngIf="submitted && f.shortCode.errors" class="invalid-text">
                  <div *ngIf="f.shortCode.errors.required">
                    Short Code is required
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-2">
                <label>Parent Category <span style="color: red;">*</span></label><br>
                <select class="form-control" #category formControlName="parentCategoryId" (change)='selected(category.value)'>
                  <option value="0">Set as Parent Category</option>
                  <option *ngFor="let item of getdata4" value="{{item.categoryId}}">
                    {{item.categoryName}}</option>
                </select>
                <div *ngIf="submitted && f.parentCategoryId.errors" class="invalid-text">
                  <div *ngIf="f.parentCategoryId.errors.required">
                    Parent Category is required
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-2">
                <label>Sort Order <span style="color: red;">*</span></label><br>
                <input type="number" class="form-control" formControlName="sortOrder" placeholder="Enter Sort Order">
                <div *ngIf="submitted && f.sortOrder.errors" class="invalid-text">
                  <div *ngIf="f.sortOrder.errors.required">
                    Sort Order is required
                  </div>
                </div>
              </div>

              <div class="col-4 mt-2">
                <div style="margin-top: 40px;">
                  <input type="checkbox" formControlName="showUser" class="clickable-checkbox"> <span
                    class="label-checkbox">Show to Customer</span>
                </div>
              </div>

              <div class="col-4 mt-2">
                <label>Form Type</label>
                <select class="form-control" formControlName="formType">
                  <option value="0">No Enquiry Form Required</option>
                  <option value="1">Cake Enquiry Form</option>
                  <option value="2">Dessert Table Enquiry Form</option>
                  <option value="3">Wedding Cake Enquiry Form</option>
                  <option value="4">Show Thumbnail Instead of Product</option>
                </select>
              </div>

              <div class="col-lg-6 img-div-modal">
                <input type="file" id="upload-degn" #file (change)="uploadFile($event)" accept="image/*"
                  style="display:none">
                <label for="upload-degn" *ngIf="!haveImg">
                  <span class="btn btn-file-upload">Choose Image</span>
                </label>
                <br>
                <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG,  upto 50
                  MB,</span>
                <br>
                <span class="file-formate">Image Size Should be ( width:350px X height:350px ) </span> 

                <div *ngIf="haveImg" class="mt-2">
                  <i class="fa fa-trash del-icon" aria-hidden="true" (click)="deleteImage()"></i>
                  <img src="{{imgUrl}}" class="img img-fluid">
                </div>
                <div *ngIf="submitted && f.imagePath.errors" class="invalid-text">
                  <div *ngIf="f.imagePath.errors.required">
                    Image is required
                  </div>
                </div>
              </div>

            </div>
            <!--end row-->
          </div>
          <!--end Modal body-->
        </form>
        <div class="modal-footer">
          <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">Close</button>
          <button type="button" (click)="onSubmit()" class="button-1 btn-pink"> <span *ngIf="isEdited != 'edited'">Create</span>
            <span *ngIf="isEdited == 'edited'">Save</span> <i style="margin-left: 4px;" class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

</section>