<section class="offset-width well-white">
   <app-header [pageName]="'Customer Summary Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Customer Summary Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row align_items_end">
               <div class="col-lg-6 col-xl-2 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date"
                        class="form-control uppercase input_style widthChange">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-2 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date"
                        class="form-control uppercase input_style widthChange">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Status</label>

                     <ng-multiselect-dropdown [formControl]="filterForm?.get('status')" class="multi_selectStyle"
                        [placeholder]="'Select Status'" [settings]="statusSettings" [data]="statusOptions">
                     </ng-multiselect-dropdown>
                     <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="status" class="form-control input_style widthChange form-control-sm">
                        <option selected value="">Select Status</option>
                        <option [value]="data?.value" *ngFor="let data of statusOptions">
                           {{data?.view}}
                        </option>
                     </select> -->
                  </div>
               </div>
               <div class="col-lg-3 col-xl-2 col-md-3 mr_3 text-align-right">
                  <button (click)="getCustomerReport(true)" class="btn btn-sm btn-reset btn_search">Search</button>
               </div>
               <div class="col-lg-3  col-xl-2 col-md-3">
                  <button (click)="reset()" *ngIf="hasOneValue||reports?.length>0"
                     class="btn btn-sm reset_btn tooltip-container ml_0 ml-2">
                     <img src="../../../assets/images/logo/reset.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Reset</h1>
                     </div>
                  </button>
                  <button (click)="export()" *ngIf="reports?.length>0"
                     class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4"><img
                        src="../../../assets/images/logo/order_last.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Export</h1>
                     </div>
                  </button>
               </div>
            </div>





         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col">Created On</th>
                        <th scope="col">Customer Id</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Mobile No</th>
                        <th scope="col">Email Id</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Date of Birth</th>
                        <th scope="col">Status</th>


                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of reports">
                        <td class="pl-26">{{ item.createdDate|date:'dd-MM-yyyy hh:mm a' }}</td>
                        <td>{{ item.userId }}</td>
                        <td>
                           <div class="tooltip-container">
                              <span>{{item.customerName| trimtext:8:'.' }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{item.customerName }}</h1>
                              </div>
                           </div>
                        </td>
                        <td>{{ item.mobileNumber }}</td>
                        <td>
                           <div class="tooltip-container">
                              <span>{{item.emailId| trimtext:10:'.' }}</span>
                              <div class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{item.emailId }}</h1>
                              </div>
                           </div>
                        </td>
                        <td>{{ item.gender|titlecase }}</td>
                        <td>{{checkNull(item?.dateOfBirth ) ? (item?.dateOfBirth |date:'dd-MM-yyyy' ):'-' }}</td>
                        <td><span [ngClass]="item.active ==true ? 'active-status':'inactive-status'">{{
                              item?.active==true ? 'Active' :'In-active'}}</span></td>

                     </tr>
                  </tbody>
               </table>
               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO CUSTOMER RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>