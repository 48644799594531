<app-notification [notificationList]="notify" (dataId)="getBYid($event)"></app-notification>
<div class="row header-margin">

  <div class="col-6">
    <a routerLink="/home"> <i class="fa fa-home home-icon"> </i></a>
    <i class="fa fa-angle-double-right double-right"> </i>
    <a class="dashboard-a" routerLink="/home" >Dashboard</a>

    <span *ngIf="pageName">
      <i class="fa fa-angle-double-right double-right"> </i>
      <a [routerLink]="pageLink" class="dashboard-a">{{pageName|titlecase}}</a>
    </span>

    <span *ngIf="pageNameTwo">
      <i class="fa fa-angle-double-right double-right"> </i>
      <span class="pagenme">{{pageNameTwo|titlecase}}</span>
    </span>

    <span *ngFor="let item of pageNamethree">
      <i class="fa fa-angle-double-right double-right"> </i>
      <span class="pagenme"> {{item.categoryName|titlecase}} </span>
    </span>

  </div>

  <div class="col-6">
<div class="">
  <div class="profile_item" style="position:absolute;right:40px; bottom: -26px;">
     
    

    <img class="profile-img" *ngIf="currentUser?.imageUrl" [src]="currentUser?.imageUrl" src="../../../assets/images/logo/puppys_awatar.svg" alt="">
    <img class="profile-img" *ngIf="!currentUser?.imageUrl" src="../../../assets/images/logo/puppys_awatar.svg" alt="">
    <!-- <img  src="/assets/images/img/avatar.jpg"> -->
    <span style="margin-left: 10px; font-weight: 500;">{{currentUser?.firstName}}</span>
  </div>
</div>

    <div style="position: relative; bottom: 24px; right: 24px;" *ngIf="permission.hasPermission(['notification'],['notification_view'])">
  
        <img  class="bell-icon" data-toggle="modal" data-target="#myModal2" (click)="getAllNotifiation()" src="../../../assets/images/logo/notify.svg" alt="">
        <span class="badge badge-danger">{{badge}}</span>
   

      <!-- <i class="fa fa-bell bell-icon" ></i> -->

    </div>
    
    
	
    <i (click)="fullScreen()" *ngIf="zoomIn" class="fa fa-arrows-alt top-icon"></i>
    <i (click)="zoomoutScreen()" *ngIf="zoomOut" class="fa fa-search-minus top-icon"></i>

  </div>

</div>
<!--End row-->

	

<!--Mobile Nav Bar-->
<nav class="navbar navbar-expand-lg navbar-light bg-light2 boot-navbar">
  <a class="navbar-brand" href="#"></a>
  <!-- <i class="fa fa-bell bell-icon"  data-toggle="modal" data-target="#myModal2" ><span class="badge badge-danger">5</span></i> -->
  <button class="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">

      <li class="nav-item active" *ngIf="role?.dashboardRead == true">
        <a class="nav-link" routerLink="/home">Dashboard</a>
      </li>

      <li class="nav-item active" *ngIf="role?.approvalRead == true">
        <a class="nav-link" routerLink="/order" [queryParams]="{page: '1', status: 'all_orders'}">Approval History</a>
      </li>

      <li class="nav-item active" *ngIf="role?.couponRead == true">
        <a class="nav-link" routerLink="/promocode">Coupon List</a>
      </li>

      <li class="nav-item active dropdown" *ngIf="role?.homePageRead == true">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Categories
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/categories">Categories</a>
          <a class="dropdown-item" routerLink="/products">Products</a>
          <a class="dropdown-item" routerLink="/products/create-products">Create Products</a>
          <a class="dropdown-item" routerLink="/products/create-products" [queryParams]="{type: 'GIFT'}"> Create Gift </a>
          <a class="dropdown-item" routerLink="/products/create-products" [queryParams]="{type: 'DESIGN'}"> Design My Cake </a>
        </div>
      </li>

      <li class="nav-item active dropdown" *ngIf="role?.homePageRead == true">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Homepage
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/create-banner">Banner</a>
          <a class="dropdown-item" routerLink="/short-link">Short-Link</a>
          <a class="dropdown-item" routerLink="/cake-list" [queryParams]="{cake: 'feature'}">Cakes By occasion</a>
          <a class="dropdown-item" routerLink="/cake-list" [queryParams]="{cake: 'trending'}">Trending Cake</a>
          <a class="dropdown-item" routerLink="/cake-list" [queryParams]="{cake: 'bestseller'}">Best Seller</a>
          <a class="dropdown-item" routerLink="/create-testimonial" [queryParams]="{cake: 'bestseller'}">Testmonial </a>
        </div>
      </li>

      <li *ngIf="role?.blogRead == true" class="nav-item active">
        <a class="nav-link" routerLink="/blog">Blog Contents</a>
      </li>

      <li class="nav-item active" *ngIf="role?.deliveryRead == true">
        <a class="nav-link" routerLink="/deliveries"> Delivery Management</a>
      </li>

      <li class="nav-item active" *ngIf="role?.newsLetterRead == true">
        <a class="nav-link" routerLink="/news-letter">News Letter</a>
      </li>

      <li class="nav-item active" *ngIf="role?.userRead == true">
        <a class="nav-link" routerLink="/create-user">User Management</a>
      </li>

      <li class="nav-item active">
        <a class="nav-link" routerLink="/privilages" *ngIf="role?.privilagesRead == true">Privilages</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" *ngIf="role?.homePageNotificationRead == true" routerLink="/home-page-popup">Home Page
          Notification</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" *ngIf="role?.deliveryChargeRead == true" routerLink="/home-page-popup"
          [queryParams]="{type: 'delivery'}"> Delivery Charge</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" *ngIf="role?.reviewRead == true" routerLink="/review-management">Review Management</a>
      </li>

      <li class="nav-item active">
        <a class="nav-link" *ngIf="role?.loyaltyRead == true" routerLink="/loyalty-programme"> Loyalty Programme </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/delivery-time"> Delivery Time </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/image-exporal"> Image/Video Explorer </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/notification-list"> Notifications</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/invoice"> Invoice setting</a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/activity-log"> Activity log </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" (click)="logout()">Logout</a>
      </li>

    </ul>
  </div>
</nav>



<br>