import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../constants/app.constants';
import { PermissionService } from 'src/app/services/core/permission.service';
import { Reviewmodel } from './reviewmodel';

@Component({
  selector: 'app-review-management',
  templateUrl: './review-management.component.html',
  styleUrls: ['./review-management.component.css'],
})
export class ReviewManagementComponent implements OnInit {
  config;
  loader;
  statusType;
  adminAccess;
  paginationDTO : Reviewmodel;
  public page:number=1
  public lastPage:number
  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private router: Router,
    private route: ActivatedRoute,
    public permission: PermissionService
  ) {
    this.paginationDTO = new Reviewmodel();
  }
  mainDomain;
  ngOnInit(): void {
    this.mainDomain = AppConstants.SERVER_CONFIG.DOMAIN;
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: 5,
      totalItems: '',
    };

    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('status')) {
        this.statusType = params['status'];
      } else {
        this.statusType = 'Pending';
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.pageValue(params['page']);
    });
  }

  //pagination
  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get(this.statusType);
      window.scrollTo(0, 0);
    } else {
      this.config.currentPage = 1;
      this.get(this.statusType);
    }
  }

  pageChange(newPage: number) {
    let data:any = document.getElementById('status2')
    var newval = data.value
    console.log(newval);
    
    this.router.navigate(['/review-management'], {
      queryParams: { page: newPage,status:newval },
    });
  }

  getdata2;
  getdata;
  get(type) {
    if (type) {
      this.router.navigate(['/review-management'], {
        queryParams: { page: this.config.currentPage, status: this.statusType },
      });
    }

    this.loader = 'show';
    this.http
      .get('/admin/ratings?status=' + type + '&page=' + this.config.currentPage)
      .subscribe(
        (data) => {
          console.log(data);
          this.getdata2 = data;
          this.getdata = this.getdata2.content;
          this.lastPage = Math.round(this.getdata?.length/5);
          
          console.log(this.lastPage,'last page handled by the front end ------------>');
          
          this.loader = '';
          this.config = {
            currentPage: this.getdata2.currentPage,
            itemsPerPage: this.getdata2.limit,
            totalItems: this.getdata2.totalRatings,
          };
        },
        (error:any) => {
          this.toastr.errorToastr(error?.message ?error?.message :'Something went wrong!' , '');
          this.loader = '';
        }
      );
  }

  //OnChange status
  getChange(type) {
    this.page=1
    this.config = {
      currentPage: 1,
      itemsPerPage: 5,
      totalItems: '',
    };
    this.router.navigate(['/review-management'], {
      queryParams: { page: this.config.currentPage, status: type },
    });
  }

  //change Status of review
  changeStatus(type, id) {
    this.loader = 'show';
    this.http
      .put('/admin/ratings/' + id, JSON.parse('{ "status" : "' + type + '"}'))
      .subscribe(
        (data) => {
          this.get(this.statusType);
          this.loader = '';
          this.toastr.successToastr('Successfully updated', '');
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        }
      );
  }


  paginate(newPage:any){
    let data:any = document.getElementById('status2')
    var newval = data.value
    console.log(newval);
    if(newPage=='add'&& this.page!=this.lastPage){
      this.page++
      this.router.navigate(['/review-management'], {
        queryParams: { page: this.page,status:newval },
      });
    }
    else if( newPage=='sub'&&this.page>1){
      this.page--
      this.router.navigate(['/review-management'], {
        queryParams: { page: this.page,status:newval },
      });
    }
 
    
   
  }
}
