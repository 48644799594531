<section class="offset-width well-white">
    <app-header [pageName]="'Roles'"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>

    <div class="container-fluid">
        <h5>Roles</h5>

        <button type="button" (click)="onClickCreate()" *ngIf="permission.hasPermission(['roles'],['roles_create'])"
            class="button-1 btn-pink btn-margin"> <i class="fa fa-plus"></i> Create </button> <br><br>

        <div class="card col-12 table-bg">
            <div class="card-body "> <br>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ROLE</th>
                                <th scope="col">CREATED DATE AND TIME</th>
                                <th scope="col" *ngIf="permission.hasPermission(['roles'],['roles_update']) || permission.hasPermission(['roles'],['roles_delete'])">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of getdata;let index = index">
                                <td>{{index + 1 }}</td>
                                <td>{{item.role | titlecase}}</td>
                                <td>{{ item.createdOn | date: 'dd-MM-yyyy, h:mm a'}}</td>
                                <td *ngIf="permission.hasPermission(['roles'],['roles_update']) || permission.hasPermission(['roles'],['roles_delete'])">
                                    <a *ngIf="permission.hasPermission(['roles'],['roles_update'])"><img class="pointer" (click)="onClickEdit(item)" src="/assets/images/icon/pencil-2.svg"></a>
                                    <i *ngIf="permission.hasPermission(['roles'],['roles_delete'])" class="fa fa-trash del-icon" (click)="deleteRole(item.id)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> <br><br>



        <!-- Modal -->
        <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <form [formGroup]="form">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"><span> {{isEdited == true ? "Update" : "Create"}}</span> Role</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <label>Role Name <span style="color: red;">*</span></label>
                            <input type="text" formControlName="role" class="form-control box-control"
                                placeholder="Enter Role Name">
                            <div *ngIf="submitted && f.role.errors" class="invalid-text">
                                <div *ngIf="f.role.errors.required"> Role Name is required</div><br>
                            </div><br>


                            <!-- <table class="table table-bordered text-center" formGroupName="privileges">
                                <thead>
                                    <tr>
                                        <th scope="col">Access</th>
                                        <th scope="col">Read</th>
                                        <th scope="col">Write</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Approval History</td>
                                        <td><input class="check-access" formControlName="approvalRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="approvalWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Coupon</td>
                                        <td><input class="check-access" formControlName="couponRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="couponWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Home Page</td>
                                        <td><input class="check-access" formControlName="homePageRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="homePageWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Catelogue Management</td>
                                        <td><input class="check-access" formControlName="catalogueRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="catalogueWrite"
                                                type="checkbox"></td>
                                    </tr>

                                    <tr>
                                        <td>Blog</td>
                                        <td><input class="check-access" formControlName="blogRead" type="checkbox"></td>
                                        <td><input class="check-access" formControlName="blogWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Delivery Management</td>
                                        <td><input class="check-access" formControlName="deliveryRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="deliveryWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Newsletter</td>
                                        <td><input class="check-access" formControlName="newsLetterRead"
                                                type="checkbox"></td>
                                        <td><input class="check-access" formControlName="newsLetterWrite"
                                                type="checkbox"></td>
                                    </tr>

                                    <tr>
                                        <td>User</td>
                                        <td><input class="check-access" formControlName="userRead" type="checkbox"></td>
                                        <td><input class="check-access" formControlName="userWrite" type="checkbox">
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>Home Page Notification</td>
                                        <td><input class="check-access" formControlName="homePageNotificationRead"
                                                type="checkbox"></td>
                                        <td><input class="check-access" formControlName="homePageNotificationWrite"
                                                type="checkbox"></td>
                                    </tr>

                                    <tr>
                                        <td>Delivery Charge & tax</td>
                                        <td><input class="check-access" formControlName="deliveryChargeRead"
                                                type="checkbox"></td>
                                        <td><input class="check-access" formControlName="deliveryChargeWrite"
                                                type="checkbox"></td>
                                    </tr>

                                    <tr>
                                        <td>Review</td>
                                        <td><input class="check-access" formControlName="reviewRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="reviewWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Loyalty</td>
                                        <td><input class="check-access" formControlName="loyaltyRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="loyaltyWrite" type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Privilages</td>
                                        <td><input class="check-access" formControlName="privilagesRead"
                                                type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="privilagesWrite"
                                                type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Dashboard</td>
                                        <td><input class="check-access" formControlName="dashboardRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="dashboardWrite"
                                                type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Instagram</td>
                                        <td><input class="check-access" formControlName="instagramRead" type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="instagramWrite"
                                                type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Order Issue</td>
                                        <td><input class="check-access" formControlName="orderIssueRead"
                                                type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="orderIssueWrite"
                                                type="checkbox">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Pricing Update</td>
                                        <td><input class="check-access" formControlName="pricingUpdateRead"
                                                type="checkbox">
                                        </td>
                                        <td><input class="check-access" formControlName="pricingUpdateWrite"
                                                type="checkbox">
                                        </td>
                                    </tr>


                                </tbody>
                            </table> -->


                        </div>
                        <br><br>
                        <!--modal body end-->
                        <div class="modal-footer">
                            <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">Close</button>
                            <button type="button" class="button-1 btn-pink" (click)="onSubmit()">Submit <i
                                    style="margin-left: 4px;" class="fa fa-long-arrow-right"></i> </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!--modal end-->


    </div>

</section>