import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../service/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(private loaderService: LoaderService) { }
  isLoading: Observable<any>
  ngOnInit(): void {
    this.isLoading = this.loaderService.status.asObservable();
  }

}
