<section class="offset-width well-white">
   <app-header [pageName]="'Coupon Code Transaction Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Coupon Transaction Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="ccol-lg-6 col-xl-3 col-md-6 mb-4 mb_20">
                  <label class="label_style">Coupon Type</label>
                  <ng-multiselect-dropdown [formControl]="filterForm?.get('couponTypeId')"
                     [placeholder]="'Select Coupon Type'" [settings]="selectboxSettings" [data]="couponTypeOptions">
                  </ng-multiselect-dropdown>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb-4  mb_20">
                  <label class="label_style">Status</label>
                  <ng-multiselect-dropdown [formControl]="filterForm?.get('orderStatus')"
                     [placeholder]="'Select Status'" [settings]="selectboxSettings" [data]="statusOptions">
                  </ng-multiselect-dropdown>
               </div>
               <div class="row mt-2">
                  <div class="col-lg-3 col-xl-5 col-md-6 mb-4  text-align-right">
                     <div class="d-flex align-items-center">

                        <button (click)="getCouponTransactionReport(true)"
                           class="btn btn-sm btn-reset btn_search">Search</button>
                        <div class="d-flex align-items-center">
                           <button *ngIf="hasOneValue||reports?.length>0" (click)="reset()"
                              class="btn btn-sm reset_btn tooltip-container ml-4">
                              <img src="../../../assets/images/logo/reset.svg" alt="">
                              <div class="tooltip">
                                 <h1 class="tooltip_style">Reset</h1>
                              </div>
                           </button>
                           <button *ngIf="reports?.length>0" (click)="export()"
                              class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4">
                              <img src="../../../assets/images/logo/order_last.svg" alt="">
                              <div class="tooltip">
                                 <h1 class="tooltip_style">Export</h1>
                              </div>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </form>
      </div>

      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col">Ordered On</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Coupon Type</th>
                        <th scope="col">Coupon Code</th>
                        <th scope="col">Gross Amount(₹)</th>
                        <th scope="col">Discount Amount(₹)</th>
                        <th scope="col">Net Amount(₹)</th>
                        <th scope="col">Delivery Charge</th>
                        <th scope="col">Order Amount(₹)</th>
                        <th scope="col">Order Status</th>

                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of reports">
                        <td class="pl-26">{{ item.orderDate |date:'dd-MM-yyyy hh:mm a' }}</td>
                        <td><a class="linkAdd" style="cursor: pointer;" (click)="navigateToOrder(item.orderId)">{{
                              item.orderId }}</a></td>
                        <td>{{ item.customerName|emptyText }}</td>
                        <td class="text_left">
                           <div class="tooltip-container">
                              <span class="trim_text ">{{ item.couponTypeName }}</span>
                              <div *ngIf="checkNull(item.name)" class="tooltip productName_style">
                                 <h1 class="tooltip_style  mb-0">{{ item.couponTypeName }}</h1>
                              </div>
                           </div>
                        </td>

                        <td><a>{{ item.couponCode }}</a></td>
                        <td class="text-right">{{ item.grossAmount|digit:2 }}</td>
                        <td class="text-right">{{ item.discountAmount|digit:2 }}</td>
                        <td class="text-right">{{ item.netAmount|digit:2 }}</td>
                        <td class="text-right">{{ item.deliveryCharge|digit:2 }}</td>
                        <td class="text-right">{{ item.orderAmount|digit:2 }}</td>
                        <td>
                           <div class="status-payment" [ngClass]="item?.orderStatus">
                              <span>{{ findStatus(item.orderStatus)}}</span>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </table>

               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO COUPON TRANSACTION RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>