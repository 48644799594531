<section class="offset-width well-white">
   <app-header [pageName]="'GST Audit Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">GST Audit Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row align_items_end">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-3 col-xl-2 col-md-3 text-align-right">
                  <button (click)="getGSTReport(true)"
                     class="btn btn-sm btn-reset btn_search product_btn">Search</button>
               </div>
               <div class="col-lg-3  col-xl-3 col-md-3">
                  <button (click)="reset()" *ngIf="hasOneValue||reports?.length>0"
                     class="btn btn-sm reset_btn tooltip-container ml_0 ml-4">
                     <img src="../../../assets/images/logo/reset.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Reset</h1>
                     </div>
                  </button>
                  <button *ngIf="reports?.length>0" (click)="export()"
                     class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-4">
                     <img src="../../../assets/images/logo/order_last.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Export</h1>
                     </div>
                  </button>
               </div>
            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr class="first_row">
                        <th class="border_topApply" scope="col">Bill Date</th>
                        <th scope="col"><span>Bill No</span></th>
                        <th scope="col">Customer</th>
                        <th scope="col">Order ID</th>
                        <th scope="col" class="text-right">Amount(₹)</th>
                        <th scope="col" colspan="2" class="text-center bb_none"><span class="border_class">
                              CGST
                           </span>
                           <div class="d-flex align-items-center justify-content-between pt-10">
                              <div class=" pr_45">%</div>
                              <div class="w80">₹</div>
                           </div>
                        </th>


                        <th scope="col" colspan="2" class="text-center bb_none"><span class="border_class">
                              SGST
                           </span>
                           <div class="d-flex align-items-center justify-content-between pt-10">
                              <div class=" pr_45">%</div>
                              <div class="w80">₹</div>
                           </div>
                        </th>

                        <th scope="col" colspan="2" class="text-center bb_none"><span class="border_class">
                              IGST
                           </span>
                           <div class="d-flex align-items-center justify-content-between pt-10">
                              <div class=" pr_45">%</div>
                              <div class="w80">₹</div>
                           </div>
                        </th>



                        <th scope="col" class="text-right border_topRight"><span>Bill Amount(₹)</span></th>
                     </tr>
                     <!-- <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th class="text-right">%</th>
                        <th class="text-right">₹</th>
                        <th class="text-right">%</th>
                        <th class="text-right">₹</th>
                        <th></th>

                     </tr> -->
                  </thead>


                  <tbody>
                     <tr *ngFor="let item of reports">
                        <td class="pl-26">{{ item.invoiceDate |emptyText }}</td>
                        <td>{{ item.invoiceNumber|emptyText }}</td>
                        <td>{{ item.customerName|emptyText }}</td>
                        <td><a class="linkAdd" style="cursor: pointer;" (click)="navigateToOrder(item.orderId)">{{
                              item.orderId|emptyText }}</a></td>
                        <td class="text-right">{{ item?.taxableAmount|digit }}</td>

                        <!-- CGST -->
                        <td class="text-right border_rightApply"><div class="wSet">
                           {{ CGSTand(item).percent }}
                        </div></td>
                        <td class="text-right"><div class="w90">
                           {{ CGSTand(item).amount }}
                        </div></td>

                        <!-- SGST -->
                        <td class="text-right border_rightApply"><div class="wSet">
                           {{CGSTand(item).percent }}
                        </div></td>
                        <td class="text-right"><div class="w90">
                           {{ CGSTand(item).amount}}
                        </div></td>

                        <!-- IGST -->
                        <td class="text-right border_rightApply"><div class="wSet">
                           {{ IGST(item).percent }}
                        </div></td>
                        <td class="text-right"><div class="w90">
                           {{ IGST(item).amount }}
                        </div></td>


                        <td class="text-right pr-26"><span>{{ item.billAmount|digit }}</span></td>
                     </tr>

                     <tr *ngIf="reports?.length>0">
                        <td class="pl-26"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-right">{{calculation.taxableAmount|digit:2}}</td>
                        <td class="text-right"></td>
                        <td class="text-right">{{calculation.cgst|digit:2}}</td>
                        <td class="text-right"></td>
                        <td class="text-right">{{calculation.sgst|digit:2}}</td>
                        <td class="text-right"></td>
                        <td class="text-right">{{calculation.igst|digit:2}}</td>
                        <td class="text-right pr-26"><span>{{ calculation.billAmount|digit:2 }}</span></td>
                     </tr>
                  </tbody>
               </table>
               <div class="" style="display: flex;justify-content: center;align-items: center;font-weight: 800;"
                  *ngIf="reports.length==0">
                  NO GST RECORDS FOUND
               </div>
            </div>
         </div>
      </div>
   </div>
</section>