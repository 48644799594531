import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})

export class GenerateRouteService {


    constructor(private route: Router) {

    }

    public queueCount = new ReplaySubject<any>(0);

    /** queue count */

    setQueueCount(property: any, storeProp: boolean = false) {
        if (storeProp) {
            localStorage.setItem('queueCount', JSON.stringify(property));
        }
        this.queueCount.next(property);
    }

    getQueueCount() {
        return this.queueCount;
    }

    navigateRouteForGym(portalName, page, idObj) {
        console.log
        if (page == 'order_view') {
            this.route.navigate([`/order-detail/${idObj.orderId}`]);
        }else{
            this.route.navigate(["notification-list"]);
        }
    }


    array = { "key": "id", "value": "[2]", "description": "" }
}