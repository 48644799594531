<section class="offset-width well-white">
    <app-header [pageName]="'Flavour'"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>
    <div class="container-fluid">
        <h5>Flavour</h5>
        <div class="row ">
            <div class="text-end" *ngIf = " permission.hasPermission(['flavours'], ['flavours_create'])">
                <button type="button" (click)="create()"
                class="button-1 btn-20 btn-pink btn-margin"> <i class="fa fa-plus"></i> Create Flavour </button>
            </div>
         
        </div>
        <br>
        <div class="row">
            <div class="card col-12 table-bg bg-table-color">
                <div class="card-body ">
                    <br>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Flavour name </th>
                                    <th scope="col">Created Date And Time</th>
                                    <th *ngIf = " permission.hasPermission(['flavours '], ['flavours_update'])|| permission.hasPermission(['flavours'], ['flavours_delete'])" >ACTION</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="falvourList">
                                <!-- 1 -->
                           <tr *ngFor = " let data of falvourList ; let i = index;">
                            <td>{{i+1}}.</td>
                            <td>{{data?.flavoursName|titlecase}}</td>
                            <td>{{data?.updatedOn|date:"medium"}}</td>
                            <td><img  (click)="edit(data)" *ngIf = " permission.hasPermission(['flavours'], ['flavours_update'])"
                              class="pointer"    src="/assets/images/icon/pencil-2.svg"><i  class="fa fa-trash btn-del"  *ngIf = "permission.hasPermission(['flavours'], ['flavours_delete'])" (click)="deleteFlavour(data?.flavoursId )"></i></td>
                           </tr>
                            </tbody>
                          
                        </table>
                        <h5 *ngIf="falvourList?.length<1" style="text-align: center;">No Data Found...</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- here the modal is present -->
      <!-- Modal -->
      <div
      class="modal fade bd-example-modal-lg"
      id="flavour_modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form [formGroup]="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> <span>{{isEdited!=true ? "Create" : "Update"}}</span> Flavour</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close" (click)="close()"
              >
                <span aria-hidden="true">&times;</span>
              </button>
             
            </div>
            <div class="modal-body ">
                <label>Flavour Name <span style="color: red;">*</span></label><br />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter the flavour name"
                  formControlName="flavoursName"
                />

                <div style="padding-left: 10px;" *ngIf="submitted && f.flavoursName.errors" class="invalid-text">
                    <div *ngIf="f.flavoursName.errors.required">
                       Flavour name  is required
                    </div>
                    <div *ngIf="f.flavoursName.errors.pattern">
                        Please provide a valid flavour name
                    </div>
                </div>

                <input hidden
                type="text"
                class="form-control"
                placeholder=""
                formControlName="flavoursId"
                 style="text-transform:capitalize;"
              />
              </div>
            <!--End Modal Body-->
            <div class="modal-footer">
              <button
                type="button"
                class="button-1 btn-white"
                data-dismiss="modal"
              (click)="close()" >
                Close
              </button>
              <button
                type="button"
                class="button-1 btn-pink"
              (click)=" onSubmit()"
              >
                Submit
                <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
</section>