import { Component, OnInit } from '@angular/core';
import { paginationDTO } from '../paginationDto';
import { Router } from '@angular/router';
import { checkNull } from 'src/app/constants/custom-functions';
import { PuppysCustomValidation, sweetAlert } from 'src/app/shared/validations/puppys-custom';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ReportService } from '../reports/_services/report.service';
import { CustomerDTO } from './customer.dto';
import { CustomerService } from './customer.services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  filterForm: FormGroup;
  pagination: CustomerDTO;
  statusSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: false
  }
  statusOptions: any = [
    { view: 'Active', value: true },
    { view: 'In-active', value: false }
  ]


  reports: any[] = []

  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: CustomerService,
    private router: Router
  ) {
    this.pagination = new CustomerDTO()
  }

  ngOnInit(): void {
    this.intialForm();
    this.getAllCUstomers()
  }

  intialForm() {
    this.filterForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      status: new FormControl('')
    }, { validators: PuppysCustomValidation.DateValidator('startDate', 'endDate') })
  }






  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }


  reset() {
    this.intialForm()
    this.filterForm.updateValueAndValidity();
    this.pagination = new CustomerDTO();
    this.getAllCUstomers()
  }

  getAllCUstomers(filter?: boolean) {

    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter == true) {
      this.pagination.pageNo = 1;
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form.status)) {
        this.pagination.status = form.status.map((ele) => ele?.value)
      } else {
        this.pagination.status = ''
      }


      this.loader.open()
      console.log(this.pagination);

      this.service.getAllCustomers(this.pagination).subscribe((res: any) => {
        this.loader.close();
        this.reports = res?.content ?? [];
        this.pagination.lastPage = Math.ceil(Number(res.totalElements) / this.pagination.pageSize)
        console.log(res);

      }, (err: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again');
        this.pagination.lastPage = 0
        this.reports = []
      })
    } else {
      sweetAlert('error', 'Start date should be before End date')
    }


  }

  checkNull(data) {
    return checkNull(data)
  }

  paginate(event) {
    this.pagination.pageNo = event;
    this.getAllCUstomers()
  }

  updateStatus(data) {
    this.loader.open()
    let statusId = data?.active == true ? '0' : 1
    let userId = data?.userId.split('#')[1]
    this.service.updateStatus({ statusId, userId }).subscribe((res: any) => {
      this.loader.close();
      this.getAllCUstomers();
      this.toastr.successToastr(res?.msg ?? '')
    }, (err) => {
      this.loader.close();
      this.toastr.errorToastr('Try again!')
    })
  }

}
