<section class="offset-width well-white">
   <app-header [pageName]="'Order Report'"></app-header>
   <div class="container-fluid">
      <h5 class="list-item">Order Report</h5>
      <div class="card-body card-design">
         <form [formGroup]="orderForm">
            <div class="row">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date" class="form-control uppercase input_style">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Location</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="couponType" class="form-control input_style form-control-sm" #isPan>
                        <option selected value="">Select Location</option>
                        <option>Chennai
                        </option>
                        <option>Madurai
                        </option>
                     </select>
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Order Type</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="couponType" class="form-control input_style form-control-sm">
                        <option selected value="">Select Order Type</option>
                        <option>
                           Pan India Order
                        </option>
                        <option>Local Order
                        </option>
                     </select>
                  </div>
               </div>
            </div>
            <div class="row align_items_end mt-4">
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Order Status</label>
                     <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                     <select formControlName="couponType" class="form-control input_style form-control-sm">
                        <option selected value="">Select Order Status</option>
                        <option>
                           Accepted
                        </option>
                        <option>Cake is Baking
                        </option>
                        <option>On Transit
                        </option>
                        <option>Delivered
                        </option>
                        <option>Cancelled
                        </option>
                        <option>Abandoned Carts
                        </option>
                     </select>
                  </div>
               </div>
               <div class="col-lg-6 col-xl-5 col-xxl-4 col-md-6">
                  <label class="label_style mb-3">Delivery</label>
                  <div class="d-flex align-items-center">
                     <label class="custom-checkbox">
                     <input name="delay" id="delay" type="checkbox" checked
                        class="clickable-checkbox checkbox_style"> <span
                        class="label-checkbox  checkmark"> </span>Delayed Delivery <br>
                     </label>
                     <label class="custom-checkbox ml-5">
                     <input name="mindnight" id="mindnight" type="checkbox" 
                        class="clickable-checkbox checkbox_style "> <span class="label-checkbox checkmark"></span>
                     Mid-night Delivery<br>
                     </label>
                  </div>
               </div>
               <div class="col-lg-3 col-xl-2 col-md-3 text-align-right">
                  <button  class="btn btn-sm btn-reset btn_search">Search</button>
               </div>
               <div class="col-lg-2 col-xl-1 col-md-2 text-align-right">
                  <button 
                     class="btn  tooltip-container btn-primary ml-4 btn-sm btn-export reset_btn  ml-3">
                     <img src="../../../assets/images/logo/order_last.svg" alt="">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Export</h1>
                     </div>
                  </button>
               </div>
            </div>
         </form>
      </div>
      <div class="card bg-table-color">
         <div class="card-body">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th scope="col">Ordered Date</th>
                        <th scope="col"><span>Order ID</span></th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Order Type</th>
                        <th scope="col">Delivery</th>
                        <th scope="col"><span class="text-right">Amount</span></th>
                        <th scope="col">Status</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let item of tableData">
                        <td class="pl-26">{{ item.orderdDate }}</td>
                        <td class="ordered_id"><span class="pan_india nav-item">{{ item.orderId }}</span></td>
                        <td>{{ item.deliveryDate }}</td>
                        <td>{{ item.customer }}</td>
                        <td>{{ item.orderType }}</td>
                        <td>{{ item.delivery }}</td>
                        <td><span class="text-right">{{ item.amount }}</span></td>
                        <td>
                           <span [ngClass]="'status-payment ' + (
                              item.status === 'Accepted' ? 'Accepted' :
                              item.status === 'Pending' ? 'Pending' :
                              item.status === 'Cake is Baking' ? 'Baking' :
                              item.status === 'On Transit' ? 'Shipped' :
                              item.status === 'Delivered' ? 'Delivered' :
                              item.status === 'Abandoned Carts' ? 'InCart' :
                              ''
                              )">{{ item.status }}</span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
</section>