<nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end">
        <li class="page-item back mar-right" style="cursor: pointer;">
            <a class="page-link disable-one" tabindex="-1" (click)="pagination('sub')">
                << Previous </a>
        </li>
        <li class="page-item"  style="cursor: pointer;" *ngIf="(currentPage == lastPage)&&currentPage-2 > 0">
            <a class="page-link" (click)="paginateByNumber(currentPage - 2)">{{ currentPage - 2 }}</a>
        </li>
        <li class="page-item"  style="cursor: pointer;" *ngIf="currentPage > 1">
            <a class="page-link" (click)="paginateByNumber(currentPage - 1)">{{ currentPage - 1 }}</a>
        </li>
        <li class="page-item active">
            <a class="page-link">{{ currentPage }}</a>
        </li>
        <li class="page-item"  style="cursor: pointer;" *ngIf="(currentPage != lastPage)&&(currentPage + 1<=lastPage)">
            <a class="page-link" (click)="paginateByNumber( currentPage + 1)">{{ currentPage + 1 }} </a>
        </li>
        <li class="page-item"  style="cursor: pointer;" *ngIf="(currentPage != 0 )&& (currentPage == 1)&&(currentPage + 2<=lastPage)">
            <a class="page-link" (click)="paginateByNumber( currentPage + 2)">{{ currentPage + 2 }}</a>
        </li>
        <li class="page-item mar-left" style="cursor: pointer;">
            <a class="page-link disable-one" (click)="pagination('add')">Next >></a>
        </li>
    </ul>
</nav>