<section class="offset-width well-white">
    <div *ngIf="loader" class="loader-spin"> </div>
    <app-header [pageName]="'Pricing Update'"></app-header>
    <div class="container-fluid" style="margin-bottom: 200px;">

        <div class="row">
            <div class="col-lg-2">
                <h5>Pricing Update</h5>
            </div>
            <div class="col-lg-4">
                <input type="text" class="form-control form-control-sm" #uName (keyup)="search(uName.value)"
                    placeholder="Search By Product Name & Sku Code">
            </div>
        </div> <br>

        <table class="table table-bordered text-center">
            <thead>
                <tr>
                    <td class="rw rm">Price Sku Code</td>
                    <td class="rw rm">kg/Quantity</td>
                    <td class="rw rm">Flavour</td>
                    <td class="rw rm">Discount</td>
                    <td class="rw rm">Amount</td>
                    <td></td>
                </tr>
            </thead>
        </table>
        <br>


        <div *ngFor="let item of getdata | paginate: config;let index = index" style="margin-top:-20px">
            <b>{{incrementNumber+index+1}}). {{item.productName | titlecase}} </b> - ( {{item.categoryName | titlecase}}
            ) -
            <b>{{item.sku}}</b> (Updated On : {{item.updatedOn | date: 'medium' }})

            <table class="table table-borderless">
                <tbody>
                    <!--show pricing list-->
                    <tr *ngFor="let item2 of item.productDetails.pricing;  let i = index">
                        <td class="rw">
                            <input type="text" (keyup)="updateItem('sku',$event.target.value, index,i )"
                                [value]="item2?.sku" class="form-control" placeholder="Sku Code">
                        </td>
                        <td class="rw">
                            <select (change)="updateItem('kg',$event.target.value, index,i )" class="form-control"
                                [selected]="item2?.kg">
                                <option [selected]="item2?.kg == 0.5" value="0.5">0.5</option>
                                <option [selected]="item2?.kg == 1" value="1"> 1</option>
                                <option [selected]="item2?.kg == 2" value="2"> 2</option>
                                <option [selected]="item2?.kg == 3" value="3"> 3</option>
                                <option [selected]="item2?.kg == 4" value="4"> 4</option>
                                <option [selected]="item2?.kg == 5" value="5"> 5</option>
                                <option [selected]="item2?.kg == 6" value="6"> 6</option>
                                <option [selected]="item2?.kg == 7" value="7"> 7</option>
                                <option [selected]="item2?.kg == 8" value="8"> 8</option>
                                <option [selected]="item2?.kg == 9" value="9"> 9</option>
                                <option [selected]="item2?.kg == 10" value="10"> 10</option>
                            </select>
                        </td>
                        <td class="rw">
                            <select class="form-control" (change)="updateItem('flavour',$event.target.value, index,i )">
                                <option value="">No Flavour</option>
                                <option *ngFor="let itemFlavour of flavourList"
                                    [selected]="item2?.flavour === itemFlavour" value="{{itemFlavour}}">
                                    {{itemFlavour | titlecase}}</option>
                            </select>
                        </td>
                        <td class="rw"><input type="text" (keyup)="updateItem('discount',$event.target.value, index,i )"
                                [value]="item2?.discount" class="form-control" placeholder="Discount in %">
                        </td>
                        <td class="rw">
                            <i class="fa fa-trash del-icon" (click)="deleteIndex(index,i)"></i>
                            <input style="width: 80%;" type="text"
                                (keyup)="updateItem('price',$event.target.value, index,i )" [value]="item2?.price"
                                class="form-control" placeholder="Enter Amount">
                        </td>
                      
                    </tr>
                    <!--End show pricing list-->

                    <!--add new pricing form-->
                    <tr style="background: #f2f2f2;" *ngIf="visibleIndex === index">
                        <!--show add form-->
                        <td class="rw"> <input [(ngModel)]="addItem.sku" type="text" class="form-control"
                                placeholder="Sku Code"> </td>
                        <td class="rw">
                            <select class="form-control" [(ngModel)]="addItem.kg">
                                <option value="0.5">0.5</option>
                                <option value="1"> 1</option>
                                <option value="2"> 2</option>
                                <option value="3"> 3</option>
                                <option value="4"> 4</option>
                                <option value="5"> 5</option>
                                <option value="6"> 6</option>
                                <option value="7"> 7</option>
                                <option value="8"> 8</option>
                                <option value="9"> 9</option>
                                <option value="10"> 10</option>
                            </select>
                        </td>
                        <td class="rw">
                            <select class="form-control" [(ngModel)]="addItem.flavour">
                                <option value="">No Flavour</option>
                                <option *ngFor="let itemFlavour of flavourList"
                                    [selected]="item2?.flavour === itemFlavour" value="{{itemFlavour}}">
                                    {{itemFlavour | titlecase}}</option>
                            </select>
                        </td>
                        <td class="rw"><input type="text" [(ngModel)]="addItem.discount" [value]="item2?.discount"
                                class="form-control" (keypress)="numberOnly($event)" placeholder="Discount in %">
                        </td>
                        <td class="rw">
                            <i (click)="showSubItem(index)"
                                class="fa fa-times del-icon" *ngIf="visibleIndex === index && rolepermission.hasPermission(['pricing_update'],['pricing_update_delete'])"></i>
                            <input type="text" style="width: 80%;" [value]="item2?.price" [(ngModel)]="addItem.price"
                                class="form-control" (keypress)="numberOnly($event)" placeholder="Enter Amount">
                        </td>
                    </tr>
                    <!--add new pricing form-->
             </tbody>
            </table>

            <h6 class="priceError" *ngIf="priceError">All Field is Required</h6>
            <button class="btn btn-add" *ngIf="visibleIndex != index && rolepermission.hasPermission(['pricing_update'],['pricing_update_create'])" (click)="showSubItem(index)">Add</button>
            <button class="btn btn-add sub" *ngIf="visibleIndex === index && rolepermission.hasPermission(['pricing_update'],['pricing_update_create'])"
                (click)="addPricing(index,item.productId)">Save</button>
            <br>
        </div>

        <!--floating button-->
        <div class="float-btn" *ngIf="rolepermission.hasPermission(['pricing_update'],['pricing_update_create','pricing_update_update'])" (click)="onSubmit()"><span class="float-text">Save</span></div>

        <div>
            <h5 class="noResult" *ngIf="noResult">No Result Found</h5>
        </div>

        <div class="pagination-align">
            <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
                screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
        </div>

    </div>
</section>