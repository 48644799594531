import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/services/core/permission.service';
import { HttpService } from 'src/app/services/http/http.service';
import { paginationDTO } from '../paginationDto';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NavigationService } from 'src/app/services/core/navigation.service';
import { Router } from '@angular/router';

export class changeStatus{
  id:number
  messageRead:number
}

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {
  loader:any;
  pagination :paginationDTO;
  notifications:any=[];
statusChange:changeStatus;
userId:any
public page:number=1
@Output() dataId = new EventEmitter()

  constructor(    
    public permission: PermissionService,
    private http: HttpService,
    private toaster: ToastrManager,
    public navService: NavigationService,
    private router: Router) {

this.pagination= new paginationDTO()
this.statusChange=new changeStatus();

   }

  ngOnInit(): void {
let data= JSON.parse(localStorage.getItem('currentUser'))
this.userId=data?.id;
this.pagination.pageNo=1;
this.pagination.pageSize=10;
    this.getAllNotification()

  }

public lastPage:number
getAllNotification(){
let params = new HttpParams();

for (let data in this.pagination){
  params = params.set(data ,this.pagination[data])
}
this.loader='show'
  this.http.getData('/notificationAlert/Pagination',params).subscribe((res:any)=>{
    console.log(res);
    this.notifications=res?.content;
    this.lastPage=res?.totalPages
    this.navService.notificationBadge.next(this.notifications?.filter(x => Number(x.messageRead) == 0)?.length)
    this.loader=''
  })
}

markRead(data:any,i:any){
  this.statusChange.id=data.id;
  this.statusChange.messageRead=1
  let payload:any = JSON.parse(data.data);
  console.log(payload,'payload')
  if(Number(data.messageRead) != 1 ){
    this.dataId.emit(data)

    let params  = new HttpParams();
  
    for (let data in this.statusChange){
      params = params.set(data,this.statusChange[data])
    }
    this.loader='show'
  this.http.getData('/notificationAlert/admin/notification/changeStatusById',params).subscribe((res:any)=>{
  // this.toaster.toastrManager.infoToastr(res?.msg);
  this.router.navigate([`/order-detail/${payload.orderId}`]);
  this.toaster.infoToastr(res?.msg);
  if(res?.errorCode==200){
    this.loader=''
    this.getAllNotification();
  }
  })
  
  }else{
    this.router.navigate([`/order-detail/${payload.orderId}`]);
  }
  
}
markAllasRead(){
  console.log(this.userId);
  
  let params = new HttpParams({
    fromObject:{
      userId:this.userId
    }
  })
  this.loader='show'
this.http.put('/notificationAlert/admin/notification/userId',params).subscribe((res:any)=>{
console.log(res);
this.loader=''
this.toaster.infoToastr('All message read successfully !')
this.getAllNotification()
})

}

// pagination 
paginate(data:any){

  if(data=='add'&&this.page!=this.lastPage){
    this.page++
    this.pagination.pageNo=this.page;
  this.getAllNotification()

  }
  if(data=='sub'&&this.page!=1){
    this.page--
    this.pagination.pageNo=this.page;
  this.getAllNotification()

  }
}



}
