<section class="offset-width well-white">
   <!-- <div *ngIf="loader" class="loader-spin"> </div> -->
   <app-header [pageName]="'Create General Coupon'"></app-header>
   <form [formGroup]="createForm">
      <div class="container-fluid">
         <div class="row">
            <div>
               <h1>Create Coupon</h1>
            </div>
            <div class="col-lg-4 col-md-6 col-12   mb-3">
               <div class="relative">
                  <label class="label_style">Coupon Type<span class="req">*</span></label>
                  <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                  <select (change)="couponTypeChange()" formControlName="couponType"
                     class="form-control input_style form-control-sm" #isPan>
                     <option selected disabled value="">Select Coupon Type</option>
                     <option [value]="data?.value" *ngFor="let data of couponTypeOptions">{{data?.view}}</option>
                  </select>
               </div>

               <div class="hinata" *ngIf="form.couponType.touched&&form.couponType.invalid">
                  <span *ngIf="form.couponType.errors?.required&&!form.couponType.errors?.required">Please select the
                     coupon type</span>
                  <span *ngIf="form.couponType.errors?.pattern&&!form.couponType.errors?.required">Please select the
                     coupon type</span>
               </div>

            </div>
            <div class="col-lg-4 col-md-6 col-12   mb-3" *ngIf="form?.couponType?.value=='2'">
               <div class="relative">
                  <label class="label_style">Customer Name<span class="req">*</span></label>
                  <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image"> -->
                  <ng-multiselect-dropdown [placeholder]="'Select Customer'" formControlName="customerName"
                     [settings]="customerOptions" [data]="customers">
                  </ng-multiselect-dropdown>
               </div>

               <div class="hinata" *ngIf="form.customerName.touched&&form.customerName.invalid">
                  <span *ngIf="form.customerName.errors?.required&&!form.customerName.errors?.pattern">Please select
                     the
                     customer</span>
                  <span *ngIf="form.customerName.errors?.pattern&&!form.customerName.errors?.required">Please select the
                     customer</span>
               </div>

            </div>
            <div class="col-lg-4 col-md-6 col-12   mb-3" *ngIf="form?.couponType?.value=='3'">
               <div class="relative">
                  <label class="label_style">Product Name<span class="req">*</span></label>
                  <!-- <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image"> -->
                  <ng-multiselect-dropdown [placeholder]="'Select Product'" formControlName="productName"
                     [settings]=" productOptions" [data]="products">
                  </ng-multiselect-dropdown>
               </div>

               <div class="hinata" *ngIf="form.productName.touched&&form.productName.invalid">
                  <span *ngIf="form.productName.errors?.required&&!form.productName.errors?.pattern">Please select
                     the
                     product</span>
                  <span *ngIf="form.productName.errors?.pattern&&!form.productName.errors?.required">Please select the
                     product</span>
               </div>
            </div>

         </div>
         <div class="row border_apply">
            <div class="col-lg-4 col-md-6 col-12   mb-3">
               <div class="relative">
                  <label class="label_style">Coupon Name<span class="req">*</span></label>
                  <input formControlName="couponName" type="text" placeholder="Enter Coupon Name"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>

               <div class="hinata" *ngIf="form.couponName.touched&&form.couponName.invalid">
                  <!-- Required -->
                  <span
                     *ngIf="form.couponName.errors?.required&&(!form.couponName.errors?.pattern&&!form.couponName.errors?.minlength)">Please
                     provide
                     the
                     coupon name</span>
                  <!-- Minimum Length -->
                  <span
                     *ngIf="form.couponName.errors?.minlength&&(!form.couponName.errors?.required&&!form.couponName.errors?.pattern)">Coupon
                     name should have atleast 3 characters</span>
                  <!-- Pattern -->
                  <span
                     *ngIf="form.couponName.errors?.pattern&&(!form.couponName.errors?.required&&!form.couponName.errors?.required)">Please
                     provide a
                     valid coupon name</span>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12   mb-3">
               <div class="relative">
                  <label class="label_style">Coupon Code<span class="req">*</span></label>
                  <input style='text-transform:uppercase' formControlName="couponCode" type="text"
                     placeholder="Enter Coupon Code"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>


               <div class="hinata" *ngIf="form.couponCode.touched&&form.couponCode.invalid">
                  <!-- Required -->
                  <span
                     *ngIf="form.couponCode.errors?.required&&(!form.couponCode.errors?.pattern&&!form.couponCode.errors?.minlength)">Please
                     provide
                     the
                     coupon code</span>
                  <!-- Minimum Length -->
                  <span
                     *ngIf="form.couponCode.errors?.minlength&&(!form.couponCode.errors?.required&&!form.couponCode.errors?.pattern)">Coupon
                     code should have atleast 3 characters</span>
                  <!-- Pattern -->
                  <span
                     *ngIf="form.couponCode.errors?.pattern&&(!form.couponCode.errors?.required&&!form.couponCode.errors?.required)">Please
                     provide a
                     valid coupon code</span>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12   mb-3">
               <label class="label_style">Discount Type<span class="req">*</span></label>
               <div class="d-flex align-items-center pt-12">
                  <label class="custom-checkbox">
                     <input (change)="discountTypeChange()" formControlName="discountType" type="radio" value="1"
                        checked class="clickable-checkbox checkbox_style">
                     <span class="label-checkbox  checkmark">
                     </span>Flat
                  </label>
                  <label class="custom-checkbox">
                     <input (change)="discountTypeChange()" formControlName="discountType" type="radio" value="2"
                        class="clickable-checkbox checkbox_style">
                     <span class="label-checkbox  checkmark">
                     </span>Percentage
                  </label>
               </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12   mb-3" *ngIf="form.discountType.value=='1'">
               <div class="relative">
                  <label class="label_style">Discount Amount<span class="req">*</span></label>
                  <input formControlName="discountAmount" type="number" placeholder="Enter Discount Amount"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>

               <div class="hinata" *ngIf="form.discountAmount.touched&&form.discountAmount.invalid">
                  <!-- Required -->
                  <span
                     *ngIf="form.discountAmount.errors?.required&&(!form.discountAmount.errors?.min&&!form.discountAmount.errors?.max)">Please
                     provide
                     the
                     discount amount</span>
                  <!-- Minimum -->
                  <span
                     *ngIf="form.discountAmount.errors?.min&&(!form.discountAmount.errors?.required&&!form.discountAmount.errors?.max)">Discount
                     amount should be greater than zero</span>
                  <!-- maximum -->
                  <span
                     *ngIf="form.discountAmount.errors?.max&&(!form.discountAmount.errors?.required&&!form.discountAmount.errors?.min)">Discount
                     amount should be less than or equal to 100</span>
               </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12   mb-3" *ngIf="form.discountType.value=='2'">
               <div class="relative">
                  <label class="label_style">Discount Percentage<span class="req">*</span></label>
                  <input formControlName="discountPercentage" type="number" placeholder="Enter Discount Percentage"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>
               <div class="hinata" *ngIf="form.discountPercentage.touched&&form.discountPercentage.invalid">
                  <!-- Required -->
                  <span
                     *ngIf="form.discountPercentage.errors?.required&&(!form.discountPercentage.errors?.min&&!form.discountPercentage.errors?.max)">Please
                     provide
                     the
                     discount percentage</span>
                  <!-- Minimum -->
                  <span
                     *ngIf="form.discountPercentage.errors?.min&&(!form.discountPercentage.errors?.required&&!form.discountPercentage.errors?.max)">Discount
                     percentage should be greater than zero</span>
                  <!-- maximum -->
                  <span
                     *ngIf="form.discountPercentage.errors?.max&&(!form.discountPercentage.errors?.required&&!form.discountPercentage.errors?.min)">Discount
                     percentage should be less than hundred</span>
               </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12   mb-3 mt-5">
               <div class="d-flex align-items-center pt3">
                  <label class="custom-checkbox">
                     <input formControlName="showToUser" type="checkbox" class="clickable-checkbox checkbox_style">
                     <span class="label-checkbox radius-0 checkmark"> </span>Show to User
                  </label>
               </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12   mb-3">
               <div class="relative">
                  <label class="label_style">Description<span class="req">*</span></label>
                  <textarea formControlName="description" placeholder="Enter Description" class="form-control"
                     rows="3"></textarea>
               </div>
               <div class="hinata" *ngIf="form.description.touched&&form.description.invalid">
                  <!-- Required -->
                  <span
                     *ngIf="form.description.errors?.required&&(!form.description.errors?.pattern&&!form.description.errors?.minlength)">Please
                     provide
                     the
                     description</span>
                  <!-- Minimum Length -->
                  <span
                     *ngIf="form.description.errors?.minlength&&(!form.description.errors?.required&&!form.description.errors?.pattern)">Description
                     should have atleast 25 characters</span>
               </div>
            </div>
         </div>
         <div class="row border_apply">
            <label class="label_style font_add pt_3">User Restrictions</label>

            <ng-container *ngIf="form?.couponType?.value!='2'">
               <div class="col-lg-6 col-xl-4 col-md-6 col-12   mt-0 mt_3">
                  <label class="label_style">Customer Eligibility<span class="req">*</span></label>
                  <div class="d-flex align-items-center pt-12">
                     <label class="custom-checkbox">
                        <input type="radio" value="1" formControlName="usageFor"
                           class="clickable-checkbox checkbox_style">
                        <span class="label-checkbox  checkmark">
                        </span>For New User
                     </label>
                     <label class="custom-checkbox">
                        <input formControlName="usageFor" type="radio" value="2"
                           class="clickable-checkbox checkbox_style">
                        <span class="label-checkbox  checkmark">
                        </span>For Everyone
                     </label>
                  </div>
               </div>
            </ng-container>

            <div class="col-lg-6 col-xl-3 col-md-6 col-12   mt-5">
               <div class="d-flex align-items-center">
                  <label class="custom-checkbox">
                     <input formControlName="limitCustomerUsage" type="checkbox"
                        class="clickable-checkbox checkbox_style"> <span class="label-checkbox radius-0 checkmark">
                     </span>Limit to use per customer?
                  </label>
               </div>
            </div>

            <div class="col-lg-6 col-xl-3 col-md-6 col-12   mt-5">
               <div class="d-flex align-items-center">
                  <label class="custom-checkbox">
                     <input formControlName="limitCouponUsage" type="checkbox" (change)="limitChange()"
                        class="clickable-checkbox checkbox_style">
                     <span class="label-checkbox radius-0 checkmark"> </span>Usage Limit for Coupon
                  </label>
               </div>
            </div>
            <div class="col-lg-6 col-xl-2 col-md-6 col-12   mt-0">
               <div class="relative position_grp" *ngIf="form?.limitCouponUsage?.value==true">
                  <label class="label_style mt-0 mb-2">No of Limit's<span class="req">*</span></label>
                  <input formControlName="limit" type="number" placeholder="Enter No of Limit's"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>

               <div class="hinata" *ngIf="form.limit.touched&&form.limit.invalid">
                  <!-- Required -->
                  <span *ngIf="form.limit.errors?.required&&(!form.limit.errors?.min&&!form.limit.errors?.max)">Please
                     provide
                     the
                     limit</span>
                  <!-- Minimum -->
                  <span *ngIf="form.limit.errors?.min&&(!form.limit.errors?.required&&!form.limit.errors?.max)">Limit
                     should be greater than zero</span>
               </div>
            </div>

            <div class="col-lg-6 col-xl-2 col-md-6 col-12" [ngClass]="form?.couponType?.value=='2' ? '':'mt-3'">
               <div>
                  <label class="label_style">Min.Spend<span class="req">*</span></label>
                  <input formControlName="minimumAmount" type="text" placeholder="Enter Min.Spend"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>

               <div class="hinata"
                  *ngIf="form.minimumAmount.touched&&(form.minimumAmount.invalid||createForm.hasError('limit'))">
                  <!-- Required -->
                  <span
                     *ngIf="form.minimumAmount.errors?.required&&(!form.minimumAmount.errors?.min&&!createForm.hasError('limit'))">Please
                     provide
                     the
                     minimum amount</span>
                  <!-- Minimum -->
                  <span
                     *ngIf="form.minimumAmount.errors?.min&&(!form.minimumAmount.errors?.required&&!createForm.hasError('limit'))">Minimum
                     amount should be greater than zero</span>
                  <!-- Limit -->
                  <span
                     *ngIf="createForm.hasError('limit')&&(!form.minimumAmount.errors?.required&&!form.minimumAmount.errors?.min)">Minimum
                     amount should be less than the maximum amount</span>
               </div>
            </div>
            <div class="col-lg-6 col-xl-2 col-md-6 " [ngClass]="form?.couponType?.value=='2' ? '':'mt-3'">
               <div>
                  <label class="label_style">Max.Spend<span class="req">*</span></label>
                  <input formControlName="maximumAmount" type="text" placeholder="Enter Max.Spend"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add">
               </div>

               <div class="hinata"
                  *ngIf="form.maximumAmount.touched&&(form.maximumAmount.invalid||createForm.hasError('limit'))">
                  <!-- Required -->
                  <span
                     *ngIf="form.maximumAmount.errors?.required&&(!form.maximumAmount.errors?.min&&!createForm.hasError('limit'))">Please
                     provide
                     the
                     maximum amount</span>
                  <!-- Minimum -->
                  <span
                     *ngIf="form.maximumAmount.errors?.min&&(!form.maximumAmount.errors?.required&&!createForm.hasError('limit'))">Maximum
                     amount should be greater than zero</span>
                  <!-- Limit -->
                  <span
                     *ngIf="createForm.hasError('limit')&&(!form.maximumAmount.errors?.required&&!form.maximumAmount.errors?.min)">Maximum
                     amount should be greater than the minimum amount</span>
               </div>
            </div>
         </div>
         <div class="row border_apply">
            <div>
               <label class="label_style font_add pt_3">Coupon Duration</label>
            </div>
            <div class="col-md-6 col-lg-3 col-xs-12 col-sm-12 col-12 mt-3">
               <label class="label_style">Start Date and Time<span class="req">*</span></label>
               <div (click)="markAsTouched('startDate')" class="mb-15 relative">
                  <i class='fa-solid fa-business-time fa-9x icon_calender'></i>
                  <input id="startDate" onkeydown="return false"
                     class="form-control search_widthset form-control-sm input_style input-style padding_add"
                     type="text" placeholder="Select Start Date and Time" autocomplete="new-datetimepicker" />
               </div>

               <div class="hinata"
                  *ngIf="form.startDate.touched&&(form.startDate.invalid||createForm.hasError('invalidDate'))">
                  <!-- Required -->
                  <span *ngIf="form.startDate.errors?.required&&!createForm.hasError('invalidDate')">Please
                     select
                     the
                     start date and Time</span>
                  <!-- Minimum -->
                  <span *ngIf="(!form.startDate.errors?.required&&createForm.hasError('invalidDate'))&&!isToday">
                     Start date and time should be before end date and time</span>

                  <span *ngIf="(!form.startDate.errors?.required&&createForm.hasError('invalidDate'))&&isToday">
                     Start date and time should be 10 minutes before end date and time</span>
               </div>

            </div>
            <div class="col-md-6 col-lg-3 col-xs-12 col-sm-12 col-12  mt-3">
               <label class="label_style">End Date and Time<span class="req">*</span></label>
               <div (click)="markAsTouched('endDate')" class="mb-15 relative">
                  <i class='fa-solid fa-business-time fa-9x icon_calender'></i>
                  <input id="endDate" onkeydown="return false"
                     class="form-control  search_widthset form-control-sm input_style input-style padding_add"
                     type="text" placeholder="Select End Date and Time" autocomplete="new-datetimepicker" />
               </div>

               <div class="hinata"
                  *ngIf="form.endDate.touched&&(form.endDate.invalid||createForm.hasError('invalidDate'))">
                  <!-- Required -->
                  <span *ngIf="form.endDate.errors?.required&&!createForm.hasError('invalidDate')">Please
                     select
                     the
                     end date and Time</span>
                  <!-- Minimum -->
                  <span *ngIf="(!form.endDate.errors?.required&&createForm.hasError('invalidDate'))&&!isToday">End date
                     and time
                     should be after start date and time</span>

                  <span *ngIf="(!form.endDate.errors?.required&&createForm.hasError('invalidDate'))&&isToday">End date
                     and time
                     should be 10 minutes after start date and time</span>
               </div>
            </div>
         </div>
         <div *ngIf="permission.hasPermission(['coupon_new'],['coupon_new_create'])" class="col-lg-12 text-right">
            <button (click)="createCoupon()" type="button" class="button-1 my-4 btn-pink btn-create">Submit
               <i class="fa fa-long-arrow-right ml_4"></i> </button>
         </div>
      </div>
   </form>
</section>