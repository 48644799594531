<section class="offset-width well-white">
    <app-header [pageName]="'Image Exporal'"></app-header>

    <div class="container-fluid">
        <h5>Gift bulk upload</h5>

        <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left btn-fontsize" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Preview bulk upload-1
                  </button>
                </h2>
              </div>
          
              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    <div class="card">
                        <div class="preview-card">
                            <div class="row key-value">
            
                                <div class="col-md-3">
                                    <label>Name:</label>
                                    <p>Vijaykumar</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Categories:</label>
                                    <p>Cakes</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Sub categories:</label>
                                    <p>Gifting</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Delivery time:</label>
                                    <p>12:20 AM</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Customer Id:</label>
                                    <p>#8955</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Minimum order:</label>
                                    <p>15</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Delivery type:</label>
                                    <p>COD</p>
                                </div>
                                <div class="col-md-3">
                                    <label>Products points:</label>
                                    <p>10</p>
                                </div>
            
                                <div class="description">
                                    <label>Product Description:</label>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's</p>
                                </div>
            
                                <div class="description">
                                    <label>Notes:</label>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the industry's</p>
                                </div>
                            </div>
            
                            <div class="thumbnail">
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
            
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
            
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
                                <div class="thumb-img">
                                    <img src="assets/images/img/img.png" alt="images">
                                </div>
                            </div>
                        </div>
            
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Flavour</th>
                                    <th>Discount</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>vennila</td>
                                    <td>10%</td>
                                    <td>300</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Chocolate</td>
                                    <td>20%</td>
                                    <td>200</td>
                                </tr>
                            </tbody>
                        </table>
            
                    </div>
                </div>
              </div>
            </div>
            </div>

    </div>

</section>