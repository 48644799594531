import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from 'src/app/services/http/http.service';
import * as _ from 'lodash';
import { paginationDTO } from 'src/app/components/paginationDto';
import { HttpParams } from '@angular/common/http';
import { PermissionService } from 'src/app/services/core/permission.service';

declare var $: any;

@Component({
  selector: 'app-image-exporal',
  templateUrl: './image-exporal.component.html',
  styleUrls: ['./image-exporal.component.css'],
})
export class ImageExporalComponent implements OnInit {
  imgUrl;
  fileImg;
  fileName;
  loader;
  config;
  exporerImages: any = [];
  copied: boolean;
  indexForCopy: any;
  imageForm: FormGroup;
  fileType: boolean = false;
  fileSize: boolean = false;
  @ViewChild('file') file: ElementRef;
  imageId: any;
  searchForm: FormGroup;
  pagination: paginationDTO;
  imageOr: boolean;
  format: string;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.newForm();
    this.newSearchForm();
    this.searchBy();
    this.copied = false;
    this.imgUrl = '';
    this.loader = '';
    this.getExporerImage();
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: '',
    };
  }

  newForm() {
    this.imageForm = this.formBuilder.group({
      imageName: new FormControl('', [Validators.required]),
      imageUrl: new FormControl('', Validators.required),
    });
  }

  newSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: new FormControl(''),
    });
  }

  // uploadFile(event: any) {
  //  this.fileImg = event.target.files[0];
  //  this.fileName = this.fileImg['name'];
  //   if (event.target.files && event.target.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = (event: any) => {

  //       //////upload file and get path///////
  //       if (this.fileImg) {
  //         if(this.fileImg.type == 'image/jpg' || this.fileImg.type == 'image/jpeg' || this.fileImg.type == 'image/jfif' || this.fileImg.type == 'image/png' || this.fileImg.type == 'video/mp4' ){
  //         const formData: FormData = new FormData();
  //         formData.append('image', this.fileImg, this.fileName);
  //         this.loader = 'show';
  //         this.http.post('/uploadimage', formData).subscribe(
  //           (data:any) => {
  //            let fullUrl = data;
  //             this.imgUrl = fullUrl.path;
  //             console.log(this.imgUrl);
  //            this.exporerImge(this.imgUrl);
  //           },
  //           error => {
  //             this.loader = '';
  //             // this.exporerImge('https://i.picsum.photos/id/345/200/300.jpg?hmac=_qOjrd4yW7rtmkQN1PgF8hczgXJezqk92MxgRUzB06s');
  //            // this.toastr.errorToastr('Try Again', '');
  //           });
  //         }else{
  //           this.toastr.infoToastr('Allowed Image Format JPG,JPEG,PNG,JFIF,MB4', '');
  //         }
  //       }
  //     }
 // reader.readAsDataURL(event.target.files[0]);
  //   }
  // }

  checkImage(): boolean {
    return ['png', 'jpg', 'jpeg', 'jfif'].some((ele: any) =>
      this.imgUrl.includes(ele)
    );
  }

  checkVideo() {
    return ['mp4'].some((ele: any) => this.imgUrl.includes(ele));
  }

  uploadFile(event: any) {
    this.fileImg = event.target.files[0];
    this.fileName = this.fileImg['name'];
    console.log(this.fileImg.type, 'this is the type');

    const max_width = Number(1442);
    const max_height = Number(1229);
    const max_size = 50;
    const filetypes = [
      'image/jpeg',
      'image/png',
      'video/mp4',
      'image/jpg',
      'image/jfif',
    ];

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const image = new Image();
      reader.readAsDataURL(this.fileImg);
      if (this.fileImg.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (this.fileImg.type.indexOf('video') > -1) {
        this.format = 'video';
      }
      // file type validation
      if (_.includes(filetypes, this.fileImg.type)) {
        // file size validation 50 mb
        if (this.fileImg.size < 1024 * 1024 * 50) {
          // file dimension validation
          reader.onload = (read: any) => {
            if (
              this.fileImg.type == 'video/mp4' &&
              this.fileImg.size < 1024 * 1024 * 50
            ) {
              this.imageOr = false;
              const formData: FormData = new FormData();
              formData.append('image', this.fileImg, this.fileName),
              formData.append('fileType', 'product');
              formData.append('thumbnail', 'false');
                (this.loader = 'show');
              this.http.post('/uploadimage', formData).subscribe(
                (data: any) => {
                  let fullUrl = data;
                  this.loader = '';
                  this.imgUrl = fullUrl.path;
                  this.imageForm.get('imageUrl').setValue(this.imgUrl);
                  console.log(this.imgUrl);
                  this.file.nativeElement.value = '';
                },
                (error) => {
                  this.loader = '';
                  this.file.nativeElement.value = '';
                  this.imgUrl = '';
                  this.imageForm.get('imageUrl').setValue('');
                  // this.exporerImge('https://i.picsum.photos/id/345/200/300.jpg?hmac=_qOjrd4yW7rtmkQN1PgF8hczgXJezqk92MxgRUzB06s');
                  // this.toastr.errorToastr('Try Again', '');
                }
              );
            } else {
              this.imageOr = true;
              image.src = read.target.result;
              image.onload = (load: any) => {
                const height = load.currentTarget['height'];
                const width = load.currentTarget['width'];
                console.log(height, width);

                if (max_height == height && max_width == width) {
                  const formData: FormData = new FormData();
                  formData.append('image', this.fileImg, this.fileName),
                  formData.append('fileType', 'product');
                  formData.append('thumbnail', 'true');
                    (this.loader = 'show');
                  this.http.post('/uploadimage', formData).subscribe(
                    (data: any) => {
                      let fullUrl = data;
                      this.imgUrl = fullUrl.path;
                      this.loader = '';
                      this.imageForm.get('imageUrl').setValue(this.imgUrl);
                      console.log(this.imageForm.value, 'image value');
                      this.file.nativeElement.value = '';
                    },
                    (error) => {
                      this.loader = '';
                      this.file.nativeElement.value = '';
                      this.imgUrl = '';
                      this.imageForm.get('imageUrl').setValue('');
                      // this.exporerImge('https://i.picsum.photos/id/345/200/300.jpg?hmac=_qOjrd4yW7rtmkQN1PgF8hczgXJezqk92MxgRUzB06s');
                      // this.toastr.errorToastr('Try Again', '');
                    }
                  );
                } else {
                  this.toastr.errorToastr(
                    'Please provide the image with valid dimension' +
                      max_width +
                      '×' +
                      max_height +
                      'pixels'
                  );
                  this.imageForm.get('imageUrl').setValue('');
                  this.file.nativeElement.value = '';
                  this.imgUrl = '';
                }
              };
            }
          };
        } else {
          this.fileSize = true;
          this.toastr.errorToastr(
            'Please provide the image with valid size' + max_size + 'MB'
          );
          this.file.nativeElement.value = '';
          this.imageForm.get('imageUrl').setValue('');
          this.imgUrl = '';
        }
      } else {
        this.fileType = true;
        this.toastr.errorToastr('Please provide the image with valid type');
        this.file.nativeElement.value = '';
        this.imageForm.get('imageUrl').setValue('');
        this.imgUrl = '';
      }
    }
    // /
  }
  exporerImge() {
    let form = this.imageForm;
    if (
      form.get('imageName').value != '' &&
      form.get('imageName').value != null &&
      form.get('imageUrl').value != '' &&
      form.get('imageUrl').value != null
    ) {
      const formData = {
        name: this.imageForm.get('imageName').value,
        image_url: this.imageForm.get('imageUrl').value,
      };
      this.http.post('/admin/imageexploreradd', formData).subscribe(
        (data: any) => {
          this.loader = '';
            this.toastr.successToastr(
              'Image Explorer Upload Successfully.',
              ''
            );
            this.imageForm.get('imageUrl').setValue('');
            this.imageForm.get('imageName').setValue('');
            this.file.nativeElement.value = '';
            this.imgUrl = '';
            $('#createModal').modal('hide');
            this.getExporerImage();
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr(error?.errorCode==409 ? "Name already exists!" :'Something went wrong!', '');
        }
      );
    } else {
      this.toastr.errorToastr('Please fill the mandatory field');
    }
  }

  getExporerImage() {
    this.http.get('/admin/getallimage').subscribe(
      (data: any) => {
        this.exporerImages = data;
        console.log(this.exporerImages, ' this.exporerImages ');
        this.loader = '';
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  searchBy() {
    this.loader = 'show';
    this.searchForm.get('search').valueChanges.subscribe((res: any) => {
      this.pagination = new paginationDTO();
      this.pagination.search = res;
      console.log(this.pagination.search);

      let params = new HttpParams({
        fromObject: {
          search: this.pagination.search,
        },
      });
      this.http.getData('/admin/searchAll', params).subscribe((res: any) => {
        this.exporerImages = res;
        this.loader = '';
      });
    });
  }

  pageChange(newPage: number) {
    this.loader = 'show';
    this.config.currentPage = newPage;
    this.http.get(`/page/${this.config.currentPage}`).subscribe(
      (data: any) => {
        this.exporerImages = data;
        console.log(this.exporerImages, ' this.exporerImages ');
        this.loader = '';
        this.config.currentPage = data?.currentPage;
        (this.config.itemsPerPage = 10),
          (this.config.totalItems = data?.totalItems);
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  // submit the form

  submit() {
    if (this.imageForm.valid) {
      console.log(this.imageForm.value);
      this.imgUrl = '';
    } else {
      console.log('not valid', this.imageForm.value);
      this.imgUrl = '';
    }
  }

  //Copy Text
  copyText(val: string, i) {
    console.log(val, i, 'for the image video explorer');
let newVal = val.split('com');
    this.indexForCopy = i;
    let value = `${newVal[1]}`;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    // console.log(selBox.value, 'substring(35)');

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

  videoOrImg(item) {
    let dataType = item.split('.').splice(-1);
    if (dataType == 'mp4') {
      return 'video';
    } else {
      return 'img';
    }
  }
  onClickCreate() {
    $('#createModal').modal('show');
  }
  onClickEdit(element, index) {
    $('#editModal').modal('show');
    console.log(element, index);
    this.imageForm.get('imageName').setValue(element.name);
    this.imageForm.get('imageUrl').setValue(element.image_url);
    this.imgUrl = element.image_url;
    this.imageId = element.id;
  }

  updateImage() {
    if (this.imageForm.valid) {
      if (
        this.imageForm.get('imageName').value != null &&
        this.imageForm.get('imageName').value != '' &&
        this.imageForm.get('imageUrl').value != null &&
        this.imageForm.get('imageUrl').value != '' &&
        this.imageId != '' &&
        this.imgUrl != ''
      ) {
        this.loader = 'show';
        // let formData = new FormData()
        const formData = {
          id: this.imageId,
          image_url: this.imageForm.get('imageUrl').value,
          name: this.imageForm.get('imageName').value,
        };
        this.http
          .put('/admin/updateImageExplorer', formData)
          .subscribe((res: any) => {
            if (res.errorCode == '200') {
              this.getExporerImage();
              this.toastr.successToastr(' Updated successfully', '');
              this.close();
              this.loader = '';
            } else {
              this.toastr.errorToastr(res.message);
            }
          });
      }
    } else {
      this.toastr.errorToastr('Please fill the mandatory fields');
    }
  }

  deleteImageList(image, index) {
    this.loader = 'show';
    this.http.delete(`/admin/imageurl/${image.id}`).subscribe((res: any) => {
      if (res.errorCode == '200') {
        this.getExporerImage();
        this.loader = '';
        this.toastr.successToastr(res.message);
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  clearForm() {
    // $('#createModal').modal('hide');
    // $('#editModal').modal('hide');
    this.imageForm.get('imageUrl').setValue('');
    // this.imageForm.get('imageName').setValue('')
    this.file.nativeElement.value = '';
    this.imgUrl = '';
  }

  close() {
    $('#createModal').modal('hide');
    $('#editModal').modal('hide');
    this.imageForm.get('imageUrl').setValue('');
    this.imageForm.get('imageName').setValue('');
    this.file.nativeElement.value = '';
    this.imgUrl = '';
  }
}
