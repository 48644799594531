<section class="offset-width well-white">
  <app-header [pageName]="'Notifications'"></app-header>
  <div *ngIf="loader" class="loader-spin"></div>

  <div class="container-fluid">
    <h5>Notifications</h5>

    <button type="button" *ngIf="
        permission.hasPermission(['notification'], ['notification_status'])
      " class="button-1 btn-pink btn-margin" (click)="markAllasRead()">
      Mark all as read
    </button>
    <br /><br />

    <div class="card col-12 table-bg">
      <div class="card-body">
        <br />
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">TITLE</th>
                <th scope="col">DESCRPTION</th>
                <th scope="col">CREATED ON</th>
                <th scope="col">STATUS</th>
              </tr>
            </thead>
            <tbody *ngFor="let data of notifications; let i = index">
              <tr (click)="markRead(data, i)">
                <td>{{ data?.title }}</td>
                <td>{{ data?.body }}</td>
                <td>{{ data?.createdOn }}</td>
                <td *ngIf="data?.messageRead == 1">
                  <span class="Active status-payment">Read</span>
                </td>
                <td *ngIf="data?.messageRead == 0">
                  <span class="In-Active status-payment">Un-Read</span>
                </td>
              </tr>
            </tbody>
          </table>
          <h4 style="text-align: center" *ngIf="notifications?.length <= 0">
            No Data Found
          </h4>
        </div>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li class="page-item back">
            <a class="page-link disable-one" tabindex="-1" (click)="paginate('sub')">
              << Previous </a>
          </li>
          <li class="page-item" *ngIf="page == lastPage">
            <a class="page-link">{{ page - 2 }}</a>
          </li>
          <li class="page-item" *ngIf="page > 1">
            <a class="page-link">{{ page - 1 }}</a>
          </li>
          <li class="page-item active">
            <a class="page-link">{{ page }}</a>
          </li>
          <li class="page-item" *ngIf="page != lastPage">
            <a class="page-link">{{ page + 1 }}</a>
          </li>
          <li class="page-item" *ngIf="page != 0 && page == 1">
            <a class="page-link">{{ page + 2 }}</a>
          </li>
          <li class="page-item">
            <a class="page-link disable-one" (click)="paginate('add')">Next >></a>
          </li>
        </ul>
      </nav>
    </div>

    <br /><br />

    <!-- Modal -->

    <!--modal end-->
  </div>
</section>