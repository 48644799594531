<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"></div>
  <app-header [pageName]="'Delivery Time'"></app-header>

  <div class="container-fluid">
    <div class="row text-list">
      <h5 class="text-name">Delivery Time</h5>

      <div class=" text-end">
        <button *ngIf="permission.hasPermission(['delivery_time'],['deliveryTime_create'])" type="button"
          (click)="onClickCreate()" class="button-1 btn-pink btn-margin">
          <i class="fa fa-plus"></i> Create
        </button>
      </div>

      <div class="card col-12 table-bg">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-2 time-new">
              <h5>Delivery Time List</h5>
            </div>
            <!-- <div class="col-lg-4">
              <input type="text" class="form-control form-control-sm" #uName placeholder="Search By Name & Short Code">
            </div> -->
          </div>
          <br />
          <br />

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">DELIVERY NAME</th>
                  <th scope="col">SHORT CODE</th>
                  <th scope="col">FROM TIME</th>
                  <th scope="col">TO TIME</th>
                  <th scope="col">BAKING TIME</th>
                  <th scope="col">ENABLE CUT-OFF</th>
                  <th scope="col">CUT-OFF TIME</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of getdata2">
                  <td>
                    <a href="#" target="_blank"> {{ data?.deliveryName }} </a>
                  </td>
                  <td>{{ data?.shortCode }}</td>
                  <td>{{ data?.fromTime }}</td>
                  <td>{{ data?.toTime }}</td>
                  <td>{{ data?.backingTime ? data?.backingTime : '---'}} <span
                      *ngIf="data?.backingTime > 1 && data?.backingTime !=null">Hrs</span>
                    <span *ngIf="data?.backingTime <= 1 && data?.backingTime !=null">Hr</span>
                  </td>
                  <td>{{ data?.status == "1" ? "Yes" : "No" }}</td>
                  <td>{{ data?.cutOffTime }}</td>
                  <td
                    *ngIf="permission.hasPermission(['delivery_time'],['deliveryTime_update']) || permission.hasPermission(['delivery_time'],['deliveryTime_delete'])">
                    <span *ngIf="permission.hasPermission(['delivery_time'],['deliveryTime_update'])"
                      (click)="onClickEdit(data)">
                      <img class="pointer" src="/assets/images/icon/pencil-2.svg" /></span>
                    <i *ngIf="permission.hasPermission(['delivery_time'],['deliveryTime_delete'])"
                      class="fa fa-trash del-table" (click)="deleteData(data?.deliverytimeId)" aria-hidden="true"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <!-- <h5 class="noResult" *ngIf="noResult">No Result Found</h5> -->
            </div>
            <div class="pagination-align">
              <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
                screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade bd-example-modal-lg show" id="createModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ isEdited == true ? "Update" : "Create" }} Delivery Time
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="DeliverytimeForm">
          <div class="modal-body">
            <!--row-->
            <div>
              <div class="row">
                <div class="col-lg-6">
                  <div>
                    <label>Delivery Name <span style="color: red;">*</span></label><br />
                    <input type="text" class="form-control" formControlName="deliveryTimeName"
                      placeholder="Enter Delivery Name" />
                  </div>
                  <div class="invalid-text">
                    <div *ngIf="
                        (submitted && f.deliveryTimeName.errors?.required) ||
                        (f.deliveryTimeName.errors?.required &&
                          f.deliveryTimeName.touched)
                      ">
                      Delivery Name is required
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <label>Short code<span style="color: red;">*</span></label>
                  <input type="text" class="form-control" formControlName="shortCode" placeholder="Enter Short Code" />
                  <div class="invalid-text">
                    <div *ngIf="
                        (submitted && f.shortCode.errors?.required) ||
                        (f.shortCode.errors?.required && f.shortCode.touched)
                      ">
                      Short Code is required
                    </div>
                    <div *ngIf="
                        (submitted &&
                          uniqueCode &&
                          !f.shortCode.errors?.required) ||
                        (uniqueCode && !f.shortCode.errors?.required)
                      ">
                      Delivery Name & Short Code is same, Please enter unique
                      code
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-lg-6">
                  <div>
                    <label>From Time <span style="color: red;">*</span></label><br />
                    <input type="time" class="form-control" formControlName="fromTime" id="from-time" />
                  </div>
                  <div class="invalid-text">
                    <div *ngIf="
                        (submitted && f.fromTime.errors?.required) ||
                        (f.fromTime.errors?.required && f.fromTime.touched)
                      ">
                      From Time is required
                    </div>
                  </div>
                  <br />
                </div>
                <div class="col-lg-6">
                  <div>
                    <label>To Time <span style="color: red;">*</span></label><br />
                    <input type="time" class="form-control" formControlName="toTime" id="to-time" />
                  </div>
                  <div class="invalid-text">
                    <div *ngIf="
                        (submitted && f.toTime.errors?.required) ||
                        (f.toTime.errors?.required && f.toTime.touched)
                      ">
                      To Time is required
                    </div>
                    <div *ngIf="
                        (submitted &&
                          fromToTime &&
                          !f.toTime.errors?.required) ||
                        (fromToTime && !f.toTime.errors?.required)
                      ">
                      please choose the to time greater than from time
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div>
                    <label>Baking Time (Hrs) <span style="color: red;">*</span></label><br />
                    <input type="number" class="form-control" formControlName="backingTime" id="backing-time" />
                  </div>
                  <div class="invalid-text">
                    <div *ngIf="
                        (submitted && f.backingTime.errors?.required) ||
                        (f.backingTime.errors?.required && f.backingTime.touched)
                      ">
                      Baking Time is required
                    </div>
                    <div *ngIf="
                        (submitted && f.backingTime.errors?.pattern) ||
                        (f.backingTime.errors?.required && f.backingTime.touched)
                      ">
                      Provide Valid Baking Time
                    </div>
                    <div *ngIf="f?.backingTime?.errors?.min">
                      Baking Time cannot be negative
                    </div>
                  </div>
                  <br />
                </div>
              </div>



              <div>
                <label>Enable Cut-off <span style="color: red;">*</span></label><br />
                <div class="flex">
                  <div class="mr-10 flex-center" *ngFor="let data of enableCutOff">
                    <input type="radio" class="radio-cutoff" [value]="data.value" (click)="enableCutOf(data.label)"
                      formControlName="enableCutOff" />
                    <span class="radio-label">{{ data.label }}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="submitted && f.enableCutOff.errors" class="invalid-text">
                <div *ngIf="f.enableCutOff.errors.required">
                  Enable Cut-off is required
                </div>
              </div>
              <br />

              <div *ngIf="f.enableCutOff.value == '1'">
                <label>Booking Cut-off Time</label><br />
                <input type="time" formControlName="cutOffTime" class="form-control" id="cutoff-time" />
                <div class="invalid-text">
                  <div *ngIf="
                      (submitted && f.cutOffTime?.errors?.required) ||
                      (f.cutOffTime?.errors?.required && f.cutOffTime?.touched)
                    ">
                    Cut-off is required
                  </div>
                  <div *ngIf="
                      (submitted &&
                        enableCutOffTime &&
                        !f.cutOffTime?.errors?.required) ||
                      (enableCutOffTime && !f.cutOffTime?.errors?.required)
                    ">
                    please choose the Cut-off time between from time and to time
                  </div>
                </div>
              </div>
              <div class="static-notes mt-3">
                <h1 class="mb-3 font-bold">What is the use of cut-off time and how does it work with minimum delivery
                  interval and without it?</h1>
                <h2 class="mb-2">1.Cut-off time is used to disable all the remaining time slots of the same day delivery
                  for specific visitors.</h2>
                <h3 class="">2. <span>For example,</span> if you have a working day from 10:00 AM to 5:00 PM and you
                  want to get an order for same-day delivery till 4.00 PM. By selecting this option will not allow
                  getting order delivery after 4.00 PM and will hide the time slots for the day and the next day all the
                  time slots will be enabled.</h3>
              </div>

              <br />
            </div>
            <!-- <div class="invalid-text">
              <div
                *ngIf="submitted && f.cutOffTime?.errors?.required || f.cutOffTime?.errors?.required && f.cutOffTime?.touched">
                Cut-off is required
              </div>
              <div
                *ngIf="submitted && enableCutOffTime && !f.cutOffTime?.errors?.required || enableCutOffTime && !f.cutOffTime?.errors?.required">
                please choose the Cut-off time between from time and to time
              </div>
            </div><br> -->
          </div>
          <!--end Modal body-->
        </form>
        <div class="modal-footer">
          <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">
            Close
          </button>
          <button type="button" class="button-1 btn-pink" (click)="onSubmit()">
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>