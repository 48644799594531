import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-us-report',
  templateUrl: './contact-us-report.component.html',
  styleUrls: ['./contact-us-report.component.css']
})
export class ContactUsReportComponent implements OnInit {
  contactForm: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }
  tableData = [
    {
      createdDate: '26-02-2024',
      category: 'Classic Chocolate Cake',
      productName: 'Belgian White Forest Cake',
      sku: 'CLC-NR9SYGU7A5-0000',
      orderType: 'Pan India',
      deliveryStatus: 'Off',
      deliveryType: '-',
      status: 'Active'
    },
    {
      createdDate: '16-02-2024',
      category: 'Themed Cake',
      productName: 'Chocolate Cake',
      sku: 'CLC-NR9SYGU7A5-0000',
      orderType: 'Local',
      deliveryStatus: 'On',
      deliveryType: 'Pickup From Store',
      status: 'Inactive'
    },
    {
      createdDate: '22-04-2024',
      category: 'Classic Chocolate Cake',
      productName: 'Belgian White Forest Cake',
      sku: 'BWF-340095',
      orderType: 'Pan India',
      deliveryStatus: 'Off',
      deliveryType: '-',
      status: 'Active'
    },
    {
      createdDate: '13-01-2024',
      category: 'Themed Cake',
      productName: 'Belgian White Forest Cake',
      sku: 'ATC-CTBUE5H1SU-0000',
      orderType: 'Local',
      deliveryStatus: 'On',
      deliveryType: 'Door Delivery',
      status: 'Inactive'
    }
  ];
}
