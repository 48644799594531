import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppConstants } from 'src/app/constants/app.constants';
import { PermissionService } from 'src/app/services/core/permission.service';
import { HttpService } from 'src/app/services/http/http.service';
declare var $: any;
@Component({
  selector: 'app-short-link',
  templateUrl: './short-link.component.html',
  styleUrls: ['./short-link.component.css']
})
export class ShortLinkComponent implements OnInit {
  getdata = []
  shortLinkForm: FormGroup;
  displayName = 'Create';
  loader = '';
  constructor(public http: HttpService,
    public toastr: ToastrManager,
    public rolepermission: PermissionService) { }

  ngOnInit(): void {
    
    this.getList();
    this.form()
  }
  
  getURL(data) {
    
    return data.length > 25 ? data.slice(0, 25).concat('...') : data;
  }
  
  getList() {
    this.loader = 'show';
    this.http.get('/admin/shortlink/searchAll')
      .subscribe(
        (res: any) => {
          if (res?.content) {
            this.loader = '';
            this.getdata = res?.content;
          } else {
            this.loader = '';
            this.getdata =[];
            this.toastr.errorToastr('Try Again', '');
          }

        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr(error?.msg ? error?.msg : 'Try Again', '');
        }
      );

  }
  form() {
    this.shortLinkForm = new FormGroup({
      title: new FormControl('', Validators.required),
      slug: new FormControl('', Validators.required),
      website: new FormControl('', [Validators.required, Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageDev : AppConstants.SERVER_CONFIG.localDev),
      ,]),
      mobile: new FormControl('', [Validators.required, Validators.pattern(AppConstants.SERVER_CONFIG.USE_URL == 'STAGING' ? AppConstants.SERVER_CONFIG.stageMob : AppConstants.SERVER_CONFIG.localMob)]),
      shortLinkId:new FormControl('')
    })
  }
  createShortLink(data?) {
    !data ? this.shortLinkForm.reset() : '';
    this.displayName = data ? 'Edit' : 'Create';
    $('#createModal').modal('show');
    if (this.displayName == 'Edit') {
      this.shortLinkForm.patchValue({
        title: data?.title,
        slug: data?.slug,
        website: data?.desktopVersionUrl,
        mobile: data?.mobileVersionURL,
        shortLinkId: data?.shortLinkId
      })
    }
  }

  submit() {
    if (this.shortLinkForm.valid) {
      let form = this.shortLinkForm.value
      let obj = {

        "desktopVersionUrl": form?.website,
        "mobileVersionURL": form?.mobile,
        "shortLinkId": this.displayName == 'Edit' ? form?.shortLinkId:null,
        "slug": form?.slug,
        "title": form?.title

      }
      this.loader = 'show';
      if (this.displayName == 'Create') {
        this.http.post('/admin/create/shortlink', obj)
          .subscribe(
            (res: any) => {
              if (res?.msg == "Success") {
                this.loader = '';
                this.toastr.successToastr('Successfully Created', '');
                $('#createModal').modal('hide');
                this.getList();
              } else {
                this.loader = '';
                this.toastr.errorToastr('Try Again', '');
              }

            },
            (error) => {
              this.loader = '';
              console.log(error,'error')
              this.toastr.errorToastr(error?.msg ? error?.msg : 'Try Again', '');
            }
          );
      } else {
        this.http.put('/admin/update/shortlink', obj)
          .subscribe(
            (res: any) => {
              if (res?.msg == "Success") {
                this.loader = '';
                this.toastr.successToastr('Successfully Updated', '');
                $('#createModal').modal('hide');
                this.getList();
              } else {
                this.loader = '';
                this.toastr.errorToastr('Try Again', '');
                
              }

            },
            (error) => {
              this.loader = '';
              this.toastr.errorToastr(error?.msg ? error?.msg : 'Try Again', '');
            }
          );
      }
     

    } else {
      this.shortLinkForm.markAllAsTouched();
      this.shortLinkForm.updateValueAndValidity();
    }
  }

  
  deleteShortLink(id) {
    this.loader = 'show';
    this.http.delete(`/admin/delete/shortlink/${id}`)
      .subscribe(
        (res: any) => {
          if (res?.msg == "Success") {
            this.loader = '';
            this.toastr.successToastr('Successfully Deleted', '');
            this.getList();
          } else {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }

        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr(error?.msg ? error?.msg : 'Try Again', '');
        }
      );
  }
  
  close() {
    $('#createModal').modal('hide');
  }

  copyToClipBoard(str) {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.toastr.successToastr('Copied');
  }

  generateSlug(value:string){
    if(value != null && value != undefined && value != ""){
      let title = value.replace(/[\s~`!@#$%^&*()_+\-={[}\]|\\:;"'<,>.?/]+/g, '-')
      this.shortLinkForm.get('slug').setValue(title);
    }else{
      this.shortLinkForm.get('slug').setValue(value);
    }
  }
}
