<section class="offset-width well-white">
  <div *ngIf="loader" class="loader-spin"></div>
  <app-header [pageName]="'Delivery Management'"></app-header>
  <div class="container-fluid">
    <h5> Delivery Management</h5>
    <button type="button" *ngIf="permission.hasPermission(['delivery'], ['delivery_create'])" (click)="create()"
      class="button-1 btn-pink btn-margin">
      <i class="fa fa-plus"></i> Create
    </button>
    <br /><br />

    <div class="card bg-table-color" *ngIf="permission.hasPermission(['delivery'], ['delivery_view'])">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">PINCODE</th>
                <th scope="col">CITY</th>
                <!-- <th scope="col">MINIMUM AMOUNT</th> -->
                <th style="text-align: right;" scope="col">DELIVERY CHARGE</th>
                <th scope="col">NIGHT DELIVERY AVAILABILITY</th>
                <th style="text-align: right;" scope="col">NIGHT DELIVERY CHARGE </th>
                <th *ngIf="
                    permission.hasPermission(
                      ['delivery'],
                      ['delivery_view', 'delivery_delete', 'delivery_update']
                    )
                  " scope="col">
                  ACTION
                </th>
              </tr>
            </thead>
            <tr *ngFor="let item of getdata; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item.pincode }}</td>
              <td>{{ item.city }}</td>
              <!-- <td>{{item.minimumAmount}}</td> -->
              <td style="text-align: right;">₹ {{ item.deliveryCharge ? (item.deliveryCharge.toFixed(2)):'-' }}</td>
              <td *ngIf="item.enablemidDelivery == true">YES</td>
              <td *ngIf="
                  item.enablemidDelivery == false ||
                  item.enablemidDelivery == null
                ">
                NO
              </td>
              <td style="text-align: right;"> {{ item.middeliveryCharge!=null ? '₹'+( item.middeliveryCharge?.toFixed(2)
                ):'-' }}</td>
              <td *ngIf="adminAccess.privileges.deliveryWrite">
                <img class="pointer" src="/assets/images/icon/pencil-2.svg" *ngIf="
                    permission.hasPermission(['delivery'], ['delivery_update'])
                  " (click)="onClickEdit(item)" />
                <i class="fa fa-trash del-icon" *ngIf="
                    permission.hasPermission(['delivery'], ['delivery_delete'])
                  " (click)="deleteData(item.deliveryId)"></i>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <form [formGroup]="form">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> <span> {{isEdited==true ? "Update" : "Create"}}</span>
                Delivery</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <label>Delivery Pincode <span style="color: red;">*</span></label><br />
              <input type="number" class="form-control" placeholder="Enter Delivery Pincode"
                formControlName="pincode" />
              <div *ngIf="submitted && f.pincode.errors" class="invalid-text">
                <div *ngIf="f.pincode.errors.required">Pincode is required</div>
                <div *ngIf="f.pincode.errors.pattern">
                  Enter Valid 6 digit pincode
                </div>
              </div>
              <br />

              <label>Delivery Area <span style="color: red;">*</span></label><br />
              <input type="text" class="form-control" placeholder="Enter Delivery area" formControlName="city" />
              <div *ngIf="submitted && f.city.errors" class="invalid-text">
                <div *ngIf="f.city.errors.required">City is required</div>
              </div>
              <br />

              <label>Delivery Charge (In Rupees) <span style="color: red;">*</span></label><br />
              <input type="number" class="form-control" placeholder="Enter Delivery Charge"
                formControlName="deliveryCharge" />
              <div *ngIf="submitted && f.deliveryCharge.errors" class="invalid-text">
                <div *ngIf="f.deliveryCharge.errors.required">
                  This field is required
                </div>
                <div *ngIf="f.deliveryCharge.errors.min">
                  Delivery charge cannot be negative
                </div>
              </div>
              <br />

              <!-- <label>Minimum Delivery Charge</label><br>
              <input type="number" class="form-control" placeholder="Enter Minimum Delivery Charge"
                formControlName="minimumAmount">
              <div *ngIf="submitted && f.minimumAmount.errors" class="invalid-text">
                <div *ngIf="f.minimumAmount.errors.required">
                  This field is required
                </div>
              </div>
              <br> -->

              <label>Mid Night Delivery <span style="color: red;">*</span></label><br />
              <select class="form-control" formControlName="enablemidDelivery"
                (change)="midDelivery($event.target.value)">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="submitted && f.enablemidDelivery.errors" class="invalid-text">
                <div *ngIf="f.enablemidDelivery.errors.required">
                  This field is required
                </div>
              </div>
              <br />
              <!-- -->
              <div *ngIf="f.enablemidDelivery.value =='true'">
                <label> Mid-Night Delivery charge (In Rupees) <span style="color: red;">*</span></label><br />
                <input type="number" class="form-control" placeholder="Enter Mid-Night Delivery charge"
                  formControlName="middeliveryCharge" />
                <div *ngIf="submitted && f.middeliveryCharge.errors" class="invalid-text">
                  <div *ngIf="f.middeliveryCharge.errors.required">
                    This field is required
                  </div>
                </div>
                <br />
              </div>
            </div>
            <!--End Modal Body-->
            <div class="modal-footer">
              <button type="button" class="button-1 btn-white" data-dismiss="modal" (click)="close()">
                Close
              </button>
              <button type="button" class="button-1 btn-pink" (click)="onSubmit()">
                Submit
                <i style="margin-left: 4px" class="fa fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>