import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { $ } from 'protractor';
import { ConfigService } from 'src/app/services/core/config.service';
import { PermissionService } from 'src/app/services/core/permission.service';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-roles-permission',
  templateUrl: './roles-permission.component.html',
  styleUrls: ['./roles-permission.component.css']
})
export class RolesPermissionComponent implements OnInit {
  loader = '';
  rolesPermissions: any = [];
  roles: any = [];
  data: any;
  menus: any = [];
  permList = new Set();
  selectedData = new Set();
  selectedRole = new Set();
  updatePermission: any = [];
  constructor(
    private toaster: ToastrManager,
    public configService: ConfigService,
    public permission: PermissionService,
    public http: HttpService) { }


    ngOnInit() {
      this.getData();
    }
  
    checkSelected() {
      this.roles.forEach(el => {
        let perm = Array.from(this.selectedData).filter(val => val['id'] === el['id']);
        let uniq = this.menus.filter(({ menuId: id1 }) => perm.some(({ menuId: id2 }) => id2 === id1));
        let chLength = this.menus.length == uniq.length ? true : false;
        if (chLength) {
          let roleId = el.id;
          this.selectedRole.add(roleId);
        }
      })
    }
  
    getData() {
      this.loader = 'show';
      this.http.get('/admin/roles/getAllRolePermission').subscribe((data:any) => {
        this.data = data.Content;
        this.menus = this.data?.menu;
        this.roles = this.data?.role;
        this.rolesPermissions = this.data?.perm;
        this.makePermList();
        if (data.errorCode == 200) {
          this.loader = '';
        } else {
          this.loader = '';
          this.toaster.errorToastr('Try Again', '');
        }
      });
    }
  
    update() {
      this.updatePermission = [];
      let Permitarr = Array.from(this.permList);
      let obj: any;
      Permitarr.forEach((res: string) => {
        let splitmenu = res.split(',');
        obj = {
          menu: {
            menuId: splitmenu[0]
          },
          permissionId: this.rolesPermissions.permissionId,
          role: {
            id: splitmenu[1]
          }
        }
        this.updatePermission.push(obj);
      });
      this.loader = 'show';
      this.http.put('/admin/roles/updatePermission',this.updatePermission).subscribe((data:any) => {
        this.data = data.Content;
        if (data.errorCode == 200) {
          this.toaster.successToastr("Role Permission Update Successfully");
          this.loader = '';
        } else {
          this.loader = '';
          this.toaster.errorToastr('Try Again', '');
        }
      });
    }
  
    overAllSelect(checked: boolean, roleId) {
      if (checked) {
        this.menus.forEach((x) => {
          let permission = x.menuId + ',' + roleId;
          this.permList.add(permission);
          this.selectedData.add({ menuId: x.menuId, id: x.id });
        })
      } else {
        this.menus.forEach((x) => {
          let permission = x.menuId + ',' + roleId;
          this.permList.delete(permission);
          this.selectedData.delete({ menuId: x.menuId, id: x.id });
        })
      }
    }
  
    onValChange(checked: boolean, permission: string) {
      let val = permission.split(',');
      if (checked) {
        this.permList.add(permission);
        this.selectedData.add({ menuId: Number(val[0]), id: Number(val[1]) });
        this.checkSelected();
      } else {
        this.permList.delete(permission);
        this.selectedData.forEach((el, index) => {
          if (el['menuId'] == Number(val[0])) {
            var filterRole = Number(permission.split(',')[1]);
            this.selectedRole.delete(filterRole);
            this.selectedData.delete(el);
  
          }
        })
        this.checkSelected();
      }
    }
  
    makePermList() {
      if (this.rolesPermissions.length > 0) {
        this.rolesPermissions.forEach((x:any) => {
          this.permList.add(`${x.menuId},${x.roleId}`);
          this.selectedData.add({ menuId: x.menuId, id: x.roleId });
        });
        this.checkSelected();
      }
    }
  
    checkItsAdmin(roleId, menuUrl): boolean {
      console.log(this.configService);
      
      if (this.configService.userType == 'admin') {
        return true
  
      }
    }
  
  }
  