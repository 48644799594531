import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PermissionService } from 'src/app/services/core/permission.service';
import{HttpService} from 'src/app/services/http/http.service'
// import { UsersService } from './services/users.service';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit ,AfterViewInit {
  loader:boolean=true;
  invoiceForm : FormGroup
  invoiceId: any;
  inVoice: any;
  submitted:boolean=false;
  constructor(private formBuilder : FormBuilder,
    private toaster : ToastrManager,
    private http :HttpService,
    public permission: PermissionService,
    ) { }

  ngOnInit(): void {
    this.loader=false
this.newForm()
this.getInvoice()

  }

newForm(){
  this.invoiceForm = this.formBuilder.group({
    invoiceId: new FormControl(''),
    invoice : new FormControl('',[Validators.required])
  })
}

getInvoice(){

  let url='/admin/getAllInvoice'
  this.http.get(url).subscribe((res:any)=>{
    if(res.errorCode==200){
      this.inVoice=res?.content[0]?.invoicePrefix
      this.invoiceForm.get('invoice').setValue(this.inVoice)
      // this.toaster.successToastr('Invoice viewed successfully')
    }else{
      this.toaster.errorToastr(res.msg)
    }

  })
}

updateInvoice(){
  this.submitted=true;
  let form = this.invoiceForm
  let obj ={
    "invoicePrefix":  form.get('invoice').value
  }
  if(form.valid){
    this.http.post('/admin/Invoice/save',obj).subscribe((res:any)=>{
      if(res.errorCode==200){
        this.toaster.successToastr('Updated Successfully')
        setTimeout(() => {
        this.getInvoice()
          
        }, 1000);
      }
      else{
        this.toaster.errorToastr('Please provide a valid invoice')
      }
      
    })
  }
}
  
get f (){return this.invoiceForm.controls}
  ngAfterViewInit(): void {
    setTimeout(() => {
     
    }, 1000);
  }

}
