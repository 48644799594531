import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { AppConstants } from '../../constants/app.constants';
import { PermissionService } from 'src/app/services/core/permission.service';
declare var $: any;

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.css']
})
export class OrderReportComponent implements OnInit {

  config
  loader
  statusType
  adminAccess
  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private router: Router,
    private route: ActivatedRoute,
    public permission: PermissionService
  ) { }

  mainDomain
  ngOnInit(): void {
    this.mainDomain = AppConstants.SERVER_CONFIG.DOMAIN;
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: ''
    };

    // set page on reload
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('status')) {
        this.statusType = params['status']
      } else {
        this.statusType = 'all_status'
      }
    });

    this.route.queryParams.subscribe(params => {
      this.pageValue(params['page']);
    });

  }

  pageValue(page) {
    if (page) {
      this.config.currentPage = page;
      this.get(this.statusType);
      window.scrollTo(0, 0)
    } else {
      this.config.currentPage = 1;
      this.get(this.statusType);
    }
  }

  pageChange(newPage: number) {
    this.router.navigate(['order-report'], { queryParams: { page: newPage } });
  }


  modalData
  disTextBox = true
  viewReport(item, type) {
    this.modalData = item
    $('#reportModal').modal('show');
    if (type == 'create') {
      this.disTextBox = false
    } else {
      this.disTextBox = true
    }
  }


  ////get order report with pagination & filter by order status
  getdata2; getdata
  get(type) {

    if (type) {
      this.router.navigate(['order-report'], { queryParams: { page: this.config.currentPage, status: this.statusType } });
    }

    this.loader = 'show';
    this.http.get('/admin/order/issue?status=' + type + '&page=' + this.config.currentPage).subscribe(
      data => {
        console.log(data);
        this.getdata2 = data;
        this.getdata = this.getdata2.content;
        this.loader = '';
        this.config = {
          currentPage: this.getdata2.currentPage,
          itemsPerPage: this.getdata2.limit,
          totalItems: this.getdata2.totalRatings
        };

      },
      error => {
        this.loader = '';
      });
  }

  //onChange Order Status --filter
  getChange(type) {
    //pagination
    this.config = {
      currentPage: 1,
      itemsPerPage: '',
      totalItems: ''
    };
    this.router.navigate(['/order-report'], { queryParams: { page: this.config.currentPage, status: type } });
  }


  //change Status
  changeStatus(msg, id) {

    if (!msg) {
      this.toastr.errorToastr('Admin reply is required');
      return;
    }

    let body = {
      status: 'closed',
      adminComments: msg,
    }

    this.loader = 'show';
    this.http.put('/admin/order/issue/' + id, body).subscribe(
      data => {
        this.get(this.statusType);
        this.loader = '';
        this.toastr.successToastr('Successfully updated', '');
        $('#reportModal').modal('hide');
      },
      error => {
        this.loader = '';
        this.toastr.errorToastr(error.message);
      });

  }


  
  close(){
    $('#reportModal').modal('hide')
  
  }



}
