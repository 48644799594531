export class paginationDTO{

    pageNo:any;
    pageSize:any
    search:string
    sortBy:string
    sortDirection: string
    fromDate: any 
    toDate: any 
    orderType: any 
    delay: any 
    midnight: any
    isPan:any
    
}