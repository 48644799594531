import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export function titleCase(st) {
  return st.toLowerCase().split(" ").reduce((s, c) =>
    s + "" + (c.charAt(0).toUpperCase() + c.slice(1) + " "), '');
}
import { ToastrManager } from 'ng6-toastr-notifications';

interface Labels {
  name: string,
  label: string,
  type?: any,
  number?: number,
  min?: any
  max?: any
}


function forFormControl(control: FormControl, name) {
  if (control.invalid) {
    return {
      name: name,
      type: 'control',
      errors: Object.keys(control.errors)[0],
    }
  }
}

function forFormGroup(name, errors, grp) {
  return {
    name: name,
    type: 'group',
    errors: errors,
    group_errors: grp
  }
}

function forFormArray(name, errors, inx, grpErr) {
  return {

    row: inx + 1,
    errors: errors,
    group_errors: grpErr
  }
}

function ErrorMessageForControl(error: string, label: any): string {
  let errorMessage = ''
  switch (error) {
    case 'required': label?.label.includes('Mobile App Usage Limit') ? errorMessage = `Please provide the duration atleast minimum 0 !` : errorMessage = `Please ${label?.type} ${label?.the == false ? '' : 'the'}  ${label?.label}!`;
      break
    case 'email':
    case 'pattern': label?.label.includes('password') || label?.label.includes('Password') ? errorMessage = (`${label?.label} must contain 1 uppercase, 1 lowercase and 1 number!`) : errorMessage = `Please ${label?.type} a valid ${String(label?.label).toLocaleLowerCase()}!`;
      break;
    // matDatepickerMin
    case 'matDatepickerMin': errorMessage = `${titleCase(label?.label)} can\'t be the past date!`;
      break;
    case "minlength": errorMessage = `${titleCase(label?.label)} should have atleast ${label?.minlength ? label?.minlength : label?.number} characters!`;
      break
    case "maxlength": errorMessage = `${titleCase(label?.label)} should be less than ${label?.maxlength ? label?.maxlength : label?.number} !`;
      break
    case 'min': errorMessage = `${titleCase(label?.label)}  should be greater than ${label?.min ? label?.min : label?.number} !`
      break
    case 'max': errorMessage = `${titleCase(label?.label)}  should be less than or equal to ${label?.max ? label?.max : label?.number} !`
      break
    case "mustMatch": errorMessage = `New password and confirm password doesn\'t match!`;
      break
    // matchCaptcha
    case "matchCaptcha": errorMessage = `Please match the captcha given below!`;
      break
    // dayWeek  // dayMonth // weekMonth
    case "dayWeek": errorMessage = `Please provide daily limit less than weekly limit`;
      break
    case "dayMonth": errorMessage = `Please provide daily limit less than monthly limit`;
      break
    case "weekMonth": errorMessage = `Please provide weekly limit less than monthly limit`;
      break
    case "minMax": errorMessage = `Minimum amount should be less than maximum amount!`;
      break
    case "maxCash": errorMessage = `Cashback amount should be less than maximum amount!`;
      break
    case 'inValidDate': errorMessage = `From date and time should be before end date time`;
      break
    case 'invalidDate': errorMessage = `From date and time should be before end date time`;
      break
    case 'pastFromTime': errorMessage = `Start time should be after the current time!`;
      break
    case 'pastToTime': errorMessage = `End time should be after the current time!`;
      break
    case 'invalidTime': errorMessage = `Please provide a valid start and end time!`;
      break
    case 'pastFromDate': errorMessage = `${titleCase(label.label)} can't be the past date!`
      break
    case 'limit': errorMessage = `Minimum amount should be less than maximum amount!`
      break
    case 'fromDateRequired': errorMessage = `${titleCase(label.label)} is also required to filter!`
      break
    default: errorMessage = `Please fill the mandatory fields!`
      break
  }
  return errorMessage

}

function ErrorMessageForFormArray(group: any, control, aLabel: any, cLabel: any) {
  let errorMessage = ''
  // Its for the custom validations such as range date validations and many others
  if (group?.group_errors != null) {
    switch (group?.group_errors) {
      case 'limit': errorMessage = `Minimum amount  should be less than or equal to the gross weight for the ${endedWith(group?.row)} row`
        break
      case 'range': errorMessage = `Please provide valid MRP and selling price for the ${endedWith(group?.row)} row of ${aLabel?.label}!`
        break;
      case 'need2values': errorMessage = `Please provide atleast 1 option value for the ${endedWith(group?.row)} row of  ${aLabel?.label}! `;
        break
      case "fromTo": errorMessage = `To amount should be greater than from amount for the ${endedWith(group?.row)} row of  ${aLabel?.label}! `
        break
      case "fromCommision": errorMessage = `Commission amount should be less than from amount for the ${endedWith(group?.row)} row of  ${aLabel?.label}}! `
        break
      case "toCommision": errorMessage = `Commission amount should be less than to amount for the ${endedWith(group?.row)} row of  ${aLabel?.label}}! `
        break
      default: errorMessage = 'Please fill the mandatory fields!'
        break
    }

  }
  // Its for the general formcontrols------->
  else {
    switch (control?.errors) {
      case 'required': errorMessage = `Please ${cLabel?.type} the ${cLabel?.label} for the ${endedWith(group?.row)} row !`;
        break
      case 'email':
      case 'pattern': errorMessage = `Please ${cLabel?.type} a valid ${cLabel?.label} for the ${endedWith(group?.row)} row of ${aLabel?.label}!`;
        break
      case 'min': errorMessage = `Please ${cLabel?.type}  ${cLabel?.label} greater than ${cLabel?.number ? cLabel?.number : cLabel?.min} for the ${endedWith(group?.row)} row`;
        break
      case 'max': errorMessage = `Please ${cLabel?.type}  ${cLabel?.label} less than ${cLabel?.number ? cLabel?.number : cLabel?.max} for the ${endedWith(group?.row)} row`;
        break
      default: errorMessage = `Please fill the mandatory fields!`
        break
    }

  }

  return errorMessage

}


function endedWith(num: any) {
  let number = String(num)
  let value = '';
  switch (true) {
    case number.endsWith('1'): value = `${num}st`;
      break
    case number.endsWith('2'): value = `${num}nd`;
      break
    case number.endsWith('3'): value = `${num}rd`;
      break
    default: value = `${num}th`;
      break
  }
  return value

}

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageV2Service {

  constructor(
    private toaster: ToastrManager,
  ) { }


  invalidControls(form: FormControl | FormGroup | FormArray | any) {
    switch (true) {
      case form instanceof FormControl: {

        return []
      }
        break;
      case form instanceof FormGroup: {
        return this.forInvalidFormGroup(form)
      }
        break
      case form instanceof FormArray: {

        return []
      }
        break
      default: {

        return []
      }
        break;
    }

  }

  private forInvalidFormGroup(form: FormGroup): any {

    let invalidData: any[] = []

    const names: string[] = Object.keys(form.controls);
    names.forEach((name: any) => {
      const control = form.get(name) as FormArray | FormControl | FormGroup;
      /************************************For the Form controls********************************/
      if (control instanceof FormControl) {
        forFormControl(control, name) != undefined ? invalidData.push(forFormControl(control, name)) : ''
      }
      /*********************************For the Form group****************************************/
      else if (control instanceof FormGroup) {
        invalidData.push(forFormGroup(name, this.forInvalidFormGroup(control), control.errors != null ? Object.keys(control.errors) : null))
      }
      /**********************************For the Form array**************************************/
      else if (control instanceof FormArray) {
        let arr = []

        control.controls.filter((ele: any, inx) => {
          if (ele.invalid) {
            arr.push(forFormArray(name, this.forInvalidFormGroup(ele), inx, ele?.errors != null ? Object.keys(ele?.errors)[0] : ele?.errors))
          }
        })
        if (arr.length > 0) {
          invalidData.push({
            name: name,
            type: 'array',
            errors: arr
          })
        }
      }
      else {


      }

    })

    return invalidData
  }



  throwErrorMessage(form: AbstractControl, foundErrors: any[], labels: Labels[]) {
    const first = foundErrors[0];
    function findLabel(name, label: any[]): any {
      let data = label.find((ele: any) => ele?.name == name)
      return data
    }
    switch (first.type) {

      case 'control': {
        const firstControl = foundErrors[0];
        const ctrLabel = findLabel(firstControl?.name, labels)
        form.get(firstControl?.name).markAsTouched();
        form.get(firstControl?.name).updateValueAndValidity();
        this.toaster.errorToastr(ErrorMessageForControl(firstControl?.errors, ctrLabel))
      }
        break;

      case 'array': {
        const firstArrayControl = foundErrors[0];
        const arrLabel = findLabel(firstArrayControl.name, labels);
        const firstGroup = firstArrayControl.errors[0];
        const firstControl = firstGroup.errors[0]

        if (firstGroup?.group_errors != null) {
          this.toaster.errorToastr(ErrorMessageForFormArray(firstGroup, firstControl, arrLabel, ''))
          return
        } else {
          const ctrLabel = findLabel(firstControl?.name, labels)
          const arrayControl = form.get(firstArrayControl.name) as FormArray
          const control = arrayControl.at(firstGroup.row - 1).get(firstControl?.name) as FormControl
          control.markAsTouched();
          control.updateValueAndValidity()
          this.toaster.errorToastr(ErrorMessageForFormArray(firstGroup, firstControl, arrLabel, ctrLabel))
        }
      }
        break;

    }



  }


}
