import { HttpParams } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { MessagingService } from 'src/app/services/core/messaging.service';
import { NavigationService } from 'src/app/services/core/navigation.service';
import { PermissionService } from 'src/app/services/core/permission.service';
import { HttpService } from 'src/app/services/http/http.service';
import { paginationDTO } from '../paginationDto';
declare var $ :any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() pageName: string;
  @Input() pageLink: string;
  @Input() pageNameTwo: string;
  @Input() pageNamethree: string;
  @Input () notification:any=[]

  zoomIn
  zoomOut
  notify: any;
  badge:number=0
  paginationDTO:paginationDTO
  constructor(
    private authenticationService: AuthenticationService,
    private messagingService : MessagingService,
    private http :HttpService,
    private toaster : ToastrManager,
    public navService : NavigationService,
    private router: Router,
    public permission : PermissionService
  ) { 
    const token = window.localStorage.getItem('currentUser');
    this.messagingService.requestPermission(token);
    this.messagingService.receiveMessage()
    this.paginationDTO=new paginationDTO();

  }

  currentUser; role;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = this.currentUser.privileges;
    // this.messagingService.requestPermission('user')

    if (window.innerWidth == screen.width && window.innerHeight == screen.height) {
      this.zoomIn = '';
      this.zoomOut = 'show';
    } else {
      this.zoomIn = 'show';
      this.zoomOut = '';
    }
    
    this.getAllNotifiation();
    this.getTheBadgeCount()

  }


  logout() {
    this.authenticationService.logout();
  }

  getAllNotifiation(){
    let params = new HttpParams();

  for (let data in this.paginationDTO){
    params = params.set(data,this.paginationDTO[data])
  }
   this.http.getData('/notificationAlert/Pagination',params).subscribe((res:any)=>{
     this.notify= res?.content.filter(x => Number(x.messageRead) == 0);
     this.navService.notificationBadge.next(this.notify?.length) 
    //  this.getAllNotifiation()
    this.getTheBadgeCount()
   })
  }

  getTheBadgeCount(){
    this.navService.badgeCount$.subscribe((res:any)=>{
console.log(res);
this.badge=res

    })
  }

  getBYid(data){
    console.log(data,this.notification);
    let payload:any = JSON.parse(data.data);
    let params = new HttpParams({
      fromObject:{
        id:data.id,
        messageRead:'1'
      }
    })
    this.http.getData(`/notificationAlert/admin/notification/changeStatusById`,params).subscribe((res:any)=>{
// this.toaster.infoToastr(res?.msg);
if(res.errorCode==200){
  this.notification=[]
  this.getAllNotifiation();
      this.router.navigate([`/order-detail/${payload.orderId}`])
}
    })
  }

  //full screen
  fullScreen() {
    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen
      || elem['mozRequestFullscreen']
      ||
      elem['msRequestFullscreen'];
    this.zoomIn = '';
    this.zoomOut = 'show';
    if (methodToBeInvoked) methodToBeInvoked.call(elem);
  }

  zoomoutScreen() {
    document.exitFullscreen();
    this.zoomIn = 'show';
    this.zoomOut = '';
  }


}