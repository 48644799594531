<aside class="col-2 px-0 fixed-top left-bar-width">
  <br />
  <div class="text-center img-replace">

    <img src="/assets/images/logo/logo.png" routerLink="/home" />
  </div>
  <br />

  <div *ngFor="let menu of menuItems; let i = index">
  <div *ngIf="menu.type == 'link'" routerLinkActive="open">
    <a routerLink="/{{menu.state}}" (click)="changeSubmenu()" *ngIf="menu.prams == undefined" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active-link" class="categories-link user"> <i class="{{menu.icon}}"></i>
      {{menu.name}} </a>
      <a routerLink="/{{menu.state}}"  (click)="changeSubmenu()" *ngIf="menu.prams != undefined" [queryParams]="menu.prams" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active-link" class="categories-link user" > <i class="{{menu.icon}}"></i>
        {{menu.name}} </a>
  </div>

  <div *ngIf="menu.type == 'dropDown'" >
    
    <a class="categories-link user" (click)="open(i, subMenus)" [ngClass]="menuindex == i ? 'active-link' : ''"  > <i class="{{menu.icon}}"></i> {{menu.name}}
      <span *ngIf="(subMenus == 'close') || (subMenus != 'close' && menuindex != i)" class="active-link">
        <i  class="fa fa-angle-up fa-angle"></i>
      </span>
      <!-- <span  class="active-link"  style="visibility: hidden;"> 
        wwwqqqqqaaaaa

      </span> -->

      <span class="active-link"  *ngIf="subMenus == 'open' && menuindex == i">
        <i class="fa fa-angle-down fa-angle"></i>
      </span>

    </a>
      <div class="open-div" *ngIf="subMenus == 'open' && menuindex == i">
      <div *ngFor="let subMenu of menu.sub" routerLinkActive="open">
        <a  *ngIf="subMenu.prams == undefined" routerLink="/{{subMenu.state}}" routerLinkActive="active-link" class="categories-link"> <i
          class="{{subMenu.icon}}"></i> {{subMenu.name}} </a>
          <a  *ngIf="subMenu.prams != undefined" [queryParams]="subMenu.prams" routerLink="/{{subMenu.state}}" routerLinkActive="active-link" class="categories-link"> <i
            class="{{subMenu.icon}}"></i> {{subMenu.name}} </a>
      </div>
    </div>

  </div>





 




  </div>
  <!-- <div class="open-div" *ngIf="role.dashboardRead == true">
    <a routerLink="/home" routerLinkActive="active-link" class="dashboard-link">
      <i class="fa fa-pie-chart"></i> Dashboard
    </a>
  </div>

  <div *ngIf="role.approvalRead == true">
    <a
      class="categories-link user"
      routerLink="/order"
      routerLinkActive="active-link"
      [queryParams]="{ page: '1', status: 'all_orders' }"
    >
      <i class="fa fa-pie-chart"></i> Order History
    </a>
  </div>
  <div *ngIf="role.couponRead == true">
    <a
      class="categories-link user"
      routerLink="/promocode"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Coupon Management
    </a>
  </div>

  <div *ngIf="role.pricingUpdateRead == true">
    <a
      class="categories-link user"
      routerLink="/pricing"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Pricing Update
    </a>
  </div>

  <div class="open-div" *ngIf="role.homePageRead == true">
    <a class="categories-link-header" (click)="homePageToggle()">
      <i class="fa fa-pie-chart"></i> Home Page
      <i *ngIf="homePage" class="fa fa-angle-up fa-angle"></i>
      <i *ngIf="!homePage" class="fa fa-angle-down fa-angle"></i></a
    ><br />

    <div *ngIf="homePage">
      <a
        routerLink="/create-banner"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Banner
      </a>
      <a
        routerLink="/cake-list"
        routerLinkActive="active-link"
        [queryParams]="{ cake: 'feature' }"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Cakes By occasion
      </a>
      <a
        routerLink="/cake-list"
        routerLinkActive="active-link"
        [queryParams]="{ cake: 'trending' }"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Trending Cake
      </a>
      <a
        routerLink="/cake-list"
        routerLinkActive="active-link"
        [queryParams]="{ cake: 'bestseller' }"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Best Seller
      </a>
      <a
        routerLink="/create-testimonial"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Testmonial
      </a>
    </div>
  </div>

  <div class="open-div" *ngIf="role.catalogueRead == true">
    <a class="categories-link-header" (click)="categoriesToggle()">
      <i class="fa fa-pie-chart"></i> Catalogue
      <i *ngIf="categorie" class="fa fa-angle-up fa-angle"></i>
      <i *ngIf="!categorie" class="fa fa-angle-down fa-angle"></i> </a
    ><br />
    <div *ngIf="categorie">
      <a
        routerLink="/categories"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Categories
      </a>
      <a
        routerLink="/products"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Products
      </a>
    </div>
  </div>

  <div>
    <a
      *ngIf="role.blogRead == true"
      class="categories-link user"
      routerLink="/blog"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Blog Contents</a
    >

    <a
      *ngIf="role.deliveryRead == true"
      class="categories-link user"
      routerLink="/deliveries"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Delivery Management
    </a>

    <a
      *ngIf="role.newsLetterRead == true"
      class="categories-link user"
      routerLink="/news-letter"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> News Letter</a
    > -->
    <!-- 
    <a class="categories-link user" routerLink="/create-user" *ngIf="role.userRead == true"
      routerLinkActive="active-link"> <i class="fa fa-pie-chart"></i> User Management </a>

    <a class="categories-link user" routerLink="/privilages" *ngIf="role.privilagesRead == true"
      routerLinkActive="active-link"> <i class="fa fa-pie-chart"></i> Privilages </a> -->

    <!-- <a
      *ngIf="role.homePageNotificationRead == true"
      class="categories-link user"
      routerLink="/home-page-popup"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Home Page Notification
    </a>

    <a
      *ngIf="role.deliveryChargeRead == true"
      class="categories-link user"
      routerLink="/home-page-popup"
      [queryParams]="{ type: 'delivery' }"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Delivery Charge
    </a>

    <a
      *ngIf="role.reviewRead == true"
      class="categories-link user"
      routerLink="/review-management"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Review Management
    </a>

    <a
      *ngIf="role.loyaltyRead == true"
      class="categories-link user"
      routerLink="/loyalty-programme"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Loyalty Programme
    </a>

    <a
      *ngIf="role.instagramRead == true"
      class="categories-link user"
      routerLink="/instagram"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Instagram
    </a>

    <a
      class="categories-link user"
      routerLink="/gallary"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Gallery
    </a>

    <a
      *ngIf="role.orderIssueRead == true"
      class="categories-link user"
      routerLink="/order-report"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Order Issue
    </a>
    <a
      class="categories-link user"
      routerLink="/delivery-time"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Delivery Time
    </a>
    <a
      class="categories-link user"
      routerLink="/image-exporal"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Image/Video explorer
    </a>
    <a
      class="categories-link user"
      routerLink="/notification-list"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Notifications</a
    >
    <a
      class="categories-link user"
      routerLink="/invoice"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> Invoice setting</a
    >
    <a
      class="categories-link user"
      routerLink="/activity-log"
      routerLinkActive="active-link"
    >
      <i class="fa fa-pie-chart"></i> activity-log</a
    >
  </div> -->

  <!-- new changes-->

  <!-- <div class="open-div" *ngIf="role.catalogueRead == true">
    <a class="categories-link-header" (click)="categoriesToggle()">
      <i class="fa fa-pie-chart"></i> Administration
      <i *ngIf="categorie" class="fa fa-angle-up fa-angle"></i>
      <i *ngIf="!categorie" class="fa fa-angle-down fa-angle"></i> </a
    ><br />
    <div *ngIf="categorie">
      <a
        routerLink="/Roles"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Roles
      </a>

      <a
        routerLink="/create-user"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Users
      </a>

      <a
        routerLink="/Roles-Permission"
        routerLinkActive="active-link"
        class="categories-link"
      >
        <i class="fa fa-birthday-cake"></i> Roles & Permission
      </a>
    </div>
  </div> -->

  <a class="categories-link user" (click)="logout()">
    <i class="fa fa-sign-out"></i> Logout
  </a>
</aside>
