<section class="offset-width well-white">
   <app-header [pageName]="'Products'"></app-header>
   <div *ngIf="loader" class="loader-spin"></div>
   <div class="container-fluid">
      <!-- 
         <div class="btn-group btn-right">
           <button type="button" class="button-1 btn-pink btn-margin" (click)="onClickCreate()">
             Gift bulk upload </button>
         </div> -->
      <div class="heading">
         <div class="row align-items-center mb-20">
            <div class="col-lg-4 col-xl-6 col-md-4">
               <div class="new-list">
                  <h5 class="mb-0">Products List</h5>
               </div>
            </div>
            <div class="col-lg-8 col-xl-6 col-md-8">
               <div class="flex">
                  <button type="button" class="bulk_download_btn"
                     *ngIf="rolepermission.hasPermission(['products'],['products_bulk_upload'])"
                     (click)="onClickdownload()"><i class="fa-solid fa-download pr-8"></i>
                     Cake bulk download </button>
                  <button type="button" class="cake_upload_btn"
                     *ngIf="rolepermission.hasPermission(['products'],['products_bulk_upload'])"
                     (click)="onClickCreateCake()"><i class="fa-solid fa-upload pr-8"></i>
                     Cake bulk upload </button>
               </div>
            </div>
         </div>
         <div class="row ">
            <div class="col-xl-6 col-lg-4 col-md-4">
               <div class="relative">
                  <img class="search_img" src="../../../assets/images/icon/search.svg" alt="image">
                  <input type="text" class="form-control input_style form-control-sm" #uName
                     (keyup)="search(uName.value)" placeholder="Search By Name & Sku" />
               </div>
            </div>
            <div class="col-xl-6 col-lg-8 col-md-8">
               <div class="flex">
                  <!------------   circle animation  ---------->
                  <span [ngClass]="deliveryButton ? 'circle_blink_on': 'circle_blink_off'"></span>
                  <!-- <span  *ngIf="!deliveryButton" class="circle_blink_off  "></span> -->
                  <!-- <button *ngIf="!deliveryButton" type="button" class="create-btn bg_clr_offdelivery     padd-remove" (click)="changeAllDeliveryStatus('0')">
                     <img class="pr-8 w_36" src="assets/images/icon/off_icon.svg" alt="icon"> 
                  Turn Off Delivery
                  </button> -->
                  <button (click)="changeAllDeliveryStatus(deliveryButton ? 1 :'0')" type="button"
                     [ngClass]="deliveryButton ? 'create-btn bg_clr_ondelivery padd-remove':'create-btn bg_clr_offdelivery padd-remove'">
                     <img class="pr-8 w_36" src="assets/images/icon/{{deliveryButton ?'on_icon':'off_icon'}}.svg"
                        alt="icon">
                     {{deliveryButton ? 'Turn On Delivery' : 'Turn Off Delivery'}}
                  </button>
                  <a routerLink="/products/create-product"
                     *ngIf="rolepermission.hasPermission(['products'],['products_gift_create'])"
                     [queryParams]="{ type: 'gift' }" class="create-btn"><i class="fa fa-plus pr-8"
                        aria-hidden="true"></i>
                     Create Gift
                  </a>
                  <a routerLink="/products/create-product" [queryParams]="{ type: 'product' }"
                     *ngIf="rolepermission.hasPermission(['products'],['products_create'])" class="create-btn"><i
                        class="fa fa-plus pr-8" aria-hidden="true"></i>
                     Create Product
                  </a>
                  <i class="fa fa-trash btn-del" (click)="deleteProduct()" *ngIf="selection.length > 0"></i>
                  <br />
                  <button class="export-btn" style="display: none">
                     Export <i class="fa fa-arrow-down"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div class="card col-12 table-bg">
         <div class="card-body pt-18">
            <div class="table-responsive">
               <table class="table box-down">
                  <thead>
                     <tr>
                        <th scope="col">
                           <input type="checkbox" (click)="selectAll($event)" /> IMAGE
                        </th>
                        <th scope="col">PRODUCTS NAME
                        </th>
                        <th scope="col">
                           CATEGORY
                           <i *ngIf="sortData != '&category=yes'" class="fa filtr fa-caret-down"
                              (click)="sort('category')"></i>
                           <i *ngIf="sortData == '&category=yes'" class="fa filtr fa-caret-up" (click)="sort('no')"></i>
                        </th>
                        <th scope="col">SKU</th>
                        <th scope="col">
                           CREATED DATE
                           <i *ngIf="sortData != '&time=yes'" class="fa filtr fa-caret-down" (click)="sort('time')"></i>
                           <i *ngIf="sortData == '&time=yes'" class="fa filtr fa-caret-up" (click)="sort('no')"></i>
                        </th>
                        <th>PAN-INDIA</th>
                        <th scope="col">STATUS</th>
                        <!-- <th scope="col" *ngIf="rolepermission.hasPermission(['products'],['products_status'])">In-Active/Active</th> -->
                        <th scope="col" *ngIf="rolepermission.hasPermission(['products'],['products_update'])">
                           ACTION
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="
                        let item of getdata | paginate: config;
                        let index = index
                        ">
                        <td class="pl-24">
                           <input type="checkbox" [checked]="checkBoxChecked" (click)="changeCheckbox(item, $event)" />
                           <!-- <i *ngIf="isVideoUrl(item.productImages[0])" style="font-size: 18px; margin-left: 6px"
                              class="fa fa-file-video-o"></i> -->
                           <video controls class="pro-img" *ngIf="isVideoUrl(item.productImages[0])">
                              <source [src]="item.productImages[0]" type="video/mp4">
                           </video>
                           <img *ngIf="!isVideoUrl(item.productImages[0])" (click)="gallerypopup(item.designImages)"
                              class="pro-img" [lightbox]="0" [gallery]="'anotherLightbox'"
                              [src]="item.productImages[0]" />
                        </td>
                        <td>
                           <a href="{{ mainDomain + '/details/'+item.productName.split(' ').join('-')+item.productId }} "
                              target="_blank">
                              {{ item.productName | titlecase }}
                           </a>
                           <div
                              [ngClass]="item?.deliveryStatus ? 'status_delivery':'status_delivery status_delivery_off'">
                              {{ item?.deliveryStatus ? 'Delivery On' :'Delivery Off'}}

                           </div>
                        </td>
                        <td>
                           <a href="{{ mainDomain + '/category/' +item.categoryName.split(' ').join('-')+'-'+item.categoryId }} "
                              target="_blank">
                              <span *ngIf="!item.categoryName">Parent</span>
                              {{ item.categoryName | titlecase }}
                           </a>
                        </td>
                        <td>{{ item.sku }}</td>
                        <td class="no_wrap">{{ item.createdOn | date: 'dd-MM-yyyy, h:mm a' }}</td>
                        <td style="text-align: center;">{{item.isPanIndiaProduct==true?'Yes':'No'}}</td>
                        <td>
                           <span class="Active status-payment" *ngIf="item.status ==1">Active</span>
                           <span class="In-Active status-payment" *ngIf="item.status ==0">Inactive</span>
                        </td>
                        <!-- <td *ngIf="rolepermission.hasPermission(['products'],['products_status'])">
                           <ui-switch size="small" color="#007bff" (change)="changeStatus(item)" [checked]="item.status == 1"
                           style="margin-left: 30PX;"></ui-switch>
                           </td> -->
                        <td>
                           <div class="dropdown flex_center">
                              <span class="eye_img ml-2" id="dropdownMenuButton" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false"> <i
                                    class="fa fa-ellipsis-v ml-2 three-dots color_add"></i>
                              </span>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                 <button *ngIf="rolepermission.hasPermission(['products'],['products_update'])"
                                    (click)="update(item)" class="dropdown-item btn_status">Edit</button>
                                 <span *ngIf="rolepermission.hasPermission(['products'],['products_status'])">
                                    <button class="dropdown-item btn_status" href="#" (click)="changeStatus(item)"
                                       *ngIf="item.status == 1">Inactive</button>
                                    <button class="dropdown-item btn_status" href="#" (click)="changeStatus(item)"
                                       *ngIf="item.status == 0">Active</button>
                                 </span>
                                 <span>
                                    <button class="dropdown-item btn_status"
                                       (click)="changeDeliveryStatus(item)">Delivery {{item?.deliveryStatus ?
                                       'off':'on'}}</button>
                                 </span>
                                 <button class="dropdown-item btn_status" (click)="deleteSingle(item)">Delete</button>
                              </div>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <div>
                  <h5 class="noResult" *ngIf="noResult">No Result Found</h5>
               </div>
            </div>
            <div class="pagination-align">
               <pagination-controls (pageChange)="pageChange($event)" autoHide="true" maxSize="5"
                  screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                  screenReaderCurrentLabel="You're on page" responsive="true"></pagination-controls>
            </div>
         </div>
      </div>
   </div>
   <!-- Modal Form-->
   <div class="modal fade bd-example-modal-lg" id="createModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <form>
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Gift bulk upload</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="bulk-upload">
                     <button class="btn-pink button-1" data-dismiss="modal"
                        routerLink="/bulk-upload"><a>UPLOAD</a></button>
                     <p>CSV 5mb allowed</p>
                     <a href="#">Download Sample</a>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" data-dismiss="modal"
                     (click)="close()">Cancel</button>
                  <button type="button" class="button-1 btn-pink">Import <i style="margin-left: 4px;"
                        class="fa fa-long-arrow-right"></i> </button>
               </div>
            </div>
         </form>
      </div>
   </div>
   <!-- cake bulk model -->
   <div class="modal fade bd-example-modal-lg" id="createModal-2" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <form>
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Cake bulk upload</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div class="modal-body">
                  <div class="bulk-upload">
                     <input class="hideIMg" type="file" #file name="" id="" (change)="bulkUploadinCsv($event)">
                     <button class="btn-pink button-1" (click)="file.click()"><a>UPLOAD</a></button>
                     <p>CSV File size should allowed 5mb only </p>
                     <p *ngIf='filename != null'>{{filename}}</p>
                     <a href="https://api.puppysbakery.com/downloadFile/1637764535062.csv"
                        download="product-bulkupload-template">Download Sample</a>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="button-1 btn-white" data-dismiss="modal"
                     (click)="close()">Cancel</button>
                  <button type="button" data-toggle="modal" class="button-1 btn-pink" (click)="onSubmit()">Import <i
                        style="margin-left: 4px;" class="fa fa-long-arrow-right"></i> </button>
               </div>
            </div>
         </form>
      </div>
   </div>
   <!--End Modal Form-->
   <div class="modal fade" id="response-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" id="exampleModalLabel">Bulk upload validation</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bulkErrorClose()">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body" style="height: 250px;overflow:auto;">
               <div *ngFor="let uploadError of bulkUploadError">
                  <h6>Row {{uploadError.row}}</h6>
                  <ul class="error-lists" *ngFor="let msg of uploadError.messages">
                     <li class="error_msg">{{msg}}</li>
                  </ul>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="button-1 btn-white" data-dismiss="modal"
                  (click)="bulkErrorClose()">Close</button>
            </div>
         </div>
      </div>
   </div>
</section>