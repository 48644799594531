

export const APP_VARIABLES = {

    validators: {
        image: /(.*?)\.(jpg|jpeg|png|bmp|jfif)$/,
        integer: /^-?(0|[1-9]\d*)?$/,
        removebmp: /(.*?)\.(jpg|jpeg|png|jfif)$/,
        basicType: /(.*?)\.(jpg|jpeg|png|JPG|JPEG|PNG)$/,
        allowOnlyPlusValue: /^[0-9]\d*$/,
        // decimalTwoDigitOnly: /^\d{1,1000}(\.\d{1,2})?$/, // allows plus value and two digit
        decimalTwoDigitOnly: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/, // allows plus value and two digit
        decimalThreeDigitOnly: /^\d{1,1000}(\.\d{1,3})?$/, // allows plus value and two digit
        decimal: /^\-?(\d+\.?\d*|\d*\.?\d+)$/, // allows + or - values 
        sixDigitInteger: /^[0-9]{6}$/,
        tenDigitInteger: /^[0-9]{10}$/,
        mobileNo: /^[6,7,8,9]{1}[0-9]{9}$/,
        aadharNo: /^[0-9]{12}$/,
        alphaNumeric: /^[0-9a-zA-Z]+$/, // RSDFS3454fgfet 
        lettersOnly: /^[A-Za-z]+$/, // ABCabcRtvd
        imageAndPdf: /(.*?)\.(jpg|jpeg|png|bmp|pdf)$/,
        imagesAndPdf: /(.*?)\.(jpg|jpeg|png|pdf)$/,
        Pdf: /(.*?)\.(pdf)$/,
        PdfExcel: /(.*?)\.(pdf|msexcel|csv)$/,
        emailPattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        removeWhitespace: /^[^ ][\w\W ]*[^ ]/,
        mobilenumber: /^[6,7,8,9]{1}[0-9]{9}$/,
        removeWSWLetter: /^\S$|^\S[\s\S]*\S$/, // remove white space prefix, suffix and center of the letters
        numberOnly: '^[0-9]*$',
        numeric: /^[1-9][0-9]*$/,
        numericWithZero: /^[0-9]*$/ ,
        // numeric: /^[1-9]\d*(\.\d+)?$/,
        Minpasswordlength: /(?=.*\d)(?=.*[0-9]).{8,}/,
        url:/^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1,5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/,
        date: /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/,
        urllink: /^((https?|http?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    }

}
