import { ValidatorFn, FormGroup, FormControl, ValidationErrors } from "@angular/forms";
import * as moment from "moment";
import { checkNull } from "src/app/constants/custom-functions";




export class PuppysCustomValidation {

    // For Reference
    static RentalLimitValidator: ValidatorFn = (form: FormGroup) => {

        const minimum = form.get('minimum') as FormControl
        const maximum = form.get('maximum') as FormControl

        const min = Number(minimum.value);
        const max = Number(maximum.value);

        switch (true) {
            case checkNull(min) || checkNull(max): return null
            case min > max: return { limit: true }
        }
    }

    // In Usage
    static LimitValidator(min, max): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            const minimum = Number(form.get(min).value)
            const maximum = Number(form.get(max).value)
            switch (true) {
                case (!checkNull(minimum) || !checkNull(maximum)): return null
                case minimum > maximum: return { limit: true }
            }
        }
    }


    static DateValidator(date1, date2): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            const from = form.get(date1).value
            const to = form.get(date2).value

            switch (true) {
                case !checkNull(from) || !checkNull(to): {
                    return null
                }
                    break
                case !checkFromDateToDate(from, to): {
                    return { invalidDate: true }
                }
                    break
                default: return null
                    break

            }

        }
    }

    static DateValidator2(date1, date2): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            const from = form.get(date1).value
            const to = form.get(date2).value

            switch (true) {
                case !checkNull(from) || !checkNull(to): {
                    return null
                }
                    break
                case !checkFromDateToDate(from, to): {
                    return { invalidDate2: true }
                }
                    break
                default: return null
                    break

            }

        }
    }

    static DateAndTimeValidator(date1, date2): ValidatorFn {
        return (form: FormGroup): ValidationErrors | null => {
            const from = form.get(date1).value
            const to = form.get(date2).value

            switch (true) {
                case !checkNull(from) || !checkNull(to): {
                    return null
                }
                    break
                case !checkDateAndTime(from, to): {
                    return { invalidDate: true }
                }
                    break
                default: return null
                    break

            }

        }
    }



}



function checkDateAndTime(fromDate, todate) {
    return moment(fromDate).add(10, 'minutes').isBefore(moment(todate))
}


function checkFromDateToDate(fromDate, todate) {
    let from = new Date(fromDate);
    let to = new Date(todate);
    if ((from.getFullYear() <= to.getFullYear())) {

        if ((from.getFullYear() == to.getFullYear())) {
            if (((from.getMonth() + 1) <= (to.getMonth() + 1))) {
                if (((from.getMonth() + 1) == (to.getMonth() + 1))) {
                    if ((from.getDate() <= to.getDate())) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            } else {
                return false
            }
        } else {
            return true
        }
    } else {
        return false
    }
}


import Swal from 'sweetalert2';
export function sweetAlert(type, message) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    Toast.fire({
        icon: type,
        title: message
    })
}

