import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ReportDTO } from "../_services/report.dto";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ToastrManager } from "ng6-toastr-notifications";
import { checkNull } from "src/app/constants/custom-functions";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/shared/service/loader.service";
import { PuppysCustomValidation, sweetAlert } from "src/app/shared/validations/puppys-custom";
import { ReportService } from "../_services/report.service";
class ReportCalculation {
  taxableAmount
  cgst
  sgst
  igst
  billAmount
  constructor(data) {
    this.taxableAmount = data?.map((ele) => Number(ele?.taxableAmount)).reduce((a, b) => a + b, 0) ?? 0
    this.cgst = data?.map((ele) => this.CGSTand(ele)).reduce((a, b) => a + b, 0) ?? 0
    this.sgst = data?.map((ele) => this.CGSTand(ele)).reduce((a, b) => a + b, 0) ?? 0
    this.igst = data?.map((ele) => this.IGST(ele)).reduce((a, b) => a + b, 0) ?? 0
    this.billAmount = data?.map((ele) => Number(ele?.billAmount)).reduce((a, b) => a + b, 0) ?? 0
  }


  CGSTand(data) {
    let storeAddress = JSON.parse(data?.storeAddress);

    let homeAddress = JSON.parse(data?.homeAddress)

    if (checkNull(storeAddress?.id) || homeAddress?.state == 'Tamil Nadu') {
      return Number(data?.sgstAmount)
    } else {
      return 0
    }
  }

  IGST(data) {

    let storeAddress = JSON.parse(data?.storeAddress);

    let homeAddress = JSON.parse(data?.homeAddress)

    if (checkNull(storeAddress?.id) || homeAddress?.state == 'Tamil Nadu') {
      return 0
    } else {
      return Number(data?.sgstAmount) * 2
    }
  }
}
@Component({
  selector: "app-gst-audit-report",
  templateUrl: "./gst-audit-report.component.html",
  styleUrls: ["./gst-audit-report.component.css"],
})
export class GSTAuditReportComponent implements OnInit {
  filterForm: FormGroup;
  pagination: ReportDTO;



  reports: any[] = []

  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.pagination = new ReportDTO()
  }

  ngOnInit(): void {
    this.intialForm()
  }

  intialForm() {
    this.filterForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    }, { validators: PuppysCustomValidation.DateValidator('startDate', 'endDate') })
  }






  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }


  reset() {
    this.intialForm()
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = []
  }

  navigateToOrder(orderId) {
    this.router.navigate([`order-detail/${orderId}`])
  }

  calculation: ReportCalculation
  getGSTReport(filter?: boolean) {

    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    if (filter && false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the product report!')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }
      this.loader.open()
      this.service.getGSTReport(this.pagination).subscribe((res: any) => {
        this.loader.close();
        this.reports = res?.content ?? [];
        this.calculation = new ReportCalculation(res?.content ?? [])
        console.log(this.calculation, 'this is the calculation');

      }, (err: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again');
        this.reports = []
        this.calculation = new ReportCalculation([])
      })
    } else {
      sweetAlert('error', 'Start date should be before End date')
    }


  }

  export() {
    let form = this.filterForm.value
    if (false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the GST report!')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }
      this.service.exportReport('/gst/report/export', 'GST Report', this.pagination)

    } else {
      sweetAlert('error', 'Start date should be before End date')
    }
  }


  CGSTand(data) {
    let storeAddress = JSON.parse(data?.storeAddress);

    let homeAddress = JSON.parse(data?.homeAddress)

    if (checkNull(storeAddress?.id) || homeAddress?.state == 'Tamil Nadu') {
      return { percent: Number(data?.taxPercentage), amount: Number(Number(data?.sgstAmount)).toFixed(2) }
    } else {
      return { percent: '0%', amount: '0.00' }
    }
  }

  IGST(data) {

    let storeAddress = JSON.parse(data?.storeAddress);

    let homeAddress = JSON.parse(data?.homeAddress)

    if (checkNull(storeAddress?.id) || homeAddress?.state == 'Tamil Nadu') {
      return { percent: '0%', amount: '0.00' }
    } else {
      return { percent: (Number(data?.taxPercentage) * 2), amount: Number(Number(data?.sgstAmount) * 2).toFixed(2) }
    }


  }





  // Non-developed
  tableData = [
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "16%",
      cgstAmt: "₹ 400.00",

      sgstPercentage: "20%",
      sgstAmt: "₹ 350.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "20%",
      cgstAmt: "₹ 400.00",

      sgstPercentage: "20%",
      sgstAmt: "₹ 560.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "20%",
      cgstAmt: "₹ 400.00",
      sgstPercentage: "30%",
      sgstAmt: "₹ 400.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "30%",
      cgstAmt: "₹ 400.00",
      sgstPercentage: "14%",
      sgstAmt: "₹ 570.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "26%",
      cgstAmt: "₹ 300.00",
      sgstPercentage: "20%",
      sgstAmt: "₹ 320.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "26%",
      cgstAmt: "₹ 300.00",
      sgstPercentage: "20%",
      sgstAmt: "₹ 320.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
    {
      billDate: "26-02-2024",
      billNo: "40767654",
      customer: "Selvam",
      gstNumber: "45ABR56UJK98TYR",
      amount: "₹ 200.00",
      cgstPercentage: "26%",
      cgstAmt: "₹ 300.00",
      sgstPercentage: "20%",
      sgstAmt: "₹ 320.00",
      igstPercentage: "10%",
      igstAmt: "₹ 100.00",
      roundOff: "₹ 360.00",
      billAmount: "₹ 360.00",
    },
  ];
}
