import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { serviceProvider } from './services';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    serviceProvider,
  ],
})
export class CoreModule { }
