<div class="text-center"></div>
<!-- Modal -->
<div
  class="modal right fade"
  id="myModal2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel2"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel2">Notifications</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="hide()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body p-0">
        <div
          class="notification-container"
          *ngFor="let data of notificationList | slice: 0:10; let i = index"
        >
          <div class="card" data-dismiss="modal" (click)="changeStatus(data)">
            <div class="card-body">
              <h6 class="notification-head">{{ data?.title }}</h6>
              <span class="notifi-span">{{ data?.body }}</span>
            </div>
          </div>
        </div>

        <h5
          style="text-align: center; margin-top: 20px"
          *ngIf="notificationList?.length <= 0"
        >
          No notification found
        </h5>

        <div class="btn-cont" *ngIf="notificationList?.length >= 1">
          <button
            type="submit"
            routerLink="/notification-list"
            data-dismiss="modal"
            class="button-1 btn-pink"
            (click)="hide()"
            routerLink="/notification-list"
          >
            Show more
          </button>
        </div>
      </div>
    </div>
    <!-- modal-content -->
  </div>
  <!-- modal-dialog -->
</div>
<!-- modal -->
