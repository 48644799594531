import { Injectable } from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { checkNull, queryMaker } from "src/app/constants/custom-functions";
import { HttpService } from "src/app/services/http/http.service";
import { LoaderService } from "src/app/shared/service/loader.service";
import { ReportDTO } from "../reports/_services/report.dto";
import { CustomerDTO } from "./customer.dto";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})

export class CustomerService {

    constructor(
        private http: HttpService,
        private toaster: ToastrManager,
        private loader: LoaderService
    ) { }



    getAllCustomers(dto: CustomerDTO) {
        let query = queryMaker(dto)
        return this.http.getData(`/customer/management/searchAll${query}`)
    }


    updateStatus(dto) {
        let params = new HttpParams();
        for (let data in dto) {
            if (checkNull(dto[data])) {
                params = params.append(data,dto[data])
            }
        }
            return this.http.getData(`/customer/management/changeStatusById`,params) 
    }
}