<section class="offset-width well-white">
   <!-- <div *ngIf="loader" class="loader-spin"> </div> -->
   <app-header [pageName]="'Customers'"></app-header>
   <div class="container-fluid">
      <div class="row">
         <div class="col-lg-2 List-item">
            <h5>Customers</h5>
            <br>
         </div>
      </div>
      <div class="card-body card-design">
         <form [formGroup]="filterForm">
            <div class="row align-items-end ">
               <div class="col-lg-6 col-xl-2 col-md-6 mb_20">
                  <div>
                     <label class="label_style">From Date</label>
                     <input formControlName="startDate" type="date"
                        class="form-control uppercase input_style widthChange">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-2 col-md-6 mb_20">
                  <div>
                     <label class="label_style">To Date</label>
                     <input formControlName="endDate" type="date"
                        class="form-control uppercase input_style widthChange">
                  </div>
               </div>
               <div class="col-lg-6 col-xl-3 col-md-6 mb_20">
                  <div class="relative">
                     <label class="label_style">Status</label>

                     <ng-multiselect-dropdown [formControl]="filterForm?.get('status')" class="multi_selectStyle"
                        [placeholder]="'Select Status'" [settings]="statusSettings" [data]="statusOptions">
                     </ng-multiselect-dropdown>
                  </div>
               </div>
               <div class="col-lg-3 col-xl-2 col-md-3 mr_3 text-align-right">
                  <button (click)="getAllCUstomers(true)" class="btn btn-sm btn-reset btn_search">Search</button>
               </div>
               <div class="col-lg-3  col-xl-2 col-md-3">
                  <button (click)="reset()" *ngIf="hasOneValue"
                     class="btn btn-sm reset_btn tooltip-container ml_0 ml-2">
                     <img src="../../../assets/images/logo/reset.svg" alt="icon">
                     <div class="tooltip">
                        <h1 class="tooltip_style">Reset</h1>
                     </div>
                  </button>
               </div>
            </div>





         </form>
      </div>
      <div class="card col-12 table-bg px-0">
         <div class="card-body px-0">
            <div class="table-responsive">
               <table class="table">
                  <thead>
                     <tr>
                        <th class="text_left w_17" scope="col">Created On</th>
                        <th class="text_left" scope="col">Customer Id</th>
                        <th class="text_left" scope="col">Customer Name</th>
                        <th class="text_left" scope="col">Mobile No</th>
                        <th scope="col">Email Id</th>
                        <th class="text_left" scope="col">Gender</th>
                        <th class="text_left" scope="col">Date of Birth</th>
                        <th class="text_left" scope="col">Status</th>
                        <th class="text_left" scope="col">Action</th>

                     </tr>
                  </thead>
                  <tbody>
                     <tr *ngFor="let data of reports;">
                        <td>{{data?.createdDate|date:'dd-MM-yyyy'}}&nbsp;{{data?.createdDate|date:'hh:mm a'}}</td>
                        <td>{{data?.userId|emptyText}}</td>

                        <td>{{data?.customerName|emptyText}}</td>
                        <td>{{data?.mobileNumber|emptyText}}</td>

                        <td>{{data?.emailId|emptyText}}</td>
                        <td>{{checkNull(data?.gender)? (data?.gender|titlecase):'-'}}</td>
                        <td>{{checkNull(data?.dateOfBirth)? (data?.dateOfBirth|date:'dd-MM-yyyy'):'-'}}</td>
                        <td><span class="active-status"
                              [ngClass]="data?.active==true ?'active-status':'inactive-status' ">{{data?.active==true
                              ?'Active':'In-active'}}</span>

                        <td>


                           <div class="dropdown d-flex align-items-center">

                              <span class="eye_img ml-2" id="dropdownMenuButton" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fa fa-ellipsis-v ml-2 three-dots color_add"></i>
                              </span>
                              <div style="cursor: pointer;" (click)="updateStatus(data)" class="dropdown-menu"
                                 aria-labelledby="dropdownMenuButton" id="show">
                                 <div><span class="btn-status">{{data?.active==true
                                       ?'In-active':'Active'}}</span>
                                 </div>
                              </div>
                           </div>

                        </td>



                     </tr>
                  </tbody>
               </table>
               <div class="d-flex justify-content-center" style="font-weight: 500;" *ngIf="reports.length==0">NO
                  CUSTOMER RECORDS FOUND</div>

               <div class="pagination-align" *ngIf="reports.length!=0">
                  <app-paginator [currentPage]="pagination.pageNo" [lastPage]="pagination.lastPage"
                     (paginate)="paginate($event)"></app-paginator>

               </div>
            </div>
         </div>
      </div>

   </div>
</section>