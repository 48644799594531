import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { HttpService } from '../../services/http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppConstants } from '../../constants/app.constants';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from 'protractor';
import * as _ from 'lodash';
import { checkNull } from 'src/app/constants/custom-functions';

declare var $: any;

@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.css'],
})
export class ProductsFormComponent implements OnInit {
  @ViewChild('weight') weight: ElementRef;
  @ViewChild('kilomultiSelect') kilomultiSelect;
  @ViewChild('flavourmultiSelect') flavourmultiSelect;
  productId = '';
  editForm: FormGroup;
  priceForm: FormGroup;
  bulkPriceForm: FormGroup;
  variantForm: FormGroup;
  submitted = false;
  loader = '';
  isEdited = false;
  imgUrl;
  designType = '';
  IsShowPricing = true;
  kgText = 'Kg';
  //multiple select box
  dropdownList = [];
  selectedKiloItems: any = [];
  dropdownSettings: IDropdownSettings;

  dropdownList1 = [];
  selectedFlavourItems = [];
  dropdownSettings1: IDropdownSettings;
  //dropdown flavour list in pricing
  flavourList = [];
  //generate 1 to 20 number array
  itemQuantityNumber = Array(20)
    .fill(0)
    .map((x, i) => i + 1);

  productImgUrl: any;
  defaultProductImageUrl: any = '';
  selectedDesignImgurls = [];
  bulkordertable: any = [];
  amountFieldRequired = '';
  variantFomSubmitted = false;
  designImgurls = [];
  deliveryTimes = [];
  deliveryTimeData: Object;
  isEditPriceDetail: any = false;
  isEditPriceIndex: any = -1;
  isSameNet: boolean = false;
  showRequired: boolean = true;
  constructor(
    public http: HttpService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.imgUrl = AppConstants.SERVER_CONFIG.IMG_URL;
    this.emptyForm();
    this.getFlavourlist();
    this.productId = this.route.snapshot.paramMap.get('productId');
    //get product data
    if (this.productId) {
      this.isEdited = true;
      this.get();
    } else {
      this.productId = '';
      this.isEdited = false;
    }
    this.deliveryTime();
    this.getCategoryName();
    ///gift & design designType
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('type')) {
        this.designType = params.type;


        this.getDesignId(params['type']);
      }
    });

    this.dropdownList = this.getUomList();

    this.getDeliveryTime();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'flavoursId',
      textField: 'flavoursName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.bulkPriceForm = this.formBuilder.group({
      tabel: new FormArray([this.getbulk([])]),
    });

    // set variant form validation here
    this.setVariantForm();

    this.setVariantModalCallBack();
  }

  /****************************************************** Flavour List Dynamic******************************************************************/
  getFlavourlist() {
    this.http.get('/admin/getAllFlavours').subscribe(
      (res: any) => {
        this.flavourList = res?.content;
        this.dropdownList1 = this.flavourList;

      },
      (error: any) => {
        this.flavourList = [];
      }
    );
  }

  getUomLabel() {
    return 'Select ' + this.kgText;
  }

  setVariantModalCallBack() {
    var $this = this;
    $('#exampleModal').on('show.bs.modal', function (e) {
      $this.setUomList();
    });
  }

  setUomList() {
    this.dropdownList = this.getUomList();
  }

  getUomList() {
    return [
      { item_id: 0.5, item_text: '500 ' + 'gm', variant: [] },
      { item_id: 1, item_text: '1 ' + this.kgText, variant: [] },
      { item_id: 2, item_text: '2 ' + this.kgText, variant: [] },
      // { item_id: 3, item_text: '3 ' + this.kgText, variant: [] },
      // { item_id: 4, item_text: '4 ' + this.kgText, variant: [] },
      // { item_id: 5, item_text: '5 ' + this.kgText, variant: [] },
      // { item_id: 6, item_text: '6 ' + this.kgText, variant: [] },
      // { item_id: 7, item_text: '7 ' + this.kgText, variant: [] },
      // { item_id: 8, item_text: '8 ' + this.kgText, variant: [] },
      // { item_id: 9, item_text: '9 ' + this.kgText, variant: [] },
      // { item_id: 10, item_text: '10 ' + this.kgText, variant: [] },
    ];
  }

  getbulk(value) {
    return this.formBuilder.group({
      kg: [value.kg],
      variant: [value.variant],
      discount: [
        '',
        [
          Validators.pattern(/^\d{1,1000}(\.\d{1,2})?$/),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
      amount: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{1,1000}(\.\d{1,2})?$/),
          Validators.min(0),
        ],
      ],
      gross: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{1,1000}(\.\d{1,2})?$/),
          Validators.min(0.1),
        ],
      ],

      net: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{1,1000}(\.\d{1,2})?$/),
          Validators.min(0.1),
        ],
      ],
    });
  }

  //////////////////////////if design Cake or Gift//////////////////// /////////////////
  getDesignId(type) {
    this.loader = 'show';
    this.http.get('/design').subscribe(
      (data) => {
        this.designTypeData = data;
        this.loader = '';
        this.setDesignType(type);
      },
      (error) => {
        this.loader = '';
        // this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  onItemSelect(item: any) {
    // this.SelectItems.push(item);
  }
  onSelectAll(items: any) {
    // this.SelectItems.push(items);
  }

  onItemSelect1(item: any) {
    // this.SelectItem.push(item);
  }
  onSelectAll1(items: any) {
    // this.SelectItem.push(items);
  }

  openBulkVariantModal() {

    this.variantForm.reset();
    this.bulkPriceForm.reset();
    $('#exampleModal').modal({ backdrop: 'static', keyboard: false });
    $('#exampleModal').modal('show');
  }

  generateVariant() {

    this.variantFomSubmitted = true;
    // reset popup
    this.bulkPriceForm.reset();
    this.bulkordertable = [];

    if (!this.variantForm.valid) {
      return false;
    } else {
      this.variantFomSubmitted = false;


      this.selectedKiloItems.forEach((kg, index) => {
        if (
          this.selectedFlavourItems == null ||
          this.selectedFlavourItems?.length < 1
        ) {
          var tableitem = {
            kg: kg.item_id,
            variant: 'NA',
            item_id: kg.item_id,
          };
          this.bulkordertable.push(tableitem);
        } else {
          console.warn('here come into the condition');

          this.selectedFlavourItems.forEach((flv, i) => {
            var tableitem = {
              kg: kg.item_id,
              variant: flv.flavoursName,
              item_id: kg.flavoursId,
            };
            this.bulkordertable.push(tableitem);
          });
        }
      });

      this.bulkordertable = this.bulkordertable.sort(
        (x, y) => x.item_id - y.item_id
      );

      var control = <FormArray>this.bulkPriceForm.get('tabel');
      control.clear();
      // this.bulkordertable.forEach((element) => {
      //   control.push(this.getbulk(element));
      // });
      this.bulkordertable.map((ele) => control.push(this.getbulk(ele)));
      console.log(
        this.bulkPriceForm.get('tabel').value,
        'let me get the values of bulk add--------------------->'
      );

      $('#exampleModal').modal('hide');
      $('#myModal').modal({ backdrop: 'static', keyboard: false });
      $('#myModal').modal('show');
    }
  }
  closeBigModal() {
    $('#myModal').modal('hide');
  }

  weightChaneg(inx: any, check) {
    if (check) {
      const arr = this.bulkPriceForm.get('tabel') as FormArray;
      arr.at(inx).get('net').setValue(arr.at(inx).get('gross').value);
      arr.at(inx).get('net').updateValueAndValidity();
    }
  }

  netChaneg(inx: any, check) {
    if (check) {
      const arr = this.bulkPriceForm.get('tabel') as FormArray;
      arr.at(inx).get('gross').setValue(arr.at(inx).get('net').value);
      arr.at(inx).get('gross').updateValueAndValidity();
    }
  }

  grossWeightChaneg(value: any, check) {
    console.log(this.isSameNet, 'this.isSameNet');
    if (this.isSameNet == true) {
      this.netWeight.nativeElement.value = value;
    }
    value == 0 ? (this.grossFieldZero = 'show') : (this.grossFieldZero = '');
  }

  netWeightChaneg(value: any, check) {
    if (this.isSameNet == true) {
      this.grossWeight.nativeElement.value = value;
    }
    value == 0 ? (this.netFieldZero = 'show') : (this.netFieldZero = '');
  }

  get sameAsFn() {
    //this.isSameNet = this.editForm.get('updatepricedata').value.every((ele: any) => Number(ele.net) == Number(ele.gross) && Number(ele.net) != 0 && Number(ele.gross)!=0) && this.editForm.get('updatepricedata').value.length > 0
    return (
      this.editForm
        .get('updatepricedata')
        .value.every((ele: any) => Number(ele.net) == Number(ele.gross)) &&
      this.editForm.get('updatepricedata').value.length > 0
    );
  }

  sameAs(event) {
    if (event.target.checked) {
      const arr1: any[] = this.bulkPriceForm.get('tabel').value;
      const arr = this.bulkPriceForm.get('tabel') as FormArray;
      arr1.forEach((ele, inx) => {
        arr
          .at(inx)
          .get('net')
          .setValue(ele.gross ?? 0);
        arr.at(inx).get('net').updateValueAndValidity();
      });
    }
  }

  bulkSubmit() {
    console.log(this.bulkPriceForm, this.bulkPriceForm.value);
    if (this.bulkPriceForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation',
        text: 'Fill Required fields. Discount, Amount, Net and Gross weight are Numeric Fields.',
        confirmButtonText: 'Ok',
      });
      return;
    } else {
      this.emptyPriceForm();
      this.priceValue = [];
      var form = this.bulkPriceForm.value;
      console.log(form, 'while bulk submit');

      var control = <FormArray>this.editForm.get('updatepricedata');
      control.clear();
      if (form?.tabel.length != 0) {
        form?.tabel.forEach((res) => {
          this.priceValue.push({
            kg: res.kg,
            flavour: res.variant,
            discount: res.discount,
            price: res.amount,
            net: res.net ?? 0,
            gross: res?.gross ?? 0,
          });
          control.push(
            this.getPriceData({
              kg: res.kg,
              flavour: res.variant,
              discount: res.discount,
              price: res.amount,
              net: res.net ?? 0,
              gross: res.gross ?? 0,
            })
          );
        });
      }
    }

    this.priceValue = this.priceValue.sort((x, y) => x.kg - y.kg);
    control.clear();
    this.priceValue.forEach((element) => {
      control.push(this.getPriceData(element));
    });
    this.weight.nativeElement.value = '';
    this.amount.nativeElement.value = '';
    this.flavour.nativeElement.value = '';
    this.discount.nativeElement.value = '0';
    // this.emptyPriceForm();

    $('#myModal').modal('hide');
  }

  closeModal() {
    $('#exampleModal').modal('hide');
  }
  editProductPrice(index, allow) {
    var control = <FormArray>this.editForm.get('updatepricedata');
    console.log(control, 'control');
    this.isEditPriceDetail = allow;
    this.isEditPriceIndex = allow ? index : -1;
  }
  setVariantForm() {
    this.variantForm = new FormGroup({
      kilograms: new FormControl(this.selectedKiloItems, Validators.required),
      flavours: new FormControl(this.selectedFlavourItems),
    });
  }

  get fv() {
    return this.variantForm.controls;
  }

  BacktoModal() {
    Swal.fire({
      icon: 'warning',
      title: 'Confirm?',
      text: 'You have go back?. the data will be deleted!',
      showCancelButton: true,
      confirmButtonColor: '#FD7297',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.value) {
        this.bulkordertable = [];
        this.variantForm.reset();
        this.bulkPriceForm.reset();
        var control = <FormArray>this.bulkPriceForm.get('tabel');
        control.reset();
        $('#exampleModal').modal({
          backdrop: 'static',
          keyboard: false,
        });
        $('#myModal').modal('hide');
      }
    });
  }

  removeData(i) {
    const control = <FormArray>this.bulkPriceForm.controls['tabel'];
    if (control.length > 1) {
      this.bulkordertable.splice(i, 1);
      control.removeAt(i);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Validation',
        text: 'You cannot remove all rows.',
        confirmButtonText: 'Ok',
      });
    }
  }

  designTypeID;
  designTypeData;
  setDesignType(type) {
    if (type) {
      this.designType = type;
    }

    if (this.designType == 'GIFT') {
      if (this.designTypeData.giftId) {
        this.designTypeID = this.designTypeData.giftId;
        //set category Id
        this.editForm.controls['categoryId'].setValue(this.designTypeID);
        console.log(this.editForm.controls['categoryId'].value);
      } else {
        this.sweetAlert(
          'Kindly Create Gift Category By Using Shortcode - GIFT'
        );
      }
    }

    if (this.designType == 'DESIGN') {
      if (this.designTypeData.designId) {
        this.designTypeID = this.designTypeData.designId;
        //set category Id
        this.editForm.controls['categoryId'].setValue(this.designTypeID);
      } else {
        this.sweetAlert(
          'Kindly Create Design My Cake Categorie By Using Shortcode - DESIGN'
        );
      }
    }

    //Allow one price varient only for gift
    if (this.getdata?.productDetails?.pricing?.length == 1) {
      this.IsShowPricing = false;
    }
  }
  //////////////////////if design Cake or Gift End////////// /////////////////

  emptyForm() {
    console.log(this.priceValue);

    this.submitted = false;
    this.productImgUrl = [];
    this.designImgurls = [];
    this.emptyPriceForm();
    this.editForm = this.formBuilder.group({
      productName: ['', Validators.required],
      categoryId: ['', Validators.required],
      sortOrder: ['', Validators.required],
      designImages: [''],
      isPanIndiaProduct: ['', Validators.required],
      status: [1],
      listings: [''],
      isKg: [true],
      productPoints: [0, Validators.required],
      paymentDetails: new FormGroup({
        cod: new FormControl(''),
        online: new FormControl(true),
      }),
      productDetails: new FormGroup({
        pricing: new FormControl(),
        egg: new FormControl(),
        eggless: new FormControl(),
        description: new FormControl(''),
        product_details: new FormControl(''),
        notes: new FormControl(''),
        parentCategoryId: new FormControl(''),
      }),
      enableWelcomeText: new FormControl('', Validators.required),
      productImages: [''],
      sku: [null],
      active: [true],
      available: [true],
      minimumOrder: [1],
      deliverySpeed: [''],
      flavour: this.formBuilder.array([]),
      updatepricedata: new FormArray([this.getPriceData([])]),
    });
    if (this.designType == 'GIFT' || this.designType == 'DESIGN') {
      this.editForm.controls['categoryId'].setValue(this.designTypeID);
    }
  }

  getPriceData(element) {
    console.log(element, 'element');
    return this.formBuilder.group({
      kg: [element.kg ? String(element.kg) : ''],
      price: [element.price ? String(element.price) : ''],
      flavour: [element.flavour ? String(element.flavour) : ''],
      discount: [element.discount ? String(element.discount) : ''],
      net: [element.net ? String(element.net) : '0'],
      gross: [element.gross ? String(element.gross) : '0'],
    });
  }
  sweetAlert(msg) {
    Swal.fire({
      title: 'Create Categorie',
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/categories']);
      }
    });
  }

  getdata;
  getdata2;
  pathHeader = new Array(0);
  get() {
    // alert('get')
    this.loader = 'show';
    if (this.productId) {
      this.http.get('/admin/product/' + this.productId).subscribe(
        (data) => {
          this.getdata2 = data;
          this.getdata = this.getdata2.content;

          this.pathHeader = this.getdata2.path;
          if (this.getdata?.sku?.replace(/[0-9,-]/g, '') == 'GIFT') {
            this.getDesignId('GIFT');
          }
          this.setformvalue();
          this.loader = '';
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        }
      );
    }
  }

  deliveryTime() {
    this.http.get('/admin/deliveryTime').subscribe((res) => {
      this.deliveryTimeData = res;
    });
  }
  isKgChange(event) {
    if (event.target.checked === true) {
      //push
      this.kgText = 'Kg';
    } else {
      //remove
      this.kgText = 'Quantity';
    }
  }

  isSameNetChange(event) {
    if (event.target.checked == true) {
      //push
      this.isSameNet = true;
      this.grossWeight.nativeElement.value = this.netWeight.nativeElement.value;
    } else {
      //remove
      this.isSameNet = false;
    }
  }

  isPan(event) {
    console.log(event);
    if (event == 'true' || event == true) {
      this.editForm.get('deliverySpeed').clearValidators();
      this.editForm.get('deliverySpeed').updateValueAndValidity();
      this.showRequired = false;
      console.log(this.showRequired, 'this.showRequired');
    } else {
      this.editForm.get('deliverySpeed').setValidators(Validators.required);
      this.editForm.get('deliverySpeed').updateValueAndValidity();
      this.showRequired = true;
      console.log(this.showRequired, 'this.showRequired1');
    }
  }

  getdata3;
  getdata4;
  //get Parent category list
  getCategoryName() {
    this.loader = 'show';
    this.http.get('/admin/category/parent').subscribe(
      (data) => {
        this.getdata3 = data;
        this.getdata4 = this.getdata3.content;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  getdata5;
  // get subCategory List
  getSubCategoryName(id) {
    if (id) {
      this.http.get('/admin/category/sub?id=' + id).subscribe(
        (data: any) => {
          this.getdata5 = data.content;
        },
        (error) => { }
      );
    }
  }

  // get delivery time

  getDeliveryTime() {
    this.http.get('/admin/deliveryTime').subscribe(
      (data: any) => {
        this.deliveryTimes = data;
      },
      (error) => { }
    );
  }

  //update form value if edited
  setformvalue() {
    //reset design image at initial load
    this.designImgurls = [];
    this.editForm = this.formBuilder.group({
      productName: [this.getdata.productName, Validators.required],
      categoryId: [this.getdata.categoryId, Validators.required],
      sortOrder: [this.getdata.sortOrder, Validators.required],
      designImages: [''],
      isPanIndiaProduct: [this.getdata.isPanIndiaProduct, Validators.required],
      listings: [''],
      isKg: [this.getdata.isKg],
      productPoints: [this.getdata.productPoints, Validators.required],
      paymentDetails: new FormGroup({
        cod: new FormControl(this.getdata.paymentDetails?.cod),
        online: new FormControl(this.getdata.paymentDetails?.online),
      }),
      productDetails: new FormGroup({
        pricing: new FormControl(this.getdata.productDetails?.pricing),
        egg: new FormControl(this.getdata.productDetails.egg),
        eggless: new FormControl(this.getdata.productDetails.eggless),
        description: new FormControl(this.getdata.productDetails.description),
        product_details: new FormControl(
          this.getdata.productDetails.product_details
        ),
        notes: new FormControl(this.getdata.productDetails.notes),
        parentCategoryId: new FormControl(
          this.getdata.productDetails.parentCategoryId
        ),
      }),
      enableWelcomeText: new FormControl(
        this.getdata?.enableWelcomeText,
        Validators.required
      ),
      productImages: ['', Validators.required],
      sku: [this.getdata.sku],
      active: [true],
      available: [true],
      status: new FormControl(this.getdata.status),
      minimumOrder: [this.getdata.minimumOrder],
      deliverySpeed: [this.getdata.deliverySpeed],
      flavour: this.formBuilder.array([]),
      updatepricedata: new FormArray([this.getPriceData([])]),
    });

    if (this.getdata.isKg == true) {
      this.kgText = 'Kg';
    } else {
      this.kgText = 'Quantity';
    }

    this.isPan(this.getdata.isPanIndiaProduct);

    this.getSubCategoryName(this.getdata.productDetails.parentCategoryId);

    if (this.getdata.flavour.length > 0 && this.getdata.flavour[0].length > 2) {
      this.flavourData = this.getdata.flavour;
    } else {
      this.flavourData = [];
    }

    if (this.getdata.designImages[0].length > 5) {
      this.designImgurls = this.getdata.designImages;
    }

    if (this.getdata.productImages[0].length > 5) {
      this.designImgurls.push(this.getdata.productImages[0]);
      this.productImgUrl = this.getdata.productImages;
      this.defaultProductImageUrl = this.getdata.productImages[0];
    }
    var control = <FormArray>this.editForm.get('updatepricedata');
    this.priceValue = this.getdata.productDetails.pricing;
    control.clear();
    this.priceValue.forEach((element) => {
      control.push(this.getPriceData(element));
    });
    this.listingCheckBox = this.getdata.listings;
    //check listingbox
    if (this.getdata.listings.indexOf('featureCake') > -1 == true) {
      this.featureCake = true;
    }
    if (this.listingCheckBox.indexOf('trendingCake') > -1 == true) {
      this.trendingCake = true;
    }
    if (this.getdata.listings.indexOf('bestSellerCake') > -1 == true) {
      this.bestSellerCake = true;
    }
    this.selectedDesignImgurls = this.designImgurls.filter(
      (e) => this.productImgUrl != e
    );
  }
  featureCake = false;
  trendingCake = false;
  bestSellerCake = false;

  imageCall() { }
  // convenience getter for easy access to form fields
  get f() {
    return this.editForm.controls;
  }

  onSubmit() {
    console.log(this.editForm, 'this.editform');
    this.editForm
      .get('productDetails')
      .get('pricing')
      .setValue(this.priceValue);
    this.editForm.controls['listings'].setValue(this.listingCheckBox);
    this.editForm.controls['productImages'].setValue(this.productImgUrl);
    this.editForm.controls['designImages'].setValue(this.selectedDesignImgurls);
    console.log(this.editForm.value);

    this.editForm.setControl(
      'flavour',
      this.formBuilder.array(this.flavourData || [])
    );

    this.submitted = true;
    if (this.editForm.get('productName').invalid) {
      this.toastr.errorToastr('Cake Name is required', '');
      return;
    }

    if (
      (this.editForm.controls['productDetails'].value.parentCategoryId ==
        undefined ||
        this.editForm.controls['productDetails'].value.parentCategoryId ==
        null ||
        this.editForm.controls['productDetails'].value.parentCategoryId ==
        '') &&
      this.designType != 'GIFT'
    ) {
      console.log(this.editForm.controls['productDetails'].value);

      this.toastr.errorToastr('Parent Category is Required', '');
      return;
    }

    if (this.editForm.get('categoryId').invalid) {
      this.toastr.errorToastr('Sub Category is required', '');
      return;
    }

    if (this.editForm.get('sortOrder').invalid) {
      this.toastr.errorToastr('Sort Order is required', '');
      return;
    }

    if (
      (this.editForm.controls['productDetails'].value.egg == undefined ||
        this.editForm.controls['productDetails'].value.egg == null ||
        this.editForm.controls['productDetails'].value.egg == '') &&
      (this.editForm.controls['productDetails'].value.eggless == undefined ||
        this.editForm.controls['productDetails'].value.eggless == null ||
        this.editForm.controls['productDetails'].value.eggless == '') &&
      this.designType != 'GIFT'
    ) {
      console.log(this.editForm.controls['productDetails'].value);

      this.toastr.errorToastr('Made With is Required', '');
      return;
    }

    if (this.editForm.controls['productDetails'].value.pricing.length === 0) {
      this.toastr.errorToastr('Pricing is required', '');
      return;
    }
    if (this.productImgUrl.length === 0) {
      this.toastr.errorToastr('Design image is required', '');
      return;
    }
    if (this.editForm.get('deliverySpeed').invalid) {
      this.toastr.errorToastr('Delivery Time is required', '');
      return;
    }

    if (this.editForm.get('enableWelcomeText').invalid) {
      this.toastr.errorToastr('Message is required', '');
      return;
    }

    if (this.editForm.get('productPoints').invalid) {
      this.toastr.errorToastr('Product Points is required', '');
      return;
    }
    if (
      (this.editForm.get('paymentDetails').get('cod').value == undefined ||
        this.editForm.get('paymentDetails').get('cod').value == null ||
        this.editForm.get('paymentDetails').get('cod').value == '') &&
      (this.editForm.get('paymentDetails').get('online').value == undefined ||
        this.editForm.get('paymentDetails').get('online').value == null ||
        this.editForm.get('paymentDetails').get('online').value == '')
    ) {
      this.toastr.errorToastr(' Delivery Type is required', '');
      return;
    }
    if (this.editForm.get('isPanIndiaProduct').invalid) {
      this.toastr.errorToastr("It's Pan-India Cake is required", '');
      return;
    }
    // stop here if form is invalid
    if (this.editForm.invalid) {
      this.toastr.errorToastr('Fill All required fields', '');
      return;
    }
    if (this.isEdited == true) {
      this.loader = 'show';
      this.http
        .put(
          '/admin/product/' + this.productId + '/' + this.getdata.version,
          this.editForm.value
        )
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Product Updated Successfully', '');
            this.get();
            this.submitted = false;
            this.router.navigateByUrl('/products');
          },
          (error) => {
            this.toastr.errorToastr('Try Again update', '');
            this.loader = '';
          }
        );
    } else {
      console.log(this.editForm.value);

      this.loader = 'show';
      this.http.post('/admin/product', this.editForm.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Product created  Successfully', '');
          this.emptyForm();
          this.priceValue = [];
          this.router.navigateByUrl('/products');
        },
        (error) => {
          this.toastr.errorToastr('Try Again', '');
          this.loader = '';
        }
      );
    }
  }

  //add flavour
  flavourData = [];
  @ViewChild('addFlavour') addFlavour: ElementRef;
  onSubmitFlavour(flavour) {
    if (flavour) {
      this.flavourData.push(flavour);
      this.addFlavour.nativeElement.value = '';
    }
  }
  removeflavour(index) {
    this.flavourData.splice(index, 1);
  }

  //////////////////////////// Pricing Section//////////////////////////////////////

  //empty pice
  emptyPriceForm() {
    this.priceForm = this.formBuilder.group({
      sku: [''],
      kg: [''],
      price: [''],
      flavour: [''],
      discount: ['0'],
      net: [0],
      gross: [0],
    });
  }

  @ViewChild('discount') discount: ElementRef;
  @ViewChild('amount') amount: ElementRef;
  @ViewChild('flavour') flavour: ElementRef;
  @ViewChild('grossWeight') grossWeight: ElementRef;
  @ViewChild('netWeight') netWeight: ElementRef;

  @ViewChild('sku') sku: ElementRef;
  @ViewChild('file') file: ElementRef;
  priceFieldRequired = '';
  WeightFieldRequired = '';
  flavourFieldRequired = '';
  grossFieldRequired = '';
  netFieldRequired = '';
  grossFieldZero = '';
  netFieldZero = '';
  priceValue = [];
  //add price
  showWeight;

  // Adding Price and Quantity
  onSubmitPrice(weight, flavour, discount, amount, gross?: any, net?: any) {
    console.log('bulk add working----> 1');
    var control = <FormArray>this.editForm.get('updatepricedata');
    if (this.priceValue.length == 0) {
      control.clear();
    }
    this.priceFieldRequired = '';
    this.WeightFieldRequired = '';
    this.flavourFieldRequired = '';
    this.grossFieldRequired = '';
    this.netFieldRequired = '';
    this.grossFieldZero = '';
    this.netFieldZero = '';
    // check already  has same variant added.
    var itemsFound = this.priceValue.find(
      (item) => item.kg == weight && item.flavour == flavour
    );
    if (itemsFound && this.designType != 'GIFT') {
      this.toastr.errorToastr(
        'Items has already added. Remove it, then add.',
        ''
      );
      return;
    }

    if (
      checkNull(weight) &&
      checkNull(amount) &&
      checkNull(gross) &&
      gross > 0 &&
      net > 0 &&
      checkNull(net)
    ) {
      if (this.designType == 'GIFT') {
        this.IsShowPricing = false;
      }

      this.priceForm = this.formBuilder.group({
        // sku: [sku],
        kg: [weight],
        price: [amount],
        flavour: [flavour],
        discount: [discount],
        gross: [gross > 0 ? gross : 0],
        net: [net > 0 ? net : 0],
      });

      this.priceValue.push(this.priceForm.value);
      this.priceValue = this.priceValue.sort((x, y) => x.kg - y.kg);
      control.clear();
      this.priceValue.forEach((element) => {
        control.push(this.getPriceData(element));
      });
      this.weight.nativeElement.value = '';
      this.amount.nativeElement.value = '';
      this.flavour.nativeElement.value = '';
      this.discount.nativeElement.value = '0';
      this.netWeight.nativeElement.value = '';
      this.grossWeight.nativeElement.value = '';
      this.emptyPriceForm();
    } else {
      if (!weight && !amount) {
        this.priceFieldRequired = 'show';
        this.WeightFieldRequired = 'show';
      } else if (!weight) {
        this.WeightFieldRequired = 'show';
      } else if (!amount) {
        this.priceFieldRequired = 'show';
      } else if (!gross) {
        this.grossFieldRequired = 'show';
      } else if (!net) {
        this.netFieldRequired = 'show';
      } else if (gross == 0) {
        this.grossFieldZero = 'show';
      } else if (net == 0) {
        this.netFieldZero = 'show';
      } else {
        this.priceFieldRequired = 'show';
        this.WeightFieldRequired = 'show';
        this.grossFieldRequired = 'show';
        this.netFieldRequired = 'show';
      }
    }
  }

  priceFieldUpdatedRequired = '';
  WeightFieldUpdatedRequired = '';
  flavourFieldUpdatedRequired = '';
  updatePrice(index) {
    var control = <FormArray>this.editForm.get('updatepricedata');
    // if(this.priceValue.length == 0){
    //   control.clear();
    // }
    this.priceFieldUpdatedRequired = '';
    this.WeightFieldUpdatedRequired = '';
    this.flavourFieldUpdatedRequired = '';
    // check already  has same variant added.
    var itemsFound = this.priceValue.find(
      (item) =>
        item.kg == control.value[index].kg &&
        item.flavour == control.value[index].flavour
    );
    let alreadyUsedIndex = this.priceValue.findIndex(
      (item) =>
        item.kg == control.value[index].kg &&
        item.flavour == control.value[index].flavour
    );
    console.log(index != alreadyUsedIndex, index, alreadyUsedIndex, 'index');
    if (itemsFound && this.designType != 'GIFT' && index != alreadyUsedIndex) {
      this.toastr.errorToastr(
        'Items has already added. Remove it, then add.',
        ''
      );
      return;
    }

    if (control.value[index].kg && control.value[index].price) {
      if (this.designType == 'GIFT') {
        this.IsShowPricing = false;
      }

      let obj = {
        kg: control.value[index].kg,
        price: control.value[index].price,
        flavour: control.value[index].flavour,
        discount: control.value[index].discount,
        net: control.value[index].net,
        gross: control.value[index].gross,
      };
      this.priceValue[index] = obj;
      this.priceValue = this.priceValue.sort((x, y) => x.kg - y.kg);
      control.clear();
      this.priceValue.forEach((element) => {
        control.push(this.getPriceData(element));
      });
      this.isEditPriceDetail = false;
      this.isEditPriceIndex = -1;
    } else {
      if (!control.value[index].kg && !control.value[index].price) {
        this.priceFieldUpdatedRequired = 'show';
        this.WeightFieldUpdatedRequired = 'show';
      } else if (!control.value[index].kg) {
        this.WeightFieldUpdatedRequired = 'show';
      } else if (!control.value[index].price) {
        this.priceFieldUpdatedRequired = 'show';
      } else {
        this.priceFieldUpdatedRequired = 'show';
        this.WeightFieldUpdatedRequired = 'show';
      }
    }
  }
  removePricing(index) {
    console.log(this.priceValue, 'here its b4 deleting', index);
    this.priceValue.splice(index, 1);
    let control = <FormArray>this.editForm.get('updatepricedata');
    if (control?.length > 0) {
      control.removeAt(index);
    }
    console.log(this.priceValue, 'here its  deleting');
    this.IsShowPricing = true;
  }

  //drag & drop pricing
  dropPrice(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.priceValue, event.previousIndex, event.currentIndex);
  }

  ////////////////////////////End  Pricing Section//////////////////////////////////////

  //cake listing
  listingCheckBox = [];
  changeCheckbox(item: any, event) {
    if (event.target.checked === true) {
      //push
      this.listingCheckBox = [...this.listingCheckBox, item];
    } else {
      //remove
      this.listingCheckBox = this.listingCheckBox.filter(
        (user) => user !== item
      );
    }
  }
  changeDeliveryType(item: any, event) {
    if (event.target.checked === true) {
      //push
      if (item == 'online') {
        this.editForm
          .get('paymentDetails')
          .get('online')
          .setValue(event.target.checked);
      } else if (item == 'cod') {
        this.editForm
          .get('paymentDetails')
          .get('cod')
          .setValue(event.target.checked);
      }
    } else {
      //remove
      if (item == 'online') {
        this.editForm
          .get('paymentDetails')
          .get('online')
          .setValue(event.target.checked);
      } else if (item == 'cod') {
        this.editForm
          .get('paymentDetails')
          .get('cod')
          .setValue(event.target.checked);
      }
    }
  }
  //////////////////////////// Image Section //////////////////////////////////////

  //image2
  fileImgDesign;
  fileDesignName;
  designImgurlData;

  /*designImg(event: any) {
    var filesAmount = event.target.files.length;
    console.log(filesAmount, this.designImgurls);
    
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.fileImgDesign = event.target.files[0];
      this.fileDesignName = this.fileImgDesign['name'];

      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          if (this.fileImgDesign) {
            this.loader = 'show';
            const formData: FormData = new FormData();
            let dataType = this.fileDesignName.split('.').splice(-1);

            if (dataType == 'mp4') {
              formData.append('video', this.fileImgDesign, this.fileDesignName);
              this.http.post('/uploadVideo', formData).subscribe(
                (data) => {
                  this.designImgurlData = data;
                  this.loader = '';
                  this.designImgurls.push(this.designImgurlData.path);
                  this.setDefaultImage(this.designImgurlData.path);
                },
                (error) => {
                  this.toastr.errorToastr('Try Again', '');
                  this.loader = '';
                }
              );
            } else {
              formData.append('image', this.fileImgDesign, this.fileDesignName);
              this.http.post('/uploadimage', formData).subscribe(
                (data) => {
                  this.designImgurlData = data;
                  this.loader = '';
                  this.designImgurls.push(this.designImgurlData.path);
                  this.setDefaultImage(this.designImgurlData.path);
                },
                (error) => {
                  this.toastr.errorToastr('Try Again', '');
                  this.loader = '';
                }
              );
            }
          }
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  } */

  designImg(event: any) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      this.fileImgDesign = event.target.files[0];
      this.fileDesignName = this.fileImgDesign['name'];
      const max_width = 1442;
      const max_height = 1229;
      const filetypes = [
        'image/jpeg',
        'image/png',
        'video/mp4',
        'image/jpg',
        'image/jfif',
      ];

      // file size validation

      if (this.fileImgDesign.size < 1024 * 1024 * 50) {
        // file type validation

        if (!_.includes(filetypes, this.fileImgDesign.type)) {
          this.toastr.errorToastr(
            'File type should be of images/jpeg,images/png,images/jpg,images/jfif',
            ''
          );
          this.loader = '';
          this.file.nativeElement.value = '';
        } else {
          const reader = new FileReader();
          const image = new Image();
          reader.readAsDataURL(this.fileImgDesign);
          reader.onload = (read: any) => {
            if (this.fileImgDesign) {
              this.loader = 'show';
              const formData: FormData = new FormData();
              let dataType = this.fileDesignName.split('.').splice(-1);
              if (this.fileImgDesign.type == 'video/mp4') {
                formData.append(
                  'image',
                  this.fileImgDesign,
                  this.fileDesignName
                );
                formData.append('fileType', 'product');
                formData.append('thumbnail', 'false');
                this.http.post('/uploadimage', formData).subscribe(
                  (data) => {
                    this.designImgurlData = data;
                    this.loader = '';
                    this.designImgurls.push(this.designImgurlData.path);
                    this.setDefaultImage(this.designImgurlData.path);
                    this.file.nativeElement.value = '';
                  },
                  (error) => {
                    this.toastr.errorToastr('Try Again', '');
                    this.loader = '';
                    this.file.nativeElement.value = '';
                  }
                );
              } else {
                // file dimension validation
                image.src = read.target.result;
                image.onload = (load: any) => {
                  const height = load.currentTarget['height'];
                  const width = load.currentTarget['width'];

                  if (height == max_height && width == max_width) {
                    formData.append(
                      'image',
                      this.fileImgDesign,
                      this.fileDesignName
                    );
                    formData.append('fileType', 'product');
                    formData.append('thumbnail', 'true');
                    this.http.post('/uploadimage', formData).subscribe(
                      (data) => {
                        this.designImgurlData = data;
                        this.loader = '';
                        this.designImgurls.push(this.designImgurlData.path);
                        this.setDefaultImage(this.designImgurlData.path);
                        this.file.nativeElement.value = '';
                      },
                      (error) => {
                        this.toastr.errorToastr('Try Again', '');
                        this.loader = '';
                        this.file.nativeElement.value = '';
                      }
                    );
                  } else {
                    this.toastr.errorToastr(
                      'File dimension should be ' +
                      max_width +
                      '×' +
                      max_height +
                      'pixels',
                      ''
                    );
                    this.loader = '';
                    this.file.nativeElement.value = '';
                  }
                };
              }
            }
          };
        }
      } else {
        this.toastr.errorToastr('File size should be less than 50Mb', '');
        this.loader = '';
        this.file.nativeElement.value = '';
      }
    }
  }

  designImgRemove(index) {
    if (this.designImgurls.length == 1) {
      console.log(this.designImgurls, ' image url array 1');
      this.defaultProductImageUrl = '';
      this.productImgUrl = [];
      this.selectedDesignImgurls = [];
      this.designImgurls = [];
    } else {
      console.log(this.designImgurls, ' image url array 2');

      this.designImgurls.splice(index, 1);
      this.selectedDesignImgurls.splice(index - 1, 1);
    }
  }

  videoOrImg(item) {
    let dataType = item.split('.').splice(-1);
    if (dataType == 'mp4') {
      return 'video';
    } else {
      return 'img';
    }
  }

  setDefaultImage(url) {
    if (this.designImgurls.length == 1) {
      this.productImgUrl = [url];
      this.selectedDesignImgurls = [];
      this.defaultProductImageUrl = url;
    } else {
      let data = this.selectedDesignImgurls.push(url);
    }
  }

  GetImage(e, url) {
    this.defaultProductImageUrl = url; // set as default
    if (this.designImgurls.length == 1) {
      this.productImgUrl = [url];
      this.selectedDesignImgurls = [];
    } else if (this.designImgurls.length > 1) {
      this.productImgUrl = [];
      this.productImgUrl.push(this.designImgurls[e]);
      this.selectedDesignImgurls = this.designImgurls.filter(
        (x) => x != this.productImgUrl[0]
      );
    }
  }

  productImgRemove(index) {
    this.productImgUrl.splice(index, 1);
  }

  ////////////////////////////End Image Section //////////////////////////////////////

  //Text Editor
  configTextbox: any = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage'], ['insertVideo'], ['customClasses']],
  };

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    console.log(charCode > 31 && (charCode < 48 || charCode > 57), charCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
    $('input[type=number]').on('keydown', function (e) {
      if (e.keyCode === 188) {
        this.value += '.';
        e.preventDefault();
      }
    });
  }
}
