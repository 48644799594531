import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ReportDTO } from '../_services/report.dto';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ReportService } from '../_services/report.service';
import { PuppysCustomValidation, sweetAlert } from 'src/app/shared/validations/puppys-custom';
import { checkNull, queryMaker } from 'src/app/constants/custom-functions';
import * as moment from 'moment';
@Component({
  selector: 'app-coupon-code-summary-report',
  templateUrl: './coupon-code-summary-report.component.html',
  styleUrls: ['./coupon-code-summary-report.component.css']
})
export class CouponCodeSummaryReportComponent implements OnInit {


  filterForm: FormGroup;
  pagination: ReportDTO;
  customers: any[] = [];
  products: any[] = []
  selectboxSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'view',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  }
  couponTypeOptions: any[] = [
    { view: 'General', value: '1' },
    { view: 'Customer', value: '2' },
    { view: 'Product', value: '3' },
    { view: 'Birthday', value: '4' },
    { view: 'Work Anniversary', value: '5' },
    // { view: 'Wedding Anniversary', value: '6' },
    // { view: 'Valentine\'s day', value: '7' },
    // { view: 'Children\'s Day', value: '8' },
  ]

  // https://prnt.sc/400wuFGp8r59
  discountTypeOptions: any[] = [
    { view: 'Amount', value: 'true' },
    { view: 'Percentage', value: 'false' },
  ]

  eligibilityOptions: any[] = [
    { view: 'New users', value: 'false' },
    { view: 'All users', value: 'true' },
  ]

  statusOptions: any[] = [
    { view: 'Upcoming', value: '1' },
    { view: 'Ongoing', value: '2' },
    { view: 'Expired', value: '3' }
  ]

  reports: any[] = []


  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: ReportService,
    private router: Router
  ) {
    this.intialForm()
    this.pagination = new ReportDTO()
  }

  ngOnInit(): void {

    this.getAllCustomers();
    this.getAllProducts()
  }

  intialForm() {
    this.filterForm = new FormGroup({
      // created On
      couponFromDate: new FormControl(''),
      couponToDate: new FormControl(''),
      // available from
      couponAvailStartDate: new FormControl(''),
      couponAvailEndDate: new FormControl(''),

      couponTypeId: new FormControl(''),
      customerId: new FormControl(''),
      productId: new FormControl(''),

      discountTypeId: new FormControl(''),

      customerEligibility: new FormControl(''),
      showToUser: new FormControl(''),

      couponStatusId: new FormControl('')


    }, { validators: [PuppysCustomValidation.DateValidator('couponFromDate', 'couponToDate'), PuppysCustomValidation.DateValidator2('couponAvailStartDate', 'couponAvailEndDate'),] })
  }


  async getAllProducts() {
    await this.service.getAllProducts().subscribe((res: any) => {
      this.products = res ?? []
    }, (err: any) => {
      this.products = []
    })
  }

  async getAllCustomers() {
    await this.service.getAllCustomers().subscribe((res: any) => {
      this.customers = res ?? []
    }, (err: any) => {
      this.customers = []
    })
  }


  onCouponTypeChange(event: any) {
    // console.log(event.target.value);
    // let value = event.target.value;
    let productControl = this.filterForm.get("productId") as FormControl;
    let customerControl = this.filterForm.get("customerId") as FormControl;
    productControl.setValue('');
    customerControl.setValue('');
    productControl.updateValueAndValidity();
    customerControl.updateValueAndValidity()
  }



  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }


  reset() {
    this.intialForm()
    this.filterForm.updateValueAndValidity();
    this.pagination = new ReportDTO();
    this.reports = []
  }

  getCouponSummaryReport(filter?: boolean) {

    const form = this.filterForm.value;
    console.log(this.filterForm.value);

    /**
 *    couponFromDate
    couponToDate
    couponAvailStartDate
    couponAvailEndDate
 */

    if (filter && false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the coupon summary report!')
      return
    }

    if (this.filterForm.valid) {

      // Validation for from and to date

      if (checkNull(form?.couponToDate) && checkNull(form.couponFromDate)) {
        this.pagination.couponFromDate = moment(form.couponFromDate).format('YYYY-MM-DD')
        this.pagination.couponToDate = moment(form.couponToDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.couponToDate) && !checkNull(form.couponFromDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.couponToDate) && checkNull(form.couponFromDate)) {
        this.pagination.couponFromDate = moment(form.couponFromDate).format('YYYY-MM-DD')
        this.pagination.couponToDate = moment(new Date()).format('YYYY-MM-DD')
      }


      // validation for start and end date

      if (checkNull(form?.couponAvailEndDate) && checkNull(form.couponAvailStartDate)) {
        this.pagination.couponAvailStartDate = moment(form.couponAvailStartDate).format('YYYY-MM-DD')
        this.pagination.couponAvailEndDate = moment(form.couponAvailEndDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.couponAvailEndDate) && !checkNull(form.couponAvailStartDate)) {
        sweetAlert('error', 'Start date is required')
        return
      }

      if (!checkNull(form?.couponAvailEndDate) && checkNull(form.couponAvailStartDate)) {
        this.pagination.couponAvailStartDate = moment(form.couponAvailStartDate).format('YYYY-MM-DD')
        this.pagination.couponAvailEndDate = moment(new Date()).format('YYYY-MM-DD')
      }




      if (checkNull(form?.couponTypeId)) {
        this.pagination.couponTypeId = form?.couponTypeId
      }

      if (checkNull(form.customerId)) {
        this.pagination.customerId = form?.customerId
      }
      if (checkNull(form.productId)) {
        this.pagination.productId = form?.productId
      }

      if (checkNull(form.discountTypeId)) {
        this.pagination.discountTypeId = form.discountTypeId.map((ele) => ele?.value)
      } else {
        this.pagination.discountTypeId = ''
      }

      if (checkNull(form.couponStatusId)) {
        this.pagination.couponStatusId = form.couponStatusId.map((ele) => ele?.value)
      } else {
        this.pagination.couponStatusId = ''
      }

      if (checkNull(form.customerEligibility)) {
        this.pagination.customerEligibility = form.customerEligibility.map((ele) => ele?.value)
      } else {
        this.pagination.customerEligibility = ''
      }

      queryMaker(this.pagination)

      this.loader.open()
      this.service.getCouponSummaryReport(this.pagination).subscribe((res: any) => {
        this.loader.close();
        this.reports = res?.content ?? [];
        console.log(res);

      }, (err: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again');
        this.reports = []
      })
    } else {
      let has = this.filterForm.errors?.invalidDate2 ? false : true
      sweetAlert('error', `${has == true ? 'From' : 'Start'} date should be before ${has == true ? 'to' : 'end'} date`)
    }


  }

  export() {
    let form = this.filterForm.value
    if (false) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter the coupon summary report!')
      return
    }

    if (this.filterForm.valid) {

      // Validation for from and to date

      if (checkNull(form?.couponToDate) && checkNull(form.couponFromDate)) {
        this.pagination.couponFromDate = moment(form.couponFromDate).format('YYYY-MM-DD')
        this.pagination.couponToDate = moment(form.couponToDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.couponToDate) && !checkNull(form.couponFromDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.couponToDate) && checkNull(form.couponFromDate)) {
        this.pagination.couponFromDate = moment(form.couponFromDate).format('YYYY-MM-DD')
        this.pagination.couponToDate = moment(new Date()).format('YYYY-MM-DD')
      }


      // validation for start and end date

      if (checkNull(form?.couponAvailEndDate) && checkNull(form.couponAvailStartDate)) {
        this.pagination.couponAvailStartDate = moment(form.couponAvailStartDate).format('YYYY-MM-DD')
        this.pagination.couponAvailEndDate = moment(form.couponAvailEndDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.couponAvailEndDate) && !checkNull(form.couponAvailStartDate)) {
        sweetAlert('error', 'Start date is required')
        return
      }

      if (!checkNull(form?.couponAvailEndDate) && checkNull(form.couponAvailStartDate)) {
        this.pagination.couponAvailStartDate = moment(form.couponAvailStartDate).format('YYYY-MM-DD')
        this.pagination.couponAvailEndDate = moment(new Date()).format('YYYY-MM-DD')
      }




      if (checkNull(form?.couponTypeId)) {
        this.pagination.couponTypeId = form?.couponTypeId
      }

      if (checkNull(form.customerId)) {
        this.pagination.customerId = form?.customerId
      }
      if (checkNull(form.productId)) {
        this.pagination.productId = form?.productId
      }


      if (checkNull(form.discountTypeId)) {
        this.pagination.discountTypeId = form.discountTypeId.map((ele) => ele?.value)
      } else {
        this.pagination.discountTypeId = ''
      }

      if (checkNull(form.couponStatusId)) {
        this.pagination.couponStatusId = form.couponStatusId.map((ele) => ele?.value)
      } else {
        this.pagination.couponStatusId = ''
      }

      if (checkNull(form.customerEligibility)) {
        this.pagination.customerEligibility = form.customerEligibility.map((ele) => ele?.value)
      } else {
        this.pagination.customerEligibility = ''
      }

      queryMaker(this.pagination)
      this.service.exportReport(`/coupon/summary/report/export${queryMaker(this.pagination)}`, 'Coupon Summary Report')

    } else {
      let has = this.filterForm.errors?.invalidDate2 ? false : true
      sweetAlert('error', `${has == true ? 'From' : 'Start'} date should be before ${has == true ? 'to' : 'end'} date`)
    }



  }


  checkNull(data) {
    return checkNull(data)
  }


  // Non-Developed



  tableData = [
    {
      createdOn: '26-02-2024',
      couponCode: 'VA#00AQ1',
      couponType: 'Product',
      couponName: '-',
      customerName: '-',
      productName: 'Themed Cake',
      discountType: 'Percentage',
      cusEligibility: 'New User',
      couponLimit: '-',
      usedCoupon: '-',
      available: '-',
      showUser: 'Yes',
      reminder: '12 Days',
      startDate: "12-04-2024",
      endDate: "15-04-2024",
      status: 'Expired'
    },
    {
      createdOn: '16-03-2024',
      couponCode: 'VA#00AQ1',
      couponType: 'General',
      couponName: 'BirthDay Coupon',
      customerName: '-',
      productName: '-',
      discountType: 'Flat',
      cusEligibility: 'Everyone',
      couponLimit: '100',
      usedCoupon: '500',
      available: '500',
      showUser: 'Yes',
      reminder: '10 Days',
      startDate: "12-04-2024",
      endDate: "15-04-2024",
      status: 'Ongoing'
    },

    {
      createdOn: '26-02-2024',
      couponCode: 'VA#00AQ1',
      couponType: 'Customer',
      couponName: '-',
      customerName: 'Rajesh',
      productName: '-',
      discountType: 'Percentage',
      cusEligibility: 'New User',
      couponLimit: '-',
      usedCoupon: '-',
      available: '-',
      showUser: 'Yes',
      reminder: '2 Days',
      startDate: "12-04-2024",
      endDate: "15-04-2024",
      status: 'Ongoing'
    },

    {
      createdOn: '26-02-2024',
      couponCode: 'VA#00AQ1',
      couponType: 'Product',
      couponName: '-',
      customerName: '-',
      productName: 'Themed Cake',
      discountType: 'Flat',
      cusEligibility: 'Everyone',
      couponLimit: '100',
      usedCoupon: '500',
      available: '500',
      showUser: 'Yes',
      reminder: '3 Days',
      startDate: "12-04-2024",
      endDate: "15-04-2024",
      status: 'Upcoming'
    },

  ];


}
