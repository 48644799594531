import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;
import { PermissionService } from 'src/app/services/core/permission.service';

@Component({
  selector: 'app-deliveries-list',
  templateUrl: './deliveries-list.component.html',
  styleUrls: ['./deliveries-list.component.css'],
})
export class DeliveriesListComponent implements OnInit {
  adminAccess;
  loader;
  form: FormGroup;
  submitted = false;
  isEdited;

  constructor(
    public http: HttpService,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public permission: PermissionService
  ) { }

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.loader = '';
    this.submitted = false;
    this.emptyForm();
    this.get();
  }

  //form
  emptyForm() {
    this.submitted = false;
    this.form = this.formBuilder.group({
      city: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern('^[0-9]{6,6}$')]],
      createdOn: [''],
      // minimumAmount: ['',Validators.required],
      deliveryCharge: ['', [Validators.required, Validators.min(0)]],
      enablemidDelivery: ['', Validators.required],
      middeliveryCharge: ['', Validators.required],
      updatedOn: [''],
      active: true,
    });
  }

  //get delivery pincode
  getdata2;
  getdata;
  get() {
    this.loader = 'show';
    this.http.get('/admin/delivery').subscribe(
      (data) => {
        console.log(data);
        this.getdata = data;
        this.loader = '';
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }

  //add pincode
  create() {
    this.emptyForm();
    $('#createModal').modal('show');
    this.isEdited = false;
  }

  //edit pincode
  onClickEdit(item) {
    this.isEdited = true;
    $('#createModal').modal('show');
    if (item?.middeliveryCharge != null) {
      this.form.get('middeliveryCharge').setValidators(Validators.required);
      this.form.updateValueAndValidity()
    } else {
      this.form.get('middeliveryCharge').clearValidators()
      this.form.updateValueAndValidity()
    }
    this.form = this.formBuilder.group({
      deliveryId: [item.deliveryId],
      city: [item.city, Validators.required],
      pincode: [
        item.pincode,
        [Validators.required, Validators.pattern('^[0-9]{6,6}$')],
      ],
      createdOn: [''],
      // minimumAmount: [item.minimumAmount,Validators.required],
      deliveryCharge: [item.deliveryCharge,[Validators.required, Validators.min(0)]],
      enablemidDelivery: [String(item.enablemidDelivery), Validators.required],
      middeliveryCharge: [item.middeliveryCharge],
      updatedOn: [''],
      active: true,
    });
    console.log(item, '-------------------> edit call');

  }

  //submit form
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    console.log('let me check that now -------------->', this.form.controls);

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.isEdited == true) {
      this.loader = 'show';

      this.http
        .put('/admin/delivery/' + this.form.value.deliveryId, this.form.value)
        .subscribe(
          (data) => {
            this.loader = '';
            this.toastr.successToastr('Successfully Submitted', '');
            this.emptyForm();
            this.get();
            $('#createModal').modal('hide');
          },
          (error) => {
            this.loader = '';
            this.toastr.errorToastr('Try Again', '');
          }
        );
    } else {
      this.loader = 'show';
      this.http.post('/admin/delivery', this.form.value).subscribe(
        (data) => {
          this.loader = '';
          this.toastr.successToastr('Successfully Submitted', '');
          this.emptyForm();
          this.get();
          $('#createModal').modal('hide');
        },
        (error) => {
          this.loader = '';
          console.log(error);
          this.toastr.errorToastr(error?.message ? error?.message : 'Try Again', '');
        }
      );
    }
  }

  //delete pincode
  deleteData(id) {
    this.loader = 'show';
    this.http.delete('/admin/delivery/' + id).subscribe(
      (data) => {
        this.toastr.successToastr('Deleted Successfully', '');
        this.loader = '';
        this.get();
      },
      (error) => {
        this.loader = '';
        this.toastr.errorToastr('Try Again', '');
      }
    );
  }

  midDelivery(data) {
    if (data == 'true') {
      this.form.get('middeliveryCharge').setValidators([Validators.required]);
      this.form.get('middeliveryCharge').updateValueAndValidity()
    }
    else {
      this.form.get('middeliveryCharge').setValue('')
      this.form.get('middeliveryCharge').clearValidators();
      this.form.get('middeliveryCharge').updateValueAndValidity()
      console.log(this.form.value);


    }
  }

  close() {
    $('#createModal').modal('hide')
  }
}
