import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { HttpService } from '../../services/http/http.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppConstants } from '../../constants/app.constants';
import { PermissionService } from 'src/app/services/core/permission.service';
import { checkNull } from 'src/app/constants/custom-functions';
import { OrdersCalculation, setCalculation } from 'src/app/shared/service/calculation';
interface HistoryStatus {
  name, status?, delay?, cancel?, all?
}

@Component({
  selector: 'app-order-detail-list',
  templateUrl: './order-detail-list.component.html',
  styleUrls: ['./order-detail-list.component.css'],
})
export class OrderDetailListComponent implements OnInit {
  List;
  imgUrl;
  orderId = '';
  loader;
  adminAccess;
  GST: number;
  NetAmount: number;
  homeAddress: boolean = true;
  storeAddress: boolean = true;
  constructor(
    public http: HttpService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    public rolepermission: PermissionService
  ) { }

  ngOnInit(): void {
    this.adminAccess = JSON.parse(localStorage.getItem('currentUser'));
    this.imgUrl = AppConstants.SERVER_CONFIG.IMG_URL;
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.get();
    this.emptyForm();
  }

  changeNumber(data) {
    return Number(data)
  }

  checkNull(data) {
    return checkNull(data);
  }



  showStatus = ['Pending', 'Accepted', 'Baking', 'Shipped']
  displayNext(status: 'Pending' | 'Accepted' | 'Baking' | 'Shipped', item): HistoryStatus {

    let data = item?.data
    const isStore = data.lineItemsInfo.every((ele: any) => ele.attribute?.store == true)
    const hasLocal = data.lineItemsInfo.every((ele: any) => checkNull(ele.attribute?.deliveryIn))
    const self = this
    function hasPermission(actions: any[]) {
      return self.rolepermission.hasPermission(['order_history'], actions)
    }



    switch (status) {

      case 'Pending': {

        const allActions = hasPermission(['order_history_pending_cancel', 'order_history_pending_to_accepted', 'order_history_pending_delay_time']);
        const cancelActions = hasPermission(['order_history_pending_cancel']);
        const statusActions = hasPermission(['order_history_pending_to_accepted']);
        const delayActions = hasPermission(['order_history_pending_delay_time'])

        return { name: 'Change to Accepted', all: allActions, cancel: cancelActions, status: statusActions, delay: delayActions }
      };
        break
      case 'Accepted': {
        const allActions = hasPermission(['order_history_accepted_cancel', 'order_history_accepted_to_accepted', 'order_history_accepted_delay_time']);
        const cancelActions = hasPermission(['order_history_accepted_cancel']);
        const statusActions = hasPermission(['order_history_accepted_to_baking']);
        const delayActions = hasPermission(['order_history_accepted_delay_time'])
        return { name: 'Change to Cake is Baking', all: allActions, cancel: cancelActions, status: statusActions, delay: delayActions }
      }
        break
      case 'Baking': {
        const allActions = hasPermission(['order_history_baking_cancel', 'order_history_baking_to_accepted', 'order_history_baking_delay_time', 'order_history_shipped_to_delivered']);
        const cancelActions = hasPermission(['order_history_baking_cancel']);
        const statusActions = hasPermission(['order_history_baking_to_shipped', 'order_history_shipped_to_delivered']);
        const delayActions = hasPermission(['order_history_baking_delay_time'])
        if (isStore) {


          return { name: 'Change to Delivered', all: allActions, cancel: cancelActions, status: statusActions && hasLocal, delay: delayActions }
        } else {
          return { name: 'Change to On Transit', all: allActions, cancel: cancelActions, status: statusActions && hasLocal, delay: delayActions }
        }
      }
        break
      case 'Shipped': {
        const allActions = hasPermission(['order_history_shipped_cancel', 'order_history_shipped_to_accepted', 'order_history_shipped_delay_time']);
        const cancelActions = hasPermission(['order_history_shipped_cancel']);
        const statusActions = hasPermission(['order_history_shipped_to_delivered']);
        const delayActions = hasPermission(['order_history_shipped_delay_time'])
        return { name: 'Change to Delivered', all: allActions, cancel: cancelActions, status: statusActions, delay: delayActions }
      }
        break
    }
  }


  changeOrderStatus(status: 'Pending' | 'Accepted' | 'Baking' | 'Shipped', item: any) {

    let data = item?.data
    const isStore = data.lineItemsInfo.every((ele: any) => ele.attribute?.store == true)
    switch (status) {

      case 'Pending': {
        this.orderConfirmed(item.orderId)
      };
        break
      case 'Accepted': {
        this.orderBaking(item.orderId)
      }
        break
      case 'Baking': {
        if (isStore) {
          this.orderDelivered(item.orderId)
        } else {
          this.orderShipped(item.orderId)
        }
      }
        break
      case 'Shipped': {
        this.orderDelivered(item.orderId)
      }
        break
    }

  }










  random: any
  trackOrder1: any

  openTrackOrder(data) {
    this.trackOrder1 = data
    this.random = Math.random()
    $('#trackOrderModal1').modal('show')
  }

  getdata;
  getdata2;
  user;
  itemCount;

  calculation: OrdersCalculation

  hasStore: any
  hasHome: any
  //get Order
  get() {
    this.loader = 'show';
    this.http.get('/admin/order/' + this.orderId).subscribe(
      (data: any) => {
        this.getdata2 = data;
        this.user = this.getdata2[0];


        this.getdata = this.getdata2[1];
        this.calculation = setCalculation(this.getdata2[1]);
        let lineItemsInfo: any = data[1]?.data?.lineItemsInfo

        this.hasStore = lineItemsInfo.some(ele => ele?.attribute?.store == true);
        this.hasHome = lineItemsInfo.some(ele => ele?.attribute?.door == true);

        Object.values(this.getdata2[1]?.homeAddress).length > 0 ? this.homeAddress = true : this.homeAddress = false;
        Object.values(this.getdata2[1]?.storeAddress).length > 0 ? this.storeAddress = true : this.storeAddress = false;
        // console.log(this.getdata2[1]?.homeAddress, "Product Details--------->");
        this.loader = '';
        this.itemCount = this.getdata.data.lineItemsInfo.length;

        this.getdata.data.lineItemsInfo.map((x) => {
          if (x.product.productImages) {
            x.product.productImages = x.product.productImages.split(',')[0];
          }
          if (x.product.sku) {
            x.product.designType = x.product.sku.replace(/[0-9,-]/g, '');
          }
        });

        this.getdata.calculation = setCalculation(this.getdata);




        if (this.getdata) {
          switch (this.getdata.status) {
            case 'InCart':
              this.getdata.statusDisplayName = 'Abandoned Carts';
              break;
            case 'Accepted':
              this.getdata.statusDisplayName = 'Accepted';
              break;
            case 'Baking':
              this.getdata.statusDisplayName = 'Cake is Baking';
              break;
            case 'Shipped':
              this.getdata.statusDisplayName = 'On Transit';
              break;
            default:
              this.getdata.statusDisplayName = this.getdata.status;
              break;
          }
        }
      },
      (error) => {
        this.toastr.errorToastr('Try Again', '');
        this.loader = '';
      }
    );
  }
  replaceBR(data) {
    return data.replaceAll(',', '<br>')
  }

  orderStatusCancel() {

    this.orderChangeStatus = '';
    this.orderStatusId = '';
    this.submitted = false;
    this.form.reset();

  }

  ////////////////Order Confirmation Modal Open ////////////////////////////////
  orderChangeStatus = '';
  orderMessage;
  orderStatusId = '';
  orderCancelled(id) {
    $('#confirm-order').modal('show');
    this.orderMessage = 'Are you sure you want to cancel this order?';
    this.orderChangeStatus = 'Cancelled';
    this.orderStatusId = id;
  }

  orderConfirmed(id) {
    $('#confirm-order').modal('show');
    this.orderMessage = 'Are you sure you want to Accept this order?';
    this.orderChangeStatus = 'Accepted';
    this.orderStatusId = id;
  }

  orderBaking(id) {

    $('#confirm-order').modal('show');
    this.orderMessage = 'Is Cake is Baking?';
    this.orderChangeStatus = 'Baking';
    this.orderStatusId = id;
  }

  orderDelivered(id) {
    $('#confirm-order').modal('show');
    this.orderMessage = 'Are you sure this order is delivered?';
    this.orderChangeStatus = 'Delivered';
    this.orderStatusId = id;
  }

  orderShipped(id) {
    $('#shipped-order').modal('show');
    this.orderChangeStatus = 'Shipped';
    this.orderStatusId = id;
    this.submitted = false;
  }
  ////////////////Order Confirmation Modal End ////////////////////////////////

  //Change Status
  orderChangeStatusfun() {
    if (this.orderStatusId == '' || this.orderChangeStatus == '') {
      this.toastr.errorToastr('Try Again', '');
      return;
    }

    this.loader = 'show';
    this.http
      .put(
        '/admin/order/' + this.orderStatusId + '/',
        JSON.parse('{ "status" : "' + this.orderChangeStatus + '"}')
      )
      .subscribe(
        (data) => {
          this.loader = '';
          this.get();
          this.toastr.successToastr('Successfully updated', '');
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        }
      );
  }

  form: FormGroup;
  submitted = false;
  shipError = false;
  get f() {
    return this.form.controls;
  }
  orderChangeToShipped(name, id, url) {
    this.shipError = false;
    if (name == '' || id == '' || url == '') {
      this.shipError = true;
      return;
    }

    if (this.orderStatusId == '' || this.orderChangeStatus != 'Shipped') {
      this.toastr.errorToastr('Try Again', '');
      return;
    }

    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.loader = 'show';
    this.http
      .put('/admin/order/' + this.orderStatusId + '/', this.form.value)
      .subscribe(
        (data) => {
          this.loader = '';
          $('#shipped-order').modal('hide');
          this.get();
          this.toastr.successToastr('Successfully updated', '');
        },
        (error) => {
          this.loader = '';
          this.toastr.errorToastr('Try Again', '');
        }
      );
  }

  emptyForm() {
    this.form = this.formBuilder.group({
      status: ['Shipped'],
      shippingDetails: new FormGroup({
        agentName: new FormControl(''),
        trackingId: new FormControl(''),
        trackingUrl: new FormControl(''),
      }),
    });
  }

  visibleIndex = -1;
  showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  getClickableLink(link) {
    var pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(link)) {
      return 0;
    } else {
      return 1;
    }
  }

  subTotal(item) {
    let discount: any = 0;
    let Total: any = 0;
    item?.lineItemsInfo.filter((res) => {
      // discount += res?.costDetails?.discount;
      Total += res?.costDetails?.itemPrice;
    });
    return Total;
  }

  GSTAmount(data) {
    var constVal = (1 + ((data?.costDetails?.taxPercentage / 100) * 1));
    let excGST = (this.getNetamout(data) / constVal);
    this.GST = (this.getNetamout(data) - excGST)
    return this.GST;
  }

  getNetamout(item) {
    return this.subTotal(item) - this.discount(item) - this.coupon(item);
  }

  netAmount(data) {
    this.NetAmount =
      data?.costDetails?.delivery -
      data?.costDetails?.discount +
      data?.costDetails?.price +
      this.getdata?.midnightCharge;
    return this.NetAmount;
  }

  discount(item) {
    let discount: any = 0;
    item?.lineItemsInfo.filter((res) => {
      discount += res?.costDetails?.discount;
    });
    return discount;
  }

  coupon(data: any) {
    let coupon = data?.costDetails?.discount;

    return coupon;
  }

  getTotal(delivery, netamout, midnightCharge) {
    let total = Math.round(Number(delivery) + Number(netamout) + Number(midnightCharge));
    return total
  }
  getroundOff(delivery, netamout, midnightCharge) {
    let realAmount = Number(delivery) + Number(netamout) + Number(midnightCharge);
    let total = Math.round(Number(delivery) + Number(netamout) + Number(midnightCharge));
    return total - realAmount
  }
  close() {
    $('#confirm-order').modal('hide')

  }
  close1() {
    $('#shipped-order').modal('hide')
  }

  newSubtotal(item: any) {
    let discount: any = 0;
    let Total: any = 0;
    item?.lineItemsInfo.filter((res) => {
      // discount += res?.costDetails?.discount;
      Total += res?.cost;
    });
    return Number(Total);
  }


  newTotalAmount(item: any) {

    let coupon = this.coupon(item?.data);
    let shipping = item?.panDeliveryCharge > 0 ? item?.panDeliveryCharge : item?.data?.costDetails?.delivery > 0 ? item?.data?.costDetails?.delivery : 0;
    let midnightCharge = item?.midnightCharge;
    let subTotal = this.newSubtotal(item?.data);

    let grandTotal = Math.round(Number(subTotal) + Number(midnightCharge) + Number(shipping) - Number(coupon));
    return Number(grandTotal).toFixed(2)

  }




}
