import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { CouponcodeService } from '../promocode-general/couponcode.service';
import { REGEXP, checkNull } from 'src/app/constants/custom-functions';
import { PuppysCustomValidation } from 'src/app/shared/validations/puppys-custom';
import { ErrorMessageV2Service } from 'src/app/services/otherServices/error-message-v2.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { PermissionService } from 'src/app/services/core/permission.service';
const couponLabels = [
  {
    "name": "prefix",
    "label": "coupon prefix",
    "type": "provide",
  },
  {
    "name": "reminder",
    "label": "reminder days",
    "type": "provide",
    "min": 1
  },
  {
    "name": "discountAmount",
    "label": "discount amount",
    "type": "provide",
    "min": 1
  },
  {
    "name": "discountPercentage",
    "label": "discount percentage",
    "type": "provide",
    "min": 1,
    "max": 100
  },
  {
    "name": "minimumAmount",
    "label": "minimum spend",
    "type": "provide",
    "min": 1,
  },
  {
    "name": "maximumAmount",
    "label": "maximum spend",
    "type": "provide",
    "min": 1,
  },

]
@Component({
  selector: 'app-occasion-update',
  templateUrl: './occasion-update.component.html',
  styleUrls: ['./occasion-update.component.css']
})
export class OccasionUpdateComponent implements OnInit {


  createForm: FormGroup;
  Occasions: any[] = [];
  active = 0;
  couponTypeId

  constructor(
    private loader: LoaderService,
    private toaster: ToastrManager,
    private service: CouponcodeService,
    private errorMessage: ErrorMessageV2Service,
    private activatedRoute: ActivatedRoute,
    public permission: PermissionService
  ) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.couponTypeId = res?.couponTypeId ?? ''
    })
  }

  ngOnInit(): void {
    this.getAllOccasions();
    this.initialForm()
  }


  getAllOccasions() {
    this.loader.open()
    this.service.getAllOccasions().subscribe((res: any) => {
      this.loader.close();
      this.Occasions = res?.Content ?? [];
      console.log(this.Occasions, " this.Occasions")
      if (this.Occasions.length > 0) {
        if (checkNull(this.couponTypeId)) {
          let index = this.Occasions.findIndex((ele) => ele?.couponTypeId == this.couponTypeId);
          this.active = index
          this.patchValue(this.Occasions[index])
        } else {
          this.patchValue(this.Occasions[0])
        }
      }

    }, (err) => {
      this.loader.close();
      this.toaster.errorToastr('Try again.');
      this.Occasions = []
    })
  }

  tabChange(inx, data) {
    this.active = inx;
    this.patchValue(data)
  }


  initialForm() {
    this.createForm = new FormGroup({
      OccasionId: new FormControl(''),
      prefix: new FormControl('', [Validators.required, Validators.pattern(REGEXP.removeWSWLetter), Validators.minLength(3)]),
      reminder: new FormControl('', [Validators.required, Validators.min(1)]),
      discountType: new FormControl('', [Validators.required]),
      discountAmount: new FormControl('', [Validators.min(1)]),
      discountPercentage: new FormControl('', [Validators.min(1), Validators.max(100)]),
      minimumAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      maximumAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      couponTypeId: new FormControl('')
    }, { validators: [PuppysCustomValidation.LimitValidator('minimumAmount', 'maximumAmount')] })
  }


  patchValue(value) {

    const discountAmount = this.createForm.get('discountAmount') as FormControl
    const discountPercentage = this.createForm.get('discountPercentage') as FormControl

    this.createForm.patchValue({
      OccasionId: value?.couponOccasionId,
      prefix: value?.couponCode,
      reminder: value?.remainderDays,
      discountType: value?.discountTypeId == '1' ? '1' : '2',
      discountAmount: value?.discountTypeId == '1' ? value?.discountAmount : '',
      discountPercentage: value?.discountTypeId != '1' ? value?.discountAmount : '',
      minimumAmount: value?.minimumSpend,
      maximumAmount: value?.maximumSpend,
      couponTypeId: value?.couponTypeId
    })

    switch (value?.discountTypeId) {
      case '1': {
        discountAmount.setValidators([Validators.required, Validators.min(1)]);
        discountAmount.updateValueAndValidity();

        discountPercentage.setValue('');
        discountPercentage.clearValidators();
        discountPercentage.updateValueAndValidity()
      };
        break
      case '2':
      case '0': {
        discountPercentage.setValidators([Validators.required, Validators.min(1), Validators.max(100)]);
        discountPercentage.updateValueAndValidity();

        discountAmount.setValue('');
        discountAmount.clearValidators();
        discountAmount.updateValueAndValidity()
      };
        break

    }

    this.createForm.markAllAsTouched()
  }

  discountTypeChange() {
    const discountType = this.createForm.get('discountType').value;

    const discountAmount = this.createForm.get('discountAmount') as FormControl;
    const discountPercentage = this.createForm.get('discountPercentage') as FormControl

    switch (discountType) {

      case '1': {
        discountAmount.setValue('');
        discountAmount.setValidators([Validators.required, , Validators.min(1)]);
        discountAmount.markAsUntouched()
        discountAmount.updateValueAndValidity()

        discountPercentage.setValue('');
        discountPercentage.clearValidators();
        discountPercentage.markAsUntouched()
        discountPercentage.updateValueAndValidity()
      }
        break
      case '2': {
        discountPercentage.setValue('');
        discountPercentage.setValidators([Validators.required, , Validators.min(1), Validators.max(100)]);
        discountPercentage.markAsUntouched()
        discountPercentage.updateValueAndValidity()

        discountAmount.setValue('');
        discountAmount.clearValidators();
        discountAmount.markAsUntouched()
        discountAmount.updateValueAndValidity()
      }
        break
    }
  }


  get form() {
    return this.createForm.controls
  }



  updateOccasion() {
    const errors: any[] = this.errorMessage.invalidControls(this.createForm);
    console.log(errors);
    const value = this.createForm.value
    if (errors.length == 0) {
      let data = {
        "couponCode": value?.prefix,
        "couponOccasionId": Number(value?.OccasionId),
        "couponTypeId": Number(value?.couponTypeId),
        "discountAmount": value?.discountType == '1' ? value?.discountAmount : value?.discountPercentage,
        "discountTypeId": value?.discountType == '1' ? true : false,
        "maximumSpend": value?.maximumAmount ?? 0,
        "minimumSpend": value?.minimumAmount ?? 0,
        "occasionStatus": true,
        "remainderDays": value?.reminder ?? 0
      }

      this.loader.open();
      this.service.updateOccasion(data, value?.OccasionId).subscribe(async (res: any) => {
        await this.loader.close();
        await this.toaster.successToastr('Occasional coupon was updated successfully');
      }, (err) => {
        this.loader.close();
        this.toaster.errorToastr(err?.error?.message ?? 'Try again');
        console.log(err);

      })
    } else {
      this.errorMessage.throwErrorMessage(this.createForm, errors, couponLabels)
    }


  }

}
