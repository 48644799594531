<section class="offset-width well-white">
  <app-header [pageName]="'Blogs'"></app-header>
  <div *ngIf="loader" class="loader-spin"></div>
  <div class="container-fluid">
    <h5>{{ blogList ? 'Blogs' : (!isEdited ? 'Create Blog' : 'Update Blog') }}</h5>
    <button
      type="button"
      *ngIf="editBlog"
      (click)="onClickCreate()"
      class="button-1 btn-pink btn-margin"
    >
      Close
    </button>
    <button
      type="button"
      *ngIf="permission.hasPermission(['blogs'], ['blogs_create']) && !editBlog"
      (click)="onClickCreate()"
      class="button-1 btn-pink btn-margin"
    >
      <i class="fa fa-plus"></i> Create
    </button>
    <br /><br />
    <div class="card edit-content" *ngIf="editBlog">
      <div class="card-body">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-lg-6">
              <label>Title <span style="color: red;">*</span></label><br />
              <input
                type="text"
                class="form-control"
                placeholder="Enter Blog Title"
                formControlName="title"
              />
              <div *ngIf="submitted && f.title.errors" class="invalid-text">
                <div *ngIf="f.title.errors.required">Title is required</div>
              </div>
              <br />
            </div>
            <div class="col-lg-6">
              <label>Author <span style="color: red;">*</span></label><br />
              <input
                type="text"
                class="form-control"
                placeholder="Enter Author Name"
                formControlName="author"
              />
              <div *ngIf="submitted && f.author.errors" class="invalid-text">
                <div *ngIf="f.author.errors.required">Author is required</div>
              </div>
              <br />
            </div>
            <br />
            <div class="col-lg-6">
              <label>Sort Order</label><br />
              <input
                type="number"
                class="form-control"
                placeholder="Enter Sort Order"
                formControlName="sortOrder"
              />
              <div *ngIf="submitted && f.sortOrder.errors" class="invalid-text">
                <div *ngIf="f.sortOrder.errors.required">
                  Sort Order is required
                </div>
              </div>
              <br />
            </div>
            <div class="col-lg-6">
              <input
                id="upload-degn"
                type="file"
                #file
                (change)="uploadFile($event)"
                style="display: none"
              />
              <label for="upload-degn" *ngIf="!imgUrl?.length > 0">
                <span class="btn btn-file-upload ml-3">Choose Image</span> <span style="color: red;">*</span>
              </label>
              <br />
              <span class="file-formate">
                <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, 
                upto 50 MB,</span
              >
              <br />
              <span class="file-formate"
                >Image Size Should be ( width:440px X height:225px )
              </span>
              <div *ngIf="imgUrl?.length > 0" class="mt-2">
                <i
                  class="fa fa-trash del-icon"
                  aria-hidden="true"
                  (click)="deleteImage()"
                ></i>
                <img src="{{ imgPth + imgUrl[0] }}" class="img img-fluid" />
              </div>
              <div *ngIf="submitted && f.images.errors" class="invalid-text">
                <div *ngIf="f.images.errors.required">
                  <br />
                  Image is required
                </div>
              </div>
            </div>
            <br />
            <div class="col-lg-12">
              <h6>Blog Content <span style="color: red;">*</span></h6>
              <angular-editor
                class="te-basic-tools"
                [config]="configText"
                formControlName="content"
              ></angular-editor>
              <div *ngIf="submitted && f.content.errors" class="invalid-text">
                <div *ngIf="f.content.errors.required">Content is required</div>
              </div>
              <button
                type="button"
                class="button-1 btn-pink btn-create"
                (click)="onSubmit()"
              >
                Submit
              </button>
              <br /><br />
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--   *ngIf="permission.hasPermission(['blogs'], ['blogs_view'])" -->
    <div class="card bg-table-color" *ngIf="blogList">
      <div
        class="card-body"
      
      >
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">VIEW</th>
                <th scope="col">TITLE</th>
                <th scope="col">AUTHOR</th>
                <th scope="col">DATE & TIME</th>
                <th
                  scope="col"
                  *ngIf="permission.hasPermission(['blogs'], ['blogs_update','blogs_delete'])"
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let item of getdata; let index = index">
              <tr>
                <td>{{ index + 1 }}</td>
                <td
                
                  (click)="showSubItem(index, item.images[0])"
                >
                  <i class="fa fa-expand pointer" style="color: #fd7297"></i>
                </td>
                <td>
                  <a
                    href="{{ mainDomain + '/blog/' + item.blogsId }} "
                    target="_blank"
                  >
                    {{ item.title }}
                  </a>
                </td>
                <td>{{ item.author }}</td>
                <td>{{ item.createdOn | date: "dd-MM-yyyy, h:mm a" }}</td>
                <td >
                  <img
                    (click)="onClickEdit(item)" class="pointer" 
                    *ngIf="
                      permission.hasPermission(['blogs'], ['blogs_update'])
                    "
                    src="/assets/images/icon/pencil-2.svg"
                  />
                  <i
                    (click)="deleteData(item.blogsId)"
                    class="fa fa-trash del-icon2"
                    *ngIf="
                      permission.hasPermission(['blogs'], ['blogs_delete'])
                    "
                  ></i>
                </td>
              </tr>
              <tr *ngIf="visibleIndex === index">
                <td colspan="1"></td>
                <td colspan="2">
                  <img style="max-width: 250px" src="{{ imgPth + showImg }}" />
                </td>
              </tr>
            </tbody>
          </table>
          <h5 *ngIf="getdata?.length<1" style="text-align: center;">No Data Found...</h5>
        </div>
      </div>
    </div>
  </div>
</section>
