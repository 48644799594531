<section class="offset-width well-white">
    <app-header [pageName]="'Products'" [pageNamethree]="pathHeader"></app-header>
    <h5>{{ !isEdited ? 'Create' : 'Update'}} Product</h5>
    <div *ngIf="loader" class="loader-spin"> </div>
    <form [formGroup]="editForm">
        <!--container-->
        <div class="container-fluid">
            <h5>Cake Details</h5> <br>
            <div class="row align-items-end">
                <div class="col-lg-3">
                    <label class="req">Cake Name</label>
                    <input type="text" class="form-control mrgn-btm" formControlName="productName"
                        placeholder="Enter Cake Name">
                    <div *ngIf="submitted && f.productName.errors" class="invalid-text">
                        <div *ngIf="f.productName.errors.required">
                            Cake Name is required
                        </div>
                    </div>
                </div>

                <div class="col-lg-3" formGroupName="productDetails">
                    <label [ngClass]="{'req': designType == ''  }">Parent Categories </label>
                    <select formControlName="parentCategoryId" #parentCategory
                        (change)="getSubCategoryName(parentCategory.value)" class="form-control mrgn-btm "
                        [ngClass]="{'diable-textbox': designType != ''  }">
                        <option value=''></option>
                        <option *ngFor="let item of getdata4" value="{{item.categoryId}}">
                            {{item.categoryName}} - {{item?.shortCode}}</option>
                    </select>
                    <div *ngIf="designType == '' && submitted && (editForm.get('productDetails').get('parentCategoryId').value == undefined || editForm.get('productDetails').get('parentCategoryId').value == null || editForm.get('productDetails').get('parentCategoryId').value == '')"
                        class="invalid-text">
                        <div>
                            Parent Category is required
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label [ngClass]="{'req': designType == ''  }">Sub Categories </label>
                    <select class="form-control mrgn-btm" [ngClass]="{'diable-textbox': designType != ''  }"
                        formControlName="categoryId">
                        <option value=''></option>
                        <option *ngFor="let item of getdata5" value="{{item.categoryId}}">
                            {{item.categoryName}} - {{item?.shortCode}}</option>
                    </select>
                    <div *ngIf="designType == '' && submitted && f.categoryId.errors" class="invalid-text">
                        <div *ngIf="f.categoryId.errors.required">
                            Sub Category is required
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label [ngClass]="{'req': designType == ''  }">Sort Order </label><br>
                    <input type="number" class="form-control " formControlName="sortOrder"
                        placeholder="Enter Sort Order">
                    <div *ngIf="designType == '' && submitted && f.sortOrder.errors" class="invalid-text">
                        <div *ngIf="f.sortOrder.errors.required">
                            Sort Order is required
                        </div>
                    </div>
                </div>
            </div>

            <!--row cake Listing-->
            <div class="row mt-4" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                <div class="mob-br"><br> <br></div>
                <div class="col-lg-5">
                    <div class="change-status">
                        <label>Cake Listing </label><br>

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="clchk1" [checked]="featureCake"
                                (click)="changeCheckbox('featureCake', $event)">
                            <label class="form-check-label" for="clchk1">Cakes By occasion</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="clchk2" [checked]="trendingCake"
                                (click)="changeCheckbox('trendingCake', $event)">
                            <label class="form-check-label" for="clchk2">Trending Cake</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="clchk3" [checked]="bestSellerCake"
                                (click)="changeCheckbox('bestSellerCake', $event)">
                            <label class="form-check-label" for="clchk3">Best
                                Seller Cake</label>
                        </div>

                        <!-- <input type="checkbox" [checked]="featureCake" (click)="changeCheckbox('featureCake', $event)">
                        <span class="label-checkbox"> Feature
                            Cake </span> -->
                        <!-- <input type="checkbox" [checked]="trendingCake"
                            (click)="changeCheckbox('trendingCake', $event)"> <span class="label-checkbox"> Trending
                            Cake </span> -->
                        <!-- <input type="checkbox" [checked]="bestSellerCake"
                            (click)="changeCheckbox('bestSellerCake', $event)"> <span class="label-checkbox"> Best
                            Seller Cake </span> -->



                    </div>
                </div>
                <div class="col-lg-3" formGroupName="productDetails">
                    <label class="req">Made With</label><br>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="mwchk1" formControlName="egg">
                        <label class="form-check-label" for="mwchk1">Egg</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="mwchk2" formControlName="eggless">
                        <label class="form-check-label" for="mwchk2">Eggless</label>
                    </div>
                    <div *ngIf="submitted && (editForm.get('productDetails').get('egg').value == undefined || editForm.get('productDetails').get('egg').value == null || editForm.get('productDetails').get('egg').value == '') && (editForm.get('productDetails').get('eggless').value == undefined || editForm.get('productDetails').get('eggless').value == null || editForm.get('productDetails').get('eggless').value == '')"
                        class="invalid-text">
                        <div>
                            Made With is required
                        </div>
                    </div>
                </div>
            </div>
            <!--end row cake Listing-->

            <!-----------Pricing section---------------->
            <div class="row mt-4">
                <div class="mob-br"><br> <br></div>
                <div class="col-lg-12" style="background: aliceblue;">

                    <table class="table table-borderless" *ngIf="IsShowPricing" style="margin-bottom:0px">
                        <tbody>
                            <tr>
                                <!--<td><input type="text" class="form-control" placeholder="Sku Code" #sku> </td>-->
                                <td style="width: 12%;" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <select class="form-control" #weight>
                                        <option [value]="designType ? 1 : '' ">Select {{kgText}}
                                        </option>
                                        <!--set gift value 1-->
                                        <option value="0.5"> 500 {{kgText == 'Quantity' ? kgText : "gm"}}</option>
                                        <option value="1"> 1 {{kgText}} </option>
                                        <option value="2"> 2 {{kgText}} </option>
                                        <!-- <option value="3"> 3 {{kgText}} </option>
                                        <option value="4"> 4 {{kgText}} </option> -->
                                        <!-- <option value="5"> 5 {{kgText}} </option>
                                        <option value="6"> 6 {{kgText}} </option>
                                        <option value="7"> 7 {{kgText}} </option>
                                        <option value="8"> 8 {{kgText}} </option>
                                        <option value="9"> 9 {{kgText}} </option>
                                        <option value="10"> 10 {{kgText}} </option> -->
                                    </select>
                                    <p *ngIf="WeightFieldRequired" class='invalid-text'>Weight is
                                        Required</p>
                                </td>
                                <td style="width: 20%;">
                                    <select class="form-control" #flavour
                                        [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                        <option value="">Select Flavour</option>
                                        <option *ngFor="let item of flavourList" value="{{item?.flavoursName}}">
                                            {{item?.flavoursName | titlecase}}</option>
                                    </select>
                                   
                                </td>
                                <td style="width: 12%;">
                                    <input type='number' step='0.01' class="form-control" placeholder="Discount in %"
                                        #discount>
                                </td>

                                <td style="width: 12%;">
                                    <input type='number' step='0.01' class="form-control" placeholder="Enter Amount"
                                        #amount>
                                    <p *ngIf="priceFieldRequired" class='invalid-text'>Amount is
                                        Required</p>
                                </td>
                                <td style="width: 12%;" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <input #grossWeight type='number' step='0.01' class="form-control" placeholder="Gross Weight" (keyup)="grossWeightChaneg(grossWeight.value,sameAsFn)">
                                    <p *ngIf="grossFieldRequired" class='invalid-text'>Gross weight is
                                        Required</p>
                                    <p *ngIf="grossFieldZero && !grossFieldRequired" class='invalid-text'>Gross weight must be greater than 0
                                        </p>
                                </td>
                                <td style="width: 12%;" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <input type='number' #netWeight step='0.01' (keyup)="netWeightChaneg(netWeight.value,sameAsFn)"
                                        class="form-control" placeholder="Net Weight">
                                        <p *ngIf="netFieldRequired" class='invalid-text'>Net weight is
                                            Required</p>
                                        <p *ngIf="netFieldZero && !netFieldRequired" class='invalid-text'>Net weight must be greater than 0
                                        </p>
                                </td>
                                <td><button class="btn add-price-button"
                                        (click)="onSubmitPrice(weight.value, flavour.value, discount.value, amount.value,grossWeight.value,netWeight.value)">Add</button>
                                </td>
                                <td style="width: 10%;" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <button type="button" (click)="openBulkVariantModal()" style="font-size: 13px;"
                                        class="btn  btn-secondary">
                                        Bulk Add
                                    </button>
                                </td>
                                <td [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <div class="iskgDiv">
                                        <input type="checkbox" (click)="isKgChange($event)" formControlName="isKg">
                                        <span class="label-checkbox">
                                            Show Kg </span>
                                        <input type="checkbox" [checked]="isSameNet" #sameAs (click)="isSameNetChange($event)">
                                        <span class="label-checkbox">
                                            Same as Net Weight </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-bordered" *ngIf="priceValue.length > 0">
                        <thead>
                            <tr>
                                <th>Drag</th>
                                <th scope="col" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    {{kgText}}
                                </th>
                                <th scope="col" [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    Flavour</th>
                                <th scope="col">Discount</th>
                                <th scope="col">Price</th>
                                <th scope="col">Gross Weight</th>
                                <th scope="col">Net Weight</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody cdkDropList (cdkDropListDropped)="dropPrice($event)" formArrayName="updatepricedata">
                            <tr cdkDrag cdkDragLockAxis="y"
                                *ngFor="let pricedata of editForm.get('updatepricedata')['controls'];let i = index"
                                [formGroupName]="i">
                                <td class="text-center" style="width: 7%;cursor: pointer;" cdkDragHandle><i
                                        class="fa fa-arrows arw" aria-hidden="true"></i></td>
                                <td [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <label *ngIf="isEditPriceIndex != i">{{pricedata?.controls?.kg?.value}}</label>
                                    <select class="form-control" formControlName="kg"
                                        *ngIf="isEditPriceDetail && i == isEditPriceIndex"
                                        [value]="pricedata?.controls?.kg?.value">
                                        <option [value]="designType ? 1 : '' ">Select {{kgText}}
                                        </option>
                                        <!--set gift value 1-->
                                        <option value="0.5"> 500 {{kgText == 'Quantity' ? kgText : "gm"}}</option>
                                        <option value="1"> 1 {{kgText}} </option>
                                        <option value="2"> 2 {{kgText}} </option>
                                        <!-- <option value="3"> 3 {{kgText}} </option>
                                        <option value="4"> 4 {{kgText}} </option> -->
                                        <!-- Puppy's Instructed to remove the KG on 29-07-2022 -->
                                    </select>
                                </td>
                                <td [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                    <label
                                        *ngIf="pricedata?.controls?.flavour?.value && isEditPriceIndex != i">{{pricedata?.controls?.flavour?.value}}</label>
                                    <span
                                        *ngIf="!pricedata?.controls?.flavour?.value && isEditPriceIndex != i">NA</span>
                                    <select class="form-control" formControlName="flavour"
                                        *ngIf="isEditPriceDetail && i == isEditPriceIndex"
                                        [value]="pricedata?.controls?.flavour?.value"
                                        [ngClass]="{'disply-none': designType == 'GIFT'  }">
                                        <option value="">Select Flavour</option>
                                        <option [value]="data?.flavoursName" *ngFor="let data of flavourList;">
                                            {{data?.flavoursName}}</option>
                                    </select>
                                </td>
                                <td><label *ngIf="isEditPriceIndex != i">{{pricedata?.controls?.discount?.value ?
                                        pricedata?.controls?.discount?.value: 0}}%</label>
                                    <input *ngIf="isEditPriceDetail && i == isEditPriceIndex" class="form-control"
                                        type='number' step='0.01' placeholder="Discount in %"
                                        formControlName="discount">
                                </td>

                                <td><label *ngIf="isEditPriceIndex != i">{{pricedata?.controls?.price?.value}}</label>
                                    <input *ngIf="isEditPriceDetail && i == isEditPriceIndex" class="form-control"
                                        type='number' step='0.01' placeholder="Enter Amount" formControlName="price">
                                </td>
                                <td>
                                    <label *ngIf="isEditPriceIndex != i">{{pricedata?.controls?.gross?.value}}kg</label>
                                    <input *ngIf="isEditPriceDetail && i == isEditPriceIndex" class="form-control"
                                        numbersonly maxlength="3" step='0.01' placeholder="Gross Weight"
                                        formControlName="gross">
                                </td>
                                <td>
                                    <label *ngIf="isEditPriceIndex != i">{{pricedata?.controls?.net?.value}}kg</label>
                                    <input *ngIf="isEditPriceDetail && i == isEditPriceIndex" class="form-control"
                                        step='0.01' placeholder="Net Weight" formControlName="net">
                                </td>
                                <td style="width: 9%;text-align: center;"> <span
                                        *ngIf="!isEditPriceDetail && isEditPriceIndex != i"
                                        class="pencil-dot">..</span><img
                                        *ngIf="!isEditPriceDetail && isEditPriceIndex != i"
                                        (click)="editProductPrice(i,true)" src="/assets/images/icon/pencil-2.svg"><i
                                        *ngIf="!isEditPriceDetail && isEditPriceIndex != i"
                                        class="fa fa-trash price-del" (click)="removePricing(i)"></i>
                                    <button *ngIf="isEditPriceDetail && isEditPriceIndex == i"
                                        class="btn add-price-button" (click)="updatePrice(i)">Update</button>
                                </td>
                                <!--on Drag Preview -->
                                <div *cdkDragPreview matchSize="true" class="container dragdrop-placeholder">
                                    {{item?.sku}} - {{item?.kg}} {{kgText}} - Rs.{{item.price}}
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-----------End Pricing section---------------->

            <div class="mob-br"><br></div>

            <!-- Detailed image start-->
            <h6 class="mb-20">Product Detailed Image & Video</h6>
            <div class="row">

                <div class="col-lg-3 mb-20" *ngFor='let url2 of designImgurls; let index = index'>
                    <div class="card card-upld-img">
                        <img [src]="imgUrl+url2" *ngIf="videoOrImg(url2) == 'img'" class="img img-responsive"
                            height="200">
                        <video class="img img-responsive" *ngIf="videoOrImg(url2) == 'video'" height="200" controls>
                            <source [src]="imgUrl+url2" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <span class="del-input">
                        <input type="radio" [checked]="defaultProductImageUrl == url2" class="option-input radio"
                            (change)="GetImage(index, url2)" id="html" name="image">
                        <i class="fa fa-trash del-icon" (click)="designImgRemove(index, url2)"></i>
                    </span>
                </div>
                <div class="col-lg-3 text-center mb-20">
                    <div class="card card-upld-img" style="justify-content: center;">
                        <label class="req"> Design Image &
                            Video</label>
                        <div class="file-dash">
                            <input type="file" id="upload-degn2" #file (change)="designImg($event)"
                                accept="image/*,video/mp4" multiple style="display:none">
                            <label for="upload-degn2">
                                <span class="btn btn-file-upload">Choose File</span>
                            </label>
                        </div>
                        <span class="file-formate"> <i class="fa fa-info-circle"></i> JPEG, .JPG, .GIF,.PNG, .Mp4 upto
                            50 MB,</span>
                        <span class="file-formate">Image Size Should be ( width:1442px X height:1229px ) </span>
                        <br>
                        <div *ngIf="submitted && productImgUrl.length === 0" class="invalid-text">
                            <div *ngIf="productImgUrl.length === 0">
                                Design Image is required
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <!-- Detailed image end-->

            <!--text area Start-->
            <div class="row">
                <div class="col-lg-9">
                    <div formGroupName="productDetails">
                        <h6>Product Description</h6>
                        <angular-editor class="te-basic-tools" formControlName="description" [config]="configTextbox">
                        </angular-editor>
                        <br>
                        <div style="display: none;">
                            <h6>Product Details</h6>
                            <angular-editor class="te-basic-tools" formControlName="product_details"
                                [config]="configTextbox">
                            </angular-editor>
                            <br>
                        </div>
                        <h6>Notes</h6>
                        <angular-editor class="te-basic-tools" formControlName="notes" [config]="configTextbox">
                        </angular-editor>
                        <br>
                    </div>
                </div>
            </div>
            <!--text area end-->

            <div class="row mt-2">
                <div class="col-lg-3">
                    <label>Minimum Order Quantity</label>
                    <select class="form-control" formControlName="minimumOrder">
                        <option *ngFor="let number of itemQuantityNumber" value="{{number}}">{{number}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-3">
                    <label >It's Pan-India Cake<span class="required">*</span></label>
                    <select class="form-control" #pan formControlName="isPanIndiaProduct" (change)="isPan(pan.value)">
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                    <div *ngIf="submitted && f.isPanIndiaProduct.errors" class="invalid-text">
                        <div *ngIf="f.isPanIndiaProduct.errors.required">
                            It's Pan-India Cake is required
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <label>Delivery Time<span *ngIf="showRequired" class="required">*</span></label>
                    <select class="form-control" formControlName="deliverySpeed">
                        <option value=""></option>
                        <option *ngFor="let dl of deliveryTimes" value="{{dl.deliverytimeId}}">{{dl.deliveryName}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.deliverySpeed?.errors" class="invalid-text">
                        <div *ngIf="f.deliverySpeed.errors.required">
                            Delivery Time is required
                        </div>
                    </div>
                </div>

                
                <!--flavours display none-->
                <div class="col-lg-3" style="display: none;">
                    <label>Flavour</label>
                    <div class="row">
                        <input type="text" #addFlavour class="form-control flavour-text">
                        <button class="add-price-button" (click)="onSubmitFlavour(addFlavour.value)">Add</button>
                    </div>
                    <table class="table" *ngIf="flavourData.length > 0">
                        <tbody>
                            <tr *ngFor='let item of flavourData;let index = index'>
                                <td>{{item}}</td>
                                <td> <i class="fa fa-trash price-del" style="font-size: 14px;"
                                        (click)="removeflavour(index)"></i> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-3">
                    <label class="req">Message on the cake</label>
                    <select class="form-control" formControlName="enableWelcomeText">
                        <option value="true">Show</option>
                        <option value="false">Hide</option>
                    </select>
                
                    <div *ngIf="submitted && f.enableWelcomeText.errors" class="invalid-text">
                        <div *ngIf="f.enableWelcomeText.errors.required">
                            Message is required
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label class="req">Product Points</label>
                    <input type="number" formControlName="productPoints" class="form-control">
                    <div *ngIf="submitted && f.productPoints.errors" class="invalid-text">
                        <div *ngIf="f.productPoints.errors.required">
                            Product Points is required
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <label class="req">Delivery Type</label>
                    <div formGroupName="paymentDetails">
                        <!-- <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" formControlName="cod" (click)="changeDeliveryType('cod', $event)">
                            <label class="form-check-label" for="inlineCheckbox1">Cash On</label>
                          </div> -->
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                                formControlName="online" (click)="changeDeliveryType('online', $event)">
                            <label class="form-check-label" for="inlineCheckbox2">Online Payment</label>
                        </div>
                        <!-- <label  class="label-checkbox"> <input type="checkbox" formControlName="cod">  Cash On
                            Delivery </label>
                            <label  class="label-checkbox"> <input type="checkbox" formControlName="online">
                            Online Payment </label> -->
                    </div>
                    <div *ngIf="submitted && (editForm.get('paymentDetails').get('cod').value == undefined || editForm.get('paymentDetails').get('cod').value == null || editForm.get('paymentDetails').get('cod').value == '') && (editForm.get('paymentDetails').get('online').value == undefined || editForm.get('paymentDetails').get('online').value == null || editForm.get('paymentDetails').get('online').value == '')"
                        class="invalid-text">
                        <div>
                            Delivery Type is required
                        </div>
                    </div>
                </div>
                
            </div>
            <br>
            <div class="row">
                <div class="col-lg-9">
                    <button (click)="onSubmit()" type="button" class="button-1 btn-pink btn-create">Submit
                        <i style="margin-left: 4px;" class="fa fa-long-arrow-right"></i> </button>
                </div>
            </div>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>
        <!--container end-->
    </form>
</section>
<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Bulk Variant Generator</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="bulk_generator_input_style" [formGroup]="variantForm" (ngSubmit)="generateVariant()">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="formGroupExampleInput">{{kgText}}</label>
                        <ng-multiselect-dropdown #kilomultiSelect [formControl]="fv.kilograms"
                            [placeholder]="getUomLabel()" [settings]="dropdownSettings" [data]="dropdownList"
                            [(ngModel)]="selectedKiloItems">
                        </ng-multiselect-dropdown>

                        <p *ngIf="variantFomSubmitted && fv?.kilograms?.errors">
                            <span *ngIf="fv?.kilograms?.errors?.required" style="color: red;">
                                {{kgText == 'Kg' ? 'Kilograms' : kgText }} is required.
                            </span>
                        </p>

                    </div>
                    <div class="form-group">
                        <label for="formGroupExampleInput2">Flavours</label>
                        <ng-multiselect-dropdown [placeholder]="'Select Flavours'" #flavourmultiSelect
                            [formControl]="fv.flavours" [settings]="dropdownSettings1" [data]="dropdownList1"
                            [(ngModel)]="selectedFlavourItems" (onSelect)="onItemSelect1($event)"
                            (onSelectAll)="onSelectAll1($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closeModal()"
                        data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-pink">Generate</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="myModal">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Bulk Variant Generator</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="BacktoModal()">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body" style="height:450px; overflow-y: auto; padding: 0;">
                <form [formGroup]="bulkPriceForm">
                    <table class="table generate-table bg_input">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th style="width: 6%;" scope="col">{{kgText}}</th>
                                <th scope="col">Flavour</th>
                                <th scope="col">Made With</th>
                                <th scope="col">Discount(%)</th>
                                <th scope="col">Amount <span class="text-danger">*</span></th>
                                <th scope="col" class="d-flex">
                                    <span class="mr-2">Gross Weight</span>
                                    <input type="checkbox" (change)="sameAs($event)" #bulkGross>
                                </th>
                                <th scope="col">Net Weight</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody formArrayName="tabel">
                            <tr *ngFor="let tabels of bulkPriceForm.get('tabel')['controls']; let i = index"
                                [formGroupName]="i">
                                <td>{{ i+1 }}</td>
                                <td>
                                    <input type="text" class="form-control kg_input" formControlName="kg"
                                        placeholder="Recipient's username" readonly>
                                </td>
                                <td>
                                    <input type="text" class="form-control" formControlName="variant"
                                        placeholder="Recipient's username" readonly>
                                </td>
                                <td>
                                    <div class="relative">
                                        <img class="down_arrow_style" src="../../../assets/images/icon/down_arrow.svg" alt="image">
                                        <select class="form-control input_style form-control-sm" #isPan>
                                           <option selected disabled value="">Made With</option>
                                           <option value="YES">Egg</option>
                                           <option value="NO">Egg less</option>
                                        </select>
                                     </div>
                                </td>
                                <td>
                                    <input type="text" class="form-control" formControlName="discount"
                                        placeholder="Enter Discount">
                                    <p *ngIf="tabels.controls.discount.errors?.pattern && tabels.controls.discount?.touched"
                                        class='invalid-text'>Enter valid discount
                                    </p>
                                </td>

                                <td>
                                    <input type="text" class="form-control" formControlName="amount"
                                        placeholder="Enter Amount">
                                    <p *ngIf="tabels.controls.amount.errors?.required && tabels.controls.amount?.touched"
                                        class='invalid-text'>Amount is
                                        Required</p>

                                    <p *ngIf="tabels.controls.amount.errors?.pattern && tabels.controls.amount?.touched"
                                        class='invalid-text'>Enter valid amount</p>
                                </td>
                                <td>
                                    <input type="text" class="form-control"
                                        (keyup)="weightChaneg(i,bulkGross.checked)" formControlName="gross"
                                        placeholder="Enter Gross Weight">
                                </td>
                                <td>
                                    <input type="text" class="form-control" formControlName="net"
                                        placeholder="Enter Net Weight" (keyup)="netChaneg(i,bulkGross.checked)">
                                </td>
                                <td>
                                    <a href="javascript:;" (click)="removeData(i)"><i
                                            class="fa fa-trash clr_red text-center"></i> </a>
                                </td>

                            </tr>

                        </tbody>

                    </table>
                </form>

            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary back_btn" (click)="BacktoModal()">Back</button>
                <button type="button" [disabled]="bulkPriceForm.get('tabel')['controls'].invalid" class="btn btn-pink submit_btn"
                    (click)="bulkSubmit()">Submit</button>
            </div>

        </div>
    </div>
</div>