<section class="offset-width well-white">
  <app-header [pageName]="'Activity log'"></app-header>
  <div *ngIf="loader" class="loader-spin"></div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2">
        <h5>ACTIVITY LOG</h5>
      </div>
    </div>
    <br />
    <div class="card-body card-design" *ngIf="permission.hasPermission(['activity_log'],['activity_log_filter'])">
      <!-- form starts -->
      <form [formGroup]="filterForm">
        <div class="row">
          <div class="col-lg-12 pannel">
            <div class="col-lg-2 dis-inline">
              <h5>Filter By</h5>
            </div>
            <div class="col-lg-2 dis-inline">
              <label>From Date</label>
              <input
                type="date"
                #orderFrom
                class="form-control"
                (blur)="fromDateValidation($event)"
                formControlName="startDate"
              />
            </div>
            <div class="col-lg-2 dis-inline">
              <label>To Date</label>
              <input
                type="date"
                #orderTo
                class="form-control"
                formControlName="endDate"
                id="endDate"
                (blur)="toDateValidation($event)"
              />
              <!-- <span class="invalid-text" *ngIf="(filterForm.controls['endDate'].hasError('invalid') && filterForm.controls['endDate'].touched)"
                              > Please choose the To date greater than From date</span> -->
            </div>
            <div class="col-lg-2 dis-inline">
              <label>Activity type</label>
              <ng-multiselect-dropdown
                #kilomultiSelect
                formControlName="activityTypeId"
                [settings]="dropdownSettings"
                [data]="dropdownList"
              >
              </ng-multiselect-dropdown>
              <!-- <select class="form-control form-control-sm" formControlName="activityTypeId">
                                  <option selected disabled value="">Select Status</option>
                                  <option value="Accepted">Accepted</option>
                                  <option value="Baking">Cake is Baking</option>
                                  <option value="Shipped">On Transit</option>
                                  <option value="Delivered">Delivered</option>
                                  <option value="Cancelled">Cancelled</option>
                                  <option value="InCart">Abandoned Carts</option>
                              </select> -->
            </div>
            <div class="col-lg-4 dis-inline list-item">
              <button
                class="btn btn-sm btn-reset filter-btn"
                type="button"
                (click)="filterRequired()"
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-reset filter-btn"
                type="button"
                (click)="resetLog()"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- <div class="col-lg-2">
                      <button class="btn btn-sm btn-reset">Filter</button>
                  </div> -->
      <br />
    </div>
    <br />
    <div class="row"  *ngIf="permission.hasPermission(['activity_log'],['activity_log_view'])||permission.hasPermission(['activity_log'],['activity_log_filter'])" >
      <div class="col-lg-12">
        <div class="card card-design">
          <div class="pagination-list">
            <div class="page-item">
              <button
                type="button"
                [disabled]="pageNo <= 1"
                (click)="paginate('dec')"
              >
                <img
                  src="./assets/images/icon/arrow-left.png"
                  alt="icon"
                />Previous
              </button>
            </div>

            <div class="boxes">
              <div class="box1" *ngIf="disable() == pageNo">
                <span (click)="pTag('sub')">{{ pageNo - 2 }}</span>
              </div>
              <div class="box1" *ngIf="pageNo > 1">
                <span (click)="pTag('sub')">{{ pageNo - 1 }}</span>
              </div>
              <div class="box1 active">
                <span>{{ pageNo }}</span>
              </div>
              <div class="box1" *ngIf="disable() >= pageNo + 1">
                <span (click)="pTag('add')">{{ pageNo + 1 }}</span>
              </div>
              <div class="box1" *ngIf="totalPage != 0 && pageNo == 1">
                <span (click)="pTag('add')">{{ pageNo + 2 }}</span>
              </div>
            </div>

            <div class="page-list">
              <button
                type="button"
                (click)="paginate('inc')"
                [disabled]="disable() <= pageNo"
              >
                Next<img
                  src="./assets/images/icon/arrow-right.png"
                  alt="icon"
                />
              </button>
            </div>
          </div>
          <div class="card-dt" *ngFor="let list of logActivities | keyvalue">
            <h3>{{ list?.key }}</h3>
            <div class="card-time">
              <div class="col-10">
                <div class="new d-flex" *ngFor="let value of list.value">
                  <div>
                    <h4 style="white-space: pre; text-align: center">
                      {{ value?.createdDate | date: "shortTime" }}
                    </h4>
                    <div class="timeline"></div>
                  </div>
                  <div class="card mb10">
                    <div class="card-cont">
                      <a>
                        <i class="fa fa-user-circle-o"></i>
                      </a>
                      <p>
                        Name<br />
                        <span>Activity</span>
                      </p>
                      <div class="card-sub-cont">
                        <h4>{{ value?.name }}</h4>
                        <h6>{{ value?.description }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2
            style="
              text-align: center;
              font-family: monospace;
              margin-top: 100px;
            "
            *ngIf="emptyObject()"
          >
            No Data Found
          </h2>
        </div>
      </div>
    </div>
  </div>
</section>
