<section class="offset-width well-white">
    <app-header [pageName]="'Invoice setting'"></app-header>
    <div *ngIf="loader" class="loader-spin"> </div>
    <div class="container-fluid">

        <h5>Invoice</h5>


        <br><br>


        <div class="card col-12 table-bg">
            <div class="card-body "> <br>
                <form [formGroup]="invoiceForm">
                    <h5 class="text-center" >Invoice settings </h5>
                    <div class="invoice-grp">
                        <h6 class="p-15">Invoice Prefix <span class="error-meg">*</span></h6>
                        <div>
                            <input type="text"  formControlName="invoice" class="form-control w-100" >
                            <span class="error-meg" *ngIf=" submitted && invoiceForm?.get('invoice').errors?.required">Please provide invoice prefix</span>

                        </div>
                          

                           

                        <button *ngIf="permission.hasPermission(['invoice'],['invoice_update'])" class="btn-upload m-15 " (click)="updateInvoice()">Update</button>
            </div>

<!-- <div  *ngIf=" submitted && invoiceForm?.get('invoice').errors">
<p class="text-center text-danger"  *ngIf=" submitted && invoiceForm?.get('invoice').errors?.required">Name is reqklsdhfjhj</p>
</div> -->

                </form>
               
                        <!-- <form>
                           
                             
                                    <h5 style="text-align: center;">Invoice settings</h5>
                                
                            
                            
                                 <div style="display: flex; align-items: center; justify-content: center;">
                                     <h6 class="padd">Invoice Prefix</h6>
                                    <input type="text" class="form-control w30" formControlName="imageName" >
                                    <button class="btn-file-upload">Update</button>
                          
                                  
                                    
                                 
                                 

                                 </div>
                                    

                  
                     


                            
                                
                        </form> -->






            </div>
        </div>











    </div>






</section>