import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
declare var $: any;
import * as moment from 'moment';
import { PermissionService } from 'src/app/services/core/permission.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { COUPON, CouponDTO, CouponcodeService } from './couponcode.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { checkNull } from 'src/app/constants/custom-functions';
import { PuppysCustomValidation, sweetAlert } from 'src/app/shared/validations/puppys-custom';
import { Router } from '@angular/router';


function couponStatus(d1, d2) {

  const start = moment(d1);
  const end = moment(d2)
  const today = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

  switch (true) {
    case end.isBefore(today): return 'Expired';
    case start.isSameOrBefore(today) && end.isSameOrAfter(today): return 'Ongoing';
    case start.isAfter(today): return 'Upcoming';
    default: return ''


  }
}

@Component({
  selector: 'app-promocode-general',
  templateUrl: './promocode-general.component.html',
  styleUrls: ['./promocode-general.component.css']
})
export class PromocodeGeneralComponent implements OnInit, AfterViewInit {

  filterForm: FormGroup;
  pagination: CouponDTO;
  coupons: any[] = [];

  couponTypeOptions: any[] = [
    { view: 'General', value: '1' },
    { view: 'Customer', value: '2' },
    { view: 'Product', value: '3' },
    // { view: 'Birthday', value: '4' },
    // { view: 'Work Anniversary', value: '5' },
    // { view: 'Wedding Anniversary', value: '6' },
    // { view: 'Valentine\'s day', value: '7' },
    // { view: 'Children\'s Day', value: '8' },
  ]


  couponTypes: any[] = [
    { view: 'General', value: '1' },
    { view: 'Customer', value: '2' },
    { view: 'Product', value: '3' },
    { view: 'Birthday', value: '4' },
    { view: 'Work Anniversary', value: '5' },
    { view: 'Wedding Anniversary', value: '6' },
    { view: 'Valentine\'s day', value: '7' },
    { view: 'Children\'s Day', value: '8' },
  ]

  // https://prnt.sc/400wuFGp8r59
  discountTypeOptions: any[] = [
    { view: 'Amount', value: '1' },
    { view: 'Percentage', value: '0' },
  ]

  statusOptions: any[] = [
    { view: 'Upcoming', value: '1' },
    { view: 'Ongoing', value: '2' },
    { view: 'Expired', value: '3' }
  ]
  @ViewChild('filterInput') public filterInput: ElementRef;
  constructor(
    public http: HttpService,
    public toastr: ToastrManager,
    private loader: LoaderService,
    private service: CouponcodeService,
    private router: Router,
    public permission : PermissionService
  ) {
    this.intialForm()
    this.pagination = new CouponDTO()
  }

  ngOnInit(): void {
    this.getAllCoupons();

  }

  ngAfterViewInit(): void {
    this.filterKeyup()
  }


  intialForm() {
    this.filterForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      couponType: new FormControl(''),
      discountType: new FormControl(''),
      status: new FormControl('')
    }, { validators: PuppysCustomValidation.DateValidator('startDate', 'endDate') })
  }



  get hasOneValue() {
    const form = this.filterForm.value;
    const hasOneValue = Object.values(form).map(ele => checkNull(ele)).some(val => val == true);
    return hasOneValue
  }



  filterKeyup() {
    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(debounceTime(300), distinctUntilChanged()).subscribe((res: any) => {
      let val = this.filterInput.nativeElement.value
      this.pagination.search = checkNull(val) ? val.trim() : ''
      this.getAllCoupons(true)
    })
  }

  reset() {
    this.intialForm()
    this.filterForm.updateValueAndValidity();
    this.pagination = new CouponDTO();
    this.filterInput.nativeElement.value = '';
    this.getAllCoupons()
  }


  getAllCoupons(search?: boolean, filter?: boolean) {

    let form = this.filterForm.value;

    if (filter && !this.hasOneValue) {
      this.toastr.errorToastr('Kindly fill atleast one field to filter coupons')
      return
    }

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form?.couponType)) {
        this.pagination.couponTypeId = form?.couponType
      }

      if (checkNull(form.discountType)) {
        this.pagination.discountTypeId = form?.discountType
      }

      if (checkNull(form.status)) {
        this.pagination.couponStatusId = form.status
      }

      !search ? this.loader.open() : '';
      this.service.getAllCoupons(this.pagination).subscribe((res: any) => {
        this.loader.close();
        this.coupons = res?.content.map((ele: COUPON) => {
          return {
            createdOn: ele.createdOn,
            couponCode: ele.couponCode ?? '',
            couponType: this.couponTypes.find((cpn) => ele.couponTypeId == cpn.value).view,
            startDate: moment(ele.couponStartDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(ele.couponEndDate).format('YYYY-MM-DD HH:mm:ss'),
            discount: ele.discountTypeId == '1' ? `₹ ${ele.discountAmount}` : `${ele.discountAmount} %`,
            status: ele.couponStatusName,
            couponName: ele.couponName,
            id: ele.couponNewId,
            today: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            discountType: ele.discountTypeId == '1' ? 'Flat' : 'Percentage',
            couponTypeId: ele?.couponTypeId
          }
        }) ?? [];
        this.pagination.totalPages = Math.ceil(res?.totalElements / 10)

      }, (err: any) => {
        this.loader.close();
        this.toastr.errorToastr('Try again');
        this.coupons = []
      })
    } else {
      sweetAlert('error', 'Start date should be before End date')
    }


  }

  export() {
    let form = this.filterForm.value

    if (this.filterForm.valid) {
      if (checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(form.endDate).format('YYYY-MM-DD')
      }

      if (checkNull(form?.endDate) && !checkNull(form.startDate)) {
        sweetAlert('error', 'From date is required')
        return
      }

      if (!checkNull(form?.endDate) && checkNull(form.startDate)) {
        this.pagination.startDate = moment(form.startDate).format('YYYY-MM-DD')
        this.pagination.endDate = moment(new Date()).format('YYYY-MM-DD')
      }

      if (checkNull(form?.couponType)) {
        this.pagination.couponTypeId = form?.couponType
      }

      if (checkNull(form.discountType)) {
        this.pagination.discountTypeId = form?.discountType
      }

      if (checkNull(form.status)) {
        this.pagination.couponStatusId = form.status
      }


      this.service.exportCoupons(this.pagination)

    } else {
      sweetAlert('error', 'Start date should be before End date')
    }
  }

  deleteCoupon(data) {
    this.loader.open()
    this.service.deleteCoupon(data?.id).subscribe((res: any) => {
      this.loader.close()
      this.toastr.successToastr('Deleted coupon successfully');
      this.getAllCoupons()
    }, (err) => {
      this.loader.close();
      this.toastr.errorToastr('Try again');
    })
  }

  updateCoupon(data) {
    if (['1', '3', '2'].includes(data?.couponTypeId)) {
      this.router.navigate([`/promocode-update`], { queryParams: { couponId: data?.id } })
    } else {
      this.router.navigate([`/occasion-update`], { queryParams: { couponTypeId: data?.couponTypeId } })
    }

  }


  paginate(event) {
    this.pagination.pageNo = event;
    this.getAllCoupons()
  }

}
