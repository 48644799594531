<div *ngIf="loader" class="loader-spin"> </div>

<div class="bg-login">
  <div class="bg-color">
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-4 mx-auto">
          <div class="card card-signin margin_100">
            <div class="card-body">

              <div class="text-center imgchange">
                <img src="/assets/images/logo/logo.png">
              </div>
              <p class="card-title text-center">Admin Login</p> <br>
              <form [formGroup]="loginForm">

                <div class="form-label-group">
                  <input type="email" id="inputEmail" class="form-control" formControlName="userName"
                    placeholder="Email address" required autofocus>
                  <div *ngIf="submitted && f.userName.errors" class="invalid-text">
                    <div *ngIf="f.userName.errors.required">Email is required</div>
                    <div *ngIf="f.userName.errors.email"> Email Valid Email </div>
                  </div>
                </div><br>

                <div class="form-label-group">
                  <input [type]="password" id="inputPassword"  formControlName="password" class="form-control"
                    placeholder="Password" required>
                  <button (click)="onClick()" type="button" class="pass-eye">
                    <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                  </button>
                  <div *ngIf="submitted && f.password.errors" class="invalid-text">
                    <div *ngIf="f.password.errors.required"> Password is required </div>
                  </div>

                  <!--invalid user Name or password Error-->
                  <div *ngIf="(loginError!='')" class="invalid-text">{{loginError}}</div>
                </div>

                <br>
                <button (click)="onSubmit()" class="button-1 btn-pink float-right" type="submit">Login</button>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>