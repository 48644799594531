import { Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';





export const TestingRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'home', breadcrumb: 'home' }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: { title: 'about', breadcrumb: 'about' }
  },

];
export class TestingModule {

}
