import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import { checkNull } from '../../constants/custom-functions';
declare var $: any;


@Component({
  selector: "track-order",
  templateUrl: "./track-order.component.html",
  styleUrls: ["./track-order.component.less"],
})
export class TrackOrderComponent implements OnInit, OnChanges {
  constructor(private service: HttpService) { }

  @Input("trackOrder") public trackOrder;
  @Input("random") public randmom;
  data: TrackOrder;
  status: any;
  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(this.trackOrder);
    if (checkNull(this.trackOrder?.shipmentId) && this.trackOrder?.shipmentId != 0) {
      this.getOrderStatus();
    } else {
      this.status = undefined;
    }
  }

  close() {
    $('#trackOrderModal1').modal('hide');
  }

  checkNull(data) {
    return checkNull(data);
  }

  getOrderStatus() {
    this.service.get(`/shiprocket/track/${this.trackOrder.shipmentId}`).subscribe(
      (res: any) => {
        // console.log(res);
        this.status = new DeliveryStatus(res);
        this.data = new DeliveryStatus(res).data;
      },
      (err) => {

        //   console.log(err);
        this.status = new DeliveryStatus(err);
        this.data = undefined;
      }
    );
  }
}

class DeliveryStatus {
  deliveryStatus: any[] = [];
  data: TrackOrder;
  constructor(data: any) {
    this.deliveryStatus = data.tracking_data?.shipment_track_activities ?
      data.tracking_data?.shipment_track_activities.map((ele: any) => {
        return {
          date: ele?.date ?? "",
          activity: ele?.activity ?? "",
          location: ele?.location ?? "",
        };
      }) : [];

    this.data = new TrackOrder(data.tracking_data);
  }
}

class TrackOrder {
  month;
  date;
  year;
  day;

  constructor(data: any) {
    this.setTheVariables(data);
  }

  setTheVariables(data) {
    let months = [
      {
        short: "Jan",
        full: "January",
        number: 1,
      },
      {
        short: "Feb",
        full: "February",
        number: 2,
      },
      {
        short: "Mar",
        full: "March",
        number: 3,
      },
      {
        short: "Apr",
        full: "April",
        number: 4,
      },
      {
        short: "May",
        full: "May",
        number: 5,
      },
      {
        short: "Jun",
        full: "June",
        number: 6,
      },
      {
        short: "Jul",
        full: "July",
        number: 7,
      },
      {
        short: "Aug",
        full: "August",
        number: 8,
      },
      {
        short: "Sep",
        full: "September",
        number: 9,
      },
      {
        short: "Oct",
        full: "October",
        number: 10,
      },
      {
        short: "Nov",
        full: "November",
        number: 11,
      },
      {
        short: "Dec",
        full: "December",
        number: 12,
      },
    ];

    if (!checkNull(data?.etd)) {
      return;
    }
    let date = data?.etd.split(" ")[0].split("-");

    this.date = date[2];
    let month = months.find((ele) => ele.number == date[1]);
    this.month = month.full;
    this.year = date[0];
    this.day = this.getDayOfWeek(`${date[0]}-${month.number}-${date[2]}`);
  }

  getDayOfWeek(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Days of the week as an array
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the day of the week as a number (0-6)
    const dayNumber = date.getDay();

    // Get the corresponding day name from the array
    const dayName = daysOfWeek[dayNumber];

    return dayName;
  }
}