import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { AppConstants } from '../../constants/app.constants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  config = AppConstants.SERVER_CONFIG;
  baseurl = this.config[this.config.USE_URL];

  post(url: string, user: any) {
    return this.http.post(this.baseurl + url, user);
  }

  postData(url: string, params: HttpParams = new HttpParams()) {
    return this.http.post(this.baseurl + url, { params });
  }
  put(url: string, user: any) {
    return this.http.put(this.baseurl + url, user);
  }

  get(url: string) {
    return this.http.get(this.baseurl + url);
  }

  getData(url: string, params: HttpParams = new HttpParams()) {
    return this.http.get(this.baseurl + url, { params });
  }

  delete(url: string) {
    return this.http.delete(this.baseurl + url);
  }

  public fileDownload(
    url: any,
    params: HttpParams = new HttpParams()
  ): Observable<HttpEvent<Blob>> {
    return this.http.request(
      new HttpRequest('GET', `${this.baseurl}${url}`, {
        params: params,
        reportProgress: true,
        responseType: 'blob',
      })
    );
  }
  FilterDownloadFile(
    path: string,
    params: HttpParams = new HttpParams(),
    paramsConfig = { fileName: 'E' }
  ): Observable<HttpEvent<Blob>> {
    return this.http.request(
      new HttpRequest('GET', `${this.baseurl}${path}`, {
        params: params,
        reportProgress: true,
        responseType: 'blob',
      })
    );
  }
}
