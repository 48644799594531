import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
  FormControlName,
} from '@angular/forms';
import { HttpService } from '../../services/http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AppConstants } from '../../constants/app.constants';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element, promise } from 'protractor';
import * as _ from 'lodash';
import { checkNull } from 'src/app/constants/custom-functions';
import { ProductService } from './product.service';
import { error, groupEnd, log } from 'console';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { single } from 'rxjs/operators';
import { PuppysCustomValidation } from 'src/app/shared/validations/puppys-custom';
import { ErrorMessageV2Service } from 'src/app/services/otherServices/error-message-v2.service';
import { pricingLabels, productFormLabels } from './product.model';
function compare(a, b) {
  if (a.categoryId < b.categoryId) {
    return -1;
  }
  if (a.categoryId > b.categoryId) {
    return 1;
  }
  return 0;
}

declare var $: any;
interface SelectOptions {
  singleSelection: boolean;
  idField: string;
  textField: string;
  selectAllText: string;
  unSelectAllText: string;
  itemsShowLimit: number;
  allowSearchFilter: boolean;
}
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css'],
})
export class CreateProductComponent implements OnInit {
  productForm: FormGroup;
  loader;
  showDateFields: boolean = false;

  toggleDateFields(checked: boolean) {
    this.showDateFields = checked;
  }
  unitOptions: any[] = [
    { kgView: '0.5 KG', kg: 0.5, no: 1 },
    { kgView: '1 KG', kg: 1, no: 2 },
    { kgView: '1.5 KG', kg: 1.5, no: 3 },
    { kgView: '2 KG', kg: 2, no: 4 },
    { kgView: '2.5 KG', kg: 2.5, no: 5 },
    { kgView: '3 KG', kg: 3, no: 6 },
    { kgView: '3.5 KG', kg: 3.5, no: 7 },
    { kgView: '4 KG', kg: 4, no: 8 },
    { kgView: '4.5 KG', kg: 4.5, no: 9 },
    { kgView: '5 KG', kg: 5, no: 10 },
    { kgView: '5.5 KG', kg: 5.5, no: 11 },
    { kgView: '6 KG', kg: 6, no: 12 },
    { kgView: '6.5 KG', kg: 6.5, no: 13 },
    { kgView: '7 KG', kg: 7, no: 14 },
    { kgView: '7.5 KG', kg: 7.5, no: 15 },
    { kgView: '8 KG', kg: 8, no: 16 },
    { kgView: '8.5 KG', kg: 8.5, no: 17 },
    { kgView: '9 KG', kg: 9, no: 18 },
    { kgView: '9.5 KG', kg: 9.5, no: 19 },
    { kgView: '10 KG', kg: 10, no: 20 },
  ];

  unit1: any[] = [
    { view: '0.5 KG', value: 0.5, no: 1 },
    { view: '1 KG', value: 1, no: 2 },
    { view: '1.5 KG', value: 1.5, no: 3 },
    { view: '2 KG', value: 2, no: 4 },
    { view: '2.5 KG', value: 2.5, no: 5 },
    { view: '3 KG', value: 3, no: 6 },
    { view: '3.5 KG', value: 3.5, no: 7 },
    { view: '4 KG', value: 4, no: 8 },
    { view: '4.5 KG', value: 4.5, no: 9 },
    { view: '5 KG', value: 5, no: 10 },
    { view: '5.5 KG', value: 5.5, no: 11 },
    { view: '6 KG', value: 6, no: 12 },
    { view: '6.5 KG', value: 6.5, no: 13 },
    { view: '7 KG', value: 7, no: 14 },
    { view: '7.5 KG', value: 7.5, no: 15 },
    { view: '8 KG', value: 8, no: 16 },
    { view: '8.5 KG', value: 8.5, no: 17 },
    { view: '9 KG', value: 9, no: 18 },
    { view: '9.5 KG', value: 9.5, no: 19 },
    { view: '10 KG', value: 10, no: 20 },
  ];
  unit2: any[] = [
    { view: '1', value: 1 },
    { view: '2', value: 2 },
    { view: '3', value: 3 },
  ];
  madeWithOptions: any[] = [
    { view: 'Egg Only', value: 'egg' },
    { view: 'Eggless Only', value: 'eggless' },
    { view: 'Egg & Eggless', value: 'both' },
  ];
  itemQuantityNumber = Array(20)
    .fill(0)
    .map((x, i) => i + 1);
  configTextbox: any = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['insertImage'], ['insertVideo'], ['customClasses']],
  };

  isUpdatePage: boolean = false;
  productId;
  version;
  productData;
  type: 'product' | 'gift';

  constructor(
    public http: HttpService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public toastr: ToastrManager,
    private router: Router,
    private service: ProductService,
    private loaders: LoaderService,
    private errorMessage: ErrorMessageV2Service
  ) {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.productId = res?.productId ?? '';
      this.version = res?.version ?? 0;
      this.type = res?.type ?? 'product';
      this.getAllParentCategories();
      this.getAlldeliveryTimes();
      this.getAllFlavours();
      this.initialProductForm();

      if (checkNull(res?.productId)) {
        this.isUpdatePage = true;
        this.getProductDetails();
      } else {
        this.isUpdatePage = false;
      }
    });
  }

  ngOnInit(): void {
    // this.initialProductForm()
  }

  giftId;
  forGiftings() {
    if (this.type == 'gift') {
      let giftId = this.parentCategories.filter(
        (ele: any) => ele?.shortCode == 'GIFT'
      )[0].categoryId;
      this.productForm.patchValue({
        parentCategory: giftId,
        subCategory: giftId,
      });
      this.giftId = giftId;
      let parent = this.productForm.get('parentCategory') as FormControl;
      let child = this.productForm.get('subCategory') as FormControl;
      parent.disable();
      child.disable();
      let singleAdd = this.productForm.get('singleAdd') as FormGroup;
      singleAdd.get('type').setValue('1');
      singleAdd.get('unit').setValue(1);
      singleAdd.get('flavour').setValue('');
      singleAdd.get('madeWith').setValue('both');
      singleAdd.updateValueAndValidity();
      let pricings = this.productForm.get('pricings') as FormArray;
      pricings.clear();

      console.log(this.productForm.value);
    }
  }

  getProductDetails() {
    this.loaders.open();
    this.service.getProductDetails(this.productId).subscribe(
      (res: any) => {
        this.loaders.close();
        this.productData = res ?? undefined;
        this.patchValue();
      },
      (err) => {
        this.productData = undefined;
        this.loaders.close();
        this.toastr.errorToastr('Try again');
      }
    );
  }

  async patchValue() {
    let data = this.productData.content;
    console.log(data, "data")
    await this.productForm.patchValue({
      productId: data?.productId ?? '',
      version: data?.version ?? '',
      productName: data?.productName,
      parentCategory: Number(data?.productDetails.parentCategoryId),
      subCategory: Number(data?.categoryId),
      sortOrder: Number(data?.sortOrder),
      cakesByOccasion: data?.listings.includes('featureCake'),
      trendingCakes: data?.listings.includes('trendingCake'),
      BestSellerCakes: data?.listings.includes('bestSellerCake'),

      sku: data?.sku,
      active: data?.active ?? true,
      available: data?.available ?? true,

      // Extra
      productDescription: data?.productDetails?.description,
      notes: data?.productDetails?.notes,

      // Restrictions
      minimumQuantity: data?.minimumOrder ?? 1,
      isPanIndia: String(data?.isPanIndiaProduct),

      message: data?.enableWelcomeText,
      points: data?.productPoints,
      deliveryTime: Number(data?.deliverySpeed),
      payment: data?.paymentDetails?.online,

      isStoreDelivery:
        data?.deliveryType == 'both' || data?.deliveryType == 'store delivery',
      isHomeDelivery:
        data?.deliveryType == 'both' || data?.deliveryType == 'home delivery',
    });

    this.panChange(Boolean(data?.isPanIndiaProduct));

    this.productForm.get('deliveryTime').setValue(Number(data?.deliverySpeed));
    this.productForm.get('deliveryTime').updateValueAndValidity();

    await this.changeSubCategory().then(async () => {
      await this.productForm.get('subCategory').setValue(Number(data?.categoryId));
      this.productForm.get('subCategory').updateValueAndValidity();
    });

    const productImages = this.productForm.get('productImages') as FormArray;

    // images
    new Promise<void>((resolve, reject) => {
      productImages.clear();
      data?.productImages.filter((ele) => {
        productImages.push(
          this.initialImagesForm({ image: ele, isDefault: true })
        );
      });
      resolve();
    }).then(() => {
      data?.designImages.filter((ele) => {
        if (checkNull(ele)) {
          productImages.push(
            this.initialImagesForm({ image: ele, isDefault: false })
          );
        }
      });
    });

    // pricings
    const pricings = this.productForm.get('pricings') as FormArray;

    new Promise<void>((resolve, reject) => {
      pricings.clear();

      function madeWith(element) {
        switch (true) {
          case element?.productDetails?.egg == true &&
            data?.productDetails?.eggless == true:
            return 'both';
          case element?.productDetails?.egg != true &&
            data?.productDetails?.eggless == true:
            return 'eggless';
          case element?.productDetails?.egg == true &&
            data?.productDetails?.eggless != true:
            return 'egg';
          default:
            return 'both';
        }
      }
      data?.productDetails?.pricing.filter((ele) => {
        pricings.push(
          this.initialPricings({
            type: data?.isKg == true ? '1' : '2',
            unit: ele?.kg == 500 ? 0.5 : ele?.kg,
            flavour:
              !checkNull(ele?.flavour) || ele?.flavour == 'NA'
                ? ''
                : this.flavours.find((fla) => fla?.flavoursName == ele?.flavour)
                  ?.flavoursId ?? '',
            flavourName:
              !checkNull(ele?.flavour) || ele?.flavour == 'NA'
                ? 'NA'
                : ele?.flavour,
            netWeight:
              Number(
                ele?.net ?? data?.isKg == true
                  ? ele?.kg == 500
                    ? 0.5
                    : ele?.kg
                  : 0
              ) ?? 0,
            grossWeight:
              Number(
                ele?.gross ?? data?.isKg == true
                  ? ele?.kg == 500
                    ? 0.5
                    : ele?.kg
                  : 0
              ) ?? 0,
            amount: Number(ele?.price),
            discount: Number(ele?.discount),
            madeWith: checkNull(ele?.flavourEgg)
              ? ele?.flavourEgg
              : madeWith(data),
            editNow: false,
          })
        );
      });
      resolve();
    }).then(() => {
      console.log(this.productForm.value, 'value--------------');
    });
  }

  initialProductForm() {
    this.productForm = new FormGroup({
      productId: new FormControl(''),
      version: new FormControl(''),
      productName: new FormControl('', Validators.required),
      parentCategory: new FormControl('', Validators.required),
      subCategory: new FormControl(''),
      sortOrder: new FormControl('', [Validators.required, Validators.min(1)]),
      cakesByOccasion: new FormControl(false),
      trendingCakes: new FormControl(false),
      BestSellerCakes: new FormControl(false),

      sku: new FormControl(''),
      active: new FormControl(true),
      available: new FormControl(true),

      sameAsNet: new FormControl(false),

      singleAdd: new FormGroup({
        type: new FormControl('1'),
        unit: new FormControl(''),
        flavour: new FormControl(''),
        madeWith: new FormControl(''),
        amount: new FormControl(''),
        discount: new FormControl(0, [Validators.max(100)]),
        netWeight: new FormControl(''), // in KG
        grossWeight: new FormControl(''),
      }),

      bulkAdd: new FormGroup({
        type: new FormControl('1'),
        unit: new FormControl(''),
        flavour: new FormControl(''),
      }),

      newPricings: new FormArray([]),
      pricings: new FormArray(
        [],
        [Validators.required, Validators.minLength(1)]
      ),

      // Product Images
      productImages: new FormArray(
        [],
        [Validators.required, Validators.minLength(1)]
      ),

      // Extra
      productDescription: new FormControl(''),
      notes: new FormControl(''),

      // Restrictions
      minimumQuantity: new FormControl(1),
      isPanIndia: new FormControl('', [Validators.required]),

      message: new FormControl('', [Validators.required]),
      points: new FormControl(0),
      deliveryTime: new FormControl('', [Validators.required]),
      payment: new FormControl(true),

      isStoreDelivery: new FormControl(false),
      isHomeDelivery: new FormControl(false),
    });
  }

  get form() {
    return this.productForm.controls;
  }

  initialPricings(data?: any) {
    return new FormGroup(
      {
        type: new FormControl(data?.type ?? '', [Validators.required]),
        unit: new FormControl(data?.unit ?? '', [Validators.required]),
        flavour: new FormControl(data?.flavour ?? ''),
        flavourName: new FormControl(data?.flavourName ?? ''),
        madeWith: new FormControl(data?.madeWith ?? '', [Validators.required]),
        amount: new FormControl(data?.amount ?? '', [
          Validators.required,
          Validators.min(1),
        ]),
        discount: new FormControl(data?.discount ?? '', [
          Validators.min(0),
          Validators.max(100),
        ]),
        netWeight: new FormControl(data?.netWeight ?? '', [
          Validators.required,
          Validators.min(0.1),
        ]), // in KG
        grossWeight: new FormControl(data?.grossWeight ?? '', [
          Validators.required,
          Validators.min(0.1), // in KG
        ]),
        editNow: new FormControl(false),
      },
      {
        validators: PuppysCustomValidation.LimitValidator(
          'netWeight',
          'grossWeight'
        ),
      }
    );
  }

  initialImagesForm(data) {
    return new FormGroup({
      image: new FormControl(data?.image ?? '', Validators.required),
      isDefault: new FormControl(data?.isDefault ?? false, Validators.required),
    });
  }

  parentCategories: any[] = [];
  extractedParents: any[] = []
  getAllParentCategories() {
    this.service.getParentCategories().subscribe(
      async (res: any) => {
        this.loaders.close();
        // this.parentCategories =
        //   (this.type == 'product'
        //     ? res.content.filter((ele: any) => ele?.shortCode != 'GIFT')
        //     : res.content) ?? [];

        // this.parentCategories = this.type == 'product' ? res.content?.filter((ele: any) => res.content.some((ctc) => ctc.parentCategoryId == ele.categoryId)).filter((ele: any) => ele?.shortCode != 'GIFT') : res.content?.filter((ele: any) => res.content.some((ctc) => ctc.parentCategoryId == ele.categoryId)).filter((ele: any) => ele?.shortCode == 'GIFT')
        this.parentCategories = this.type == 'product' ? res.content?.filter((ele: any) => ele?.shortCode != 'GIFT') : res.content?.filter((ele: any) => ele?.shortCode == 'GIFT')
        console.log(this.parentCategories, "parentCategories")
        await res.content.filter(async (ele) => {
          if (await res.content.some((ctc) => ctc.parentCategoryId == ele.categoryId)) {
            this.extractedParents.push(ele)
          }
        })
        console.log(this.extractedParents.sort(compare), 'extractedParents');

        this.forGiftings();
      },
      (error: any) => {
        this.loaders.close();
        this.toastr.errorToastr('Try again.');
        this.parentCategories = [];
      }
    );
  }

  childCategories: any[] = [];

  async getAllChildtCategories() {
    let value = this.productForm.get('parentCategory').value;
    if (checkNull(value)) {
      await this.service.getChildCategories(value).subscribe(
        (res: any) => {
          this.loaders.close();
          this.childCategories = res.content ?? [];
        },
        (error: any) => {
          this.loaders.close();
          this.toastr.errorToastr('Try again.');
          this.childCategories = [];
        }
      );
    }
  }

  flavours: any[] = [];
  flavourOptions: SelectOptions = {
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    idField: 'flavoursId',
    textField: 'flavoursName',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    singleSelection: false,
  };
  getAllFlavours() {
    this.service.getFlavours().subscribe(
      (res: any) => {
        this.loaders.close();
        this.flavours = res.content ?? [];
      },
      (error: any) => {
        this.loaders.close();
        this.toastr.errorToastr('Try again.');
        this.flavours = [];
      }
    );
  }

  deliveryTimes: any[] = [];

  getAlldeliveryTimes() {
    this.service.getDeliveryTime().subscribe(
      (res: any) => {
        this.loaders.close();
        this.deliveryTimes = res ?? [];
      },
      (error: any) => {
        this.loaders.close();
        this.toastr.errorToastr('Try again.');
        this.deliveryTimes = [];
      }
    );
  }

  async changeSubCategory(event?: any) {
    this.childCategories = [];
    let value = this.productForm.get('parentCategory').value;
    console.log(value, 'child  categories');

    const subCategory = this.productForm.get('subCategory') as FormControl;
    subCategory.setValue('');
    let category = this.parentCategories.find(
      (ele: any) => ele.categoryId == value
    );

    if (category?.shortCode == 'GIFT' || category?.formType != 0) {
      subCategory.clearValidators();
      subCategory.disable();
      await subCategory.updateValueAndValidity();
    } else {
      subCategory.setValidators([Validators.required]);
      subCategory.enable();
      await subCategory.updateValueAndValidity();
    }
    await this.getAllChildtCategories();
  }

  weightChange(control1, control2) {
    let value = this.productForm.get('sameAsNet').value;
    let net = this.productForm.get('singleAdd').get(control1) as FormControl;
    let gross = this.productForm.get('singleAdd').get(control2) as FormControl;
    if (value == true) {
      gross.setValue(net.value);
      gross.updateValueAndValidity();
    }
  }

  validateSingleAdd() {
    const singleAdd = this.productForm.get('singleAdd') as FormGroup;

    const unit = singleAdd.get('unit').value;
    const flavour = singleAdd.get('flavour').value;
    const madeWith = singleAdd.get('madeWith').value;
    const amount = singleAdd.get('amount').value;
    const discount = singleAdd.get('discount').value;
    const netWeight = singleAdd.get('netWeight').value;
    const grossWeight = singleAdd.get('grossWeight').value;

    console.log(singleAdd.value);

    // Unit
    if (checkNull(unit) || this.type == 'gift') {
      // Flavour
      // if (checkNull(flavour)) {

      // preference
      if (checkNull(madeWith) || this.type == 'gift') {
        // amount
        if (checkNull(amount)) {
          if (amount > 0) {

            if (discount <= 100) {
              // Net Weight
              if (checkNull(netWeight)) {
                if (netWeight > 0) {
                  // grossWeight
                  if (checkNull(grossWeight)) {
                    if (grossWeight > 0 && netWeight <= grossWeight) {
                      return true;
                    } else {
                      this.throwMesage(
                        `Grossweight should be greater than or equal to netweight`
                      );
                      return false;
                    }
                  } else {
                    this.throwMesage(`Please provide the Grossweight`);
                    return false;
                  }
                } else {
                  this.throwMesage(`Netweight should be greater than zero`);
                  return false;
                }
              } else {
                this.throwMesage(`Please provide the netweight`);
                return false;
              }
            } else {
              this.throwMesage(`Discount percentage should be less than or equal to 100`);
              return false;
            }

          } else {
            this.throwMesage(`Amount should be greater than zero`);
            return false;
          }
        } else {
          this.throwMesage(`Please provide the amount greater than zero`);
          return false;
        }
      } else {
        this.throwMesage(`Please select the made with`);
        return false;
      }

      // } else {
      //   this.throwMesage(`Please select the flavour`)
      //   return false

      // }
    } else {
      this.throwMesage(`Please select the unit`);
      return false;
    }
  }

  get checkExistingVariant() {
    let variants: any[] = this.productForm.get('pricings').value;
    const singleAdd = this.productForm.get('singleAdd').value;
    return !variants.some(
      (ele: any) =>
        ele.unit == singleAdd.unit && ele.flavour == singleAdd.flavour
    );
  }

  async addVariants() {
    if (this.currentTab == 'single') {
      let variants = this.productForm.get('pricings') as FormArray;
      let singleAdd = this.productForm.get('singleAdd') as FormGroup;

      let value = variants.value
      if (this.type == 'gift' ? variants.controls.length <= 1 : true) {
        if (this.validateSingleAdd() == true) {

          if (value?.length > 0 ? value[0].type == singleAdd.value.type : true) {

            if (this.checkExistingVariant) {
              new Promise((resolve) => {
                let value = singleAdd.value;
                console.log(value);
                if (this.type == 'gift') {
                  value.madeWith = 'both';
                  value.unit = 1;
                }
                value.flavourName =
                  this.flavours.find((ele) => ele.flavoursId == value?.flavour)
                    ?.flavoursName ?? 'NA';
                variants.push(this.initialPricings(value));
                resolve(true);
              }).then(() => {
                singleAdd.reset();
                singleAdd.get('type').setValue('1');
                singleAdd.get('unit').setValue('');
                singleAdd.get('flavour').setValue('');
                singleAdd.get('madeWith').setValue('');
                singleAdd.updateValueAndValidity();
                console.log(this.productForm.value, 'this is value');
              });
            } else {
              this.throwMesage('Variant you have selected already exists!');
            }
          } else {
            this.throwMesage('Selected unit type is different from the added variant');
          }

          console.log(singleAdd.value, value);

        }
      } else {
        this.throwMesage('Gift can have only one variant');
      }
    } else {
      let value = this.productForm.get('bulkAdd').value;

      if (checkNull(value.unit) && value.unit?.length > 0) {
        if (checkNull(value.unit) && value.unit?.length > 0) {
          this.genrateVariant();
          $('#myModal').modal('show');
          console.log(this.productForm.value.newPricings);
        } else {
          this.throwMesage('Please select the flavour');
        }
      } else {
        this.throwMesage('Please select the unit');
      }
    }
  }

  checkNull(data) {
    return checkNull(data);
  }

  genrateVariant() {
    const newPricings = this.productForm.get('newPricings') as FormArray;
    newPricings.clear();
    let value = this.productForm.get('bulkAdd').value;

    if (value.flavour?.length > 0) {
      value.flavour.forEach((flavour: any) => {
        value.unit.forEach((unit: any) => {
          newPricings.push(
            this.initialPricings({
              type: value?.type,
              unit: unit?.value,
              flavour: flavour.flavoursId ?? '',
              flavourName: flavour.flavoursName ?? '',
              madeWith: 'both',
              amount: null,
              discount: 0,
              netWeight: null,
              grossWeight: null,
            })
          );
        });
      });
    } else {
      value.unit.forEach((unit: any) => {
        newPricings.push(
          this.initialPricings({
            type: value?.type,
            unit: unit?.value,
            flavour: '',
            flavourName: 'NA',
            madeWith: 'both',
            amount: null,
            discount: 0,
            netWeight: null,
            grossWeight: null,
          })
        );
      });
    }
  }

  throwMesage(message) {
    this.toastr.errorToastr(message, '');
  }

  sameAsChange(event: any) {
    let sameAs = this.productForm.get('sameAsNet').value;
    let netWeight = this.productForm.get('singleAdd').get('netWeight').value;
    let grossWeight = this.productForm
      .get('singleAdd')
      .get('grossWeight') as FormControl;
    if (sameAs == true) {
      grossWeight.setValue(netWeight);
      grossWeight.updateValueAndValidity();
    }
  }

  currentTab: 'single' | 'bulk' = 'single';

  unitSettings: SelectOptions = {
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    idField: 'value',
    textField: 'view',
    itemsShowLimit: 5,
    allowSearchFilter: false,
    singleSelection: false,
  };

  resetBulk() {
    let bulkAdd = this.productForm.get('bulkAdd') as FormGroup;
    let newPricings = this.productForm.get('newPricings') as FormArray;
    newPricings.clear();
    bulkAdd.get('unit').setValue('');
    bulkAdd.get('flavour').setValue('');
    bulkAdd.updateValueAndValidity();
  }

  resetSingle() {
    const singleAdd = this.productForm.get('singleAdd') as FormGroup;
    singleAdd.reset();
    singleAdd.get('type').setValue('1');
    singleAdd.get('unit').setValue('');
    singleAdd.get('flavour').setValue('');
    singleAdd.get('madeWith').setValue('');
    singleAdd.updateValueAndValidity();
  }

  switchTab(tab: 'single' | 'bulk') {
    this.productForm.get('sameAsNet').setValue(false);
    this.productForm.get('sameAsNet').updateValueAndValidity();
    this.resetBulk();
    this.resetSingle();
    this.currentTab = tab;
  }

  isToggled = false;
  toggle(): void {
    this.resetBulk();
    this.resetSingle();
    this.isToggled = !this.isToggled;
  }

  sameAsChangeBulk(index, control1, control2) {
    let sameAs = this.productForm.get('sameAsNet').value;
    const newPricings = this.productForm.get('newPricings') as FormArray;
    let netWeight = newPricings.at(index).get(control1).value;
    let grossWeight = newPricings.at(index).get(control2) as FormControl;
    if (sameAs == true) {
      grossWeight.setValue(netWeight);
      grossWeight.updateValueAndValidity();
    }
  }

  bulkAddSettings(value?: any) {
    this.resetBulk();
    this.resetSingle();
  }

  addBulkVariants() {
    const errors: any[] = this.errorMessage.invalidControls(this.productForm);
    const newPricings = errors.filter((ele) => ele?.name == 'newPricings');
    if (newPricings.length == 0) {
      let newPricings: any[] = this.productForm.get('newPricings').value;
      let pricings = this.productForm.get('pricings') as FormArray;
      new Promise((resolve, reject) => {
        pricings.clear();
        newPricings.forEach((ele) => pricings.push(this.initialPricings(ele)));
        resolve(true);
      }).then(() => {
        this.resetBulk();
        this.resetSingle();
        $('#myModal').modal('hide');
        console.log(this.productForm.value);
        this.toggle();
      });
    } else {
      this.errorMessage.throwErrorMessage(
        this.productForm,
        newPricings,
        pricingLabels
      );
      console.log(errors, this.productForm.value);
    }
  }

  editVariant(form: FormGroup, inx) {
    form.get('editNow').setValue(true);
    form.get('editNow').updateValueAndValidity();
  }

  checkExistingVariantBulk(form: FormGroup) {
    let variants: any[] = this.productForm.get('pricings').value;
    const singleAdd = form.value;
    return (
      variants.filter(
        (ele: any) =>
          ele.unit == singleAdd.unit && ele.flavour == singleAdd.flavour
      ).length == 1
    );
  }

  updateVariant(form: FormGroup, inx) {
    const errors: any[] = this.errorMessage.invalidControls(this.productForm);
    const pricings = errors.filter((ele) => ele?.name == 'pricings');
    console.log(pricings, 'errors');

    if (pricings.length == 0) {
      if (this.checkExistingVariantBulk(form)) {
        let flavour = checkNull(form.get('flavour').value)
          ? this.flavours.find(
            (ele) => ele?.flavoursId == form.get('flavour').value
          ).flavoursName ?? ''
          : '';
        form.get('flavourName').setValue(flavour);
        form.get('editNow').setValue(false);
        form.updateValueAndValidity();
      } else {
        this.throwMesage('Variant you have selected already exists!');
      }
    } else {
      this.errorMessage.throwErrorMessage(
        this.productForm,
        pricings,
        pricingLabels
      );
    }
  }

  dropPricings(event: CdkDragDrop<any>) {
    let pricings = this.productForm.get('pricings') as FormArray;
    let value: any[] = pricings.value;
    new Promise<void>((resolve, reject) => {
      moveItemInArray(value, event.previousIndex, event.currentIndex);
      resolve();
    }).then(() => {
      pricings.clear();
      value.forEach((ele) => pricings.push(this.initialPricings(ele)));
      pricings.updateValueAndValidity();
    });
  }

  bulkSameAs(value) {
    if (value == true) {
      let newPricings = this.productForm.get('newPricings') as FormArray;
      newPricings.value.filter((ele, inx) =>
        newPricings.at(inx).get('grossWeight').setValue(ele?.netWeight)
      );
      newPricings.updateValueAndValidity();
    }
  }

  removePricing(index, form) {
    Swal.fire({
      title:
        "<strong style='color:#ff9966; font-family:Inter'>Remove Variant</strong>",
      text: 'Do you want to remove this variant ?',
      // icon: "success",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      allowOutsideClick: false,
      confirmButtonColor: '#FD386B',
      cancelButtonColor: '#FD386B',
      cancelButtonText: 'No',
    }).then((response: { isConfirmed; isDenied; isDismissed; value }) => {
      if (response.isConfirmed == true) {
        let pricings = this.productForm.get(form) as FormArray;
        pricings.removeAt(index);
        pricings.updateValueAndValidity;
      }
    });
  }

  close() {
    this.resetSingle();
    this.resetBulk();
    $('#myModal').modal('hide');
  }

  uploadFiles(event) {
    const max_width = 1442;
    const max_height = 1229;
    const filetypes = [
      'image/jpeg',
      'image/png',
      'video/mp4',
      'image/jpg',
      'image/jfif',
    ];

    let formData = new FormData();
    const file: File = event.target.files[0];

    let productImages = this.productForm.get('productImages') as FormArray;
    let value = productImages.value;

    if (filetypes.includes(file.type)) {
      if (file.size <= 1024 * 1024 * 50) {
        const reader = new FileReader();
        const image = new Image();
        reader.readAsDataURL(file);
        reader.onload = (read: any) => {
          if (file) {
            this.loader = 'show';
            const formData: FormData = new FormData();

            if (file.type == 'video/mp4') {
              this.loader = 'show';
              formData.append('image', file, file.name);
              formData.append('fileType', 'product');
              formData.append('thumbnail', 'false');
              this.http.post('/uploadimage', formData).subscribe(
                (data: any) => {
                  productImages.push(
                    this.initialImagesForm({
                      image: data?.path,
                      isDefault: value?.length == 0 ? true : false,
                    })
                  );
                  this.loader = '';
                  $('#uploader').val('');
                  console.log(this.productForm.value);
                },
                (error) => {
                  this.toastr.errorToastr('Try Again', '');
                  this.loader = '';
                  $('#uploader').val('');
                }
              );
            } else {
              // file dimension validation
              image.src = read.target.result;
              image.onload = (load: any) => {
                const height = load.currentTarget['height'];
                const width = load.currentTarget['width'];

                if (height == max_height && width == max_width) {
                  this.loader = 'show';
                  formData.append('image', file, file.name);
                  formData.append('fileType', 'product');
                  formData.append('thumbnail', 'true');
                  this.http.post('/uploadimage', formData).subscribe(
                    (data: any) => {
                      productImages.push(
                        this.initialImagesForm({
                          image: data?.path,
                          isDefault: value?.length == 0 ? true : false,
                        })
                      );
                      this.loader = '';
                      $('#uploader').val('');
                      console.log(this.productForm.value);
                    },
                    (error) => {
                      this.toastr.errorToastr('Try Again', '');
                      this.loader = '';
                      $('#uploader').val('');
                    }
                  );
                } else {
                  this.toastr.errorToastr(
                    'File dimension should be ' +
                    max_width +
                    '×' +
                    max_height +
                    'pixels',
                    ''
                  );
                  this.loader = '';
                  $('#uploader').val('');
                }
              };
            }
          }
        };
      } else {
        this.toastr.errorToastr(
          'File size should be less than or equal to 50 MB',
          ''
        );
        $('#uploader').val('');
      }
    } else {
      this.toastr.errorToastr(
        'File type should be of images/jpeg,images/png,images/jpg,images/jfif,video/mp4',
        ''
      );
      $('#uploader').val('');
    }
  }

  markAsPrimary(index) {
    let productImages = this.productForm.get('productImages') as FormArray;
    let value: any[] = productImages.value;
    value.forEach((ele: any, inx) => {
      if (inx == index) {
        productImages.at(inx).get('isDefault').setValue(true);
        productImages.updateValueAndValidity();
      } else {
        productImages.at(inx).get('isDefault').setValue(false);
        productImages.updateValueAndValidity();
      }
    });
  }

  removeProductImages(index, isDefault) {
    let productImages = this.productForm.get('productImages') as FormArray;
    productImages.removeAt(index);
    productImages.updateValueAndValidity();
    let value: any[] = productImages.value;
    if (value.length > 0 && isDefault == true) {
      value.forEach((ele: any, inx) => {
        if (inx == index) {
          productImages.at(0).get('isDefault').setValue(true);
          productImages.updateValueAndValidity();
        } else {
          productImages.at(inx).get('isDefault').setValue(false);
          productImages.updateValueAndValidity();
        }
      });
    }
  }

  panChange(value) {
    let deliverySpeed = this.productForm.get('deliveryTime') as FormControl;
    if (value == false) {
      deliverySpeed.setValue('');
      deliverySpeed.enable();
      deliverySpeed.setValidators([Validators.required]);
      deliverySpeed.updateValueAndValidity();
    } else {
      deliverySpeed.setValue('');
      deliverySpeed.disable();
      deliverySpeed.clearValidators();
      deliverySpeed.updateValueAndValidity();
    }
  }

  get getListings() {
    let list = [];

    let value = this.productForm.value;

    if (value?.cakesByOccasion) {
      list.push('featureCake');
    }
    if (value?.trendingCakes) {
      list.push('trendingCake');
    }
    if (value?.BestSellerCakes) {
      list.push('bestSellerCake');
    }

    return list;
  }

  get isKg() {
    return this.productForm.value.pricings?.length > 0
      ? this.productForm.value.pricings[0].type == '2'
        ? false
        : true
      : true;
  }

  get pricings() {
    let value: any[] = this.productForm.value.pricings;
    return value.map((ele: any) => {
      return {
        kg: ele?.unit,
        flavour: ele?.flavourName,
        discount: ele?.discount ?? 0,
        price: ele?.amount ?? 0,
        net: ele?.netWeight ?? 0,
        gross: ele?.grossWeight ?? 0,
        flavourEgg: ele?.madeWith ?? '',
      };
    });
  }

  get deliveryType() {
    let isStore = this.productForm.value?.isStoreDelivery;
    let isHome = this.productForm.value?.isHomeDelivery;
    switch (true) {
      case isStore && isHome:
        return 'both';
      case !isStore && isHome:
        return 'home delivery';
      case isStore && !isHome:
        return 'store delivery';
      default:
        return 'both';
    }
  }

  get generateData() {
    let value = this.productForm.value;
    let categoryid = this.type == 'gift' ? String(this.giftId) : value?.subCategory ?? value?.parentCategory;

    return {
      productName: value?.productName,
      categoryId:
        categoryid,
      sortOrder: value?.sortOrder,
      designImages: value.productImages
        .filter((ele) => !ele.isDefault)
        .map((ele) => ele?.image),
      isPanIndiaProduct: value?.isPanIndia ?? false,
      status: 1,
      listings: this.getListings,
      isKg: this.isKg,
      productPoints: value?.points ?? 0,
      paymentDetails: {
        cod: '',
        online: value?.payment ?? false,
      },
      productDetails: {
        pricing: this.pricings,
        egg: null,
        eggless: null,
        description: value?.productDescription ?? '',
        product_details: '',
        notes: value?.productDescription ?? '',
        parentCategoryId:
          this.type == 'gift' ? String(this.giftId) : value?.parentCategory,
      },
      enableWelcomeText: value?.message ?? 'false',
      productImages: value.productImages
        .filter((ele) => ele.isDefault)
        .map((ele) => ele?.image),
      sku: checkNull(value?.sku) ? value?.sku : null,
      active: true,
      available: true,
      minimumOrder: value?.minimumQuantity ?? 1,
      deliverySpeed: value?.deliveryTime ?? '',
      updatepricedata: this.pricings,
      flavour: [],
      deliveryType: this.deliveryType,
      deliveryStatus: this.isUpdatePage ? true : null,
    };
  }

  createProduct() {
    let errors: any[] = this.errorMessage
      .invalidControls(this.productForm)
      .filter((ele) => ele?.name != 'singleAdd' && ele?.name != 'bulkAdd');
    console.log(errors);
    let isGiftType = this.type == 'gift';
    if (isGiftType) {
      errors = errors.filter(error => error.name !== 'subCategory');
    }

    let value = this.productForm.value;

    if (errors.length == 0) {
      if (value?.pricings?.length > 0) {
        if (value?.productImages?.length > 0) {
          if (value?.isStoreDelivery == true || value?.isHomeDelivery == true) {
            this.loaders.open();

            if (!this.isUpdatePage) {
              this.service.createProducts(this.generateData).subscribe(
                (res: any) => {
                  this.loaders.close();
                  this.toastr.successToastr(
                    this.isUpdatePage
                      ? 'Product updated successfully'
                      : 'Product created successfully'
                  );
                  this.router.navigate(['/products']);
                },
                (err: any) => {
                  this.loaders.close();
                  this.toastr.errorToastr('Try again');
                }
              );
            } else {
              this.service
                .updateProduct(this.productId, this.generateData, this.version)
                .subscribe(
                  (res: any) => {
                    this.loaders.close();
                    this.toastr.successToastr(
                      this.isUpdatePage
                        ? 'Product updated successfully'
                        : 'Product created successfully'
                    );
                    this.router.navigate(['/products']);
                  },
                  (err: any) => {
                    this.loaders.close();
                    this.toastr.errorToastr('Try again');
                  }
                );
            }
          } else {
            this.toastr.errorToastr('Please select the delivery type');
          }
        } else {
          this.toastr.errorToastr('Please provide atleast one product image');
        }
      } else {
        this.toastr.errorToastr(
          'Please provide the pricing and variant details for the product'
        );
      }
    } else {
      this.errorMessage.throwErrorMessage(
        this.productForm,
        errors,
        productFormLabels
      );
    }
  }
}
