export const AppConstants = {
  // https://api.puppysbakery.com/
  // https://testapipuppys.technogenesis.in/

  SERVER_CONFIG: {
    DEVELOPMENT: '',
    STAGING: 'https://api.puppysbakery.com',
    LOCAL: 'https://testapipuppys.technogenesis.in',
    USE_URL: 'STAGING',
    IMG_URL: '',
    DOMAIN: 'https://puppysbakery.com/#',
    localUrl: 'https://testapi.puppysbakery.com',
    localDev:
      /^(https?:\/\/)?(www\.)?testpuppysbakery\.technogenesis\.in(?:\/.*)?$/,
    stageDev: /^(https?:\/\/)?(www\.)?puppysbakery\.com(?:\/.*)?$/,
    localMob:
      /^(https?:\/\/)?(www\.)?mblpuppysbakery\.technogenesis\.in(?:\/.*)?$/,
    stageMob: /^(https?:\/\/)?(www\.)?m\.puppysbakery\.com(?:\/.*)?$/,
  },
};
