import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormControlName, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { REGEXP, checkNull } from "src/app/constants/custom-functions";
import { PuppysCustomValidation } from "src/app/shared/validations/puppys-custom";
import { CouponcodeService } from "../promocode-general/couponcode.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorMessageV2Service } from "src/app/services/otherServices/error-message-v2.service";
import { LoaderService } from "src/app/shared/service/loader.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, observable } from "rxjs";
import { PermissionService } from "src/app/services/core/permission.service";
declare var $: any;
interface Options { view: any, value: any }
const couponLabels = [{
  "name": "couponType",
  "label": "coupon type",
  "type": "select"
}, {
  "name": "customerName",
  "label": "customer name",
  "type": "select"
}, {
  "name": "productName",
  "label": "product name",
  "type": "select"
}, {
  "name": "couponName",
  "label": "coupon name",
  "type": "provide"
}, {
  "name": "couponCode",
  "label": "coupon code",
  "type": "provide"
}, {
  "name": "discountType",
  "label": "discount type",
  "type": "select"
}, {
  "name": "discountAmount",
  "label": "discount amount",
  "type": "provide",
  "min": 0,
  "number": 0
}, {
  "name": "discountPercentage",
  "label": "discount percentage",
  "type": "provide",
  "min": 0,
  "max": 100,
  "number": 0
}, {
  "name": "showToUser",
  "label": "showToUser",
  "type": "provide"
}, {
  "name": "description",
  "label": "description",
  "type": "provide",
  "min": 25,
  "number": 25
}, {
  "name": "usageFor",
  "label": "usageFor",
  "type": "provide"
}, {
  "name": "limitCustomerUsage",
  "label": "limitCustomerUsage",
  "type": "provide"
}, {
  "name": "limitCouponUsage",
  "label": "limitCouponUsage",
  "type": "provide"
}, {
  "name": "limit",
  "label": "coupon usage limit",
  "type": "provide"
}, {
  "name": "minimumAmount",
  "label": "minimum amount",
  "type": "provide",
  "min": 0,
  "number": 0
}, {
  "name": "maximumAmount",
  "label": "maximum amount",
  "type": "provide",
  "min": 0,
  "number": 0
}, {
  "name": "startDate",
  "label": "start date and time",
  "type": "select"
}, {
  "name": "endDate",
  "label": "end date and time",
  "type": "select"
}]
interface SelectOptions {
  singleSelection: boolean;
  idField: string;
  textField: string;
  selectAllText?: string;
  unSelectAllText?: string;
  itemsShowLimit: number;
  allowSearchFilter: boolean;
}
@Component({
  selector: "app-promocode-update",
  templateUrl: "./promocode-update.component.html",
  styleUrls: ["./promocode-update.component.css"],
})
export class PromocodeUpdateComponent implements OnInit {

  createForm: FormGroup;
  products: Options[] = [];
  customers: Options[] = []
  couponTypeOptions: Options[] = [
    { view: 'General', value: '1' },
    { view: 'Customer', value: '2' },
    { view: 'Product', value: '3' }
  ]
  customerOptions: SelectOptions = {

    idField: 'value',
    textField: 'view',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    singleSelection: true,
  };

  productOptions: SelectOptions = {
    idField: 'value',
    textField: 'view',
    itemsShowLimit: 5,
    allowSearchFilter: true,
    singleSelection: true,
  };

  couponId: any
  couponData: any
  constructor(
    private service: CouponcodeService,
    private toaster: ToastrManager,
    private errorMessage: ErrorMessageV2Service,
    private loader: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public permission: PermissionService
  ) {
    this.initialForm()
    this.activatedRoute.queryParams.subscribe(async (res: any) => {
      this.couponId = res.couponId;
      this.getAllProducts().subscribe(() => {
        this.getAllCustomers().subscribe(() => {
          this.getCoupon()
        })
      })

    })
  }




  async ngOnInit(): Promise<void> {

  }


  getCoupon() {
    this.loader.open()
    this.service.getCoupon(this.couponId).subscribe((res: any) => {
      this.loader.close();
      this.couponData = res?.Content[0];
      this.patchValues()
    }, (err) => {
      this.loader.close()
      this.toaster.errorToastr(err?.error?.message ?? 'Try again');
      this.couponData = null
    })
  }


  selectedCustomer
  selectedProduct
  async patchValues() {
    let data = this.couponData
    this.selectedCustomer = [this.customers.find((ele) => ele?.value == data?.customerId)];
    this.selectedProduct = [this.products.find((ele) => ele?.value == data?.productId)]
    await this.createForm.patchValue({
      "couponType": data?.couponTypeId,
      "customerName": [this.customers.find((ele) => ele?.value == data?.customerId)] ?? '',
      "productName": [this.products.find((ele) => ele?.value == data?.productId)] ?? '',
      "couponName": data?.couponName,
      "couponCode": data?.couponCode,
      "discountType": data?.discountTypeId == '0' ? '2' : '1',
      "discountAmount": data?.discountTypeId == '1' ? data?.discountAmount : '',
      "discountPercentage": data?.discountTypeId == '0' ? data?.discountAmount : '',
      "showToUser": data?.showToUser == '1' ? true : false,
      "description": data?.description,
      "usageFor": data?.customerEligibility == '0' ? '1' : '2',
      "limitCustomerUsage": data?.customerLimit == 'true' ? true : false,
      "limitCouponUsage": checkNull(data?.couponLimit),
      "limit": data?.couponLimit ?? null,
      "minimumAmount": data?.minimumSpend ?? '',
      "maximumAmount": data?.maximumSpend ?? '',
      "startDate": data?.couponStartDate,
      "endDate": data?.couponEndDate
    })

    await this.couponTypeChange(true);
    await this.discountTypeChange(true);
    await this.limitChange(true)
    const startDate = this.createForm.get('startDate') as FormControl;
    const endDate = this.createForm.get('endDate') as FormControl;

    await $("#startDate").datetimepicker({

      format: "d-m-Y H:i",
      yearStart: new Date().getFullYear(),
      minDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      minTime: moment(new Date()).format('HH:mm:ss'),
      value: moment(new Date(data?.couponStartDate)).format('DD-MM-YYYY HH:mm:ss'),
      step: 1,
      onChangeDateTime: function (ct, $i) {
        startDate.setValue(moment(ct).format('YYYY-MM-DD HH:mm:ss'));
        startDate.updateValueAndValidity()

      }

    })

    await $("#endDate").datetimepicker({

      format: "d-m-Y H:i",
      yearStart: new Date().getFullYear(),
      minDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      value: moment(new Date(data?.couponEndDate)).format('DD-MM-YYYY HH:mm:ss'),
      step: 1,
      onChangeDateTime: function (ct, $i) {
        endDate.setValue(moment(ct).format('YYYY-MM-DD HH:mm:ss'));
        endDate.updateValueAndValidity()
      }
    })

    this.createForm.markAllAsTouched();
    this.createForm.updateValueAndValidity()
  }


  initialForm() {
    this.createForm = new FormGroup({
      couponNewId: new FormControl(''),
      couponType: new FormControl('1', [Validators.required]),
      customerName: new FormControl(''),
      productName: new FormControl(''),
      couponName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      couponCode: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern(REGEXP.removeWhitespace)]),
      discountType: new FormControl('1', [Validators.required]),
      discountAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      discountPercentage: new FormControl(''),
      showToUser: new FormControl(false),
      description: new FormControl('', [Validators.required, Validators.minLength(25)]),
      // Restrictions
      usageFor: new FormControl('2', [Validators.required]),
      limitCustomerUsage: new FormControl(false),
      limitCouponUsage: new FormControl(false),
      limit: new FormControl(''),
      minimumAmount: new FormControl('', [Validators.required, Validators.min(1)]),
      maximumAmount: new FormControl('', [Validators.required, Validators.min(1)]),

      // Duration
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    }, { validators: [PuppysCustomValidation.LimitValidator('minimumAmount', 'maximumAmount'), PuppysCustomValidation.DateAndTimeValidator('startDate', 'endDate')] })


  }

  get form() {
    return this.createForm.controls
  }


  getAllProducts() {
    return new Observable((observer) => {
      this.service.getAllProducts().subscribe(async (res: any) => {
        this.products = await res ?? [];
        observer.next()
      }, async (err: any) => {
        observer.next()
        this.products = [];
      })
    })
  }

  getAllCustomers() {
    return new Observable((observer) => {
      this.service.getAllCustomers().subscribe(async (res: any) => {
        this.customers = await res ?? []
        observer.next()
      }, async (err: any) => {
        observer.next()
        this.customers = []
      })
    })

  }


  couponTypeChange(update?: boolean) {
    let type: '1' | '2' | '3' = this.createForm.get('couponType').value
    let customerName = this.createForm.get('customerName') as FormControl;
    let productName = this.createForm.get('productName') as FormControl;

    switch (type) {
      case '1': {
        customerName.setValue('')
        customerName.clearValidators();
        customerName.updateValueAndValidity();
        productName.setValue('')
        productName.clearValidators();
        productName.updateValueAndValidity();
      }
        break
      case '2': {
        !update ? customerName.setValue('') : ' '
        customerName.setValidators([Validators.required]);
        customerName.updateValueAndValidity();
        productName.setValue('')
        productName.clearValidators();
        productName.updateValueAndValidity();
      }
        break
      case '3': {
        customerName.setValue('')
        customerName.clearValidators();
        customerName.updateValueAndValidity();
        !update ? productName.setValue('') : ''
        productName.setValidators([Validators.required]);
        productName.updateValueAndValidity();
      }
        break
    }
  }

  discountTypeChange(update?: boolean) {
    const discountType = this.createForm.get('discountType').value;

    const discountAmount = this.createForm.get('discountAmount') as FormControl;
    const discountPercentage = this.createForm.get('discountPercentage') as FormControl

    switch (discountType) {

      case '1': {
        !update ? discountAmount.setValue('') : '';
        discountAmount.setValidators([Validators.required, , Validators.min(1)]);
        discountAmount.markAsUntouched()
        discountAmount.updateValueAndValidity()

        discountPercentage.setValue('');
        discountPercentage.clearValidators();
        discountPercentage.markAsUntouched()
        discountPercentage.updateValueAndValidity()
      }
        break
      case '2': {
        !update ? discountPercentage.setValue('') : '';
        discountPercentage.setValidators([Validators.required, , Validators.min(1), Validators.max(100)]);
        discountPercentage.markAsUntouched()
        discountPercentage.updateValueAndValidity()

        discountAmount.setValue('');
        discountAmount.clearValidators();
        discountAmount.markAsUntouched()
        discountAmount.updateValueAndValidity()
      }
        break
    }
  }

  limitChange(update?: boolean) {
    const limitCouponUsage = this.createForm.get('limitCouponUsage').value;
    const limit = this.createForm.get('limit') as FormControl
    if (limitCouponUsage) {
      !update ? limit.setValue('') : '';
      limit.setValidators([Validators.required, Validators.min(1)]);
      limit.markAsUntouched();
      limit.updateValueAndValidity()
    } else {
      limit.setValue('');
      limit.clearValidators();
      limit.markAsUntouched();
      limit.updateValueAndValidity()
    }

  }

  markAsTouched(control) {
    this.createForm.get(control).markAsTouched();
    this.createForm.get(control).updateValueAndValidity()
  }


  get isToday() {
    const startDate = moment(this.createForm.get('startDate').value).format('YYYY-MM-DD');
    const today = moment(new Date()).format('YYYY-MM-DD');
    return moment(startDate).isSame(today)
  }

  updateCoupon() {
    const errors: any[] = this.errorMessage.invalidControls(this.createForm);
    const value = this.createForm.value
    if (errors.length == 0) {
      if (this.createForm.invalid) {
        this.toaster.errorToastr(this.createForm.errors.invalidDate ? 'Start date and time should be before end date and time ' : 'Please fill the mandatory fields')
        return
      }
      let data = {
        "couponNewId": this.couponId,
        "couponCode": value?.couponCode,
        "couponEndDate": moment(value?.endDate).format(`YYYY-MM-DD HH:mm:ss`),
        "couponLimit": value?.limitCouponUsage == true ? value?.limit : null,
        "couponName": value?.couponName ?? '',
        "couponStartDate": moment(value?.startDate).format(`YYYY-MM-DD HH:mm:ss`),
        "couponTypeId": value?.couponType,
        "customerEligibility": value?.usageFor == 2 ? true : false,
        "customerId": Number(value?.customerName[0]?.value) ?? '',
        "customerLimit": value?.limitCustomerUsage,
        "description": value?.description,
        "discountAmount": value?.discountType == 1 ? value?.discountAmount : value?.discountPercentage,
        "discountTypeId": value?.discountType == 1 ? true : false,
        "maximumSpend": value?.maximumAmount ?? 0,
        "minimumSpend": value?.minimumAmount ?? 0,
        "productId": (value?.productName[0]?.value) ?? '',
        "remainderDays": 0,
        "showToUser": value?.showToUser
      }
      this.loader.open();
      this.service.updateCoupon(data).subscribe(async (res: any) => {
        await this.loader.close();
        await this.toaster.successToastr('Couponcode updated successfully');
        await this.router.navigate([`/promocode-general`])

      }, (err) => {
        this.loader.close();
        this.toaster.errorToastr(err?.message ?? 'Try again');

      })
    } else {
      this.errorMessage.throwErrorMessage(this.createForm, errors, couponLabels)
    }
  }

}
